import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid, TextField, Button, Typography, Autocomplete } from '@mui/material'
import { createCommitment, getAssistants } from '../../services/commitment'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { Signature } from './Signature'
import { Followup } from './Followup'

export const Create = ({ setLoad, employed, close }) => {

  const { register, handleSubmit, control, formState: { errors } } = useForm()

  const [collaborators, setCollaborators] = useState([])
  const [followups, setFollowups] = useState([0])

  useEffect(() => {
    getAssistants()
      .then(data => setCollaborators(data.map(item => ({
        label: item.nombre,
        value: item.id
      }))))
      .catch(error => console.error(error))
  }, [])

  const onSubmit = (values) => {
    values.empleadoId = employed.id
    createCommitment(values)
      .then(() => {
        setLoad(true)
        close()
        SucessModal()
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Error al guardar compormiso', error)
      })
  }

  return (<Grid >
    <Typography color='primary' variant='h5' marginBottom={1}>
      Agregar Compormiso para:
    </Typography>
    <Typography color='primary' variant='h6' marginBottom={1}>
      {employed.nombre}
    </Typography>
    <Grid container spacing={1}>

      <Grid item xs={12} md={6} >
        <TextField sx={{ width: '80%' }}
          label='Motivo *'
          {...register('motivo', { required: 'Este campo es obligatorio!' })}
          helperText={errors.motivo?.message}
          error={errors.motivo}
        />
      </Grid>

      <Grid item xs={12} md={6} >
        <TextField sx={{ width: '80%' }}
          label='Fecha inicio*'
          type='date'
          {...register('fechaInicio', { required: 'Este campo es obligatorio!' })}
          helperText={errors.fechaInicio?.message}
          error={errors.fechaInicio}
        />
      </Grid>

      <Grid item xs={12} >
        <TextField sx={{ width: { xs: '80%', md: '90%' } }}
          label='Analisis *'
          multiline
          rows={5}
          {...register('analisis', { required: 'Este campo es obligatorio!' })}
          helperText={errors.analisis?.message}
          error={errors.analisis}
        />
      </Grid>

      {/* <Grid item xs={12} md={6} >
        <TextField sx={{ width: '80%'}}
          label='Fecha termino*'
          type='date'
          {...register('fechaFin', { required: 'Este campo es obligatorio!' } )}
          helperText={errors.fechaFin?.message}
          error={errors.fechaFin}
        />
      </Grid> */}

      <Grid item xs={12} >
        {followups.map(id =>
          <Followup
            key={id}
            collaborators={collaborators}
            previous={`seguimientos.${id}`}
            register={register}
            control={control}
            errors={errors?.seguimientos ? errors.seguimientos[id] : {}}
          />)}
      </Grid>

      <Grid item xs={12} md={6} >
        <Controller
          name='asistentes'
          rules={{ required: 'Este campo es obligatorio!' }}
          control={control}
          render={({ field: { onChange, name } }) =>
            <Autocomplete
              disablePortal
              multiple
              options={collaborators}
              onChange={(_, data) => onChange(data)}
              renderInput={(params) =>
                <TextField sx={{ width: '80%' }}
                  {...params}
                  label='Asistentes *'
                  helperText={errors[name]?.message}
                  error={errors[name]}
                />
              }
            />
          }
        />
      </Grid>

      <Grid item xs={12} md={6} >
        <Grid
          container
          sx={{ width: '100%', height: '100%' }}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          alignContent="space-evenly"
          wrap="wrap"
        >
          <Grid item xs={12} >
            <Button
              color='success'
              onClick={() => setFollowups(s => [...s, s.length])}
            >Agregar compromiso</Button>
          </Grid>
          <Grid item xs={12} >
            <Button
              color='error'
              onClick={(e) => setFollowups(s => { s.pop(); return [...s] })}
            >Quitar compromiso</Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} >
        <TextField
          sx={{ width: '80%' }}
          type='signature'
          label='Firma del colaborador *'
          InputProps={{
            sx: { overflow: 'hidden', cursor: 'pointer' },
            inputComponent: Signature
          }}
          {...register('signature', { required: 'Este campo es obligatorio!' })}
          helperText={errors.signature?.message}
          error={errors.signature}
        />
      </Grid>

      <Grid item xs={12} md={6} >
        <TextField
          sx={{ width: '80%' }}
          type='signature'
          label='Firma del líder *'
          InputProps={{
            sx: { overflow: 'hidden', cursor: 'pointer' },
            inputComponent: Signature
          }}
          {...register('signatureLeader', { required: 'Este campo es obligatorio!' })}
          helperText={errors.signatureLeader?.message}
          error={errors.signatureLeader}
        />
      </Grid>

    </Grid>

    <Grid container justifyContent='space-evenly' padding='1rem 9%' >
      <Button color='success' onClick={handleSubmit(onSubmit)} >Enviar</Button>
      <Button color='error' onClick={close}>Cancelar</Button>
    </Grid>

  </Grid>)
}

export default Create
