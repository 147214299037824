import React from "react"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../../context/DirectoryContex"
import { AlertError } from "../AlertsModal/Alerts"

export const ProtectedRoute = ({ children, security }) => {
    if (!security) security = 5

    const navigate = useNavigate()
    const { user } = useAppContext()

    if(!user) return <></>

    if (security < user.authorization) {
        AlertError('Ruta no autorizada', 'Autorización')
        navigate('/')
        return <></>
    } return <>{children}</>
}
