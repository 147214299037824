import { useEffect,useState,useMemo } from "react";
import { TitleFirts } from "../ComponentsUtilies/Titles"
import {GetAllAdminPep} from "../../services/adminPepService"
import MaterialReactTable from "material-react-table"
import { Button } from "@mui/material";
import {ModalCreateAdminPep} from "./CrearAdminPep"
import {UpdateAdminPepState} from "./UpdateState"
import {ModalEditAdminPep} from "./EditAdminPep"

export const ListAdminPep=()=>{
    const [adminpep, setAdminPep]=useState()
    const [load,setLoad]=useState(false) 
    useEffect(()=>{
        GetAllAdminPep().then(data=>setAdminPep(data)).catch(error=>console.log(error))
        setLoad(false)
    },[load])

    const columns = useMemo(
        () => [
            {
                accessorKey: "Id",
                header: "Id"
            },
         
          {
            accessorKey: 'familia', 
            header: 'Familia',
            
          },
          {
            accessorKey: 'linea', 
            header: 'Linea',
            
          },
          {
            accessorKey: 'sublinea', 
            header: 'Sublinea',
            
          }, 
          {
            accessorKey: 'pep', 
            header: 'Pep',
            
          },
          {
            accessorKey: 'estado', 
            header: 'Estado',
            Cell : ({cell})=>cell.getValue() == 1 ? <Button variant="text" color="success">Activo</Button> : <Button variant="text" color="error">Inactivo</Button>
          },
          {
            accessorFn: (row) => row.estado ?  <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 108, marginBottom:1}} onClick={()=>ModalEditAdminPep(setLoad,row.Id)}>Editar </Button>
            <Button variant="contained" color="error" sx={{ marginLeft: 1 }}  onClick={()=>UpdateAdminPepState(row.Id, setLoad)}  >Inactivar</Button> 
            </>  : <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 90, marginBottom:1}} onClick={()=>ModalEditAdminPep(setLoad,row.Id)}>Editar </Button>
             <Button variant="contained" color="success" sx={{ marginLeft: 1 }} onClick={()=>UpdateAdminPepState(row.Id, setLoad)}  >Activar</Button></>
                
                      , //alternate way
            header: 'Acciones',
           
           
           
            
          }
        
          
        ],
        [],
      );
 
  return(
    <>
      <TitleFirts>Administración de peps</TitleFirts>
      <Button variant="contained" color="success" sx={{ width: 196,
      height: 35,
      marginBottom:1}} onClick={()=>ModalCreateAdminPep(setLoad)}>Crear</Button>
      {
        adminpep != null ? 
      <MaterialReactTable 
       columns={columns}
       data={adminpep}
      /> : <MaterialReactTable 
      columns={columns}
      data={0}
     />
         }
    </>
  )  
}