import React from 'react'
import { CardMedia } from '@mui/material'
import iconLogout from './iconLogout.svg'

export const IconLogout = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconLogout}
    />)
}
export default IconLogout 