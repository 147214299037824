import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button, Autocomplete } from "@mui/material"
import { useForm } from "react-hook-form"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"
import { UpdateMargenPep, GetMargenPep } from "../../services/margenesPep"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useState, useEffect } from "react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
const Alert = withReactContent(Swal)

const AlertModalEditFormMargenPep = ({ load, id }) => {
  const [margen, setMargenPep] = useState('')
  useEffect(() => {
    GetMargenPep(id).then(data => setMargenPep(data)).catch(error => console.log(error))
  }, [])

  const schemaValidation = yup.object().shape({
    nombre: yup.string().required(),
    descripcion: yup.number().required()
  })
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const onSubmit = (valores) => {
    //const idCategory=categoria.IdCategoria
    const envio = {
      "pep": valores.pep,
      "ingreso": valores.ingreso,
      "costo": valores.costo,
      "fecha": valores.fecha
    }


    console.log("oee", envio)
    UpdateMargenPep(envio, id).then(r => {
      console.log(r)
      if (r.status != 500) {
        AlertSuccess()
        load(true)

      }
    }).catch(error => console.log(error))


    reset()

  }

  return (
    <>
      {margen != null ? <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
          <TitleFirts>Editar margen pep</TitleFirts>
          <Grid container spacing={1}>
            <Grid item sm={12}>
            <Autocomplete
                sx={{ marginTop: 2, width: 850, marginLeft: 3.2 }}
                disablePortal
                id="combo-box-demo"
                options={[{ label: "pep-prueba1", value: "pep-prueba1" }]}
                renderInput={(params) =>
              <TextField id="outlined-basic" label="Pep" {...params}
                {...register("pep", { required: true })}
                variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }}
                helperText={(errors.pep?.type === 'required' && "Este campo es obligatorio!")}

                InputLabelProps={{ shrink: true }}
                value={margen.pep}
                onChange={(e) => setMargenPep({ pep: e.target.value })}
                error={
                  (errors.pep?.type === 'required')
                  && true
                }  
              />
              }
              />
            </Grid>

            <Grid item sm={12}>
              <TextField id="outlined-basic"
                label="Ingreso"
                {...register("ingreso", { required: true })}
                variant="outlined"
                sx={{ marginTop: 2, width: 850, marginLeft: -2 }}

                InputLabelProps={{ shrink: true }}
                value={margen.ingreso}
                onChange={(e) => setMargenPep({ ingreso: e.target.value })}
                helperText={(errors.ingreso?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.ingreso?.type === 'required')
                  && true
                } />

            </Grid>


            <Grid item sm={12}>
              <TextField id="outlined-basic"
                label="Costo"
                {...register("costo", { required: true })}
                variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }}

                InputLabelProps={{ shrink: true }}
                value={margen.costo}
                onChange={(e) => setMargenPep({ costo: e.target.value })}
                helperText={(errors.costo?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.costo?.type === 'required')
                  && true
                } />

            </Grid>



            <Grid item sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Fecha"
                  inputFormat="MM/DD/YYYY"
                  value={margen.fecha}
               
                  renderInput={(params) => <TextField {...params} 
                  id="outlined-basic" 
                  label="fecha"
                  InputLabelProps={{ shrink: true }}
                  value={margen.fecha}
                  onChange={(e)=>setMargenPep({fecha: e.target.value})}
                   {...register("fecha", { required: true })} 
                   variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} 
                   helperText={(errors.fecha?.type === 'required' && "Este campo es obligatorio!")}
                    error={
                      (errors.fecha?.type === 'required')
                      && true
                    } />}
                />


              </LocalizationProvider>
            </Grid>




          </Grid>




        </Grid>
        <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
        <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
      </form> : null}

    </>
  )
}

export const ModalEditMargenPep = (load, id) => {
  console.log("idd", id)
  Alert.fire({
    width: "970px",
    showConfirmButton: false,
    showCancelButton: false,
    position: "top",
    html: <AlertModalEditFormMargenPep load={load} id={id} />
  })
}