import {useEffect, useState} from 'react'
import {ErrorModal} from '../ComponentsUtilies/AlertsModal/Alerts'
import {ManagementsAll} from '../../services/managementsService'
import {HeadshipsAll} from '../../services/headshipsService'
import {ProcessAll} from '../../services/processService'

export const useOptions = () => {
  const [options, setOptions] = useState({
    managements: [],
    headships: [],
    processes: []
  })
  useEffect(() => {
    Promise.all([ManagementsAll(), HeadshipsAll(), ProcessAll()])
      .then((data) => {
        setOptions({
          managements: data[0].map((d) => ({label: d.nombreDireccion, value: d.id})),
          headships: data[1].map((j) => ({label: j.nombreJefatura, value: j.id})),
          processes: data[2].map((p) => ({label: p.nombre, value: p.id}))
        })
      })
      .catch((error) => {
        console.error(error)
        ErrorModal('NO se optubieron las jefaturas', error)
      })
  }, [])
  return options
}

export default useOptions
