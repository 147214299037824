import { useState, useEffect } from "react"
import { getCheckList } from '../../../services/documents'

export const useChecks = (processId) => {

    const [checks, setChecks] = useState([])
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        
        if (!load || !processId || isLoading){
            setLoad(false)
            return
        }        
        setLoad(false)
        setIsLoading(true)
        getCheckList(processId)
            .then((data) => setChecks(data))
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false))

    }, [load])
    return { checks, setLoad, isLoading }
}