import axios from "axios";
import {UpdateStatedirectors} from "../../services/directorsService"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"

export const UpdateDirectorsState=(id,setLoad,setIsLoading)=>{
   setIsLoading(true)
   UpdateStatedirectors(id).then(data=>{
       if(data.idDirector != null ){
         AlertSuccess()
         setLoad(true)
       }
    }).catch(error=>{console.log(error)}).finally(()=>setIsLoading(false))


}