import React from 'react'
import {Link} from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import {ListItemButton, ListItem, Typography} from '@mui/material'
import {useAppContext} from '../../context/DirectoryContex'

export const Item = ({path, text, isOpen, setOpen, Icon, security}) => {
  if (!security) security = 5
  const {user} = useAppContext()
  if (!user || security < user.authorization) return <></>

  return (
    <Tooltip title={text} placement='right'>
      <ListItem sx={{padding: 0}}>
        <Link to={path} style={{textDecoration: 'none'}}>
          <ListItemButton onClick={() => setOpen(false)}>
            {Icon}
            <Typography
              noWrap
              paddingLeft={1}
              fontWeight='400'
              color='white'
              sx={{width: '100%'}}
            >
              {isOpen ? text : ''}
            </Typography>
          </ListItemButton>
        </Link>
      </ListItem>
    </Tooltip>
  )
}
