import React, { useState, useMemo } from 'react'
import {
    Box,
    Typography,
    Grid,
    List,
    ListItem,
    IconButton,
} from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTheme } from '@mui/material'

const getFolderName = (path) => {
    if (path === '/') return '/'
    const parts = path.split('/')
    return parts[parts.length - 1]
}

const Folder = ({ folders, path, setFolder }) => {
    const [expand, setExpand] = useState(false)

    const children = useMemo(() => {
        if (!folders) return []
        return folders.reduce((acum, item, idx) => {
            const folderName = getFolderName(item)
            const replace = item.replace(new RegExp(path === '/' ? folderName : '/' + folderName), '')

            if (replace === path) {
                acum.push(
                    <ListItem key={idx} sx={{ padding: 0 }}>
                        <Folder
                            folders={folders}
                            path={item}
                            setFolder={setFolder}
                        />
                    </ListItem>
                )
            }
            return acum
        }, [])
    }, [folders])

    return (
        <Box >
            <Box onClick={() => {
                setExpand(s => !s)
                setFolder(path)
            }}>
                {path !== '/' &&
                    <Typography >
                        <IconButton sx={{ padding: 0 }}>
                            {expand ?
                                <ArrowRightIcon color='secondary' />
                                : <ArrowDropDownIcon color='secondary' />
                            }
                        </IconButton>
                        {getFolderName(path)}
                    </Typography>
                }
            </Box>
            {(expand ? true : path === '/') &&
                <List sx={{ paddingLeft: 2 }}>
                    {children}
                </List>
            }

        </Box >
    )
}

export const Folders = ({ folders, path, setFolder }) => {
    const { border } = useTheme()
    const [isShown, setIsShown] = useState(false)

    return (
        <Box
            sx={{ position: 'relative' }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <Typography>Carpeta : {path}</Typography>
            {isShown && folders &&
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'white',
                        border: border.container,
                        borderRadius: 1
                    }}
                >
                    <List sx={{ paddingRight: 2 }}>
                        <Folder
                            folders={folders}
                            path={'/'}
                            setFolder={setFolder}
                        />
                    </List>
                </Box>
            }
        </Box >
    )
}