import React, {useState, useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {MuiTelInput} from 'mui-tel-input'
import {Avatar, Grid, TextField, Autocomplete, Button, Alert} from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import {departamentos, ciudades, indicativosFijo} from '../../departamentoCiudades'
import {AlertSuccess, AlertError} from '../ComponentsUtilies/AlertsModal/Alerts'
import {primerLetraMayusculaCadaPalabra} from '../../utils'
import {getOptions, updateEmploye, getEmployee} from '../../services/employeesService'

export const Edit = ({employeeId, suppliers, setLoad, user, close, setOption}) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    control,
    formState: {errors},
    setValue
  } = useForm()

  const tieneMembresia = watch('tieneMembresia')
  const [employee, setEmployee] = useState(null)

  const [positionsOptions, setPositionsOptions] = useState([])
  const [directorsOptions, setDirectorsOptions] = useState([])
  const [auditorsOptions, setAuditorsOptions] = useState([])
  const [processOptions, setProcessOptions] = useState([])
  const [subProcessOptions, setSubProcessOptions] = useState([])
  const [pepOptions, setPepOptions] = useState(null)
  const [leadersOptios, setLeadersOptions] = useState(null)

  const [citiesOptions, setCitiesOptions] = useState([])
  const [resetCityValue, setResetCityValue] = useState('')
  const [fileValidate, setFileValidate] = useState(false)
  const [personImage, setpersonImage] = useState('')
  const [imageEnvio, setImagenEnvio] = useState(0)
  const [cargoValue, setCargoValue] = useState('')
  const [interventorValue, setInterventorValue] = useState('')
  const [directorValue, setDirectorValue] = useState('')
  const [departamentoValue, setDepartamentoValue] = useState('')
  const [teletrabajoValue, setTeletrabajoValue] = useState('')
  const [sexoValue, setSexoValue] = useState('')
  const [RHValue, setRHValue] = useState('')
  const [EPSValue, setEPSValue] = useState('')
  const [nivelAcademicoValue, setNivelAcademicoValue] = useState('')
  const [estadoCivilValue, setEstadoCivilValue] = useState('')
  const [tieneHijosValue, setTieneHijosValue] = useState('')
  const [indicadorFijoValue, setIndicadorFijoValue] = useState('')
  const [phone, setPhone] = useState('57')
  const [phoneContacto, setPhoneContacto] = useState('57')
  const [labelfijo, setLabelfijo] = useState(['Indicador teléfono fijo', 'Teléfono fijo'])
  const [fijoRequerido, setFijoRequerido] = useState(false)
  const [tieneCarnetSnacksValue, setTieneCarnetSnacksValue] = useState('')
  const [tieneCarnetNutresaValue, setTieneCarnetNutresaValue] = useState('')
  const [procesoValue, setProcesoValue] = useState([])
  const [subProcesoValue, setSubProcesoValue] = useState([])
  const [rolValue, setRolValue] = useState([])
  const [valCarnetSnacksAxity, setCarnetSnacksAxity] = useState([
    false,
    'Número carnet snacks Axity'
  ])
  const [valCarnetNutresa, setValCarnetNutresa] = useState([
    false,
    'Número carnet Nutresa'
  ])

  useEffect(() => {
    Promise.all([
      getEmployee(employeeId).then((de) => {
        setEmployee(de)
        return de
      }),
      getOptions(user.supplier || '')
    ])
      .then(([dataEmployee, parameters]) => {
        setOptionsSelect(dataEmployee, parameters)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const setOptionsSelect = (
    dataEmployee,
    [positions, directors, auditors, process, subProcess, peps, leaders]
  ) => {
    setPositionsOptions(positions)
    setDirectorsOptions(directors)
    setAuditorsOptions(auditors)
    setProcessOptions(process)
    setSubProcessOptions(subProcess)
    setPepOptions(peps)
    setLeadersOptions(leaders)

    setValue('tieneMembresia', Boolean(dataEmployee.serialMembresia))
    setValue('serialMembresia', dataEmployee.serialMembresia || null)
    setValue('lider', dataEmployee.lider)
    setValue('proveedorUUID', dataEmployee.proveedorUUID)
    setValue(
      'pep',
      dataEmployee.administracionPeps.map((pep) => ({
        value: pep.Id,
        label: pep.pep
      }))
    )

    setCargoValue(
      positions.find((element) => element.value === dataEmployee.cargoIdCargo)
    )
    setValue(
      'cargoIdCargo',
      positions.find((element) => element.value === dataEmployee.cargoIdCargo)
    )
    setInterventorValue(
      auditors.find((element) => element.value === dataEmployee.interventoreIdInterventor)
        ?.label
        ? auditors.find(
            (element) => element.value === dataEmployee.interventoreIdInterventor
          )
        : ''
    )
    setValue(
      'interventoreIdInterventor',
      auditors.find((element) => element.value === dataEmployee.interventoreIdInterventor)
        ?.label
        ? auditors.find(
            (element) => element.value === dataEmployee.interventoreIdInterventor
          )
        : ''
    )
    setDirectorValue(
      directors.find((element) => element.value === dataEmployee.directoreIdDirector)
        ?.label
        ? directors.find((element) => element.value === dataEmployee.directoreIdDirector)
        : ''
    )
    setValue(
      'directoreIdDirector',
      directors.find((element) => element.value === dataEmployee.directoreIdDirector)
        ?.label
        ? directors.find((element) => element.value === dataEmployee.directoreIdDirector)
        : ''
    )

    if (dataEmployee?.teletrabajo === false || dataEmployee?.teletrabajo === true) {
      setTeletrabajoValue({
        label: dataEmployee.teletrabajo ? 'SI' : 'NO',
        value: dataEmployee.teletrabajo ? 'SI' : 'NO'
      })
      setValue('teletrabajo', {
        label: dataEmployee.teletrabajo ? 'SI' : 'NO',
        value: dataEmployee.teletrabajo ? 'SI' : 'NO'
      })
    } else {
      setTeletrabajoValue('')
    }

    setDepartamentoValue(
      departamentos.find((element) => element.value === dataEmployee.departamento)?.label
        ? departamentos.find((element) => element.value === dataEmployee.departamento)
        : ''
    )
    setValue(
      'departamento',
      departamentos.find((element) => element.value === dataEmployee.departamento)?.label
        ? departamentos.find((element) => element.value === dataEmployee.departamento)
        : ''
    )

    handleChange(
      '',
      departamentos.find((element) => element.value === dataEmployee.departamento)
        ? departamentos.find((element) => element.value === dataEmployee.departamento)
        : ''
    )
    setResetCityValue({
      label: `${
        ciudades.find((element) => element.codCiudad === dataEmployee.ciudad).codCiudad
      } - ${
        ciudades.find((element) => element.codCiudad === dataEmployee.ciudad).ciudad
      }`,
      value: ciudades.find((element) => element.codCiudad === dataEmployee.ciudad)
        .codCiudad
    })
    setValue('ciudad', {
      label: `${
        ciudades.find((element) => element.codCiudad === dataEmployee.ciudad).codCiudad
      } - ${
        ciudades.find((element) => element.codCiudad === dataEmployee.ciudad).ciudad
      }`,
      value: ciudades.find((element) => element.codCiudad === dataEmployee.ciudad)
        .codCiudad
    })

    if (dataEmployee?.sexo) {
      setSexoValue({
        label: dataEmployee.sexo,
        value: dataEmployee.sexo
      })
      setValue('sexo', {
        label: dataEmployee.sexo,
        value: dataEmployee.sexo
      })
    } else {
      setSexoValue('')
    }

    if (dataEmployee?.rh) {
      setRHValue({
        label: dataEmployee.rh,
        value: dataEmployee.rh
      })
      setValue('rh', {
        label: dataEmployee.rh,
        value: dataEmployee.rh
      })
    } else {
      setRHValue('')
    }

    if (dataEmployee?.eps) {
      setEPSValue({
        label: dataEmployee.eps,
        value: dataEmployee.eps
      })
      setValue('eps', {
        label: dataEmployee.eps,
        value: dataEmployee.eps
      })
    } else {
      setEPSValue('')
    }

    if (dataEmployee?.nivelAcademico) {
      setNivelAcademicoValue({
        label: dataEmployee.nivelAcademico,
        value: dataEmployee.nivelAcademico
      })
      setValue('nivelAcademico', {
        label: dataEmployee.nivelAcademico,
        value: dataEmployee.nivelAcademico
      })
    } else {
      setNivelAcademicoValue('')
    }

    if (dataEmployee?.estadoCivil) {
      setEstadoCivilValue({
        label: dataEmployee.estadoCivil,
        value: dataEmployee.estadoCivil
      })
      setValue('estadoCivil', {
        label: dataEmployee.estadoCivil,
        value: dataEmployee.estadoCivil
      })
    } else {
      setEstadoCivilValue('')
    }

    if (dataEmployee?.hijos) {
      setTieneHijosValue({
        label: dataEmployee.hijos,
        value: dataEmployee.hijos
      })
      setValue('hijos', {
        label: dataEmployee.hijos,
        value: dataEmployee.hijos
      })
    } else {
      setTieneHijosValue('')
    }

    setPhone(dataEmployee.celular)
    setValue('celular', dataEmployee.celular)
    setIndicadorFijoValue(
      indicativosFijo.find((element) => element.value === dataEmployee.indicadorTelefono)
        ?.label
        ? indicativosFijo.find(
            (element) => element.value === dataEmployee.indicadorTelefono
          )
        : ''
    )
    setValue(
      'indicadorFijo',
      indicativosFijo.find((element) => element.value === dataEmployee.indicadorTelefono)
        ?.label
        ? indicativosFijo.find(
            (element) => element.value === dataEmployee.indicadorTelefono
          )
        : ''
    )
    setPhoneContacto(dataEmployee.celularContactoEmergencia)
    setValue('celularContactoEmergencia', dataEmployee.celularContactoEmergencia)
    if (dataEmployee?.carnetSnack) {
      setTieneCarnetSnacksValue({
        label: dataEmployee.carnetSnack,
        value: dataEmployee.carnetSnack
      })
      setValue('carnetSnack', {
        label: dataEmployee.carnetSnack,
        value: dataEmployee.carnetSnack
      })
    } else {
      setTieneCarnetSnacksValue('')
    }

    if (dataEmployee?.carnetNutresa) {
      setTieneCarnetNutresaValue({
        label: dataEmployee.carnetNutresa,
        value: dataEmployee.carnetNutresa
      })
      setValue('carnetNutresa', {
        label: dataEmployee.carnetNutresa,
        value: dataEmployee.carnetNutresa
      })
    } else {
      setTieneCarnetNutresaValue('')
    }

    if (dataEmployee.carnetSnack === 'SI') {
      setCarnetSnacksAxity([true, 'Número carnet snacks Axity *'])
      setValue('numeroCarnetSnack', dataEmployee.numeroCarnetSnack)
    }

    if (dataEmployee.carnetNutresa === 'SI') {
      setValCarnetNutresa([true, 'Número carnet Nutresa *'])
      setValue('numeroCarnetNutresa', dataEmployee.numeroCarnetNutresa)
    }

    let rolesValue = []
    dataEmployee.rol.split(',').forEach((element) => {
      if (element === '1') {
        rolesValue.push({label: 'Administrativo', value: '1'})
      } else if (element === '2') {
        rolesValue.push({label: 'Técnico', value: '2'})
      } else if (element === '3') {
        rolesValue.push({label: 'Colaborador', value: '3'})
      }
    })

    setRolValue(rolesValue)
    setValue('rol', rolesValue)
    let procesosValues = []
    dataEmployee.procesos.forEach((element) => {
      procesosValues.push({
        value: element.IdProceso,
        label: element.NombreProceso
      })
    })

    setProcesoValue(procesosValues)

    setValue('procesos', procesosValues)

    let subProcesosValues = []
    dataEmployee.subprocesos.forEach((element) => {
      subProcesosValues.push({
        value: element.IdSubProceso,
        label: element.Nombre
      })
    })

    setSubProcesoValue(subProcesosValues)

    setValue('subprocesos', subProcesosValues)
  }

  const onSubmit = (values) => {
    console.log("values del onsubmit", values.correoAxity, values.correoNutresa)
    const procesoEnvio = values.procesos.map((vd) => vd.value)
    const subProcesoEnvio = values.subprocesos.map((vd) => vd.value)
    values.foto = imageEnvio
    values.email = values.email.toLowerCase()
    values.correoAxity = values.correoAxity.toLowerCase()
    values.correoNutresa=values.correoNutresa.toLowerCase()
    values.eps = values.eps.value
    values.carnetNutresa = values.carnetNutresa.value
    values.carnetSnack = values.carnetSnack.value
    values.interventoreIdInterventor = values.interventoreIdInterventor?.value
      ? values.interventoreIdInterventor?.value
      : 26
    values.hijos = values.hijos.value
    values.indicadorTelefono = values.indicadorFijo?.value
      ? values.indicadorFijo?.value
      : null
    values.indicadorFijo = values.indicadorFijo?.value
    values.telefono = values.telefonoFijo
    values.estadoCivil = values.estadoCivil.value
    values.cargoIdCargo = values.cargoIdCargo.value
    values.ciudad = values.ciudad.value
    values.departamento = values.departamento.value
    values.directoreIdDirector = values.directoreIdDirector?.value
      ? values.directoreIdDirector?.value
      : 9
    values.nivelAcademico = values.nivelAcademico.value
    values.rh = values.rh.value
    values.sexo = values.sexo.value
    values.teletrabajo = values.teletrabajo.value === 'SI' ? 1 : 0
    values.costoEmpleado = values.costoempleado
    values.primerNombre = primerLetraMayusculaCadaPalabra(values.primerNombre)
    values.primerApellido = primerLetraMayusculaCadaPalabra(values.primerApellido)
    values.segundoApellido = primerLetraMayusculaCadaPalabra(values?.segundoApellido)
    values.segundoNombre = primerLetraMayusculaCadaPalabra(values?.segundoNombre)

    values.peps = JSON.stringify(values.pep.map((p) => p.value))
    values.procesos = JSON.stringify(procesoEnvio)
    values.subprocesos = JSON.stringify(subProcesoEnvio)
    let roles = ''

    values.rol.forEach((e) => {
      roles += e.value + ','
    })
    roles = roles.substring(0, roles.length - 1)
    values.rol = roles

    updateEmploye(employee.IdEmpleado, values)
      .then(() => {
        setOption('masivo')
        setLoad(true)
        AlertSuccess()
      })
      .catch((error) => {
        console.error(error)
        AlertError({
          title: 'Oops...',
          html: `Algo fallo si el error persiste comunicate con el administrador!
          <br><br>
          ${error.response.data.message}`
        })
      })
  }

  const uploadImage = (event, value) => {
    let file = event.target.files[0]
    let imageType = /image.*/
    if (!file.type.match(imageType)) {
      setFileValidate(true)
    } else {
      setFileValidate(false)
    }
    let reader = new FileReader()
    reader.onload = fileOnload
    reader.readAsDataURL(file)
    setImagenEnvio(event.target.files[0])
  }

  const fileOnload = (e) => {
    let result = e.target.result
    setpersonImage(result)
  }

  const cambioFijo = (event, data) => {
    const telefonoFijo = document.getElementsByName('telefonoFijo')
    if (telefonoFijo[0].value !== '' || watch('indicadorFijo')) {
      setFijoRequerido(true)
      setLabelfijo(['Indicador teléfono fijo *', 'Teléfono fijo *'])
      if (telefonoFijo[0].value === '') {
        setError('telefonoFijo', {type: 'required'})
      } else if (telefonoFijo[0].value.length !== 7) {
        setError('telefonoFijo', {type: 'minLength'})
      } else if (!/^[0-9]+$/i.test(telefonoFijo[0].value)) {
        setError('telefonoFijo', {type: 'pattern'})
      } else {
        clearErrors('telefonoFijo')
      }
      if (!watch('indicadorFijo')) {
        setError('indicadorFijo', {type: 'required'})
      } else {
        clearErrors('indicadorFijo')
      }
    } else {
      setFijoRequerido(false)
      setLabelfijo(['Indicador teléfono fijo', 'Teléfono fijo'])
      clearErrors('indicadorFijo')
      clearErrors('telefonoFijo')
    }
  }

  const cambioCarnetSnacksAxity = (event, data) => {
    const numeroCarnetSnacksAxity = document.getElementsByName('numeroCarnetSnacksAxity')
    if (data?.value === 'SI') {
      setCarnetSnacksAxity([true, 'Número carnet snacks Axity *'])
      setError('numeroCarnetSnack', {type: 'required'})
    } else {
      setCarnetSnacksAxity([false, 'Número carnet snacks Axity'])
      numeroCarnetSnacksAxity[0].value = ''
      setValue('numeroCarnetSnack', '')
      clearErrors('numeroCarnetSnack')
    }
  }

  const cambioCarnetNutresa = (event, data) => {
    const numeroCarnetNutresa = document.getElementsByName('numeroCarnetNutresa')
    if (data?.value === 'SI') {
      setValCarnetNutresa([true, 'Número carnet Nutresa *'])
      setError('numeroCarnetNutresa', {type: 'required'})
    } else {
      setValCarnetNutresa([false, 'Número carnet Nutresa'])
      numeroCarnetNutresa[0].value = ''
      setValue('numeroCarnetNutresa', '')
      clearErrors('numeroCarnetNutresa')
    }
  }

  const handleChange = (event, value) => {
    setResetCityValue('')
    let filteredCities = []
    let filteredCitiesOptions = []
    filteredCities = ciudades.filter(
      (element) => element.codDepartamento === value?.value
    )
    if (filteredCities) {
      filteredCities.forEach((element) => {
        filteredCitiesOptions.push({
          label: `${element.codCiudad} - ${element.ciudad}`,
          value: element.codCiudad
        })
      })
    }
    setCitiesOptions(filteredCitiesOptions)
  }

  if (!employee) return <>Cargando empleado...</>
  if (!leadersOptios) return <>Cargando lideres...</>
  if (!pepOptions) return <>Cargando peps...</>

  return (
    <Grid container spacing={5} mt={5}>
      <Grid item xs={12}>
        <Avatar
          sx={{marginTop: -4, width: 150, marginLeft: 4, height: 150}}
          alt='Persona'
          src={employee.foto}
        />
      </Grid>
      {user.authorization === 1 && (
        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='proveedorUUID'
            rules={{required: 'Este campo es obligatorio!'}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={suppliers}
                onChange={(_, data) => onChange(data.value || '')}
                defaultValue={suppliers.find((s) => s.value === employee.proveedorUUID)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Proveedor *'
                    helperText={errors.proveedorUUID?.message}
                    error={errors.proveedorUUID}
                  />
                )}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('primerNombre', {required: true, pattern: /^[A-Za-z| ]+$/i})}
          id='standard-basic'
          label='Primer nombre *'
          defaultValue={employee.primerNombre}
          helperText={
            (errors.primerNombre?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.primerNombre?.type === 'required' &&
              'En este campo son solo permitidas letras!')
          }
          error={errors.primerNombre}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          id='standard-basic'
          {...register('segundoNombre', {pattern: /^[A-Za-z| ]+$/i})}
          label='Segundo nombre'
          defaultValue={employee.segundoNombre}
          helperText={
            errors.segundoNombre?.type === 'pattern' &&
            'En este campo son solo permitidas letras!'
          }
          error={errors.segundoNombre}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('primerApellido', {required: true, pattern: /^[A-Za-z| ]+$/i})}
          id='standard-basic'
          label='Primer apellido *'
          defaultValue={employee.primerApellido}
          helperText={
            (errors.primerApellido?.type === 'required' &&
              'Este campo es obligatorio!') ||
            (errors.primerApellido?.type === 'pattern' &&
              'En este campo son solo permitidas letras!')
          }
          error={errors.primerApellido}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('segundoApellido', {pattern: /^[A-Za-z| ]+$/i})}
          id='standard-basic'
          label='Segundo apellido'
          defaultValue={employee.segundoApellido}
          helperText={
            errors.segundoApellido?.type === 'pattern' &&
            'En este campo son solo permitidas letras!'
          }
          error={errors.segundoApellido}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('cedula', {required: true, pattern: /^[0-9]+$/i})}
          id='standard-basic'
          label='Cedula *'
          defaultValue={employee.cedula}
          helperText={
            (errors.cedula?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.cedula?.type === 'pattern' &&
              'En este campo son solo permitidos caracteres numericos!')
          }
          error={errors.cedula}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          type='date'
          {...register('fechaNacimiento', {required: true})}
          label='Fecha nacimiento *'
          defaultValue={employee.fechaNacimiento.substring(0, 10)}
          helperText={
            errors.fechaNacimiento?.type === 'required' && 'Este campo es obligatorio!'
          }
          error={errors.fechaNacimiento}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='sexo'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'FEMENINO', value: 'FEMENINO'},
                {label: 'MASCULINO', value: 'MASCULINO'}
              ]}
              value={sexoValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Sexo *'
                  helperText={
                    errors.sexo?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.sexo}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setSexoValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='rh'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'AB+', value: 'AB+'},
                {label: 'AB-', value: 'AB-'},
                {label: 'A+', value: 'A+'},
                {label: 'A-', value: 'A-'},
                {label: 'B+', value: 'B+'},
                {label: 'B-', value: 'B-'},
                {label: 'O+', value: 'O+'},
                {label: 'O-', value: 'O-'}
              ]}
              value={RHValue}
              isOptionEqualToValue={(option, value) =>
                option.label === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='RH *'
                  helperText={
                    errors.rh?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.rh}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setRHValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='eps'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'COOSALUD EPS-S', value: 'COOSALUD EPS-S'},
                {label: 'NUEVA EPS', value: 'NUEVA EPS'},
                {label: 'MUTUAL SER', value: 'MUTUAL SER'},
                {label: 'ALIANSALUD EPS', value: 'ALIANSALUD EPS'},
                {label: 'SALUD TOTAL EPS S.A.', value: 'SALUD TOTAL EPS S.A.'},
                {label: 'EPS SANITAS', value: 'EPS SANITAS'},
                {label: 'EPS SURA', value: 'EPS SURA'},
                {label: 'FAMISANAR', value: 'FAMISANAR'},
                {
                  label: 'SERVICIO OCCIDENTAL DE SALUD EPS SOS',
                  value: 'SERVICIO OCCIDENTAL DE SALUD EPS SOS'
                },
                {label: 'SALUD MIA', value: 'SALUD MIA'},
                {label: 'COMFENALCO VALLE', value: 'COMFENALCO VALLE'},
                {label: 'COMPENSAR EPS', value: 'COMPENSAR EPS'},
                {
                  label: 'EPM - EMPRESAS PUBLICAS DE MEDELLIN',
                  value: 'EPM - EMPRESAS PUBLICAS DE MEDELLIN'
                },
                {
                  label: 'FONDO DE PASIVO SOCIAL DE FERROCARRILES NACIONALES DE COLOMBIA',
                  value: 'FONDO DE PASIVO SOCIAL DE FERROCARRILES NACIONALES DE COLOMBIA'
                },
                {label: 'CAJACOPI ATLANTICO', value: 'CAJACOPI ATLANTICO'},
                {label: 'CAPRESOCA', value: 'CAPRESOCA'},
                {label: 'COMFACHOCO', value: 'COMFACHOCO'},
                {
                  label: 'COMFAMILIAR DE LA GUAJIRA',
                  value: 'COMFAMILIAR DE LA GUAJIRA'
                },
                {label: 'COMFAORIENTE', value: 'COMFAORIENTE'},
                {
                  label: 'EPS FAMILIAR DE COLOMBIA (Antes ComfaSucre)',
                  value: 'EPS FAMILIAR DE COLOMBIA (Antes ComfaSucre)'
                },
                {label: 'ASMET SALUD', value: 'ASMET SALUD'},
                {label: 'ECOOPSOS ESS EPS-S', value: 'ECOOPSOS ESS EPS-S'},
                {label: 'EMSSANAR E.S.S.', value: 'EMSSANAR E.S.S.'},
                {label: 'CAPITAL SALUD EPS-S', value: 'CAPITAL SALUD EPS-S'},
                {label: 'SAVIA SALUD EPS', value: 'SAVIA SALUD EPS'},
                {label: 'DUSAKAWI EPSI', value: 'DUSAKAWI EPSI'},
                {
                  label: 'ASOCIACION INDIGENA DEL CAUCA EPSI',
                  value: 'ASOCIACION INDIGENA DEL CAUCA EPSI'
                },
                {label: 'ANAS WAYUU EPSI', value: 'ANAS WAYUU EPSI'},
                {label: 'MALLAMAS EPSI', value: 'MALLAMAS EPSI'},
                {label: 'PIJAOS SALUD EPSI', value: 'PIJAOS SALUD EPSI'}
              ]}
              value={EPSValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='EPS *'
                  helperText={
                    errors.eps?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.eps}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setEPSValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='nivelAcademico'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'PREESCOLAR', value: 'PREESCOLAR'},
                {label: 'PRIMARIA', value: 'PRIMARIA'},
                {label: 'BACHILLERATO', value: 'BACHILLERATO'},
                {label: 'TECNICA', value: 'TECNICA'},
                {label: 'TECNOLOGIA', value: 'TECNOLOGIA'},
                {label: 'UNIVERSITARIA', value: 'UNIVERSITARIA'},
                {label: 'ESPECIALIZACION', value: 'ESPECIALIZACION'},
                {label: 'POSTGRADO', value: 'POSTGRADO'},
                {label: 'MAESTRIA', value: 'MAESTRIA'},
                {label: 'DOCTORADO', value: 'DOCTORADO'}
              ]}
              value={nivelAcademicoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Nivel académico *'
                  helperText={
                    errors.nivelAcademico?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.nivelAcademico}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setNivelAcademicoValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('tituloObtenido')}
          label='Titulo obtenido'
          defaultValue={employee.tituloObtenido}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='celular'
          rules={{
            required: true,
            pattern: /^\+57[0-9]+$/i,
            maxLength: 13,
            minLength: 12
          }}
          control={control}
          render={({field: {onChange}}) => (
            <MuiTelInput
              label='Celular *'
              defaultCountry='co'
              value={phone}
              helperText={
                (errors.celular?.type === 'required' && 'Este campo es obligatorio!') ||
                (errors.celular?.type === 'pattern' &&
                  'En este campo son solo permitidos caracteres numericos!') ||
                (errors.celular?.type === 'maxLength' &&
                  'Un celular debe tener 10 caracteres numericos!') ||
                (errors.celular?.type === 'minLength' &&
                  'Un celular debe tener 10 caracteres numericos!')
              }
              error={errors.celular}
              onChange={(_, data) => {
                setPhone(data.numberValue === null ? '57' : data.numberValue)
                onChange(data.numberValue === null ? '57' : data.numberValue)
                return data
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='indicadorFijo'
          rules={{required: fijoRequerido}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={indicativosFijo}
              value={indicadorFijoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={labelfijo[0]}
                  helperText={
                    errors.indicadorFijo?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.indicadorFijo}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                cambioFijo(_, data)
                setIndicadorFijoValue(data)
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('telefonoFijo', {
            required: fijoRequerido,
            pattern: /^[0-9]+$/i,
            maxLength: 7,
            minLength: 7
          })}
          label={labelfijo[1]}
          onChange={(event) => cambioFijo(event)}
          defaultValue={employee.telefono}
          helperText={
            (errors.telefonoFijo?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.telefonoFijo?.type === 'pattern' &&
              'En este campo son solo permitidos caracteres numericos!') ||
            (errors.telefonoFijo?.type === 'maxLength' &&
              'Un teléfono fijo debe tener 7 caracteres numericos!') ||
            (errors.telefonoFijo?.type === 'minLength' &&
              'Un teléfono fijo debe tener 7 caracteres numericos!')
          }
          error={errors.telefonoFijo}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='estadoCivil'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'Soltero(a)', value: 'Soltero(a)'},
                {label: 'Casado(a)', value: 'Casado(a)'},
                {
                  label: 'Unión libre o unión de hecho',
                  value: 'Unión libre o unión de hecho'
                }
              ]}
              value={estadoCivilValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Estado civil *'
                  helperText={
                    errors.estadoCivil?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.estadoCivil}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setEstadoCivilValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='hijos'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'SI', value: 'SI'},
                {label: 'NO', value: 'NO'}
              ]}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              value={tieneHijosValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='¿Tiene hijos? *'
                  helperText={
                    errors.hijos?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.hijos}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setTieneHijosValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('nombreContactoEmergencia', {
            required: true,
            pattern: /^[A-Za-z ]+$/i
          })}
          label='Nombres y apellidos del contacto de emergencia *'
          defaultValue={employee.nombreContactoEmergencia}
          helperText={
            (errors.nombreContactoEmergencia?.type === 'required' &&
              'Este campo es obligatorio!') ||
            (errors.nombreContactoEmergencia?.type === 'pattern' &&
              'En este campo son solo permitidas letras!')
          }
          error={errors.nombreContactoEmergencia}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='celularContactoEmergencia'
          rules={{
            required: true,
            pattern: /^\+57[0-9]+$/i,
            maxLength: 13,
            minLength: 12
          }}
          control={control}
          render={({field: {onChange}}) => (
            <MuiTelInput
              name='celularContactoEmergencia'
              label='Celular de Contacto de emergencia *'
              defaultCountry='co'
              value={phoneContacto}
              helperText={
                (errors.celularContactoEmergencia?.type === 'required' &&
                  'Este campo es obligatorio!') ||
                (errors.celularContactoEmergencia?.type === 'pattern' &&
                  'En este campo son solo permitidos caracteres numericos!') ||
                (errors.celularContactoEmergencia?.type === 'maxLength' &&
                  'Un celular debe tener 10 caracteres numericos!') ||
                (errors.celularContactoEmergencia?.type === 'minLength' &&
                  'Un celular debe tener 10 caracteres numericos!')
              }
              error={errors.celularContactoEmergencia}
              onChange={(_, data) => {
                setPhoneContacto(data.numberValue === null ? '57' : data.numberValue)
                onChange(data.numberValue === null ? '57' : data.numberValue)
                return data
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          type='date'
          defaultValue={employee.fechaIngreso}
          {...register('fechaIngreso', {required: 'Este campo es obligatorio!'})}
          label='Fecha Ingreso *'
          helperText={errors?.fechaIngreso?.message}
          error={Boolean(errors?.fechaIngreso)}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          type='date'
          defaultValue={employee.fechaEgreso}
          disabled={employee.estado}
          {...register('fechaEgreso', {
            required: employee.estado ? false : 'Este campo es obligatorio!'
          })}
          InputProps={{
            startAdornment: <CalendarMonthOutlinedIcon />
          }}
          label='Fecha Egreso *'
          helperText={errors.fechaEgreso?.message}
          error={Boolean(errors.fechaEgreso)}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='procesos'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              multiple
              options={processOptions}
              value={procesoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Procesos'
                  helperText={
                    errors.procesos?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.procesos}
                />
              )}
              onChange={(_, data) => {
                setProcesoValue(data)
                onChange(data)
                return data.value
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='subprocesos'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              multiple
              options={subProcessOptions}
              value={subProcesoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Sub-Proceso'
                  helperText={
                    errors.subprocesos?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.subprocesos}
                />
              )}
              onChange={(_, data) => {
                setSubProcesoValue(data)
                onChange(data)
                return data.value
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='cargoIdCargo'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={positionsOptions}
              value={cargoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Cargo'
                  helperText={
                    errors.cargo?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.cargo}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setCargoValue(
                  positionsOptions.find(
                    (element) => element.value === (data?.value ? data?.value : '')
                  )?.label
                    ? positionsOptions.find(
                        (element) => element.value === (data?.value ? data?.value : '')
                      )
                    : ''
                )
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='lider'
          rules={{required: 'Este campo es obligatorio!'}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={leadersOptios}
              defaultValue={leadersOptios.find((o) => String(o.value) == employee.lider)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Lider *'
                  helperText={errors?.lider?.message}
                  error={errors.lider}
                />
              )}
              onChange={(_, data) => onChange(data.value || '')}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='interventoreIdInterventor'
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={auditorsOptions}
              value={interventorValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Interventor'
                  helperText={
                    errors.interventor?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.interventor}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setInterventorValue(
                  auditorsOptions.find(
                    (element) => element.value === (data?.value ? data?.value : '')
                  )?.label
                    ? auditorsOptions.find(
                        (element) => element.value === (data?.value ? data?.value : '')
                      )
                    : ''
                )
                return data?.value
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='directoreIdDirector'
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={directorsOptions}
              value={directorValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Director'
                  helperText={
                    errors.director?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.director}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setDirectorValue(
                  directorsOptions.find(
                    (element) => element.value === (data?.value ? data?.value : '')
                  )?.label
                    ? directorsOptions.find(
                        (element) => element.value === (data?.value ? data?.value : '')
                      )
                    : ''
                )
                return data?.value
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='teletrabajo'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'SI', value: 'SI'},
                {label: 'NO', value: 'NO'}
              ]}
              value={teletrabajoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Teletrabajo'
                  helperText={
                    errors.teletrabajo?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.teletrabajo}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setTeletrabajoValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='departamento'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={departamentos}
              value={departamentoValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Departamento *'
                  helperText={
                    errors.departamento?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.departamento}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setDepartamentoValue(
                  departamentos.find(
                    (element) => element.value === (data?.value ? data?.value : '')
                  )?.label
                    ? departamentos.find(
                        (element) => element.value === (data?.value ? data?.value : '')
                      )?.label
                    : ''
                )
                handleChange(_, data)
                return data
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='ciudad'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={citiesOptions}
              value={resetCityValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Ciudad *'
                  helperText={
                    errors.ciudad?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.ciudad}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                setResetCityValue(
                  citiesOptions.find(
                    (element) => element.value === (data?.value ? data?.value : '')
                  )?.label
                    ? citiesOptions.find(
                        (element) => element.value === (data?.value ? data?.value : '')
                      )
                    : ''
                )
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('usuarioRedAxity', {required: true})}
          label='Usuario de red Axity *'
          defaultValue={employee.usuarioRedAxity}
          helperText={
            errors.usuarioRedAxity?.type === 'required' && 'Este campo es obligatorio!'
          }
          error={errors.usuarioRedAxity}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('correoAxity', {
            required: true,
            pattern:
              /^(([^<>()[\]\.,:\s@\']+(\.[^<>()[\]\.,:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,:\s@\']+\.)+[^<>()[\]\.,:\s@\']{2,})$/
          })}
          label='Correo Axity *'
          defaultValue={employee.correoAxity}
          onChange={(event)=> setValue("correoAxity", event.target.value)}
          helperText={
            (errors.correoAxity?.type === 'pattern' &&
              'Debe ingresar una dirección de correo valida!') ||
            (errors.correoAxity?.type === 'required' && 'Este campo es obligatorio!')
          }
          error={errors.correoAxity}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='carnetSnack'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'SI', value: 'SI'},
                {label: 'NO', value: 'NO'}
              ]}
              name='carnetSnack'
              value={tieneCarnetSnacksValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'¿Tiene carnet de snacks Axity? *'}
                  helperText={
                    errors.carnetSnack?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.carnetSnack}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                cambioCarnetSnacksAxity(_, data)
                setTieneCarnetSnacksValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('numeroCarnetSnack', {required: valCarnetSnacksAxity[0]})}
          label={valCarnetSnacksAxity[1]}
          defaultValue={employee.numeroCarnetSnack}
          onChange={(event) => {
            if (event.target.value.length > 0) {
              setCarnetSnacksAxity([true, 'Número carnet snacks Axity'])
              clearErrors('numeroCarnetSnack')
              setValue('numeroCarnetSnack', event.target.value)
            }
          }}
          helperText={
            errors.numeroCarnetSnack?.type === 'required' && 'Este campo es obligatorio!'
          }
          error={errors.numeroCarnetSnack}
          name='numeroCarnetSnacksAxity'
          disabled={!valCarnetSnacksAxity[0]}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('usuarioRedNutresa')}
          label='Usuario de red Nutresa'
          defaultValue={employee.usuarioRedNutresa}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('correoNutresa', {
            pattern:
              /^(([^<>()[\]\.,:\s@\']+(\.[^<>()[\]\.,:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,:\s@\']+\.)+[^<>()[\]\.,:\s@\']{2,})$/
          })}
          label='Correo Nutresa'
          onChange={(event)=> setValue('correoNutresa', event.target.value)}
          defaultValue={employee.correoNutresa}
          helperText={
            errors.correoNutresa?.type === 'pattern' &&
            'Debe ingresar una dirección de correo valida!'
          }
          error={errors.correoNutresa}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='carnetNutresa'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'SI', value: 'SI'},
                {label: 'NO', value: 'NO'}
              ]}
              value={tieneCarnetNutresaValue}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value === ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'¿Tiene carnet de Nutresa? *'}
                  helperText={
                    errors.carnetNutresa?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.carnetNutresa}
                />
              )}
              onChange={(_, data) => {
                onChange(data)
                cambioCarnetNutresa(_, data)
                setTieneCarnetNutresaValue(data?.value ? data : '')
                return data?.value ? data : ''
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('numeroCarnetNutresa', {required: valCarnetNutresa[0]})}
          label={valCarnetNutresa[1]}
          defaultValue={employee.numeroCarnetNutresa}
          helperText={
            errors.numeroCarnetNutresa?.type === 'required' &&
            'Este campo es obligatorio!'
          }
          error={errors.numeroCarnetNutresa}
          name='numeroCarnetNutresa'
          disabled={!valCarnetNutresa[0]}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('salario', {required: true, pattern: /^[0-9]+$/i})}
          label='Salario *'
          defaultValue={employee.salario}
          helperText={
            (errors.salario?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.salario?.type === 'pattern' &&
              'En este campo son solo permitidos caracteres numericos!')
          }
          error={errors.salario}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('valorDelServicio', {required: true, pattern: /^[0-9]+$/i})}
          label='Valor del servicio *'
          defaultValue={employee.valorDelServicio}
          helperText={
            (errors.valorServicio?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.valorServicio?.type === 'pattern' &&
              'En este campo son solo permitidos caracteres numericos!')
          }
          error={errors.valorServicio}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          name='costoempleado'
          {...register('costoempleado', {required: true, pattern: /^[0-9]+$/i})}
          label='Costo empleado *'
          defaultValue={employee.costoEmpleado}
          helperText={
            (errors.costoempleado?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.costoempleado?.type === 'pattern' &&
              'En este campo son solo permitidos caracteres numericos!')
          }
          error={errors.costoempleado}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='pep'
          rules={{required: true}}
          control={control}
          render={({field: {onChange, value}}) => (
            <Autocomplete
              disablePortal
              multiple
              options={pepOptions}
              value={value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Pep *'
                  helperText={
                    errors.pep?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.pep}
                />
              )}
              onChange={(_, data) => onChange(data)}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='rol'
          rules={{required: true}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              name='rol'
              disablePortal
              multiple
              options={[
                {label: 'Administrativo', value: '1'},
                {label: 'Técnico', value: '2'},
                {label: 'Colaborador', value: '3'}
              ]}
              value={rolValue}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Rol *'
                  helperText={
                    errors.rol?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.rol}
                />
              )}
              onChange={(_, data) => {
                setRolValue(data)
                onChange(data)
                return data
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('email', {
            required: true,
            pattern:
              /^(([^<>()[\]\.,:\s@\']+(\.[^<>()[\]\.,:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,:\s@\']+\.)+[^<>()[\]\.,:\s@\']{2,})$/
          })}
          label='Correo personal *'
          name='email'
          defaultValue={employee.email}
          helperText={
            (errors.email?.type === 'required' && 'Este campo es obligatorio!') ||
            (errors.email?.type === 'pattern' &&
              'Debe ingresar una dirección de correo valida!')
          }
          error={errors.email}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          name='tieneMembresia'
          rules={{validate: (value) => typeof value === 'boolean' || 'Este campo es obligatorio!'}}
          control={control}
          render={({field: {onChange}}) => (
            <Autocomplete
              disablePortal
              options={[
                {label: 'SI', value: true},
                {label: 'NO', value: false}
              ]}
              defaultValue={
                Boolean(employee.serialMembresia || '')
                  ? {label: 'SI', value: true}
                  : {label: 'NO', value: false}
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'¿Tiene membresia Axity? *'}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <BadgeOutlinedIcon />
                  }}
                  helperText={errors?.tieneMembresia?.message}
                  error={Boolean(errors.tieneMembresia)}
                />
              )}
              onChange={(_, data) => {
                onChange(data.value)
                if (!data.value) {
                  setValue('serialMembresia', null)
                  clearErrors('serialMembresia')
                }
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          {...register('serialMembresia', {
            required: tieneMembresia ? 'Este campo es obligatorio!' : false
          })}
          defaultValue={employee.serialMembresia || null}
          disabled={!tieneMembresia}
          InputProps={{startAdornment: <BadgeOutlinedIcon />}}
          label='Serial Membresia'
          helperText={errors?.serialMembresia?.message}
          error={Boolean(errors.serialMembresia)}
        />
      </Grid>

      <Grid
        item
        sm={6}
        xs={12}
        md={12}
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >
        <Button
          variant='contained'
          component='label'
          sx={{marginTop: 5, width: 200, marginLeft: 4}}
        >
          Cargar foto de persona
          <input
            {...register('foto', {pattern: /\.(jpg|png|gif)$/i})}
            hidden
            accept='image/*'
            type='file'
            onChange={(event, value) => uploadImage(event, value)}
          />
        </Button>
        <Avatar
          sx={{marginTop: 5, width: 100, marginLeft: 4, height: 100}}
          alt='Persona'
          src={personImage}
        />
        {errors.fotoPersona?.type === 'required' && (
          <Alert sx={{width: 400}} severity='error'>
            Debes cargar una imagen!
          </Alert>
        )}
        {fileValidate && (
          <Alert sx={{width: 400}} severity='error'>
            En este campo son permitidas las imagenes jpg,png o gif!
          </Alert>
        )}
      </Grid>
      <Grid
        item
        container
        direction='row'
        justifyContent='space-evenly'
        margin='1rem 9%'
        padding='1rem 9%'
      >
        <Button color='success' onClick={handleSubmit(onSubmit)}>
          Guardar
        </Button>
        <Button color='error' onClick={close}>
          Cancelar
        </Button>
      </Grid>
    </Grid>
  )
}

export default Edit
