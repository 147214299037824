import axios from 'axios'
import {AlertSuccess, AlertError} from '../../ComponentsUtilies/Alerts'
import {config} from '../../config'

export const onSubmit = async (values) => {
  const URL = `${config.URL_API}/suppliers`
  try {
    if (values?.uuid) {
      await axios.put(URL + '/edit/' + values.uuid, values)
    } else {
      await axios.post(URL + '/create', values)
    }
    AlertSuccess()
  } catch (error) {
    console.error(error)
    AlertError(null, error.message)
  }
}
