
import {useState, useEffect} from "react"
import { getCategorys} from "../../services/categoryService.js"
import {TableCategorys} from "./CategoryList"

export const Categorys=()=>{
  const [Categorys,setCategory]=useState(0)
  const [load, setLoad]=useState(false)

  useEffect(()=>{
       getCategorys().then(data=>{
           console.log(data,"dato de la promesa")
           setCategory(data) 
       }).catch(error=>console.log(error))
       setLoad(false)
  },[load])
  console.log("si a bueno",Categorys)
   return(
    <>
     {Categorys != 0 ? 
     <TableCategorys 
       Title={"Categorias"}  
       BodyTable={Categorys}
      load={setLoad}
      />
      
      : <TableCategorys 
      Title={"Categorias"}  
      BodyTable={0}
      load={setLoad}
      />
      } 
   </> 
   )
   
}

