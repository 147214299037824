import * as React from 'react';
import { PanicButton} from '../../components/PanicButtom/PanicButtom';
import { Container } from '@mui/material';
import image from '../../assets/avenue-gf74fbc8d5_1920.jpg'
import { PanicPremiumOptions } from '../../components/PanicButtom/PanicPremiumOptions';
import { PanicNormalOptions } from '../../components/PanicButtom/PanicNormalOptions';
import { PanicButtomRequest } from '../../components/PanicButtom/PanicButtomRequest';
import { OptionsPanicButtomProvider } from '../../context/OptionsPanicButtomContex';
import { OptionsPanicButtomContex } from '../../context/OptionsPanicButtomContex';
import { MyTickects } from '../../components/PanicButtom/MyTickects';
import { MyCreateTickects } from '../../components/PanicButtom/GenerateTickect';


const BMCPanicButtonPage = () => {

  const valueContext = React.useContext(OptionsPanicButtomContex); 
  //let welcomeMessage = `Bienvenido ${JSON.parse(localStorage.getItem("InforUser"))[0].givenName} has click para resolver tu emergencia`;
  //let createTicketmessage = `${JSON.parse(localStorage.getItem("InforUser"))[0].givenName} si tu problema no puede ser resuelto por una de la opciones genera un ticket`;

  let welcomeMessage = `Bienvenido has click para resolver tu emergencia`;
  let createTicketmessage = `Si tu problema no puede ser resuelto por una de la opciones genera un ticket`;


  return (
    <>
        <OptionsPanicButtomProvider>
        <Container maxWidth={false}
             sx={
              { 
                position:"absolute",
                left:0,
                minHeight:"100vh",
                background: "linear-gradient(180deg, rgba(33,91,51,1) 0%, rgba(61,142,51,1) 35%, rgba(127,186,0,1) 100%)",
                alignItems:"center",
                flexDirection:"column"  
              }
            }
        >
            <PanicPremiumOptions backgroundColor={"#7FBA00"} rol={"User"}   BMC={true} />
            <PanicButton createTicketmessage={createTicketmessage} rol={"User"}  BMC={true} />
            <PanicButtomRequest  welcomeMessage={welcomeMessage}  rol={"User"}  BMC={true} />
            <PanicNormalOptions backgroundColor={"#215B33"} rol={"User"}  BMC={true}  />
            
        </Container>
        </OptionsPanicButtomProvider>
    </>
  );
}

export {BMCPanicButtonPage};
