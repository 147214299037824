import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import { AlertSuccess,AlertError } from "../ComponentsUtilies/AlertsModal/Alerts"
import {UpdatePositions, GetPositions} from "../../services/positionsService"
import { useState, useEffect } from "react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
const Alert= withReactContent(Swal)

const AlertModalEditFormPositions=({load, id})=>{
  const [positions, setPositions]=useState('')
   useEffect(()=>{
    GetPositions(id).then(data=>{setPositions(data)
     setValue("nombre",data.nombre)
     setValue("descripcion",data.descripcion)
    }).catch(error=>console.log(error))
   },[])

   const schemaValidation=yup.object().shape({
      nombre: yup.string().required(),
      descripcion: yup.string().required()
   })
    const { register, handleSubmit, reset,formState: { errors } , clearErrors, setValue} = useForm({ resolver: yupResolver(schemaValidation)})
  

    const onSubmit = (valores) => {
        console.log("valores", valores)
      clearErrors("nombre")
       clearErrors("descripcion")
        //const idCategory=categoria.IdCategoria
        const envio = {
            "id":id,
          "nombre": valores.nombre,
          "descripcion": valores.descripcion
          
        }
    console.log(envio, "envio de datos")
    UpdatePositions(envio).then(r => {
          console.log(r)
          if (r.status != 500) {
            AlertSuccess()
            load(true)
    
          }else{
            AlertError()
          }
        }).catch(error => console.log(error))
    
    
        reset()
    
      }

 return (
   
    <>
    {positions != null ?<form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
        <TitleFirts>Editar Posiciones</TitleFirts>
        <Grid container spacing={1}>

          <Grid item sm={12}>
            <TextField  id="outlined-disabled" label="Nombre"
             {...register("nombre")} 
            variant="outlined"
             sx={{ marginTop: 2, width: 850, marginLeft: -2 }}
              helperText={(errors.nombre?.type === 'required' && "Este campo es obligatorio!") }
            
               value={positions.nombre}
               InputLabelProps={{ shrink: true }}
                error= {errors.nombre?.message}
               onChange={(e)=>setPositions({nombre: e.target.value})}
              />

          </Grid>

          <Grid item sm={12}>
          <TextField  id="outlined-disabled" label="descripcion" {...register("descripcion")}
              error={
               errors.descripcion?.message
              } 
              helperText={(errors.descripcion?.type === 'required' && "Este campo es obligatorio!") }
               value={positions.descripcion}
               InputLabelProps={{ shrink: true }}
               onChange={(e)=>setPositions({descripcion: e.target.value})}
               sx={{ marginTop: 2, width: 850, marginLeft: -2 }}
              />        

          </Grid>

        </Grid>




      </Grid>
      <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
    </form> : null}
    
  </>
 )
}

export const ModalEditPositions=(load,id)=>{
    console.log("idd", id)
    Alert.fire({
        width: "970px",
        showConfirmButton: false,
        showCancelButton: false,
        position: "top",
        html: <AlertModalEditFormPositions load={load} id={id} />
    })
}