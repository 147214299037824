import { Paper, Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TitleFirts } from '../ComponentsUtilies/Titles'
import { Create } from './Create'
import { UpdateStateProduct } from './UpdateStateProduct'
import { Edit } from './Edit'
import MaterialReactTable from 'material-react-table'
import { useMemo } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { getAlertModal } from '../../hooks/getAlertModal'
import { useAppContext } from '../../context/DirectoryContex'
import Slider from "react-slick";

const Styles = makeStyles({
  buttonStyle: {
    width: 196,
    height: 35,

    marginBottom: 6,
    marginTop: 5
  }
})

export const TableProducts = ({ Title, BodyTable, setLoad }) => {

  const { employees } = useAppContext()
  const StyleClasses = Styles()
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'IdProducto',
        header: 'Id producto'
      },
      {
        accessorKey: 'nombre',
        header: 'Nombre'
      },
      {
        accessorFn: (row) => (
          <Grid xs={3}> 
          <div className="slider-container" style={{width: "300px", height: "200px"}}>
          <Slider {...settings} className="carrolusel">
            {row.imagenes?.imagenes.map((item) => (
               
                <img
                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                  alt={item}
                  loading='lazy'
                  key={item}
                  width={250}
                  height={150}
                  className='imagenes'
                 
                  style={{objectFit: 'contain'}}
                />
               
              
            ))}
          </Slider>
          </div>
          </Grid>
         
        ),

        header: 'Imagen'
      },
      {
        accessorFn: (row) =>
          row.empleado.cedula +
          ' - ' +
          row.empleado.primerNombre +
          ' ' +
          row.empleado.primerApellido,
        id: 'Asignado',
        header: 'Asignado',
        header: 'Asignado'
      },
      {
        accessorKey: 'marca',
        header: 'Marca'
      },
      {
        accessorKey: 'cantidad',
        header: 'Cantidad'
      },
      {
        accessorKey: 'placa',
        header: 'Placa'
      },
      {
        accessorKey: 'serial',
        header: 'Serial'
      },
      {
        accessorFn: (row) => row.categoria.nombre,
        id: 'Categoria',
        header: 'Categoria',
        header: 'Categoria'
      },
      {
        accessorFn: (row) =>
          row.estado == 1 ? (
            <Button variant='text' color='success'>
              Activo
            </Button>
          ) : (
            <Button variant='text' color='error'>
              Inactivo
            </Button>
          ),
        id: 'Estado',
        header: 'Estado',
        header: 'Estado'
      },
      {
        accessorFn: (row) => (
          <>
            {' '}
            <Button
              variant='contained'
              sx={{ marginLeft: 1, width: 90 }}
              onClick={getAlertModal(Edit, { setLoad:setLoad, employees, id: row.IdProducto })}
            >
              Editar{' '}
            </Button>
            {row.estado == 1 ? (
              <Button
                variant='contained'
                color='error'
                sx={{ marginLeft: 1, width: 90, marginTop: 1 }}
                onClick={() => UpdateStateProduct(row.IdProducto, setLoad)}
              >
                Inactivar
              </Button>
            ) : (
              <Button
                variant='contained'
                color='success'
                sx={{ marginLeft: 1, width: 90, marginTop: 1 }}
                onClick={() => UpdateStateProduct(row.IdProducto, setLoad)}
              >
                Activar
              </Button>
            )}
          </>
        ),
        id: 'Acciones',
        header: 'Acciones',
        header: 'Acciones'
      }
    ],
    []
  )

  return (
    <>
      <TitleFirts>{Title}</TitleFirts>
      <Button
        variant='contained'
        color='success'
        className={StyleClasses.buttonStyle}
        onClick={getAlertModal(Create, { setLoad, employees })}
        sx={{ marginBottom: 2 }}
      >
        Crear
      </Button>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <MaterialReactTable columns={columns} data={BodyTable} />
      </Paper>
    </>
  )
}
