import { useState, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Button, Grid, Typography } from "@mui/material";
import { UpdateDirectorsState } from "./UpdateState";
import { AlertModalEditFormDirectors } from "./Edit";
import { useAppContext } from "../../context/DirectoryContex";
import { useTheme } from "@emotion/react";
import { AlertModalEditFormAuditor } from "./Edit";
import { getAlertModal } from "../../hooks/getAlertModal";

const cellProps = {
  color: "black",
  fontSize: 14,
  noWrap: true,
  margin: "0.1rem",
};

export const ListAuditors = ({
  columnFilters,
  isLoading,
  setIsLoading,
  setLoad,
}) => {
  const { directors } = useAppContext();
  const { palette } = useTheme();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        id: "id",
        maxSize: 2,
        minSize: 1,
        header: <Typography></Typography>,
        Cell: ({ row }) => (
          <Typography {...cellProps} sx={{ paddingLeft: 1 }}>
            {row.index + 1}.
          </Typography>
        ),
      },
      {
        header: <Typography>Nombre completo</Typography>,
        accessorKey: "nombre",
        id: "nombre",
        Cell: ({ cell }) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        ),
      },

      

      {
        header: <Typography>Cedula</Typography>,
        accessorKey: "cedula",
        id: "cedula",
        Cell: ({ cell }) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        ),
      },

      {
        accessorKey: "estado",
        header: "Estado",
        id: "estado"
      },
      {

        accessorKey: "id",
        Cell: ({ row }) =>
          row.original.estado ? (
            <>
              {console.log(row.original)}
              <Grid>
                <Button
                  variant="text"
                  color="success"
                  sx={{ margin: 1 }}
                  onClick={() => UpdateDirectorsState(row.original.idDirector, setLoad,setIsLoading)}
                >
                  Activo
                </Button>
                
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ margin: 1 }}
                  onClick={getAlertModal(AlertModalEditFormDirectors, {
                    id: row.original.idDirector,
                    setIsLoading: setIsLoading,
                    setLoad: setLoad,
                  })}
                >
                  Editar{" "}
                </Button>
              </Grid>
            </>
          ) : (
            <Button
              variant="text"
              color="error"
              sx={{ margin: 1 }}
              onClick={() => UpdateDirectorsState(row.original.idDirector, setLoad,setIsLoading)}
            >
              Inactivo
            </Button>
          ),
      },
    ],
    []
  );

  return (
    <>
      <Grid item xs={12} sx={{marginTop:2}}>
        <MaterialReactTable
          columns={columns}
          data={directors}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableSorting={false}
          enableTopToolbar={false}
          //enablePagination={false}
          //enableColumnResizing
          initialState={{
            isLoading: true,
            columnVisibility: { estado: false },
          }}
          state={{ isLoading, columnFilters }}
          muiTableBodyProps={{
            sx: {
              borderCollapse: "separate",
              border: `0.6rem solid ${palette.background.main}`,
              // borderRadius: '10px',
              // maxHeight: '50vh',
              // overflow: 'auto'
            },
          }}
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              cursor: "pointer",
              borderCollapse: "separate",
              backgroundColor:
                row.index % 2 === 0 ? "white" : palette.background.main,
            },
          })}
          muiTableBodyCellProps={{ sx: { padding: "0", border: "none" } }}
        />
      </Grid>
    </>
  );
};
