import React, { useState, useMemo } from 'react'
import { Typography, Grid, Box, List, ListItem, IconButton, Button } from '@mui/material'
import { getAlertModal } from '../../../hooks/getAlertModal'
import { Create } from './Create'
import { deleteCheckList } from '../../../services/documents'
import { ConfirmModal, AlertSuccess, AlertError } from '../../../ComponentsUtilies/Alerts'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export const Item = ({ check, checks, setLoad }) => {
    const [expand, setExpand] = useState(false)
    const [isShown, setIsShown] = useState(false)

    const pasos = useMemo(() => {
        const steps = JSON.parse(check.pasos)
        if (!steps || steps.length <= 0) return []
        return steps.map((step, idx) => (
            <ListItem key={idx} sx={{ padding: 0 }}>
                <Typography color='secondary' sx={{ paddingRight: 1 }}>
                    {idx + 1}
                </Typography>
                <Typography >
                    {step}
                </Typography>
            </ListItem>
        ))
    }, [checks])

    const children = useMemo(() => {
        if (!checks) return []
        return checks.reduce((acum, item, idx) => {
            if (item.chequeoId === check.id) {
                acum.push(<ListItem key={idx} sx={{ paddingTop: 0 }}>
                    <Item
                        check={item}
                        checks={checks}
                        setLoad={setLoad}
                    />
                </ListItem>)
            }
            return acum
        }, [])
    }, [checks])

    return <Grid container>
        <Box
            sx={{ position: 'relative' }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}

        >
            <Typography >
                <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => setExpand(s => !s)}
                >
                    {expand ?
                        <ArrowRightIcon color='secondary' />
                        : <ArrowDropDownIcon color='secondary' />
                    }
                </IconButton>
                {check.nombre}
            </Typography>
            {expand && isShown && <Box
                sx={{
                    position: 'absolute',
                    zIndex: 100,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 1,
                    paddingBottom: 3,
                    left: '80%',
                    bottom: '0'
                }}
            >
                <Button
                    sx={{ padding: '0', marginRight: 1 }}
                    color='success'
                    onClick={
                        getAlertModal(Create, { processId: check.procesoId, chequeoId: check.id, setLoad })
                    }> + </Button>
                <Button
                    sx={{ padding: '0' }}
                    color='error'
                    onClick={() => {
                        ConfirmModal(
                            'Eliminar CheckList',
                            `Esta seguro que desea eliminar el CheckList "${check.nombre}" y todos sus desendientes de forma permanente?`,
                            () => deleteCheckList(check.id)
                                .then(() => AlertSuccess())
                                .catch(() => AlertError())
                                .finally(() => setLoad(true))
                        )
                    }}> - </Button>
            </Box>}
        </Box>
        <Grid container marginLeft={2} >
            {expand && <List>
                <ListItem sx={{ padding: 0 }}>
                    <Typography
                        color='black'
                        fontSize={13}
                    >{check.descripcion}
                    </Typography>
                </ListItem>
                {pasos.length > 0 && <ListItem>
                    <List>{pasos}</List>
                </ListItem>}
                {children}
            </List>}
        </Grid>
    </Grid>
}

export default Item