import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button,Autocomplete } from "@mui/material"
import { useForm } from "react-hook-form"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"
import {UpdateAdminPep, GetAdminPep} from "../../services/adminPepService"
import { useState, useEffect } from "react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
const Alert= withReactContent(Swal)

const AlertModalEditFormAdminPep=({load, id})=>{
  const [adminPep, setAdminPep]=useState('')
   useEffect(()=>{
    GetAdminPep(id).then(data=>setAdminPep(data)).catch(error=>console.log(error))
   },[])

   const schemaValidation=yup.object().shape({
      nombre: yup.string().required(),
      descripcion: yup.number().required()
   })
    const { register, handleSubmit, reset,formState: { errors } } = useForm()
    const onSubmit = (valores) => {
        //const idCategory=categoria.IdCategoria
        const envio = {
          "familia": valores.familia,
          "linea": valores.linea,
          "sublinea": valores.sublinea,
          "pep": valores.pep
        }
    
    console.log("oee", envio)
    UpdateAdminPep(envio,id).then(r => {
          console.log(r)
          if (r.status != 500) {
            AlertSuccess()
            load(true)
    
          }
        }).catch(error => console.log(error))
    
    
        reset()
    
      }

 return (
    <>
    {adminPep != null ?<form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
        <TitleFirts>Editar pep</TitleFirts>
        <Grid container spacing={1}>
        <Grid item sm={12}>
       

            <TextField  id="outlined-basic" 
            label="Familia" {...register("familia",{required: true, value: adminPep.familia})} 
            variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }}
             helperText={(errors.familia?.type === 'required' && "Este campo es obligatorio!") }
             InputLabelProps={{ shrink: true }}
             value={adminPep.familia}
             onChange={(e)=>setAdminPep({familia: e.target.value})}
              error={
                (errors.familia?.type === 'required')
                && true
              } />
          

          </Grid>

          <Grid item sm={12}>
         
          <TextField  id="outlined-basic"
           label="Sublinea" {...register("sublinea",{required: true})}
            variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} 
            InputLabelProps={{ shrink: true }}
            value={adminPep.sublinea}
            onChange={(e)=>setAdminPep({sublinea: e.target.value})}
            helperText={(errors.sublinea?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.sublinea?.type === 'required') 
                && true
              } />        
          
          </Grid>


          <Grid item sm={12}>
        
          <TextField id="outlined-basic" 
          label="Linea" {...register("linea",{required: true})}
           variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }}
           InputLabelProps={{ shrink: true }}
          value={adminPep.linea}
          onChange={(e)=>setAdminPep({linea: e.target.value})}
            helperText={(errors.linea?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.linea?.type === 'required') 
                && true
              } />        
                
          </Grid>



          <Grid item sm={12}>
        

          <TextField  id="outlined-basic" 
          label="Pep" {...register("pep",{required: false})} 
          variant="outlined" 
          sx={{ marginTop: 2, width: 850, marginLeft: -2 }}
          InputLabelProps={{ shrink: true }}
          value={adminPep.pep }
          onChange={(e)=>setAdminPep({pep: e.target.value})}
           helperText={(errors.pep?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.pep?.type === 'required') 
                && true
              } />        
            
          </Grid>
         

          

        </Grid>




      </Grid>
      <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
    </form> : null}
    
  </>
 )
}

export const ModalEditAdminPep=(load,id)=>{
    console.log("idd", id)
    Alert.fire({
        width: "970px",
        showConfirmButton: false,
        showCancelButton: false,
        position: "top",
        html: <AlertModalEditFormAdminPep load={load} id={id} />
    })
}