import { Card } from '@mui/material'
import logoNutresa from './logoNutresa.svg'

export const LogoNutresa = (props) => {
  return (<Card
    {...props}
    elevation={0}
  >
    <img
      component='img'
      width="139"
      alt='Nutresa'
      src={logoNutresa}
    />
  </Card>)
}
export default LogoNutresa