import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

export default function Save({onSave, onCancel}) {
  return (
    <Grid container justifyContent='space-evenly' padding='1rem 9%'>
      <Button color='error' onClick={onCancel}>
        Cancelar
      </Button>
      <Button color='success' onClick={onSave}>
        Enviar
      </Button>
    </Grid>
  )
}
