import {TableProducts} from './TableProduct'
import {useState, useEffect} from 'react'
import {GetProductsAll} from '../../services/productService.js'
import { Container } from '@mui/system'

export const Products = () => {
  const [products, setProducts] = useState(0)
  const [Data, setData] = useState(0)
  const [load, setLoad] = useState(false)
  useEffect(() => {
    GetProductsAll()
      .then((data) => {
        setProducts(data)
        setLoad(false)
      })
      .catch((error) => console.log(error))
  }, [load])

  return (
    <>
   <Container> 
      {products != 0 ? (
        <TableProducts
          Title={'Articulos'}
          BodyTable={products}
          ValueJson={[
            'IdProducto',
            'nombre',
            'foto',
            'marca',
            'cantidad',
            'placa',
            'serial',
            'estado'
          ]}
          setLoad={setLoad}
        />
      ) : (
        <TableProducts
          Title={'Articulos'}
          BodyTable={0}
          ValueJson={[
            'IdProducto',
            'nombre',
            'foto',
            'marca',
            'cantidad',
            'placa',
            'serial',
            'estado'
          ]}
          setLoad={setLoad}
        />
      )}
      </Container>
    </>
  )
}
