import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { TitleFirts } from "../ComponentsUtilies/Titles";
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts";
import { Updatedirectors, Getdirectors } from "../../services/directorsService";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const Alert = withReactContent(Swal);

const AlertModalEditFormDirectors = ({ id, setIsLoading, setLoad }) => {
  const [directors, setDirectors] = useState("");
  useEffect(() => {
    Getdirectors(id)
      .then((data) => {
        setDirectors(data);
        setValue("nombre",data.nombre)
        setValue("cedula",data.cedula)
      })
      .catch((error) => console.log(error));
  }, []);

  const schemaValidation = yup.object().shape({
    nombre: yup.string().required(),
    cedula: yup.number().required(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaValidation) });
  const onSubmit = (valores) => {
    //const idCategory=categoria.IdCategoria
    const envio = {
      id: id,
      nombre: valores.nombre,
      cedula: valores.cedula,
    };
    console.log(envio, "envio de datos");
    setIsLoading(true);
    Updatedirectors(envio)
      .then((r) => {
        console.log(r);
        if (r.status != 500) {
          AlertSuccess();
          setLoad(true);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    reset();
  };

  return (
    <>
      {directors != null ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <TitleFirts>Editar Director</TitleFirts>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-disabled"
                  label="Nombre"
                  {...register("nombre")}
                  sx={{ marginTop: 2, width:"90%" }}
                  helperText={
                    errors.nombre?.type === "required" &&
                    "Este campo es obligatorio!"
                  }
                  value={directors.nombre}
                  InputLabelProps={{ shrink: true }}
                  error={errors.nombre?.message}
                  onChange={(e) => setDirectors({ nombre: e.target.value })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-disabled"
                  label="Cedula"
                  {...register("cedula")}
                  error={errors.cedula?.message}
                  value={directors.cedula}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDirectors({ cedula: e.target.value })}
                  sx={{ marginTop: 2,width:"90%" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="success"
            sx={{ marginTop: 5, width: 100, marginLeft: 3,marginBottom: 1 }}
            type="submit"
          >
            Enviar
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ marginTop: 5, width: 112, marginLeft: 3,marginBottom: 1 }}
            onClick={() => Alert.close()}
          >
            Cancelar
          </Button>
        </form>
      ) : null}
    </>
  );
};

export { AlertModalEditFormDirectors };

export const ModalEditDirectors = (load, id) => {
  console.log("idd", id);
  Alert.fire({
    width: "970px",
    showConfirmButton: false,
    showCancelButton: false,
    position: "top",
    html: <AlertModalEditFormDirectors load={load} id={id} />,
  });
};
