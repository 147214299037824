import axios from 'axios'

const URL = 'https://0yets2xo9j.execute-api.us-east-1.amazonaws.com'
// const URL = 'http://localhost:4000'

export const getCommitments = async (id) => {
  const request = await axios.get(URL + '/commitments/'+id)
  return request.data
}

export const createCommitment = async (body) => {
  const request = await axios.post(URL + '/commitments/create', body)
  if (request.status !== 200) throw Error(request.data)
  return request.data
}

export const createFollowups = async (id, body) => {
  const request = await axios.post(URL + '/followups/create/'+ id, body)
  if (request.status !== 200) throw Error(request.data)
  return request.data
}


export const getAssistants = async () => {
  try {
    const request = await axios.get(URL + '/assistants/all')
    return request.data    
  } catch (error) {
    console.error(error)
    // if(error.code === 500 ) return getAssistants()
    // else return []
  }
}
