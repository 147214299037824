
import * as React from 'react';
import { Button,Box,Grid,TextField,InputAdornment,Autocomplete} from "@mui/material";
import { TitleFirts } from '../ComponentsUtilies/Titles'; 
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2"
import { useForm,Controller  } from "react-hook-form"
import { createTicket } from '../../services/createTicket';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { Loading,AlertSuccess,AlertError } from '../ComponentsUtilies/AlertsModal/Alerts';
import { categoriasPrincipales,categoriasSecundarias } from '../../categoriasContactoLista';
import { useState } from "react"
import Skeleton from '@mui/material/Skeleton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Typography from '@mui/joy/Typography';
import { getDataUserADS } from '../../services/getDataUserADS';
import { createTicketBMC } from '../../services/casosBMC.js';

const SweetAlert = withReactContent(Swal);



const GenerateTickectBMC=()=>{
    const { register, handleSubmit,control,formState: { errors },watch,clearErrors,setError,setValue } = useForm();
    const [subcategoriaOptions, setSubcategoriaOptions] = useState([]);
    const [resetSubcategoriaValue, setResetSubcategoriaValue] = useState("");
    const [userValue, setUserValue] = useState("");
    const [showErrorSearchUser, setShowErrorSearchUser] = useState(false);
    const [optionsCategoria3, setOptionsCategoria3] = useState([]);
    const [userData, setUserData] = useState({
      cedula:"",
      nombre:""
    });
    const [userDataLoading, setUserDataLoading] = useState(false);

    
    const LoadingModal = () => {
        Loading("Estamos creando el caso");
    }

    const SuccessModal = (SR) => {
        AlertSuccess(`${SR}`,`Se ha generado el caso`);
    }

    const sendProblem = (type) =>{
        console.log("type",type)
        
        LoadingModal();
        createTicketBMC(type.categoria1.value,type.categoria2,type.categoria3.value,type.fallaDescripcion).then((response) =>{
          SuccessModal(response.data.data.values["Incident Number"]);
          let message = `Hola Daniela,\nPronto resolveremos tu caso generado con ID ${response.data.data.values["Incident Number"]}`
          sendWhatsMessage(message,"320 4982189")
          .then((response) =>{
                console.log(response);
          })
          .catch((err) =>{
                console.log(err);
          });
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });
        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
    } 

    const categoriaChange = (event, value) => {
      setResetSubcategoriaValue("");
      let filteredCategoria = [];
      let filteredSubCategoriasOptions = [];
      filteredCategoria = categoriasSecundarias.filter(element => element.categoria === value?.value);
      if (filteredCategoria) {
        filteredCategoria.forEach(element => {
          filteredSubCategoriasOptions.push({
            label: element.subCategoria,
            value: element.codigo
          });
        });
      }
      setSubcategoriaOptions(filteredSubCategoriasOptions);
    }

    const usuarioRedChange = (e)=>{
      setUserValue(e)
      setValue("usuarioRed",e)
    }
    const searchInfoUser = (e) =>{
      console.log(e)
      if(e!==""){
        setUserDataLoading(true)
        getDataUserADS(userValue)
        .then((response)=>{
          console.log(response)
          if(response[0]){
            setUserData({
              cedula:response[0].description,
              nombre:response[0].displayName
            })
            setValue("usuarioNombre",response[0].displayName)
            setValue("usuarioCedula",response[0].description  )
            clearErrors("usuarioRed")
            setShowErrorSearchUser(false);
            console.log(errors)
          }else{
            setShowErrorSearchUser(true)
            setUserValue("");
            setUserData({
              cedula:"",
              nombre:""
            })
          }
          setUserDataLoading(false)
        })
        .catch((error)=>{
          console.log(error)
          setUserDataLoading(false)
          setShowErrorSearchUser(true)
          setUserValue("");
          setUserData({
            cedula:"",
            nombre:""
          })
        })
      }
      
    }
    
    const onSubmit = (data) => {
        console.log(data)
        sendProblem(data)
    };

    const changeValueCategoria =(data)=>{
      console.log(data)
      setValue("categoria2","Autoatención")
      if(data.value === "Servicios TI"){
        setOptionsCategoria3([
          {
            label:"Almacenamiento ilimitado",
            value:"Almacenamiento ilimitado"
          },
          {
            label:"Backup en la nube",
            value:"Backup en la nube"
          },
          {
            label:"Gestión de usuarios",
            value:"Gestión de usuarios"
          },
          {
            label:"Permisos de navegación",
            value:"Permisos de navegación"
          },
          {
            label:"VPN",
            value:"VPN"
          }
        ])
      }else if(data.value === "Usuarios finales"){
        setOptionsCategoria3([
          {
            label:"Problema equipo",
            value:"Problema equipo"
          },
          {
            label:"Recuperar contraseña correo",
            value:"Recuperar contraseña correo"
          },
          {
            label:"Recuperar contraseña ADS",
            value:"Recuperar contraseña ADS"
          },
          {
            label:"Problema internet",
            value:"Problema internet"
          },
          {
            label:"PQRS",
            value:"PQRS"
          }
        ]);
      }else{
        setOptionsCategoria3([]);
      }
    }

    return (
        <>
             
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
         <Grid container spacing={1}>
         
         <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
            <Controller
              name="categoria2"
              rules={{ required: true }}
              control={control}
              render={({ field:{onChange,value } }) => 
                <Autocomplete
                  disablePortal
                  disabled
                  defaultValue={"Autoatención"}
                  options={[
                    {
                      label:"Autoatención",
                      value:"Autoatención"
                    }
                  ]}
                  sx={{ marginTop: 5, width: "90%", }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label={"Seleccione la categoria 1*"}   
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <ConfirmationNumberIcon style={{color:'#3C1053'}}/>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}                  
                      helperText={(errors.categoria?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.categoria?.type === 'required')
                        && true
                      }
                    />
                  }
                  onChange={(_,data)=>{
                    onChange(data);
                    return data;
                  }}
                />
              }
            /> 
          </Grid>

        
         <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
            <Controller
              name="categoria1"
              rules={{ required: true }}
              control={control}
              render={({ field:{onChange,value } }) => 
                <Autocomplete
                  disablePortal
                  options={[
                    {
                      label:"Servicios TI",
                      value:"Servicios TI"
                    },
                    {
                      label:"Usuarios finales",
                      value:"Usuarios finales"
                    }
                  ]}
                  sx={{ marginTop: 5, width: "90%", }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label={"Seleccione la categoria 2*"}   
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <ConfirmationNumberIcon style={{color:'#3C1053'}}/>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}                  
                      helperText={(errors.categoria?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.categoria?.type === 'required')
                        && true
                      }
                    />
                  }
                  onChange={(_,data)=>{
                    onChange(data);
                    changeValueCategoria(data);
                    return data;
                  }}
                />
              }
            /> 
          </Grid>

          
          <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
            <Controller
              name="categoria3"
              rules={{ required: true }}
              control={control}
              render={({ field:{onChange,value } }) => 
                <Autocomplete
                  disablePortal
                  options={optionsCategoria3}
                  sx={{ marginTop: 5, width: "90%", }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label={"Seleccione la categoria 3*"}   
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <ConfirmationNumberIcon style={{color:'#3C1053'}}/>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}                  
                      helperText={(errors.categoria?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.categoria?.type === 'required')
                        && true
                      }
                    />
                  }
                  onChange={(_,data)=>{
                    onChange(data);
                    return data;
                  }}
                />
              }
            /> 
          </Grid>


          
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><ConfirmationNumberIcon style={{color:'#3C1053'}} /></InputAdornment>),
              }}
              {...register("fallaDescripcion", { required: true })}
              label="Descripción *"
              variant="outlined"
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              helperText={(errors.fallaDescripcion?.type === 'required' && "Este campo es obligatorio!") }
              error={
                (errors.fallaDescripcion?.type === 'required' )
                && true
              }
            />
          </Grid>

          
        </Grid>

     
        
      </Grid>
        <Button 
            variant="contained"
            color="success" 
            sx={{ marginTop: 5, width: 100, marginLeft: 3 }}
            onClick={handleSubmit(onSubmit)}
        >
            Crear
        </Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => SweetAlert.close()}>Cancelar</Button>
    
        </>
    )
}







const GenerateTickectBMCModal = () => {
    SweetAlert.fire({
        customClass:"classFormModal",
        title:"Crear Caso BMC",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top",
      allowOutsideClick: true,
      showCloseButton: true,
      html: <GenerateTickectBMC />
      
    })
  }

export {GenerateTickectBMCModal,GenerateTickectBMC}