
import * as React from 'react';
import { Button,Box,Grid,TextField,InputAdornment,Autocomplete} from "@mui/material";
import { TitleFirts } from '../ComponentsUtilies/Titles'; 
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2"
import { useForm,Controller  } from "react-hook-form"
import { createTicket } from '../../services/createTicket';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { Loading,AlertSuccess,AlertError } from '../ComponentsUtilies/AlertsModal/Alerts';
import { createTicketBMC } from '../../services/casosBMC';
const SweetAlert = withReactContent(Swal);



const MyCreateTickectsEquipo=()=>{
    const { register, handleSubmit,control,formState: { errors },watch,clearErrors,setError,setValue } = useForm();
    const LoadingModal = () => {
        Loading("Estamos buscando ayuda");
    }

    const SuccessModal = (SR) => {
        AlertSuccess(`Por favor espérala caso generado ${SR}`,`La ayuda llegará pronto caso generado`);
    }

    const getAffecteddate = ()=>{
        let date = new Date();
        let day = date.getDate();
    
        if(String(day).length === 1) day = "0" + day;
        var month = (date.getMonth()+1);
        if(String(month).length === 1) month = "0" + month;
        let hour = (date.getHours()-1)
        if(String(hour).length === 1) hour = "0" + hour ;
        if(hour === -1) hour = "00" ;
        let minute = (date.getMinutes())
        if(String(minute).length === 1) minute = "0" + minute;
        let seconds = (date.getSeconds())
        if(String(seconds).length === 1) seconds = "0" + seconds;
        let AFFECTEDDATE = date.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + seconds ;
        
        return AFFECTEDDATE;
    }
    
    const sendProblem = (type) =>{
        setValue("categoria","PROBLEMA EQUIPO")

    
        LoadingModal();
        /*createTicket(
            {
                REPORTEDBY: JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName,
                AFFECTEDPERSON: "SISS" ,
                DESCRIPTION: `${type.categoria} - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`,
                PLUSPCUSTOMER: "NUTRESA",
                CLASSSTRUCTUREID: "NUT101003009163",
                EXTERNALSYSTEM: "EMAIL",
                STATUS: "INPROG",
                "CONSECAM":"",
                "AFFECTEDDATE":getAffecteddate(),
                "REPORTDATE":getAffecteddate(),
                "OWNER":"Exssjlgaviri",
                "OWNERGROUP":"C-NUT-COL-CENTRO_DE_ADMIN_DE_DATOS_MAESTROS",
                DESCRIPTION_LONGDESCRIPTION: type.descripcion
            }
        ).then((response) =>{
            console.log(response)
            
            let data = JSON.parse(response.data.data); 
            let SR = data.data["soapenv:Envelope"]["soapenv:Body"].CreateIBMSRTUSEGUIMResponse.SRMboKeySet.SR.TICKETID._text;
            SuccessModal(SR);
            let message = `Hola ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName},\nPronto resolveremos tu "${type.categoria.value}" el caso generado fue el ${SR}`
            sendWhatsMessage(message,JSON.parse(localStorage.getItem("InforUser"))[0].mobile)
            .then((response) =>{
                console.log(response);
            })
            .catch((err) =>{
                console.log(err);
            });
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });*/

        createTicketBMC("Usuarios finales","Autoatención","Problema equipo",type.descripcion).then((response) =>{
          SuccessModal(response.data.data.values["Incident Number"]);
          let message = `Hola Daniela,\nPronto resolveremos tu caso generado con ID ${response.data.data.values["Incident Number"]}`
          sendWhatsMessage(message,"320 4982189")
          .then((response) =>{
                console.log(response);
          })
          .catch((err) =>{
                console.log(err);
          });
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });

        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
    } 
    
    const onSubmit = (data) => {
        sendProblem(data)
    };
    return (
        <>
             
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
         <Grid container spacing={1}>
         <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
            <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><ConfirmationNumberIcon style={{color:'#3C1053'}} /></InputAdornment>),
                readOnly: true
              }}
              {...register("categoria")}
              label="Problema *"
              variant="outlined"
              defaultValue={"PROBLEMA EQUIPO"}
             
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><ConfirmationNumberIcon style={{color:'#3C1053'}} /></InputAdornment>),
              }}
              {...register("descripcion", { required: true,pattern: /^[A-Za-z|0-9| ]+$/i })}
              label="Describe tu problema *"
              variant="outlined"
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              helperText={(errors.descripcion?.type === 'required' && "Este campo es obligatorio!") ||
              (errors.descripcion?.type === 'pattern' && "En este campo son solo permitidas letras y números!")}
              error={
                (errors.descripcion?.type === 'required' || errors.descripcion?.type === 'pattern')
                && true
              }
            />
          </Grid>
          
        </Grid>

     
        
      </Grid>
        <Button 
            variant="contained"
            color="success" 
            sx={{ marginTop: 5, width: 100, marginLeft: 3 }}
            onClick={handleSubmit(onSubmit)}
        >
            Crear
        </Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => SweetAlert.close()}>Cancelar</Button>
    
        </>
    )
}


const MyCreateTickectsModalEquipo = () => {
  SweetAlert.fire({
      customClass:"classFormModal",
      title:"Crear Caso",
    showConfirmButton: false,
    showCancelButton: false,
    position: "top",
    allowOutsideClick: true,
    showCloseButton: true,
    html: <MyCreateTickectsEquipo />
    
  })
}

export {MyCreateTickectsModalEquipo,MyCreateTickectsEquipo}