import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Auth} from 'aws-amplify'
import {useTheme} from '@mui/material/styles'
import {Drawer, IconButton, List, Avatar, Typography, Grid} from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import {LogoAxity} from '../../media/LogoAxity'
import {IconAxity} from '../../media/IconAxity'
import {IconUser} from '../../media/IconUser2'
import {IconPeople} from '../../media/IconPeople'
import {IconProcess} from '../../media/IconProcess'
import {IconMap} from '../../media/IconMap'
import {IconLeader} from '../../media/IconLeader'
import {IconDocument} from '../../media/IconDocument'
import {IconOpen} from '../../media/IconOpen'
import {IconClose} from '../../media/IconClose'
import {IconLogout} from '../../media/IconLogout'
import {useAppContext} from '../../context/DirectoryContex'
import {Item} from './Item'
import {searchEmployee} from '../../services/employeesService'

export const Menu = ({children}) => {
  const {user, setUser} = useAppContext()
  const {gradient} = useTheme()
  const [isOpen, setOpen] = useState(false)
  const [menuWidth, setMenuWidth] = useState(null)
  const menuRef = useRef(null)
  const navigate = useNavigate()

  const updateWidth = () => {
    const width = menuRef.current.getBoundingClientRect().width
    setMenuWidth(parseInt(width))
  }

  useEffect(() => {
    if (menuRef.current) {
      updateWidth()
      window.addEventListener('resize', updateWidth)
    }
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(({attributes, username}) => {
        handleOpen(isOpen)
        if (!attributes) return
        const authorization = ((rols) => {
          if (rols.includes('ADMINISTRADOR')) return 1
          if (rols.includes('LIDER')) return 3
          if (rols.includes('COLABORADOR')) return 9
          return 1000
        })(attributes['custom:ROL'])
        setUser({...attributes, username, authorization})
      })
      .catch((error) => {
        console.error(error)
        return navigate('/login')
      })
  }, [])

  useEffect(() => {
    if (user?.username && !user?.load) {
      searchEmployee({
        sub: user.sub,
        caedula: user.username,
        correoAxity: user.username,
        correoNutresa: user.username
      }).then((employee) => {
        if (employee) {
          setUser({
            ...user,
            foto: employee.foto,
            name: employee.nombre,
            supplier: employee.proveedorUUID,
            load: true
          })
        } else {
          setUser({...user, load: true, foto: null, supplier: null})
        }
      })
    }
  }, [user])

  const handleOpen = (value) => {
    if (menuRef.current) {
      setOpen(value)
      setTimeout(() => {
        const width = menuRef.current.getBoundingClientRect().width
        setMenuWidth(parseInt(width))
      }, 1)
    }
  }

  const exit = () => {
    localStorage.removeItem('user-token')
    localStorage.removeItem('InforUser')
    setUser(null)
    Auth.signOut()
    navigate('/login')
  }

  return (
    <Grid container spacing={0} direction='row' wrap='nowrap'>
      <IconButton
        color='secondary'
        sx={{
          position: 'fixed',
          top: isOpen ? '9rem' : '3rem',
          left: `${menuWidth - (menuWidth > 10 ? 30 : isOpen ? 0 : 15)}px`,
          display: user ? 'block' : 'none',
          zIndex: 1300,
          width: '3rem'
        }}
        onClick={() => handleOpen(!isOpen)}
      >
        {isOpen ? <IconClose /> : <IconOpen />}
      </IconButton>
      <Grid
        item
        ref={menuRef}
        xs={isOpen ? 12 : 0}
        sm={isOpen ? 6 : 1}
        md={isOpen ? 3 : 0.6}
        sx={{display: user ? 'block' : 'none'}}
        display={{
          xs: isOpen ? 'block' : 'none',
          sm: 'block'
        }}
      >
        <Drawer
          variant='permanent'
          open={isOpen}
          sx={{display: user ? 'block' : 'none'}}
          onClose={() => handleOpen(false)}
        >
          <Grid
            containet
            justifyContent='space-between'
            sx={{
              width: `${menuWidth}px`,
              backgroundImage: gradient.uva,
              minHeight: '100vh',
              overflow: 'scroll',
              '::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            <Grid container justifyContent='center'>
              {isOpen ? <LogoAxity sx={{width: '80%'}} /> : <IconAxity />}
            </Grid>
            <Grid
              container
              direction='column'
              padding='3%'
              margin={isOpen ? '10% 0' : '30% 0'}
            >
              {user?.foto ? (
                <Avatar sx={{width: 80, height: 80}} alt='Persona' src={user.foto} />
              ) : (
                <IconUser sx={{maxWidth: '5rem'}} />
              )}
              <Typography noWrap color='white'>
                {user?.name ? user.name : user?.given_name}
              </Typography>
            </Grid>
            <List>
              <Item
                path='/Proveedores'
                text='Proveedores'
                Icon={<AccountBalanceIcon color='secondary' />}
                isOpen={isOpen}
                setOpen={handleOpen}
                security={1}
              />

              <Item
                path='/Empleados'
                text='Base de datos Empleados'
                Icon={<IconPeople />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Procesos'
                text='Procesos'
                Icon={<IconProcess />}
                isOpen={isOpen}
                setOpen={handleOpen}
                security={10}
              />

              <Item
                path='/'
                text='Base de datos geografica'
                Icon={<IconMap />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/empleado'
                text='Gestion de lideres'
                Icon={<IconLeader />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Categorias'
                text='Categorias'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Articulos'
                text='Articulos'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Auditores'
                text='Interventores'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Directores'
                text='Directores'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Jefaturas'
                text='Jefaturas'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Direcciones'
                text='Direcciones'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Posiciones'
                text='Posiciones'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/AdministracionPep'
                text='AdministracionPep'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/MargenPep'
                text='MargenPep'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/PanicButtom'
                text='PanicButtom'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/Tecnicos'
                text='Tecnicos'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/CasosBMC'
                text='CasosBMC'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />

              <Item
                path='/CasosUsuariosBMC'
                text='CasosUsuariosBMC'
                Icon={<IconDocument />}
                isOpen={isOpen}
                setOpen={handleOpen}
              />
            </List>
            <Grid
              container
              spacing={1}
              direction='row'
              justifyContent={isOpen ? 'end' : 'center'}
            >
              <Grid item sx={{width: '3rem'}}>
                <IconButton color='secondary' onClick={exit}>
                  <IconLogout />
                </IconButton>
                <Typography fontSize={12} wrap color='white'>
                  Cerrar sesión
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
      <Grid item xs={isOpen ? 0.1 : 12} sm={isOpen ? 6 : 11} md={isOpen ? 9 : 11.4}>
        {children}
      </Grid>
    </Grid>
  )
}
