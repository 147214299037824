import axios from 'axios'

const URL = 'https://0yets2xo9j.execute-api.us-east-1.amazonaws.com'
// const URL = 'http://localhost:4000'

export const getDocumentsAll = async () => {
  const request = await axios.get(URL + '/documents/all')
  return request.data
}

export const getDocument = async (id) => {
  const request = await axios.get(URL + '/documents/' + id)
  return request.data
}

export const createDocument = async (body) => {
  const request = await axios.post(URL + '/documents/create', body, {
    headers: {'Content-Type': 'multipart/form-data'}
  })
  if (request.status !== 200) throw Error(request.data)
  return request.data
}

export const updateDucumentState = async (id) => {
  const request = await axios.put(URL + '/documents/updateState/' + id)
  return request.data
}

export const editDocument = async (body, id) => {
  let request = null
  if (body.doc) {
    request = await axios.put(URL + '/documents/updateFile/' + id, body, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
  } else {
    request = await axios.put(URL + '/documents/updateData/' + id, body)
  }

  return request.data
}

export const getCategoriesAll = async () => {
  const request = await axios.get(URL + '/documents/categories/all')
  return request.data
}

export const getDocumentsProcess = async (id) => {
  const request = await axios.get(URL + '/documents/process/' + id)
  return request.data
}

export const getCheckList = async (id) => {
  const request = await axios.get(URL + '/documents/checkList/' + id)
  if (request.status !== 200) throw Error(request.data)
  return request.data
}

export const createCheckList = async (body) => {
  const request = await axios.post(URL + '/documents/checkList/', body)
  if (request.status !== 200) throw Error(request.data)
  return request.data
}

export const deleteCheckList = async (id) => {
  const request = await axios.delete(URL + '/documents/checkList/' + id)
  if (request.status !== 200) throw Error(request.data)
  return request.data
}
