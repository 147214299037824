import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"
import {CreatePositions} from "../../services/positionsService"
const Alert= withReactContent(Swal)

const AlertModalFormPosition=({load})=>{
    const { register, handleSubmit, reset,formState: { errors } } = useForm()
    const onSubmit = (valores) => {
        //const idCategory=categoria.IdCategoria
        const envio = {
          "nombre": valores.nombre,
          "descripcion": valores.descripcion
        }
    
        CreatePositions(envio).then(r => {
          console.log(r)
          if (r.status != 500) {
            AlertSuccess()
            load(true)
    
          }
        }).catch(error => console.log(error))
    
    
        reset()
    
      }

 return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
        <TitleFirts>Agregar posición</TitleFirts>
        <Grid container spacing={1}>

          <Grid item sm={12}>
            <TextField id="outlined-basic" label="Nombre" {...register("nombre",{required: true})} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.nombre?.type === 'required' && "Este campo es obligatorio!") }
              error={
                (errors.nombre?.type === 'required')
                && true
              } />

          </Grid>

          <Grid item sm={12}>
          <TextField id="outlined-basic" label="descripcion" {...register("descripcion",{required: true})} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.cedula?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.descripcion?.type === 'required') || (errors.descripcion?.type === 'pattern') 
                && true
              } />        

          </Grid>

        </Grid>




      </Grid>
      <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
    </form>
  </>
 )
}

export const ModalCreatePositions=(load)=>{
    Alert.fire({
        width: "970px",
        showConfirmButton: false,
        showCancelButton: false,
        position: "top",
        html: <AlertModalFormPosition load={load} />
    })
}