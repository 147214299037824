import {UpdateStateProcess} from '../../services/processService'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'

export const UpdateStateProcessV=(id, setIsLoading,setOption,setLoad)=>{

    setIsLoading(true)
    UpdateStateProcess(id).then(res=>{
            setLoad(true)
            setOption('masivo')
            SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
            setIsLoading(false)
    }).catch(error=>{
        setIsLoading(false)
        ErrorModal('No se ejecuto, comuniquese con el administrador', 'Operacion con errores')
       console.log(error)
    })
}