import * as React from 'react';
import { Grid  } from "@mui/material"
import "./PanicButtom.css"
import { OptionsPanicButtomContex } from '../../context/OptionsPanicButtomContex';
import { Loading,AlertSuccess,AlertError } from '../ComponentsUtilies/AlertsModal/Alerts';
import { createTicket } from '../../services/createTicket';
import { DirectoryContext} from '../../context/DirectoryContex';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { changeEmailPassword } from '../../services/changeEmailPassword';
import { changeADSPassword } from '../../services/changeADSPassword';
import { MyCreateTickectsModalInternet } from './GenerateTickectInternet';
import { CommentTickectModal } from '../TechniciansButton/CommentTickect';
import { CloseTickectModal } from '../TechniciansButton/CloseTickect';
import { changeStateTicket } from '../../services/changeStateTicket';
import { createTicketBMC } from '../../services/casosBMC';


const PanicNormalOptions= (props) => {

    console.log(props.rol)
    const OptionsPanicValueContext = React.useContext(OptionsPanicButtomContex);
    const valueContext = React.useContext(DirectoryContext);
    
    const LoadingModal = () => {
        Loading("Estamos recuperando tu contraseña");
    }

    const SuccessModal = (message) => {
        AlertSuccess(message,"Contraseña cambiada");
    }
    const getAffecteddate = ()=>{
        let date = new Date();
        let day = date.getDate();
    
        if(String(day).length === 1) day = "0" + day;
        var month = (date.getMonth()+1);
        if(String(month).length === 1) month = "0" + month;
        let hour = (date.getHours()-1)
        if(String(hour).length === 1) hour = "0" + hour ;
        if(hour === -1) hour = "00" ;
        let minute = (date.getMinutes())
        if(String(minute).length === 1) minute = "0" + minute;
        let seconds = (date.getSeconds())
        if(String(seconds).length === 1) seconds = "0" + seconds;
        let AFFECTEDDATE = date.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + seconds ;
        
        return AFFECTEDDATE;
    }
    
    const cambiarCuentaCorreo = ()=>{
        LoadingModal();
        /*let SR="";
        createTicket(
            {
                REPORTEDBY: JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName,
                AFFECTEDPERSON: "SISS" ,
                DESCRIPTION: `Cambio de contraseña - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`,
                PLUSPCUSTOMER: "NUTRESA",
                CLASSSTRUCTUREID: "NUT101003009163",
                EXTERNALSYSTEM: "EMAIL",
                STATUS: "INPROG",
                "CONSECAM":"",
                "AFFECTEDDATE":getAffecteddate(),
                "REPORTDATE":getAffecteddate(),
                "OWNER":"Exssjlgaviri",
                "OWNERGROUP":"C-NUT-COL-CENTRO_DE_ADMIN_DE_DATOS_MAESTROS",
                DESCRIPTION_LONGDESCRIPTION: `Cambio contraseña - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`
            }
        ).then((response) =>{
            changeEmailPassword(JSON.parse(localStorage.getItem("InforUser"))[0].mail)
            .then((responseEamil) =>{
                let data = JSON.parse(response.data.data); 
                SR = data.data["soapenv:Envelope"]["soapenv:Body"].CreateIBMSRTUSEGUIMResponse.SRMboKeySet.SR.TICKETID._text;
                let message = `Hola ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName},\nTu nueva contraseña del correo ${JSON.parse(localStorage.getItem("InforUser"))[0].mail} es ${responseEamil.password}\n el caso generado fue el ${SR}`
                sendWhatsMessage(message,JSON.parse(localStorage.getItem("InforUser"))[0].mobile)
                .then((response) =>{
                    changeStateTicket({
                        "TICKETID":SR,
                        "CLASS":"SR",
                        "STATUS":"RESOLVED"
                    }).then((response) =>{
                        SuccessModal(message);
                    })
                }).catch((err) =>{
                    console.log(err);
                });
            })
            .catch((err) =>{
                console.log(err);
            }); 
        })
        .catch((err) =>{
            AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });*/
        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
       
        createTicketBMC("Usuarios finales","Autoatención","Recuperar contraseña correo","Recuperar contraseña correo")
        .then((response) =>{
            
            
            changeEmailPassword("gestiondeprocesosdos@serviciosnutresa.com")
            .then((responseEamil) =>{
                let message = `Hola Daniela,\nTu nueva contraseña del correo ${"gestiondeprocesosdos@serviciosnutresa.com"} es ${responseEamil.password}\n el caso generado fue el ${response.data.data.values["Incident Number"]}`
                sendWhatsMessage(message,"320 4982189")
                .then((response) =>{
                    SuccessModal(message);
                    console.log(response);
                })
                .catch((err) =>{
                    console.log(err);
                });
            }).catch((err) =>{
                console.log(err);
                AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
            });
            
          
          })
          .catch((err) =>{
            AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
          });
    }

    const cambiarContrasenaADS = ()=>{
        LoadingModal();
        let SR="";
        createTicket(
            {
                REPORTEDBY: JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName,
                AFFECTEDPERSON: "SISS" ,
                DESCRIPTION: `Cambio de contraseña - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`,
                PLUSPCUSTOMER: "NUTRESA",
                CLASSSTRUCTUREID: "NUT101003009163",
                EXTERNALSYSTEM: "EMAIL",
                STATUS: "INPROG",
                "CONSECAM":"",
                "AFFECTEDDATE":getAffecteddate(),
                "REPORTDATE":getAffecteddate(),
                "OWNER":"Exssjlgaviri",
                "OWNERGROUP":"C-NUT-COL-CENTRO_DE_ADMIN_DE_DATOS_MAESTROS",
                DESCRIPTION_LONGDESCRIPTION: `Cambio contraseña - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`
            }
        ).then((response) =>{
            changeADSPassword(JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName)
            .then((responseADS) =>{
                console.log(responseADS);
                let data = JSON.parse(response.data.data); 
                SR = data.data["soapenv:Envelope"]["soapenv:Body"].CreateIBMSRTUSEGUIMResponse.SRMboKeySet.SR.TICKETID._text;
                let message = `Hola ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName},\nTu nueva contraseña del usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName} es ${responseADS.data.password}\n el caso generado fue el ${SR}`
                
                changeStateTicket({
                    "TICKETID":SR,
                    "CLASS":"SR",
                    "STATUS":"RESOLVED"
                }).then((response) =>{
                    SuccessModal(message);
                })
                .catch((err) =>{
                });
                sendWhatsMessage(message,JSON.parse(localStorage.getItem("InforUser"))[0].mobile)
                .then((response) =>{
                    console.log(response);
                }).catch((err) =>{
                    console.log(err);
                });
            })
            .catch((err) =>{
                console.log(err);
            }); 
        })
        .catch((err) =>{
            console.log(err);
        });
        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
        
    }

  return (
    
    <Grid container spacing={0} direction="column"
    alignItems="center"
    sx={{fontWeight: 300,
    marginTop: 1,
    fontSize: { xs:14 ,sm:20, md:25, lg:25 },
    fontFamily: "Bahnschrift Condensed", 
    background: props.backgroundColor,
    height: 70,
    borderRadius: 8,   
    width: "100%",
    display:OptionsPanicValueContext.visibleNormal}}
    >
    
    <Grid container spacing={0}
    direction="row"
    alignItems="center"
    sx={{fontWeight: 300,
    fontSize: { xs:14 ,sm:20, md:25, lg:25 },
    fontFamily: "Bahnschrift Condensed",
    height: 70,
    color:"black", 
    width: "100%"}}
    >   
        {
            props.rol === "User" ?
            <Grid item xs={3} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{height:"100%",textAlign: "center"}}
            >   
                <p onClick={cambiarCuentaCorreo} className='item-normal' >Recuperar contraseña correo</p>
            </Grid>:""
        }

        {
            props.rol === "User" ?
            <Grid item xs={3} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{height:"100%"}}
            >   
                <p onClick={()=>/*cambiarContrasenaADS*/cambiarCuentaCorreo} className='item-normal'>Recupera contraseña ADS </p>
            </Grid>:""
        }

        {
            props.rol === "User" ?
            <Grid item xs={3} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{height:"100%"}}
            >   
                <a href="tel:+573122542369" className='item-normal'>LLama a la mesa</a>
            </Grid>:""
        }

        {
            props.rol === "User" ?
            <Grid item xs={3} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{height:"100%"}}
            >   
                <a target={"_blank"}  href="https://mobility177-dsom-platform.trybmc.com/com.bmc.dsm.chatbot/index.html#/chatbot/window/IDGAP8E7HGN3JARTUB0HRSTYZIV0IT" className='item-normal'>Chatea con el BOT</a>
            </Grid>:""
        }

        {
            props.rol === "Technicians" ?
            <Grid item xs={6} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{height:"100%"}}
            >   
                <p onClick={()=>CommentTickectModal("")} className='item-normal'>Comentar caso</p>
            </Grid>:""
        }
        
        {
            props.rol === "Technicians" ?
            <Grid item xs={6} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{height:"100%"}}
            >   
                <p onClick={()=>CloseTickectModal("","Cerrar")} className='item-normal'>Cerrar caso</p>
            </Grid>:""
        }
      
    </Grid>
    </Grid>
  );
}




export {PanicNormalOptions}