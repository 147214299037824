import { Buffer } from "buffer";
import axios from "axios"


const createTicket = async (casoContacto)  =>{

    let auth = "Basic " + Buffer.from("admin" + ":" + "NutresaAxity2022%*").toString("base64");

    let body = {
        "REPORTEDBY": casoContacto.REPORTEDBY,
        "AFFECTEDPERSON": casoContacto.AFFECTEDPERSON,
        "DESCRIPTION":casoContacto.DESCRIPTION,
        "PLUSPCUSTOMER": casoContacto.PLUSPCUSTOMER,
        "CLASSSTRUCTUREID": casoContacto.CLASSSTRUCTUREID,
        "EXTERNALSYSTEM": casoContacto.EXTERNALSYSTEM,
        "STATUS": casoContacto.STATUS,
        "DESCRIPTION_LONGDESCRIPTION": casoContacto.DESCRIPTION_LONGDESCRIPTION,
        "CONSECAM":casoContacto.CONSECAM,
        "AFFECTEDDATE":casoContacto.AFFECTEDDATE,
        "REPORTDATE":casoContacto.AFFECTEDDATE,
        "OWNER":casoContacto.OWNER,
        "OWNERGROUP":casoContacto.OWNERGROUP
    }
    console.log(body)
 

    let reponse = axios.post(
    'https://r9m9489j99.execute-api.us-east-1.amazonaws.com/tickets/createTicket',
    body,
    /*{
        "headers":{
           "Authorization":auth
        }
    }*/
    ).then((res) => {
        console.log(res)
    return(
        {
            data:res,
            error:""
        }
    )
    }).catch((err) => {
        console.log(err)
    return(
        {
        data:"",
        error:err.response.data
        }
    )
    })

    return reponse;
}


export {createTicket}