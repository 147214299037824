import React from 'react'
import './HolidaysIcons.css'
import { Typography } from '@mui/material'
import { useAppContext } from '../../context/DirectoryContex'

const HolidaysIcon = (props) => {
  
  const { setOptionHoliday } = useAppContext()

  return (
    <div onClick={()=>console.log(setOptionHoliday(props.selectedOption))}  className="circle" style={{
      margin:"4px",
      marginTop: `${props.marginTop}px` }}>
        <div className='circleImage'>
          {props.icon}
        </div>  
        <Typography color='secondary' fontSize={15} className="text" style={{top:`${props.topText}%`}} >
          {props.text}
        </Typography>
    </div>
  )
}

export default HolidaysIcon