import React from 'react'
import { CardMedia } from '@mui/material'
import iconDoc from './iconDoc.svg'

export const IconDoc = (props) => {
    return (<CardMedia
        {...props}      
        component='img'
        alt='Imagen Login'
        image={iconDoc}
    />)
}
export default IconDoc 