import axios from "axios";
import {UpdateStateAuditors} from "../../services/auditorsService"
import { AlertSuccess,ErrorModal } from "../ComponentsUtilies/AlertsModal/Alerts"

export const UpdateAuditorsState=(id, load,setIsLoading)=>{
   setIsLoading(true)
    UpdateStateAuditors(id).then(data=>{
       if(data.idInterventor != null ){
         setIsLoading(false)
         AlertSuccess()
          load(true)
       }
    }).catch(error=>{ErrorModal("Operación con errores", error);setIsLoading(false)}) 


}