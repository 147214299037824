
const primerLetraMayusculaCadaPalabra = (value)=>{

    if(value){
        value = value.toLowerCase()
        .trim()
        .split(' ')
        .map( v => v[0].toUpperCase() + v.substr(1) )
        .join(' ')
    }
    
    return value;
}

const eliminarDuplicadosPorClaves = (arr, claves)=> {
    const mapaTemporal = new Map();
    const objetosUnicos = [];

    for (const objeto of arr) {
        const claveConcatenada = claves.map(clave => objeto[clave]).join('|');

        if (!mapaTemporal.has(claveConcatenada)) {
            mapaTemporal.set(claveConcatenada, true);
            objetosUnicos.push(objeto);
        }
    }

    return objetosUnicos;
}

const comparadorPorClaves = (claves) => {
    return function (a, b) {
        for (const clave of claves) {
            const valorA = a[clave];
            const valorB = b[clave];

            if (typeof valorA === 'number' && typeof valorB === 'number') {
                if (valorA < valorB) return -1;
                if (valorA > valorB) return 1;
            } else if (valorA instanceof Date && valorB instanceof Date) {
                if (valorA < valorB) return -1;
                if (valorA > valorB) return 1;
            } else {
                const stringA = String(valorA).toLowerCase();
                const stringB = String(valorB).toLowerCase();
                if (stringA < stringB) return -1;
                if (stringA > stringB) return 1;
            }
        }
        return 0;
    };
}


export{
    primerLetraMayusculaCadaPalabra,
    eliminarDuplicadosPorClaves,
    comparadorPorClaves
}