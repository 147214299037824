import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { TitleFirts } from "../ComponentsUtilies/Titles";
import {
  AlertSuccess,
  AlertError,
} from "../ComponentsUtilies/AlertsModal/Alerts";
import { CreateAuditors } from "../../services/auditorsService";
const Alert = withReactContent(Swal);

const AlertModalFormAuditor = ({ setLoad, setIsLoading }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (valores) => {
    //const idCategory=categoria.IdCategoria
    const envio = {
      nombre: valores.nombre,
      cedula: valores.cedula,
    };

    setIsLoading(true);
    CreateAuditors(envio)
      .then((r) => {
        setIsLoading(false);
        AlertSuccess();
        setLoad(true);
      })
      .catch((error) => {
        AlertError();
        setIsLoading(false);
      });

    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <TitleFirts>Agregar interventor</TitleFirts>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Nombre"
                {...register("nombre", { required: true })}
                sx={{ marginTop: 2,  width:"90%" }}
                helperText={
                  errors.nombre?.type === "required" &&
                  "Este campo es obligatorio!"
                }
                error={errors.nombre?.type === "required" && true}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Cedula"
                {...register("cedula", {
                  required: true,
                  pattern: /^([0-9])*$/,
                })}
                sx={{ marginTop: 2,  width:"90%" }}
                helperText={
                  (errors.cedula?.type === "required" &&
                    "Este campo es obligatorio!") ||
                  (errors.cedula?.type === "pattern" &&
                    "Este campo debe ser numerico")
                }
                error={
                  errors.cedula?.type === "required" ||
                  (errors.cedula?.type === "pattern" && true)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="success"
          sx={{ marginTop: 5,marginBottom: 1, width: 100, marginLeft: 3 }}
          type="submit"
        >
          Enviar
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ marginTop: 5,marginBottom: 1, width: 112, marginLeft: 3 }}
          onClick={() => Alert.close()}
        >
          Cancelar
        </Button>
      </form>
    </>
  );
};

export { AlertModalFormAuditor };

export const ModalCreateAuditors = (load) => {
  Alert.fire({
    width: "970px",
    showConfirmButton: false,
    showCancelButton: false,
    position: "top",
    html: <AlertModalFormAuditor load={load} />,
  });
};
