import React, { useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Paper, Button } from '@mui/material'
import { getAlertModal } from '../../hooks/getAlertModal'
import { Edit } from './Edit'
//import { PDFViewer } from '@react-pdf/renderer' <PDFViewer><CommitmentPDF employed={employed} /></PDFViewer>

export const List = ({ data, employed, setLoad }) => {

  const columns = useMemo(() => {
    return [
      {
        header: 'Id Compormiso',
        accessorKey: 'id'
      },
      {
        header: 'Motivo',
        accessorKey: 'motivo'
      },
      {
        header: 'Analisis',
        accessorKey: 'analisis'
      },
      {
        header: 'Fecha Inicio',
        accessorKey: 'fechaInicio'
      },
      {
        header: 'PDF',
        accessorKey: 'pdf',
        Cell: ({ cell }) => <Button
          component='a'
          href={cell.getValue()}
        >Descargar</Button>
      },
      {
        header: 'Estado',
        accessorKey: 'estado',
        Cell: ({ cell }) => cell.getValue()
          ? <Button variant='text' color='success'>Activo</Button>
          : <Button variant='text' color='error'>Inactivo</Button>
      },
      {
        header: 'Seguimientos',
        accessorKey: 'id',
        Cell: ({ cell }) => <Button
          onClick={getAlertModal(Edit, { setLoad, id: cell.getValue(), employed } )}
          color='secondary'
        >Seguimientos</Button>
      }
    ]
  }, [setLoad,employed])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <MaterialReactTable
        columns={columns}
        data={data}
      />
    </Paper>
  )
}

export default List 