import React from 'react'
import {TextField, Grid} from '@mui/material'
import {useForm} from 'react-hook-form'
import {TitleFirts} from '../../../ComponentsUtilies/TitleFirts'
import Save from '../../../ComponentsUtilies/Save'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

export const DismissalDate = ({action, close}) => {
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm()

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <TitleFirts>Eliminar empleado</TitleFirts>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          type='date'
          {...register('fechaEgreso', {required: 'Este campo es obligatorio!'})}
          InputProps={{
            startAdornment: <CalendarMonthOutlinedIcon style={{color: '#3C1053'}} />
          }}
          label='Fecha Egreso *'
          helperText={errors.fechaEgreso?.message}
          error={Boolean(errors.fechaEgreso)}
        />
      </Grid>
      <Grid item xs={12}>
        <Save
          onSave={handleSubmit((values) => action(values.fechaEgreso))}
          onCancel={close}
        />
      </Grid>
    </Grid>
  )
}
