import React from 'react'
import {Avatar, Box, Card, CardContent, Typography, Button} from '@mui/material'
import {useAppContext} from '../../../context/DirectoryContex'
import {getAlertModal} from '../../../hooks/getAlertModal'
import {Edit} from '../../Employees/Edit'

export const EmployeeTree = ({employee, show, setShow, isLoader}) => {
  const {user} = useAppContext()

  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Card
          onClick={
            user.authorization < 5 &&
            getAlertModal(Edit, {
              employeeId: employee.id,
              user,
              setLoad: () => {},
              setOption: () => {}
            })
          }
          sx={{
            boxShadow: '0px 9px 20px rgb(60, 16, 83)',
            backgroundColor: 'rgba(120, 60, 189, 0.4)',
            minHeight: '210px'
          }}
          raised={true}
        >
          <CardContent sx={{cursor: 'pointer', padding: '6px'}}>
            <Avatar sx={{width: 100, height: 100}} alt='Persona' src={employee.foto} />
            <Box sx={{width: 100}}>
              <Typography noWrap sx={{fontSize: 14}}>
                {employee.nombres}
              </Typography>
              <Typography sx={{fontSize: 14}}>{employee.primerApellido}</Typography>
              <Typography color='black' sx={{marginTop: '12px', fontSize: 9}}>
                {employee.cargo.nombre}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        {isLoader &&
          (show ? (
            <Button
              variant='contained'
              sx={{height: '20px'}}
              onClick={() => {
                setShow(false)
              }}
            >
              Ver menos
            </Button>
          ) : (
            <Button
              variant='contained'
              sx={{height: '20px'}}
              onClick={(event) => {
                setShow(true)
                setTimeout(() => {
                  const {x, y} = event.target.getBoundingClientRect()
                  window.scrollBy({
                    left: x - window.innerWidth / 2,
                    top: y - window.innerHeight / 2,
                    behavior: 'smooth'
                  })
                }, 100)
              }}
            >
              Ver mas
            </Button>
          ))}
      </Box>
    </Box>
  )
}
