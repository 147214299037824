import React from 'react'
import {TreeNode} from 'react-organizational-chart'
import {Typography, Grid, Box, Card, Button, CardContent} from '@mui/material'

export const SubprocesTree = ({children, sp, show, setShow}) => {
  return (
    <TreeNode
      label={
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          alignContent='center'
        >
          <Card
            sx={{
              boxShadow: '0px 9px 20px rgb(60, 16, 83)',
              backgroundColor: 'rgba(120, 60, 189, 0.4)',
              width: 200,
              height: 110
            }}
            raised={true}
          >
            <CardContent sx={{padding: '6px'}}>
              <Box>
                <Typography sx={{fontWeight: 'bold'}}>{sp.nombre}</Typography>
                <Typography color='black' sx={{fontSize: 12, marginBottom: '0'}}>
                  {sp.descripcion}
                </Typography>
              </Box>
            </CardContent>
          </Card>
          {show ? (
            <Button sx={{height: '20px'}} onClick={() => setShow(false)}>
              Ver menos
            </Button>
          ) : (
            <Button
              sx={{height: '20px'}}
              onClick={(event) => {
                setShow(true)
                setTimeout(() => {
                  const {x, y} = event.target.getBoundingClientRect()
                  window.scrollBy({
                    left: x - window.innerWidth / 2,
                    top: y - window.innerHeight / 2,
                    behavior: 'smooth'
                  })
                }, 100)
              }}
            >
              {' '}
              Ver mas{' '}
            </Button>
          )}
        </Grid>
      }
    >
      {children}
    </TreeNode>
  )
}
