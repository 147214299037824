import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, Grid, TextField, Autocomplete } from '@mui/material'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { TitleFirts } from '../ComponentsUtilies/Titles'
import { CreateSubProcess } from '../../services/processService'
import { EditSubProcess } from '../../services/processService'


const SubProcessesFormEdit = ({ setLoad, close, setIsLoading, setOption, processes, subProcess }) => {
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm()
  const [processOptions, setProcessOptions] = useState([])

  useEffect(() => {
    setProcessOptions(processes.map(p => (
      { label: p.NombreProceso, value: p.IdProceso }
    )))

    setValue("Nombre", subProcess.Nombre)
    setValue("Descripcion", subProcess.Descripcion)
    setValue("IdProceso", subProcess.procesoIdProceso)

  }, [])


  const onSubmit = (values) => {

    values.IdSubproceso = subProcess.IdSubProceso

    setIsLoading(true)
    EditSubProcess(values).then(d => {
      SucessModal('Operacion exitosa', 'El proceso se gurdo currectamente.')
      setLoad(true)
      setIsLoading(false)
      setOption('masivo')
    }).catch(error => {
      console.error(error)
      setIsLoading(false)
      ErrorModal('No se ejecuto la operacion', error)
    })

  }

  return (<>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={1} >
        <TitleFirts>Editar Sub-proceso</TitleFirts>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} >
            <TextField
              sx={{ maxWidth: '30rem', width: '85%' }}
              {...register('Nombre', { required: 'Este campo es obligatorio!' })}
              label='Nombre del sub-proceso *'
              helperText={errors.Nombre?.message}
              error={errors.Nombre}
            />
          </Grid>

          <Grid item xs={12} sm={6} >
            <TextField
              sx={{ maxWidth: '30rem', width: '85%' }}
              {...register('Descripcion', { required: 'Este campo es obligatorio!' })}
              label='Descripcion *'
              helperText={errors.Descripcion?.message}
              error={errors.Descripcion}
            />
          </Grid>

          <Grid item xs={12} sm={6} >
            <Controller
              name='IdProceso'
              rules={{ required: 'Este campo es obligatorio!' }}
              control={control}
              render={({ field: { onChange } }) =>
                <Autocomplete
                  disablePortal
                  options={processOptions}
                  defaultValue={processes.find(element => element.IdProceso == subProcess.procesoIdProceso).NombreProceso}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Proceso'
                      sx={{ maxWidth: '30rem', width: '85%' }}
                      helperText={errors?.proceso?.message}
                      error={Boolean(errors.proceso)}
                    />
                  }
                  onChange={(_, data) => onChange(data.value)}
                />
              }
            />
          </Grid>

        </Grid>

      </Grid>
      <Button
        variant='contained'
        color='success'
        type='submit'
        sx={{ marginTop: 5, marginBottom: 2, width: 100, marginLeft: 3 }}
      >
        Enviar
      </Button>
      <Button variant='contained' color='error' sx={{ marginTop: 5, marginBottom: 2, width: 112, marginLeft: 3 }} onClick={close}>Cancelar</Button>
    </form>
  </>)
}

export default SubProcessesFormEdit