import React from 'react'
import { CardMedia } from '@mui/material'
import logoAxity from './logoAxity.png'

export const LogoAxity = (props) => {
    return (<CardMedia
        {...props}      
        component='img'
        alt='Imagen Login'
        width='321'
        image={logoAxity}
    />)
}
export default LogoAxity 