
import * as React from 'react';
import { Button,Box,Grid,TextField,InputAdornment,Autocomplete} from "@mui/material";
import { TitleFirts } from '../ComponentsUtilies/Titles'; 
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2"
import { useForm,Controller  } from "react-hook-form"
import { queryOneTickects } from '../../services/queryTickects';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { Loading,AlertSuccess,AlertError,AlertErrorHTML,AlertSuccessHTML } from '../ComponentsUtilies/AlertsModal/Alerts';
import { isArray } from 'lodash';
import { queryTickectsBMC,queryCommentsTickectsBMC } from '../../services/casosBMC';
const SweetAlert = withReactContent(Swal);



const SearchTickect=()=>{
    const { register, handleSubmit,control,formState: { errors },watch,clearErrors,setError } = useForm();
    const LoadingModal = () => {
        Loading("Estamos buscando tu caso");
    }

    const SuccessModal = (caso,comentarios) => {
      console.log(caso)
      console.log(comentarios)
      let casoHtml = `
      <p style="text-align:left">
        <strong>
          Fecha creación
        </strong>:${caso["Reported Date"]}
      </p>
      <p style="text-align:left">
        <strong>
          Solicitante
        </strong>:${caso["First Name"] + " " + caso["Last Name"] }
      </p>
      <p style="text-align:left">
        <strong>
          Resolutor
        </strong>:${caso["Assigned Group"]}
      </p>
      <p style="text-align:left">
        <strong>
          Categoria 1
        </strong>:${caso["Categorization Tier 1"]}
      </p>
      <p style="text-align:left">
        <strong>
        Categoria 2
        </strong>:${caso["Categorization Tier 2"]}
      </p>
      <p style="text-align:left">
        <strong>
        Categoria 3
        </strong>:${caso["Categorization Tier 3"]}
      </p>
      <p style="text-align:left">
        <strong>
          Descripción
        </strong>:${caso.Description}
      </p>
      
      <p style="text-align:left">
        <strong>
          Estado
        </strong>:${caso.Status}
      </p>  
      `;

      if(comentarios){
        
        comentarios.forEach(caso => {
          casoHtml += `
        <br>
        <p style="text-align:left">
          <strong>
            Comentario
          </strong>
        </p>
        
        <p style="text-align:left">
          <strong>
            Fecha del comentario
          </strong>:${caso.values["Submit Date"]}
        </p>
        <p style="text-align:left">
          <strong>
            Reportado por:
          </strong>:${caso.values.Submitter}
        </p>
        <p style="text-align:left">
          <strong>
            Descripción
          </strong>:${caso.values.Description}
        </p>
        <p style="text-align:left">
          <strong>
            Descripción detallada
          </strong>:${caso.values["Detailed Description"]}
        </p>
        
        <br>
        `  
        });
        
      }
      
      /*let casoHtml = `
      <p style="text-align:left">
        <strong>
          Fecha creación
        </strong>:${caso.CREATIONDATE._text}
      </p>
      <p style="text-align:left">
        <strong>
          Solicitante
        </strong>:${caso.REPORTEDBY._text}
      </p>
      <p style="text-align:left">
        <strong>
          Resolutor
        </strong>:${caso.OWNER._text}
      </p>
      <p style="text-align:left">
        <strong>
          Descripción
        </strong>:${caso.DESCRIPTION._text}
      </p>
      <p style="text-align:left">
        <strong>
          Descripción larga
        </strong>:${caso.DESCRIPTION_LONGDESCRIPTION._text}
      </p>
      <p style="text-align:left">
        <strong>
          Estado
        </strong>:${caso.STATUS._text == "INPROG" ? "En progreso" : caso.STATUS._text}
      </p>`

      if(caso?.WORKLOG){
        casoHtml += 
        `<br>
        <p style="text-align:left">
          <strong>
            Notas:
          </strong>
        </p>`

        if(isArray(caso?.WORKLOG)){
          caso?.WORKLOG.forEach(element => {
            casoHtml +=
            `<p style="text-align:left">
              <strong>
                Fecha creación
              </strong>:${element.CREATEDATE._text}
            </p>
            <p style="text-align:left">
              <strong>
                Creador
              </strong>:${element.CREATEBY._text}
            </p>
            <p style="text-align:left">
              <strong>
                Titulo nota
              </strong>:${element.DESCRIPTION._text}
            </p>
            <p style="text-align:left">
              <strong>
                Descripción
              </strong>:${element.DESCRIPTION_LONGDESCRIPTION._text}
            </p><br>`  
          });
        }else{
          casoHtml +=
          `<p style="text-align:left">
            <strong>
              Fecha creación
            </strong>:${caso?.WORKLOG.CREATEDATE._text}
          </p>
          <p style="text-align:left">
            <strong>
              Creador
            </strong>:${caso?.WORKLOG.CREATEBY._text}
          </p>
          <p style="text-align:left">
            <strong>
              Titulo nota
            </strong>:${caso?.WORKLOG.DESCRIPTION._text}
          </p>
          <p style="text-align:left">
            <strong>
              Descripción
            </strong>:${caso?.WORKLOG.DESCRIPTION_LONGDESCRIPTION._text}
          </p>`
        }

      }*/

      AlertSuccessHTML(casoHtml,`Caso ${caso["Incident Number"]}`);
    }

    
    const searchTicket = (type) =>{
        console.log(type);
        LoadingModal();
        /*queryOneTickects(
            {
                "key":"TICKETID",
                "value":type.numCaso
            }
        ).then((response) =>{
            console.log(response)
            let data = response.data; 
            console.log(data)
            let SR = data["soapenv:Envelope"]["soapenv:Body"].QueryIBMSRTUSEGUIMResponse.IBMSRTUSEGUIMSet?.SR;
            if(SR){
              SuccessModal(SR);
            }else{
              AlertError("Caso no encontrado","El caso buscado no fue encontrado");
            }
          
            
            
            
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });*/

        queryTickectsBMC(`q='Incident Number'="${type.numCaso}"`)
        .then((response) =>{
            console.log(response)
            let data = response?.data?.data?.entries[0]?.values; 
            let SR = data;
            if(SR){

              queryCommentsTickectsBMC(type.numCaso)
              .then((responseComments)=>{
                SuccessModal(SR,responseComments?.data?.data?.entries);
              })
              .catch(()=>{
                AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
              })

              
            }else{
              AlertError("Caso no encontrado","El caso buscado no fue encontrado");
            }
            
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });

        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
    } 
    
    const onSubmit = (data) => {
      searchTicket(data)
    };
    return (
        <>
             
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
         <Grid container spacing={1}>
         
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><ConfirmationNumberIcon style={{color:'#3C1053'}} /></InputAdornment>),
              }}
              {...register("numCaso", { required: true,pattern: /^[A-Za-z|0-9| ]+$/i })}
              label="Número de caso *"
              variant="outlined"
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              helperText={(errors.numCaso?.type === 'required' && "Este campo es obligatorio!") ||
              (errors.numCaso?.type === 'pattern' && "En este campo son solo permitidas letras y números!")}
              error={
                (errors.numCaso?.type === 'required' || errors.numCaso?.type === 'pattern')
                && true
              }
            />
          </Grid>
          
        </Grid>

     
        
      </Grid>
        <Button 
            variant="contained"
            color="success" 
            sx={{ marginTop: 5, width: 100, marginLeft: 3 }}
            onClick={handleSubmit(onSubmit)}
        >
            Buscar
        </Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => SweetAlert.close()}>Cancelar</Button>
    
        </>
    )
}







const SearchTickectModal = () => {
    SweetAlert.fire({
        customClass:"classFormModal",
        title:"Buscar Caso",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top",
      allowOutsideClick: true,
      showCloseButton: true,
      html: <SearchTickect />
      
    })
  }

export {SearchTickectModal}