import { SvgIcon } from '@mui/material'
export const IconMap = (props) => {
    return (<SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;map&#34;">
                <g id="Group">
                    <path id="Vector" d="M6.03312 5.8396C6.03312 5.61741 6.20854 5.43957 6.43304 5.43957C6.65178 5.43957 6.83293 5.62065 6.83293 5.8396C6.83293 6.05854 6.65178 6.23962 6.43304 6.23962C6.21428 6.23962 6.03312 6.05852 6.03312 5.8396Z" fill="#72E4FA" stroke="#C8C8C6" />
                    <path id="Vector_2" d="M17.0382 2.89928L2.89893 17.0401C1.38809 16.3732 0.5 14.9242 0.5 12.7713V5.22913C0.5 2.61615 2.616 0.5 5.22856 0.5H12.7699C14.9226 0.5 16.3714 1.38818 17.0382 2.89928ZM6.40746 10.2202C6.98231 10.2202 7.56969 10.017 8.02295 9.57662C8.95833 8.6868 10.1435 7.1364 9.70245 5.23385C9.29515 3.45608 7.72838 2.68608 6.40746 2.68608C5.08769 2.68608 3.52078 3.45496 3.11278 5.2235C2.66146 7.13695 3.84883 8.68872 4.7953 9.57979C5.24274 10.0039 5.82598 10.2202 6.40746 10.2202Z" fill="#72E4FA" stroke="#C8C8C6" />
                    <path id="Vector_3" d="M15.2936 16.9571C14.6107 17.31 13.7722 17.5 12.7689 17.5H5.75966L10.7936 12.4655L15.2936 16.9571ZM15.7207 16.677L15.4397 16.9579L15.4394 16.9582L15.3671 17.0305L15.3675 17.0308L15.4396 16.9586L15.4399 16.9582L15.7207 16.677Z" fill="#72E4FA" stroke="#C8C8C6" />
                    <path id="Vector_4" d="M16.9576 15.2958L12.4571 10.7948L17.5 5.75134V12.7703C17.5 13.7756 17.3093 14.6213 16.9576 15.2958Z" fill="#72E4FA" stroke="#C8C8C6" />
                </g>
            </g>
        </svg>
    </SvgIcon>)
}