import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { TitleFirts } from "../ComponentsUtilies/Titles";
import { Grid, TextField, Button, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    AlertSuccess,
    AlertErrorHTML,
  } from "../ComponentsUtilies/AlertsModal/Alerts";
const AlertSweet = withReactContent(Swal);

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [step, setStep] = useState(1); // Step 1: Forgot Password, Step 2: Reset Password
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleForgotPassword = async () => {
    if(email !== ""){
    setErrorPassword("");
    try {
      await Auth.forgotPassword(email);
      setStep(2);
    } catch (error) {
      setErrorPassword("Error enviando el código: " + JSON.stringify(error));
      console.error("Error sending reset code: ", error);
    }

    }
  };

  const handleResetPassword = async () => {
    setErrorPassword("");
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
      AlertSuccess()
      console.log("Password reset successful");
      // Puedes redirigir al usuario a una página de inicio de sesión o mostrar un mensaje de éxito aquí.
    } catch (error) {
      console.error("Error resetting password: ", error);
      setErrorPassword(`
      
      Error reestableciendo la contraseña:
      
      vaida si el código ya se venció, ya has intentado demasiadas veces cambiar la contraseña hoy o la contraseña no cumple con lo siguiente
      
      - La contraseña debe tener un mínimo de 8 caracteres.\n
    - La contraseña debe contener al menos un número.\n
    - La contraseña debe contener al menos una mayúscula.\n
    - La contraseña debe contener al menos una minúscula.\n
    - La contraseña debe contener al menos un caracter especial.\n
    ` + JSON.stringify(error));
    }
  };

  return (
    <Grid
      container
      direction="row"
      display={"flex"}
      justifyContent={"center"}
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={6}>
        <TitleFirts>Olvidé mi contraseña</TitleFirts>
      </Grid>
      {step === 1 ? (
        <Grid
          container
          spacing={1}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Ingresa tu usuario"
              type="text"
              {...register("usuario", { required: true })}
              sx={{ marginTop: 2, width: "90%" }}
              helperText={
                errors.usuari?.type === "required" &&
                "Este campo es obligatorio!"
              }
              error={errors.usuari?.type === "required" && true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Button
            variant="contained"
            color="success"
            sx={{ marginTop: 5, marginBottom: 1, width: 200, marginLeft: 3 }}
            onClick={handleForgotPassword}
          >
            Enviar código de recuperación
          </Button>
        </Grid>
      ) : (
        <Grid
          container
          spacing={1}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <TitleFirts>Restablecer contraseña</TitleFirts>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Ingresa el código que se te envio al correo"
              type="text"
              {...register("codigo", { required: true })}
              sx={{ marginTop: 2, width: "90%" }}
              helperText={
                errors.codigo?.type === "required" &&
                "Este campo es obligatorio!"
              }
              error={errors.codigo?.type === "required" && true}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Ingresa la nueva contraseña"
              type="text"
              {...register("contrasena", { required: true })}
              sx={{ marginTop: 2, width: "90%" }}
              helperText={
                errors.contrasena?.type === "required" &&
                "Este campo es obligatorio!"
              }
              error={errors.contrasena?.type === "required" && true}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>

          <Button
            variant="contained"
            color="success"
            sx={{ marginTop: 5, marginBottom: 1, width: 200, marginLeft: 3 }}
            onClick={handleResetPassword}
          >
            Restablecer contraseña
          </Button>
        </Grid>
      )}

      {errorPassword ? (
        <Grid item xs={12}>
          <Alert severity="error">{errorPassword}</Alert>
        </Grid>
      ) : (
        ""
      )}

      <Button
        variant="contained"
        color="error"
        sx={{ marginTop: 5, marginBottom: 1, width: 112, marginLeft: 3 }}
        onClick={() => AlertSweet.close()}
      >
        Cancelar
      </Button>
    </Grid>
  );
};

export default ForgotPassword;
