import React from 'react'
import {Button} from '@mui/material'
import {useAppContext} from '../../../context/DirectoryContex'
import {UpdateStateEmploye, GetEmployeesAll} from '../../../services/employeesService'
import {AlertError, AlertSuccess} from '../../ComponentsUtilies/AlertsModal/Alerts'
import {getAlertModal} from '../../../hooks/getAlertModal'
import {DismissalDate} from './DismissalDate'

export const UpdateState = ({id, isActive}) => {
  const {setCounts, setEmployees, user, setEmployee, setLeaders, setIsLoading} =
    useAppContext()
  const onSubmit = (employeeId, fechaEgreso) => {
    console.log(employeeId, fechaEgreso)
    setIsLoading(true)
    UpdateStateEmploye(employeeId, fechaEgreso)
      .then(() => {
        AlertSuccess()
        return GetEmployeesAll(user.supplier)
      })
      .then((data) => {
        setIsLoading(false)
        setEmployees(data.employees)
        setCounts(data.counts)
        if (Array.isArray(data.employees))
          setEmployee(data.employees.find((e) => e.id === id))
        setLeaders(
          Object.keys(data.leaders).map((leader) => ({
            label: data.leaders[leader],
            value: leader
          }))
        )
      })
      .catch((error) => {
        console.error(error)
        AlertError()
      })
  }

  return isActive ? (
    <Button
      variant='contained'
      color='error'
      onClick={getAlertModal(DismissalDate, {
        action: (date) => onSubmit(id, date)
      })}
    >
      Inactivar
    </Button>
  ) : (
    <Button variant='contained' color='success' onClick={() => onSubmit(id, null)}>
      Activar
    </Button>
  )
}
