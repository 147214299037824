import React, { useMemo,useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { Typography, Grid, Box, Button,TextField,Autocomplete } from '@mui/material'
import { useAppContext } from '../../context/DirectoryContex'
import { useTheme } from '@emotion/react'
import Info from '../../ComponentsUtilies/Info'
import IconWoman from '../../media/IconWoman'
import { IconUser } from '../../media/IconUser3'
import { Label } from '../../ComponentsUtilies/Label'
import { IconDoc } from '../../media/IconDoc'
import { IconLeader } from '../../media/IconLeader'
import { IconProcess } from '../../media/IconProcess'
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { ExportToCsv } from 'export-to-csv'; 
import { IconFather } from '../../media/IconFather'
import { IconWomenHoliday } from '../../media/IconWomenHoliday'
import IconPeople from '../../media/IconPeopleP'
import IconMan from '../../media/IconMan'

const cellProps = {
  color: 'black',
  fontSize: 14,
  noWrap: true,
  margin: '0.1rem'
}

export const ListAllHolidays = ({ setOption, columnFiltersA, isLoading }) => {
  const initialFilters = [
    { id: 'activo', value: '1' },
    { id: 'sexo', value: '' },
    { id: 'nombre', value: '' },
    { id: 'cedula', value: '' },
    { id: 'lider', value: '' },
    { id: 'proceso', value: '' }
  ]

  const { palette } = useTheme()
  //const { employees, isLoading } = useInfo()
  const { employee, employees, setEmployee,leaders,processOptions,optionHoliday } = useAppContext()
  let holidaysEmployees = [];
  if(optionHoliday === "madres"){
    holidaysEmployees = employees.filter(element => element.sexo==="FEMENINO" && element.hijos==="SI" && element.estado=="1" )
  }else if(optionHoliday === "padres"){
    holidaysEmployees = employees.filter(element => element.sexo==="MASCULINO" && element.hijos==="SI" && element.estado=="1" )
  }else if(optionHoliday === "madresPadres"){
    holidaysEmployees = employees.filter(element =>  element.hijos==="SI" && element.estado=="1" )
  }else if(optionHoliday === "hombres"){
    holidaysEmployees = employees.filter(element =>  element.sexo==="MASCULINO" && element.estado=="1" )
  }else if(optionHoliday === "mujeres"){
    holidaysEmployees = employees.filter(element =>  element.sexo==="FEMENINO" && element.estado=="1" )
  }
  
  const [columnFilters, setColumnFilters] = useState([...initialFilters])
  //if (!employee && employees.length > 0) setEmployee(employees[0])

  const onSearch = (id, value) => {
    setColumnFilters((state) => {
        state[id] = { ...state[id], value: value || '' }
        return [...state]
    })
    
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        id: 'id',
        maxSize: 2,
        minSize: 1,
        header: <Typography></Typography>,
        Cell: ({ row }) => <Typography {...cellProps} sx={{ paddingLeft: 1 }} >{row.index + 1}.</Typography>
      },
      {
        accessorKey: 'estado',
        id: 'activo',
      },
      {
        accessorKey: 'sexo',
        id: 'sexo',
      },
      {
        accessorKey: 'nombreCompleto',
        id: 'nombre',
        header: <Typography >Nombre completo</Typography>,
        Cell: ({ cell }) => <Typography {...cellProps} >{cell.getValue()}</Typography>
      },
      {
        accessorKey: 'cedula',
        id: 'cedula',
        maxSize: 9,
        minSize: 3,
        header: <Typography >Cédula</Typography>,
        Cell: ({ cell }) => <Typography {...cellProps} >{cell.getValue()}</Typography>
      },
      {
        accessorKey: 'liderName',
        id: 'lider',
        maxSize: 9,
        minSize: 3,
        header: <Typography >Líder</Typography>,
        Cell: ({ cell }) => <Typography {...cellProps} >{cell.getValue()}</Typography>
      },
      {
        accessorKey: 'processName',
        id: 'proceso',
        header: <Typography >Proceso</Typography>,
        Cell: ({ cell }) => <Typography {...cellProps} >{cell.getValue()}</Typography>
      }
    ],
    []
  )

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ["Nombre Completo","Cedula","Proceso"],
  };

  const handleExportData = () => {

    let dataCSV = []

    holidaysEmployees.forEach(element => {
      dataCSV.push({
        nombreCompleto:element.nombreCompleto,
        cedula:element.cedula,
        processName:element.processName
      }
      )
    });

    csvExporter.generateCsv(dataCSV);
  };

  const csvExporter = new ExportToCsv(csvOptions);

  return ( <>
  <Grid item xs={12}>
    <Typography color='primary' fontSize={24} >
      {
        optionHoliday === "madres" ?
          ("Día de la madre")
        : optionHoliday === "padres" ?
          ("Día del padre")
        : optionHoliday === "madresPadres" ?
          ("Día del niño")
        : optionHoliday === "hombres" ?
          ("Día del hombre")
        : optionHoliday === "mujeres" ?
          ("Día de la mujer")
        : <></>
      }
      
    </Typography>
  </Grid>
  
    <Grid container xs={12} md={12} sx={{marginBottom:2,marginTop:1}}   >
      
      <Grid item xs={12} sm={4}  >
          <Info
              minWidth={"50%"}
              onClick={()=>handleExportData()}
              Icon={
                optionHoliday === "madres" ?
                  (<IconWomenHoliday color="red" width="30" height="30"/>)
                : optionHoliday === "padres" ?
                  <IconFather color="blue" width="30" height="30"/>
                : optionHoliday === "madresPadres" ?
                  <IconPeople/>
                : optionHoliday === "mujeres" ?
                  <IconWoman/>
                : optionHoliday === "hombres" ?
                  <IconMan/>
                : <></>
              }
              isSelected={true }
              total={holidaysEmployees.length}
          >
            {
              optionHoliday === "madres" ?
                ("Cantidad de madres")
              : optionHoliday === "padres" ?
                ("Cantidad de padres")
              : optionHoliday === "madresPadres" ?
                ("Empleados con hijos")
              : optionHoliday === "hombres" ?
                ("Cantidad de hombres")
              : optionHoliday === "mujeres" ?
                ("Cantidad de mujeres")
              : <></>
            }
        </Info>
      </Grid>
      <Grid item xs={12} sm={8}  sx={{ display: "flex", justifyContent: "flex-end",paddingRight:2,paddingTop:2 }}>
        
          <Button
              startIcon={
                <SimCardDownloadOutlinedIcon
                 style={{ fontSize: "30px" }} 
                />
              }
              onClick={()=>handleExportData()}
              color={'background'}
              sx={{ width: '200px',  fontWeight: 600,marginBottom:2}}
          >Descargar plantilla completa</Button>
        
      </Grid>
      
    </Grid>   

    <Grid item xs={12} sx={{marginTop:2}}>
        <Grid container spacing={1}>

            <Grid item xs={6} md={2.5} >
                <TextField
                    label={<Label Icon={<IconUser />}>Nombre completo</Label>}
                    onChange={({ target }) => onSearch(2, target.value)}
                    value={columnFilters[2].value}
                />
            </Grid>

            <Grid item xs={6} md={2.5} >
                <TextField
                    label={<Label Icon={<IconDoc />}>Número documento</Label>}
                    onChange={({ target }) => onSearch(3, target.value)}
                    value={columnFilters[3].value}
                />
            </Grid>

            <Grid item xs={6} md={2.5} >
                <Autocomplete
                    disablePortal
                    options={leaders}
                    onInputChange={(_, value) => onSearch(4, value)}
                    value={{value: columnFilters[4].value, label: columnFilters[4].value }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={<Label Icon={<IconLeader />}>Líder</Label>}
                        />
                    }
                />
            </Grid>
            <Grid item xs={6} md={2.5} >
                <Autocomplete
                    disablePortal
                    options={processOptions}
                    onInputChange={(_, value) => onSearch(5, value)}
                    value={{value: columnFilters[5].value, label: columnFilters[5].value }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={<Label Icon={<IconProcess />}>Proceso</Label>}
                        />
                    }
                />
            </Grid>

            <Grid item xs={6} md={2}
                container
                display='flex'
                alignItems='flex-end'
                sx={{ maxHeight: '80%' }}
            >
                
                

              <Grid item xs={12}>
                  <CleaningServicesIcon
                      onClick={() => setColumnFilters([...initialFilters])}
                      fontSize="large"
                      sx={{cursor:"pointer"}}
                  />    
              </Grid>
              
              

                
            </Grid>

        </Grid>
    </Grid>

  <Grid item xs={12}>
    <MaterialReactTable
      columns={columns}
      data={holidaysEmployees}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableSorting={false}
      enableTopToolbar={false}
      //enablePagination={false}
      //enableColumnResizing
      initialState={{
        isLoading: true,
        columnVisibility: { sexo: false, activo: false }
      }}
      state={{ isLoading, columnFilters }}
      muiTableBodyProps={{
        sx: {
          borderCollapse: 'separate',
          border: `0.6rem solid ${palette.background.main}`,
          // borderRadius: '10px',
          // maxHeight: '50vh',
          // overflow: 'auto'
        }
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => {
          setOption('individual')
          setEmployee(row.original)
        },
        sx: {
          cursor: 'pointer',
          borderCollapse: 'separate',
          backgroundColor: row.index % 2 === 0 ? 'white' : palette.background.main
        }
      })}
      muiTableBodyCellProps={{ sx: { padding: '0', border: 'none' } }}
      
    />
  </Grid>
  </>)
}

export default ListAllHolidays