import axios from 'axios'

const URL = 'https://0yets2xo9j.execute-api.us-east-1.amazonaws.com'
 //const URL = 'http://localhost:4000'

export const getOptions = async (supplier) => {
  return Promise.all([
    axios
      .get(URL + '/positions/all')
      .then(({data}) => data.map((item) => ({label: item.nombre, value: item.idCargo}))),
    axios
      .get(URL + '/directors/all')
      .then(({data}) =>
        data.map((item) => ({label: item.nombre, value: item.idDirector}))
      ),
    axios
      .get(URL + '/auditors/all')
      .then(({data}) =>
        data.map((item) => ({label: item.nombre, value: item.idInterventor}))
      ),
    axios
      .get(URL + '/process/all')
      .then(({data}) =>
        data.map((item) => ({label: item.NombreProceso, value: item.IdProceso}))
      ),
    axios
      .get(URL + '/subprocess/all')
      .then(({data}) =>
        data.map((item) => ({label: item.Nombre, value: item.IdSubProceso}))
      ),
    axios
      .get(URL + '/adminpep/all')
      .then(({data}) => data.map((item) => ({label: item.pep, value: item.Id}))),
    axios.get(URL + '/employees/all/' + supplier).then(({data}) => {
      const leaders = data.reduce((acum, employee, _idx, all) => {
        if (!acum[employee.lider]) {
          const leader = all.find((e) => e.cedula === Number(employee.lider)) || {
            nombre: ''
          }
          acum[employee.lider] = leader.nombre
        }
        return acum
      }, {})
      return Object.entries(leaders).map(([value, label]) => ({label, value}))
    })
  ]).catch((err) => console.error(err))
}

export const getParameters = async (supplier) => {
  const positions = await axios.get(URL + '/positions/all')
  const directors = await axios.get(URL + '/directors/all')
  const auditors = await axios.get(URL + '/auditors/all')
  const employees = await axios.get(URL + '/employees/all/' + supplier)
  const employeesProcess = await axios.get(URL + '/process/all')
  const employeesSubProcess = await axios.get(URL + '/subprocess/all')

  return [
    positions.data,
    directors.data,
    auditors.data,
    employees.data,
    employeesProcess.data,
    employeesSubProcess.data
  ]
}

export const createEmploye = async (object) => {
  const request = await axios.post(URL + '/employees/create', object, {
    headers: {'Content-Type': 'multipart/form-data'}
  })
  if (request.status !== 200) throw new Error(request.data)
  return request.data
}

export const updateEmploye = async (id, object) => {
  console.log("lo que se envia pa", object )
  const request = await axios.put(`${URL}/employees/update/${id}`, object, {
    headers: {'Content-Type': 'multipart/form-data'}
  })
  return request.data
}

export const GetEmployeesAll = async (supplier) => {
  const request = await axios.get(URL + '/employees/all/' + supplier)
  return request.data.reduce(
    (data, employee, _, all) => {
      if (!data.leaders[employee.lider]) {
        const {nombre} = all.find((e) => e.cedula === Number(employee.lider)) || {
          nombre: ''
        }
        data.leaders[employee.lider] = nombre
      }
      if (!data.process.find((p) => p?.id === employee.procesoId)) {
        data.process.push(employee.proceso)
      }
      if (Boolean(employee.estado)) {
        data.counts.active += 1
        if (employee.sexo === 'MASCULINO') data.counts.man += 1
        else if (employee.sexo === 'FEMENINO') data.counts.woman += 1
      } else data.counts.inactive += 1
      employee.liderName = data.leaders[employee.lider]
      employee.processName = employee?.proceso?.nombre
      data.employees.push(employee)
      return data
    },
    {
      leaders: {},
      employees: [],
      process: [],
      counts: {
        inactive: 0,
        active: 0,
        man: 0,
        woman: 0
      }
    }
  )
}

export const UpdateStateEmploye = async (id, fechaEgreso) => {
  const request = await axios.put(URL + '/employees/' + id, {fechaEgreso})
  return request.data
}

export const getEmployee = async (id) => {
  const request = await axios.get(URL + '/employees/' + id)
  return request.data
}

export const searchEmployee = async (cuerys) => {
  cuerys = Object.entries(cuerys).reduce((acum, [key, value]) => {
    if (acum !== '?') acum += '&'
    acum += `${key}=${value}`
    return acum
  }, '?')
  const request = await axios.get(URL + '/employees/search' + cuerys)
  return request.data
}
