import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AxityThemeProvider } from '../../containers/themes/AxityThem';
import { Grid, TextField, Button,Autocomplete } from "@mui/material"
import { useForm } from "react-hook-form"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"
import {CreateAdminPep} from "../../services/adminPepService"
const Alert= withReactContent(Swal)

const AlertModalFormAdministracion=({load})=>{
    const { register, handleSubmit, reset,formState: { errors } } = useForm()
    const onSubmit = (valores) => {
        //const idCategory=categoria.IdCategoria
        const envio = {
          "familia": valores.familia,
          "linea": valores.linea,
          "sublinea": valores.sublinea,
          "pep": valores.pep
        }
        
        CreateAdminPep(envio).then(r => {
          if (r.status != 500) {
            AlertSuccess()
            load(true)
    
          }
        }).catch(error => console.log(error))
    
    
        reset()
    
      }

 return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
        <TitleFirts>Agregar pep</TitleFirts>
        <Grid container spacing={1}>

          <Grid item sm={12}>
          

            <TextField  id="outlined-basic" label="Familia" {...register("familia",{required: true})} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.familia?.type === 'required' && "Este campo es obligatorio!") }
              error={
                (errors.familia?.type === 'required')
                && true
              } />
          
          </Grid>

          <Grid item sm={12}>

       


          <TextField  id="outlined-basic" label="Sublinea" {...register("sublinea",{required: true})} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.sublinea?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.sublinea?.type === 'required') 
                && true
              } />        
            
          </Grid>


          <Grid item sm={12}>

         
          <TextField  id="outlined-basic" label="Linea" {...register("linea",{required: true})} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.sublinea?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.linea?.type === 'required') 
                && true
              } />        
              
          </Grid>



          <Grid item sm={12}>
          

          <TextField id="outlined-basic" 
          label="Pep"
           {...register("pep",{required: false})} 
          variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: "auto" }} helperText={(errors.pep?.type === 'required' && "Este campo es obligatorio!")  }
              error={
                (errors.pep?.type === 'required') 
                && true
              } />        
           
          </Grid>


        </Grid>




      </Grid>
      <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
    </form>
  </>
 )
}

export const ModalCreateAdminPep=(load)=>{
    Alert.fire({
        width: "970px",
        showConfirmButton: false,
        showCancelButton: false,
        position: "top",
        html: <AxityThemeProvider><AlertModalFormAdministracion load={load} /></AxityThemeProvider> 
    })
}