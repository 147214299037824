import React, {useMemo} from 'react'
import MaterialReactTable from 'material-react-table'
import {Typography, Button, Grid} from '@mui/material'
import {useTheme} from '@emotion/react'
import {getAlertModal} from '../../hooks/getAlertModal'
import {Form} from './Form'
import {useAppState} from '../../context/DirectoryContex'

const cellProps = {
  color: 'black',
  fontSize: 14,
  noWrap: true,
  m: 1
}

export const ListTable = () => {
  const [suppliers, reload, isLoading] = useAppState('suppliers')

  const {palette} = useTheme()
  const columns = useMemo(
    () => [
      {
        id: 'nombre',
        accessorKey: 'nombre',
        header: <Typography>Nombre</Typography>,
        Cell: ({cell}) => <Typography {...cellProps}>{cell.getValue()}</Typography>
      },
      {
        id: 'descripcion',
        accessorKey: 'descripcion',
        header: <Typography>Descripcion</Typography>,
        Cell: ({cell}) => (
          <Typography sx={{marginLeft: 2}} {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      },
      {
        id: 'uuid',
        accessorKey: 'uuid',
        header: <Typography>Acciones</Typography>,
        Cell: ({row}) => (
          <Button
            color='secondary'
            sx={{padding: 0}}
            onClick={getAlertModal(Form, {
              title: 'Editar proveedor',
              supplier: row.original,
              reload: reload
            })}
          >
            Editar
          </Button>
        )
      }
    ],
    []
  )

  if (!suppliers) return <></>

  return (
    <Grid item xs={12}>
      <MaterialReactTable
        columns={columns}
        data={suppliers}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        enablePagination={false}
        initialState={{
          isLoading: true
        }}
        state={{isLoading: isLoading}}
        muiTableBodyProps={{
          sx: {
            borderCollapse: 'separate',
            border: `0.6rem solid ${palette.background.main}`
          }
        }}
        muiTableBodyRowProps={({row}) => ({
          sx: {
            cursor: 'pointer',
            borderCollapse: 'separate',
            backgroundColor: row.index % 2 === 0 ? 'white' : palette.background.main
          }
        })}
        muiTableBodyCellProps={{sx: {padding: '0', border: 'none'}}}
      />
    </Grid>
  )
}
