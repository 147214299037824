import axios from "axios";

const URL = "https://0yets2xo9j.execute-api.us-east-1.amazonaws.com";
// const URL = "http://localhost:4000";

export const ManagementsAll = async () => {
    const request = await axios.get(URL + "/managements/all");
    return request.data;
}
export const GetManagements = async (id) => {
    const request = await axios.get(URL + "/managements/" + id);
    return request.data;
}

export const UpdateStateManagements = async (id) => {
    const request = await axios.post(URL + "/managements/updateState/" + id);
    return request.data;
}

export const CreateManagements = async (object) => {
    const request = await axios.post(URL + "/managements/create", object);
    return request.data;
}

export const EditManagements = async (id, object) => {
    console.log("object.editManagements: ", object);
    const request = await axios.post(URL + "/managements/edit/" + id, object);
    return request.data;
}