import {createTheme} from '@mui/material/styles'
import {palette as defaultPalette} from '@mui/system'

export const axity = createTheme({
  palette: {
    ...defaultPalette,
    primary: {
      main: 'rgba(60, 16, 83, 1)'
    },
    secondary: {
      main: 'rgba(4, 214, 255, 1)'
    },
    success: {
      main: 'rgba(6, 213, 172, 1)'
    },
    error: {
      main: 'rgba(244, 54, 76, 1)'
    },
    warning: {
      main: 'rgba(255, 131, 42, 1)'
    },
    info: {
      main: 'rgba(120, 60, 189, 1)'
    },
    action: {
      main: 'rgba(255, 8, 161, 1)'
    },
    disabled: {
      main: 'rgba(200, 201, 199, 1)'
    },
    placeholder: {
      main: 'rgba(136, 136, 136, 1)'
    },
    background: {
      main: 'rgba(242, 242, 242, 1)'
    },
    calido: {
      main: 'rgba(193, 38, 184, 1)'
    },
    frio: {
      main: 'rgba(150, 60, 190, 1)'
    },
    uva: {
      main: 'rgba(54, 38, 102, 1)'
    }
  },
  gradient: {
    uva: ({palette}) => `linear-gradient(${palette.secondary.main}, ${palette.uva.main})`,
    gris: `linear-gradient(90deg, rgba(60, 16, 83, 0.5) 1%, white 60%)`,
    kiwi: ({palette}) =>
      `linear-gradient(135deg, ${palette.success.main}, ${palette.primary.main} 40%, ${palette.calido.main} 99%)`
  },
  border: {
    container: ({palette}) => `6px solid ${palette.disabled.main}`
  },
  shadows: [
    'none',
    '1px 1px 2px -2px rgba(0, 0, 0, 0.5)',
    '1px 1px 2px -1px rgba(0, 0, 0, 0.5)',
    '2px 2px 3px rgba(0, 0, 0, 0.5)',
    '1px 1px 3px rgba(0, 0, 0, 0.5)',
    '2px 2px 3px rgba(0, 0, 0, 0.5)',
    '2px 2px 4px rgba(0, 0, 0, 0.5)',
    '3px 3px 3px rgba(0, 0, 0, 0.5)',
    '3px 3px 4px rgba(0, 0, 0, 0.5)'
  ],
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: ({theme}) => ({
          textTransform: 'none',
          borderRadius: '13px',
          boxShadow: theme.shadows[3],
          height: '100%'
        })
      }
    },
    MuiTypography: {
      defaultProps: {
        color: 'primary',
        fontWeight: '600'
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true
      },
      styleOverrides: {
        root: ({theme}) => ({
          color: theme.palette.primary.main,
          border: 'none'
        })
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
        style: {marginTop: '1.4rem'}
      },
      styleOverrides: {
        root: ({theme}) => ({
          color: theme.palette.primary.main,
          border: 'solid 1px black',
          backgroundColor: 'white',
          minHeight: '2.3rem',
          overflowX: 'hidden',
          overflowY: 'auto',
          padding: '0 0.2rem',
          borderRadius: '9px'
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({theme}) => ({
          color: theme.palette.primary.main,
          border: 'solid 1px black',
          borderRadius: '9px',
          backgroundColor: 'white',
          minHeight: '2.3rem',
          maxHeight: '6.3rem',
          overflowX: 'hidden',
          overflowY: 'auto',
          padding: '0 0.2rem'
        })
      }
    }
  }
})
