import React from 'react'
import { CardMedia } from '@mui/material'
import iconAxity from './iconAxity.png'

export const IconAxity = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        width='321'
        image={iconAxity}
    />)
}
export default IconAxity 