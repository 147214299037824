import axios from "axios"
import { Buffer } from "buffer";

export const changeEmailPassword = async(email)=>{
    let auth = "Basic " + Buffer.from("admin" + ":" + "NutresaAxity2022%*").toString("base64");
    const request= await axios.post(`https://r9m9489j99.execute-api.us-east-1.amazonaws.com/changeEmailPassword/changeEmailPassword`,{
        email:email,
    }/*,
    {
        "headers":{
           "Authorization":auth,
           "Access-Control-Allow-Origin":"*"
        }
    }*/)
   const response= await request.data
   return response
}