import React, { useState } from 'react'
import { useChecks } from './useCheck'
import { Button, Typography, Grid, List } from '@mui/material'
import { getAlertModal } from '../../../hooks/getAlertModal'
import { Item } from './Item'
import { Create } from './Create'

export const CheckList = ({ processId }) => {
    const { checks, isLoading, setLoad } = useChecks(processId)
    if (isLoading || !processId || !checks) return <>Cargando...</>
    return <Grid container spacing={0}>
        <Grid container>
            <Button
                color='success'
                onClick={getAlertModal(Create, { processId, setLoad })}
            >
                Agegar check list
            </Button>
        </Grid>
        <List >{
            checks.reduce((acum, item, idx) => {
                if (!Boolean(item.chequeoId)) {
                    acum.push(<Item
                        key={idx}
                        check={item}
                        checks={checks}
                        setLoad={setLoad}
                    />)
                }
                return acum
            }, [])
        }</List>
    </Grid>
}

export default CheckList