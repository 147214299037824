const departamentos = [
    { label: '91 - AMAZONAS' , value:'91'},
    { label: '05 - ANTIOQUIA' , value:'05'},
    { label: '81 - ARAUCA' , value:'81'},
    { label: '88 - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA' , value:'88'},
    { label: '08 - ATLANTICO' , value:'08'},
    { label: '13 - BOLIVAR' , value:'13'},
    { label: '15 - BOYACA' , value:'15'},
    { label: '17 - CALDAS' , value:'17'},
    { label: '18 - CAQUETA' , value:'18'},
    { label: '85 - CASANARE' , value:'85'},
    { label: '19 - CAUCA' , value:'19'},
    { label: '20 - CESAR' , value:'20'},
    { label: '27 - CHOCO' , value:'27'},
    { label: '23 - CORDOBA' , value:'23'},
    { label: '25 - CUNDINAMARCA' , value:'25'},
    { label: '11 - D.C.' , value:'11'},
    { label: '94 - GUAINIA' , value:'94'},
    { label: '95 - GUAVIARE' , value:'95'},
    { label: '41 - HUILA' , value:'41'},
    { label: '44 - LA GUAJIRA' , value:'44'},
    { label: '47 - MAGDALENA' , value:'47'},
    { label: '50 - META' , value:'50'},
    { label: '52 - NARIÑO' , value:'52'},
    { label: '54 - NORTE DE SANTANDER' , value:'54'},
    { label: '86 - PUTUMAYO' , value:'86'},
    { label: '63 - QUINDIO' , value:'63'},
    { label: '66 - RISARALDA' , value:'66'},
    { label: '68 - SANTANDER' , value:'68'},
    { label: '70 - SUCRE' , value:'70'},
    { label: '73 - TOLIMA' , value:'73'},
    { label: '76 - VALLE DEL CAUCA' , value:'76'},
    { label: '97 - VAUPES' , value:'97'},
    { label: '99 - VICHADA' , value:'99'},
];

const ciudades = [
    { departamento: 'ANTIOQUIA' , ciudad:'MEDELLIN' , codDepartamento:'05' , codCiudad:'05001'},
    { departamento: 'ANTIOQUIA' , ciudad:'ABEJORRAL' , codDepartamento:'05' , codCiudad:'05002'},
    { departamento: 'ANTIOQUIA' , ciudad:'ABRIAQUI' , codDepartamento:'05' , codCiudad:'05004'},
    { departamento: 'ANTIOQUIA' , ciudad:'ALEJANDRIA' , codDepartamento:'05' , codCiudad:'05021'},
    { departamento: 'ANTIOQUIA' , ciudad:'AMAGA' , codDepartamento:'05' , codCiudad:'05030'},
    { departamento: 'ANTIOQUIA' , ciudad:'AMALFI' , codDepartamento:'05' , codCiudad:'05031'},
    { departamento: 'ANTIOQUIA' , ciudad:'ANDES' , codDepartamento:'05' , codCiudad:'05034'},
    { departamento: 'ANTIOQUIA' , ciudad:'ANGELOPOLIS' , codDepartamento:'05' , codCiudad:'05036'},
    { departamento: 'ANTIOQUIA' , ciudad:'ANGOSTURA' , codDepartamento:'05' , codCiudad:'05038'},
    { departamento: 'ANTIOQUIA' , ciudad:'ANORI' , codDepartamento:'05' , codCiudad:'05040'},
    { departamento: 'ANTIOQUIA' , ciudad:'SANTAFE DE ANTIOQUIA' , codDepartamento:'05' , codCiudad:'05042'},
    { departamento: 'ANTIOQUIA' , ciudad:'ANZA' , codDepartamento:'05' , codCiudad:'05044'},
    { departamento: 'ANTIOQUIA' , ciudad:'APARTADO' , codDepartamento:'05' , codCiudad:'05045'},
    { departamento: 'ANTIOQUIA' , ciudad:'ARBOLETES' , codDepartamento:'05' , codCiudad:'05051'},
    { departamento: 'ANTIOQUIA' , ciudad:'ARGELIA' , codDepartamento:'05' , codCiudad:'05055'},
    { departamento: 'ANTIOQUIA' , ciudad:'ARMENIA' , codDepartamento:'05' , codCiudad:'05059'},
    { departamento: 'ANTIOQUIA' , ciudad:'BARBOSA' , codDepartamento:'05' , codCiudad:'05079'},
    { departamento: 'ANTIOQUIA' , ciudad:'BELMIRA' , codDepartamento:'05' , codCiudad:'05086'},
    { departamento: 'ANTIOQUIA' , ciudad:'BELLO' , codDepartamento:'05' , codCiudad:'05088'},
    { departamento: 'ANTIOQUIA' , ciudad:'BETANIA' , codDepartamento:'05' , codCiudad:'05091'},
    { departamento: 'ANTIOQUIA' , ciudad:'BETULIA' , codDepartamento:'05' , codCiudad:'05093'},
    { departamento: 'ANTIOQUIA' , ciudad:'CIUDAD BOLIVAR' , codDepartamento:'05' , codCiudad:'05101'},
    { departamento: 'ANTIOQUIA' , ciudad:'BRICEÑO' , codDepartamento:'05' , codCiudad:'05107'},
    { departamento: 'ANTIOQUIA' , ciudad:'BURITICA' , codDepartamento:'05' , codCiudad:'05113'},
    { departamento: 'ANTIOQUIA' , ciudad:'CACERES' , codDepartamento:'05' , codCiudad:'05120'},
    { departamento: 'ANTIOQUIA' , ciudad:'CAICEDO' , codDepartamento:'05' , codCiudad:'05125'},
    { departamento: 'ANTIOQUIA' , ciudad:'CALDAS' , codDepartamento:'05' , codCiudad:'05129'},
    { departamento: 'ANTIOQUIA' , ciudad:'CAMPAMENTO' , codDepartamento:'05' , codCiudad:'05134'},
    { departamento: 'ANTIOQUIA' , ciudad:'CAÑASGORDAS' , codDepartamento:'05' , codCiudad:'05138'},
    { departamento: 'ANTIOQUIA' , ciudad:'CARACOLI' , codDepartamento:'05' , codCiudad:'05142'},
    { departamento: 'ANTIOQUIA' , ciudad:'CARAMANTA' , codDepartamento:'05' , codCiudad:'05145'},
    { departamento: 'ANTIOQUIA' , ciudad:'CAREPA' , codDepartamento:'05' , codCiudad:'05147'},
    { departamento: 'ANTIOQUIA' , ciudad:'EL CARMEN DE VIBORAL' , codDepartamento:'05' , codCiudad:'05148'},
    { departamento: 'ANTIOQUIA' , ciudad:'CAROLINA' , codDepartamento:'05' , codCiudad:'05150'},
    { departamento: 'ANTIOQUIA' , ciudad:'CAUCASIA' , codDepartamento:'05' , codCiudad:'05154'},
    { departamento: 'ANTIOQUIA' , ciudad:'CHIGORODO' , codDepartamento:'05' , codCiudad:'05172'},
    { departamento: 'ANTIOQUIA' , ciudad:'CISNEROS' , codDepartamento:'05' , codCiudad:'05190'},
    { departamento: 'ANTIOQUIA' , ciudad:'COCORNA' , codDepartamento:'05' , codCiudad:'05197'},
    { departamento: 'ANTIOQUIA' , ciudad:'CONCEPCION' , codDepartamento:'05' , codCiudad:'05206'},
    { departamento: 'ANTIOQUIA' , ciudad:'CONCORDIA' , codDepartamento:'05' , codCiudad:'05209'},
    { departamento: 'ANTIOQUIA' , ciudad:'COPACABANA' , codDepartamento:'05' , codCiudad:'05212'},
    { departamento: 'ANTIOQUIA' , ciudad:'DABEIBA' , codDepartamento:'05' , codCiudad:'05234'},
    { departamento: 'ANTIOQUIA' , ciudad:'DON MATIAS' , codDepartamento:'05' , codCiudad:'05237'},
    { departamento: 'ANTIOQUIA' , ciudad:'EBEJICO' , codDepartamento:'05' , codCiudad:'05240'},
    { departamento: 'ANTIOQUIA' , ciudad:'EL BAGRE' , codDepartamento:'05' , codCiudad:'05250'},
    { departamento: 'ANTIOQUIA' , ciudad:'ENTRERRIOS' , codDepartamento:'05' , codCiudad:'05264'},
    { departamento: 'ANTIOQUIA' , ciudad:'ENVIGADO' , codDepartamento:'05' , codCiudad:'05266'},
    { departamento: 'ANTIOQUIA' , ciudad:'FREDONIA' , codDepartamento:'05' , codCiudad:'05282'},
    { departamento: 'ANTIOQUIA' , ciudad:'FRONTINO' , codDepartamento:'05' , codCiudad:'05284'},
    { departamento: 'ANTIOQUIA' , ciudad:'GIRALDO' , codDepartamento:'05' , codCiudad:'05306'},
    { departamento: 'ANTIOQUIA' , ciudad:'GIRARDOTA' , codDepartamento:'05' , codCiudad:'05308'},
    { departamento: 'ANTIOQUIA' , ciudad:'GOMEZ PLATA' , codDepartamento:'05' , codCiudad:'05310'},
    { departamento: 'ANTIOQUIA' , ciudad:'GRANADA' , codDepartamento:'05' , codCiudad:'05313'},
    { departamento: 'ANTIOQUIA' , ciudad:'GUADALUPE' , codDepartamento:'05' , codCiudad:'05315'},
    { departamento: 'ANTIOQUIA' , ciudad:'GUARNE' , codDepartamento:'05' , codCiudad:'05318'},
    { departamento: 'ANTIOQUIA' , ciudad:'GUATAPE' , codDepartamento:'05' , codCiudad:'05321'},
    { departamento: 'ANTIOQUIA' , ciudad:'HELICONIA' , codDepartamento:'05' , codCiudad:'05347'},
    { departamento: 'ANTIOQUIA' , ciudad:'HISPANIA' , codDepartamento:'05' , codCiudad:'05353'},
    { departamento: 'ANTIOQUIA' , ciudad:'ITAGÜI' , codDepartamento:'05' , codCiudad:'05360'},
    { departamento: 'ANTIOQUIA' , ciudad:'ITUANGO' , codDepartamento:'05' , codCiudad:'05361'},
    { departamento: 'ANTIOQUIA' , ciudad:'JARDIN' , codDepartamento:'05' , codCiudad:'05364'},
    { departamento: 'ANTIOQUIA' , ciudad:'JERICO' , codDepartamento:'05' , codCiudad:'05368'},
    { departamento: 'ANTIOQUIA' , ciudad:'LA CEJA' , codDepartamento:'05' , codCiudad:'05376'},
    { departamento: 'ANTIOQUIA' , ciudad:'LA ESTRELLA' , codDepartamento:'05' , codCiudad:'05380'},
    { departamento: 'ANTIOQUIA' , ciudad:'LA PINTADA' , codDepartamento:'05' , codCiudad:'05390'},
    { departamento: 'ANTIOQUIA' , ciudad:'LA UNION' , codDepartamento:'05' , codCiudad:'05400'},
    { departamento: 'ANTIOQUIA' , ciudad:'LIBORINA' , codDepartamento:'05' , codCiudad:'05411'},
    { departamento: 'ANTIOQUIA' , ciudad:'MACEO' , codDepartamento:'05' , codCiudad:'05425'},
    { departamento: 'ANTIOQUIA' , ciudad:'MARINILLA' , codDepartamento:'05' , codCiudad:'05440'},
    { departamento: 'ANTIOQUIA' , ciudad:'MONTEBELLO' , codDepartamento:'05' , codCiudad:'05467'},
    { departamento: 'ANTIOQUIA' , ciudad:'MURINDO' , codDepartamento:'05' , codCiudad:'05475'},
    { departamento: 'ANTIOQUIA' , ciudad:'MUTATA' , codDepartamento:'05' , codCiudad:'05480'},
    { departamento: 'ANTIOQUIA' , ciudad:'NARIÑO' , codDepartamento:'05' , codCiudad:'05483'},
    { departamento: 'ANTIOQUIA' , ciudad:'NECOCLI' , codDepartamento:'05' , codCiudad:'05490'},
    { departamento: 'ANTIOQUIA' , ciudad:'NECHI' , codDepartamento:'05' , codCiudad:'05495'},
    { departamento: 'ANTIOQUIA' , ciudad:'OLAYA' , codDepartamento:'05' , codCiudad:'05501'},
    { departamento: 'ANTIOQUIA' , ciudad:'PEÑOL' , codDepartamento:'05' , codCiudad:'05541'},
    { departamento: 'ANTIOQUIA' , ciudad:'PEQUE' , codDepartamento:'05' , codCiudad:'05543'},
    { departamento: 'ANTIOQUIA' , ciudad:'PUEBLORRICO' , codDepartamento:'05' , codCiudad:'05576'},
    { departamento: 'ANTIOQUIA' , ciudad:'PUERTO BERRIO' , codDepartamento:'05' , codCiudad:'05579'},
    { departamento: 'ANTIOQUIA' , ciudad:'PUERTO NARE' , codDepartamento:'05' , codCiudad:'05585'},
    { departamento: 'ANTIOQUIA' , ciudad:'PUERTO TRIUNFO' , codDepartamento:'05' , codCiudad:'05591'},
    { departamento: 'ANTIOQUIA' , ciudad:'REMEDIOS' , codDepartamento:'05' , codCiudad:'05604'},
    { departamento: 'ANTIOQUIA' , ciudad:'RETIRO' , codDepartamento:'05' , codCiudad:'05607'},
    { departamento: 'ANTIOQUIA' , ciudad:'RIONEGRO' , codDepartamento:'05' , codCiudad:'05615'},
    { departamento: 'ANTIOQUIA' , ciudad:'SABANALARGA' , codDepartamento:'05' , codCiudad:'05628'},
    { departamento: 'ANTIOQUIA' , ciudad:'SABANETA' , codDepartamento:'05' , codCiudad:'05631'},
    { departamento: 'ANTIOQUIA' , ciudad:'SALGAR' , codDepartamento:'05' , codCiudad:'05642'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN ANDRES' , codDepartamento:'05' , codCiudad:'05647'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN CARLOS' , codDepartamento:'05' , codCiudad:'05649'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN FRANCISCO' , codDepartamento:'05' , codCiudad:'05652'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN JERONIMO' , codDepartamento:'05' , codCiudad:'05656'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN JOSE DE LA MONTAÑA' , codDepartamento:'05' , codCiudad:'05658'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN JUAN DE URABA' , codDepartamento:'05' , codCiudad:'05659'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN LUIS' , codDepartamento:'05' , codCiudad:'05660'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN PEDRO' , codDepartamento:'05' , codCiudad:'05664'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN PEDRO DE URABA' , codDepartamento:'05' , codCiudad:'05665'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN RAFAEL' , codDepartamento:'05' , codCiudad:'05667'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN ROQUE' , codDepartamento:'05' , codCiudad:'05670'},
    { departamento: 'ANTIOQUIA' , ciudad:'SAN VICENTE' , codDepartamento:'05' , codCiudad:'05674'},
    { departamento: 'ANTIOQUIA' , ciudad:'SANTA BARBARA' , codDepartamento:'05' , codCiudad:'05679'},
    { departamento: 'ANTIOQUIA' , ciudad:'SANTA ROSA DE OSOS' , codDepartamento:'05' , codCiudad:'05686'},
    { departamento: 'ANTIOQUIA' , ciudad:'SANTO DOMINGO' , codDepartamento:'05' , codCiudad:'05690'},
    { departamento: 'ANTIOQUIA' , ciudad:'EL SANTUARIO' , codDepartamento:'05' , codCiudad:'05697'},
    { departamento: 'ANTIOQUIA' , ciudad:'SEGOVIA' , codDepartamento:'05' , codCiudad:'05736'},
    { departamento: 'ANTIOQUIA' , ciudad:'SONSON' , codDepartamento:'05' , codCiudad:'05756'},
    { departamento: 'ANTIOQUIA' , ciudad:'SOPETRAN' , codDepartamento:'05' , codCiudad:'05761'},
    { departamento: 'ANTIOQUIA' , ciudad:'TAMESIS' , codDepartamento:'05' , codCiudad:'05789'},
    { departamento: 'ANTIOQUIA' , ciudad:'TARAZA' , codDepartamento:'05' , codCiudad:'05790'},
    { departamento: 'ANTIOQUIA' , ciudad:'TARSO' , codDepartamento:'05' , codCiudad:'05792'},
    { departamento: 'ANTIOQUIA' , ciudad:'TITIRIBI' , codDepartamento:'05' , codCiudad:'05809'},
    { departamento: 'ANTIOQUIA' , ciudad:'TOLEDO' , codDepartamento:'05' , codCiudad:'05819'},
    { departamento: 'ANTIOQUIA' , ciudad:'TURBO' , codDepartamento:'05' , codCiudad:'05837'},
    { departamento: 'ANTIOQUIA' , ciudad:'URAMITA' , codDepartamento:'05' , codCiudad:'05842'},
    { departamento: 'ANTIOQUIA' , ciudad:'URRAO' , codDepartamento:'05' , codCiudad:'05847'},
    { departamento: 'ANTIOQUIA' , ciudad:'VALDIVIA' , codDepartamento:'05' , codCiudad:'05854'},
    { departamento: 'ANTIOQUIA' , ciudad:'VALPARAISO' , codDepartamento:'05' , codCiudad:'05856'},
    { departamento: 'ANTIOQUIA' , ciudad:'VEGACHI' , codDepartamento:'05' , codCiudad:'05858'},
    { departamento: 'ANTIOQUIA' , ciudad:'VENECIA' , codDepartamento:'05' , codCiudad:'05861'},
    { departamento: 'ANTIOQUIA' , ciudad:'VIGIA DEL FUERTE' , codDepartamento:'05' , codCiudad:'05873'},
    { departamento: 'ANTIOQUIA' , ciudad:'YALI' , codDepartamento:'05' , codCiudad:'05885'},
    { departamento: 'ANTIOQUIA' , ciudad:'YARUMAL' , codDepartamento:'05' , codCiudad:'05887'},
    { departamento: 'ANTIOQUIA' , ciudad:'YOLOMBO' , codDepartamento:'05' , codCiudad:'05890'},
    { departamento: 'ANTIOQUIA' , ciudad:'YONDO' , codDepartamento:'05' , codCiudad:'05893'},
    { departamento: 'ANTIOQUIA' , ciudad:'ZARAGOZA' , codDepartamento:'05' , codCiudad:'05895'},
    { departamento: 'ATLANTICO' , ciudad:'BARRANQUILLA' , codDepartamento:'08' , codCiudad:'08001'},
    { departamento: 'ATLANTICO' , ciudad:'BARANOA' , codDepartamento:'08' , codCiudad:'08078'},
    { departamento: 'ATLANTICO' , ciudad:'CAMPO DE LA CRUZ' , codDepartamento:'08' , codCiudad:'08137'},
    { departamento: 'ATLANTICO' , ciudad:'CANDELARIA' , codDepartamento:'08' , codCiudad:'08141'},
    { departamento: 'ATLANTICO' , ciudad:'GALAPA' , codDepartamento:'08' , codCiudad:'08296'},
    { departamento: 'ATLANTICO' , ciudad:'JUAN DE ACOSTA' , codDepartamento:'08' , codCiudad:'08372'},
    { departamento: 'ATLANTICO' , ciudad:'LURUACO' , codDepartamento:'08' , codCiudad:'08421'},
    { departamento: 'ATLANTICO' , ciudad:'MALAMBO' , codDepartamento:'08' , codCiudad:'08433'},
    { departamento: 'ATLANTICO' , ciudad:'MANATI' , codDepartamento:'08' , codCiudad:'08436'},
    { departamento: 'ATLANTICO' , ciudad:'PALMAR DE VARELA' , codDepartamento:'08' , codCiudad:'08520'},
    { departamento: 'ATLANTICO' , ciudad:'PIOJO' , codDepartamento:'08' , codCiudad:'08549'},
    { departamento: 'ATLANTICO' , ciudad:'POLONUEVO' , codDepartamento:'08' , codCiudad:'08558'},
    { departamento: 'ATLANTICO' , ciudad:'PONEDERA' , codDepartamento:'08' , codCiudad:'08560'},
    { departamento: 'ATLANTICO' , ciudad:'PUERTO COLOMBIA' , codDepartamento:'08' , codCiudad:'08573'},
    { departamento: 'ATLANTICO' , ciudad:'REPELON' , codDepartamento:'08' , codCiudad:'08606'},
    { departamento: 'ATLANTICO' , ciudad:'SABANAGRANDE' , codDepartamento:'08' , codCiudad:'08634'},
    { departamento: 'ATLANTICO' , ciudad:'SABANALARGA' , codDepartamento:'08' , codCiudad:'08638'},
    { departamento: 'ATLANTICO' , ciudad:'SANTA LUCIA' , codDepartamento:'08' , codCiudad:'08675'},
    { departamento: 'ATLANTICO' , ciudad:'SANTO TOMAS' , codDepartamento:'08' , codCiudad:'08685'},
    { departamento: 'ATLANTICO' , ciudad:'SOLEDAD' , codDepartamento:'08' , codCiudad:'08758'},
    { departamento: 'ATLANTICO' , ciudad:'SUAN' , codDepartamento:'08' , codCiudad:'08770'},
    { departamento: 'ATLANTICO' , ciudad:'TUBARA' , codDepartamento:'08' , codCiudad:'08832'},
    { departamento: 'ATLANTICO' , ciudad:'USIACURI' , codDepartamento:'08' , codCiudad:'08849'},
    { departamento: 'D.C.' , ciudad:'BOGOTA' , codDepartamento:'11' , codCiudad:'11001'},
    { departamento: 'BOLIVAR' , ciudad:'CARTAGENA' , codDepartamento:'13' , codCiudad:'13001'},
    { departamento: 'BOLIVAR' , ciudad:'ACHI' , codDepartamento:'13' , codCiudad:'13006'},
    { departamento: 'BOLIVAR' , ciudad:'ALTOS DEL ROSARIO' , codDepartamento:'13' , codCiudad:'13030'},
    { departamento: 'BOLIVAR' , ciudad:'ARENAL' , codDepartamento:'13' , codCiudad:'13042'},
    { departamento: 'BOLIVAR' , ciudad:'ARJONA' , codDepartamento:'13' , codCiudad:'13052'},
    { departamento: 'BOLIVAR' , ciudad:'ARROYOHONDO' , codDepartamento:'13' , codCiudad:'13062'},
    { departamento: 'BOLIVAR' , ciudad:'BARRANCO DE LOBA' , codDepartamento:'13' , codCiudad:'13074'},
    { departamento: 'BOLIVAR' , ciudad:'CALAMAR' , codDepartamento:'13' , codCiudad:'13140'},
    { departamento: 'BOLIVAR' , ciudad:'CANTAGALLO' , codDepartamento:'13' , codCiudad:'13160'},
    { departamento: 'BOLIVAR' , ciudad:'CICUCO' , codDepartamento:'13' , codCiudad:'13188'},
    { departamento: 'BOLIVAR' , ciudad:'CORDOBA' , codDepartamento:'13' , codCiudad:'13212'},
    { departamento: 'BOLIVAR' , ciudad:'CLEMENCIA' , codDepartamento:'13' , codCiudad:'13222'},
    { departamento: 'BOLIVAR' , ciudad:'EL CARMEN DE BOLIVAR' , codDepartamento:'13' , codCiudad:'13244'},
    { departamento: 'BOLIVAR' , ciudad:'EL GUAMO' , codDepartamento:'13' , codCiudad:'13248'},
    { departamento: 'BOLIVAR' , ciudad:'EL PEÑON' , codDepartamento:'13' , codCiudad:'13268'},
    { departamento: 'BOLIVAR' , ciudad:'HATILLO DE LOBA' , codDepartamento:'13' , codCiudad:'13300'},
    { departamento: 'BOLIVAR' , ciudad:'MAGANGUE' , codDepartamento:'13' , codCiudad:'13430'},
    { departamento: 'BOLIVAR' , ciudad:'MAHATES' , codDepartamento:'13' , codCiudad:'13433'},
    { departamento: 'BOLIVAR' , ciudad:'MARGARITA' , codDepartamento:'13' , codCiudad:'13440'},
    { departamento: 'BOLIVAR' , ciudad:'MARIA LA BAJA' , codDepartamento:'13' , codCiudad:'13442'},
    { departamento: 'BOLIVAR' , ciudad:'MONTECRISTO' , codDepartamento:'13' , codCiudad:'13458'},
    { departamento: 'BOLIVAR' , ciudad:'MOMPOS' , codDepartamento:'13' , codCiudad:'13468'},
    { departamento: 'BOLIVAR' , ciudad:'MORALES' , codDepartamento:'13' , codCiudad:'13473'},
    { departamento: 'BOLIVAR' , ciudad:'PINILLOS' , codDepartamento:'13' , codCiudad:'13549'},
    { departamento: 'BOLIVAR' , ciudad:'REGIDOR' , codDepartamento:'13' , codCiudad:'13580'},
    { departamento: 'BOLIVAR' , ciudad:'RIO VIEJO' , codDepartamento:'13' , codCiudad:'13600'},
    { departamento: 'BOLIVAR' , ciudad:'SAN CRISTOBAL' , codDepartamento:'13' , codCiudad:'13620'},
    { departamento: 'BOLIVAR' , ciudad:'SAN ESTANISLAO' , codDepartamento:'13' , codCiudad:'13647'},
    { departamento: 'BOLIVAR' , ciudad:'SAN FERNANDO' , codDepartamento:'13' , codCiudad:'13650'},
    { departamento: 'BOLIVAR' , ciudad:'SAN JACINTO' , codDepartamento:'13' , codCiudad:'13654'},
    { departamento: 'BOLIVAR' , ciudad:'SAN JACINTO DEL CAUCA' , codDepartamento:'13' , codCiudad:'13655'},
    { departamento: 'BOLIVAR' , ciudad:'SAN JUAN NEPOMUCENO' , codDepartamento:'13' , codCiudad:'13657'},
    { departamento: 'BOLIVAR' , ciudad:'SAN MARTIN DE LOBA' , codDepartamento:'13' , codCiudad:'13667'},
    { departamento: 'BOLIVAR' , ciudad:'SAN PABLO' , codDepartamento:'13' , codCiudad:'13670'},
    { departamento: 'BOLIVAR' , ciudad:'SANTA CATALINA' , codDepartamento:'13' , codCiudad:'13673'},
    { departamento: 'BOLIVAR' , ciudad:'SANTA ROSA' , codDepartamento:'13' , codCiudad:'13683'},
    { departamento: 'BOLIVAR' , ciudad:'SANTA ROSA DEL SUR' , codDepartamento:'13' , codCiudad:'13688'},
    { departamento: 'BOLIVAR' , ciudad:'SIMITI' , codDepartamento:'13' , codCiudad:'13744'},
    { departamento: 'BOLIVAR' , ciudad:'SOPLAVIENTO' , codDepartamento:'13' , codCiudad:'13760'},
    { departamento: 'BOLIVAR' , ciudad:'TALAIGUA NUEVO' , codDepartamento:'13' , codCiudad:'13780'},
    { departamento: 'BOLIVAR' , ciudad:'TIQUISIO' , codDepartamento:'13' , codCiudad:'13810'},
    { departamento: 'BOLIVAR' , ciudad:'TURBACO' , codDepartamento:'13' , codCiudad:'13836'},
    { departamento: 'BOLIVAR' , ciudad:'TURBANA' , codDepartamento:'13' , codCiudad:'13838'},
    { departamento: 'BOLIVAR' , ciudad:'VILLANUEVA' , codDepartamento:'13' , codCiudad:'13873'},
    { departamento: 'BOLIVAR' , ciudad:'ZAMBRANO' , codDepartamento:'13' , codCiudad:'13894'},
    { departamento: 'BOYACA' , ciudad:'TUNJA' , codDepartamento:'15' , codCiudad:'15001'},
    { departamento: 'BOYACA' , ciudad:'ALMEIDA' , codDepartamento:'15' , codCiudad:'15022'},
    { departamento: 'BOYACA' , ciudad:'AQUITANIA' , codDepartamento:'15' , codCiudad:'15047'},
    { departamento: 'BOYACA' , ciudad:'ARCABUCO' , codDepartamento:'15' , codCiudad:'15051'},
    { departamento: 'BOYACA' , ciudad:'BELEN' , codDepartamento:'15' , codCiudad:'15087'},
    { departamento: 'BOYACA' , ciudad:'BERBEO' , codDepartamento:'15' , codCiudad:'15090'},
    { departamento: 'BOYACA' , ciudad:'BETEITIVA' , codDepartamento:'15' , codCiudad:'15092'},
    { departamento: 'BOYACA' , ciudad:'BOAVITA' , codDepartamento:'15' , codCiudad:'15097'},
    { departamento: 'BOYACA' , ciudad:'BOYACA' , codDepartamento:'15' , codCiudad:'15104'},
    { departamento: 'BOYACA' , ciudad:'BRICEÑO' , codDepartamento:'15' , codCiudad:'15106'},
    { departamento: 'BOYACA' , ciudad:'BUENAVISTA' , codDepartamento:'15' , codCiudad:'15109'},
    { departamento: 'BOYACA' , ciudad:'BUSBANZA' , codDepartamento:'15' , codCiudad:'15114'},
    { departamento: 'BOYACA' , ciudad:'CALDAS' , codDepartamento:'15' , codCiudad:'15131'},
    { departamento: 'BOYACA' , ciudad:'CAMPOHERMOSO' , codDepartamento:'15' , codCiudad:'15135'},
    { departamento: 'BOYACA' , ciudad:'CERINZA' , codDepartamento:'15' , codCiudad:'15162'},
    { departamento: 'BOYACA' , ciudad:'CHINAVITA' , codDepartamento:'15' , codCiudad:'15172'},
    { departamento: 'BOYACA' , ciudad:'CHIQUINQUIRA' , codDepartamento:'15' , codCiudad:'15176'},
    { departamento: 'BOYACA' , ciudad:'CHISCAS' , codDepartamento:'15' , codCiudad:'15180'},
    { departamento: 'BOYACA' , ciudad:'CHITA' , codDepartamento:'15' , codCiudad:'15183'},
    { departamento: 'BOYACA' , ciudad:'CHITARAQUE' , codDepartamento:'15' , codCiudad:'15185'},
    { departamento: 'BOYACA' , ciudad:'CHIVATA' , codDepartamento:'15' , codCiudad:'15187'},
    { departamento: 'BOYACA' , ciudad:'CIENEGA' , codDepartamento:'15' , codCiudad:'15189'},
    { departamento: 'BOYACA' , ciudad:'COMBITA' , codDepartamento:'15' , codCiudad:'15204'},
    { departamento: 'BOYACA' , ciudad:'COPER' , codDepartamento:'15' , codCiudad:'15212'},
    { departamento: 'BOYACA' , ciudad:'CORRALES' , codDepartamento:'15' , codCiudad:'15215'},
    { departamento: 'BOYACA' , ciudad:'COVARACHIA' , codDepartamento:'15' , codCiudad:'15218'},
    { departamento: 'BOYACA' , ciudad:'CUBARA' , codDepartamento:'15' , codCiudad:'15223'},
    { departamento: 'BOYACA' , ciudad:'CUCAITA' , codDepartamento:'15' , codCiudad:'15224'},
    { departamento: 'BOYACA' , ciudad:'CUITIVA' , codDepartamento:'15' , codCiudad:'15226'},
    { departamento: 'BOYACA' , ciudad:'CHIQUIZA' , codDepartamento:'15' , codCiudad:'15232'},
    { departamento: 'BOYACA' , ciudad:'CHIVOR' , codDepartamento:'15' , codCiudad:'15236'},
    { departamento: 'BOYACA' , ciudad:'DUITAMA' , codDepartamento:'15' , codCiudad:'15238'},
    { departamento: 'BOYACA' , ciudad:'EL COCUY' , codDepartamento:'15' , codCiudad:'15244'},
    { departamento: 'BOYACA' , ciudad:'EL ESPINO' , codDepartamento:'15' , codCiudad:'15248'},
    { departamento: 'BOYACA' , ciudad:'FIRAVITOBA' , codDepartamento:'15' , codCiudad:'15272'},
    { departamento: 'BOYACA' , ciudad:'FLORESTA' , codDepartamento:'15' , codCiudad:'15276'},
    { departamento: 'BOYACA' , ciudad:'GACHANTIVA' , codDepartamento:'15' , codCiudad:'15293'},
    { departamento: 'BOYACA' , ciudad:'GAMEZA' , codDepartamento:'15' , codCiudad:'15296'},
    { departamento: 'BOYACA' , ciudad:'GARAGOA' , codDepartamento:'15' , codCiudad:'15299'},
    { departamento: 'BOYACA' , ciudad:'GUACAMAYAS' , codDepartamento:'15' , codCiudad:'15317'},
    { departamento: 'BOYACA' , ciudad:'GUATEQUE' , codDepartamento:'15' , codCiudad:'15322'},
    { departamento: 'BOYACA' , ciudad:'GUAYATA' , codDepartamento:'15' , codCiudad:'15325'},
    { departamento: 'BOYACA' , ciudad:'GÜICAN' , codDepartamento:'15' , codCiudad:'15332'},
    { departamento: 'BOYACA' , ciudad:'IZA' , codDepartamento:'15' , codCiudad:'15362'},
    { departamento: 'BOYACA' , ciudad:'JENESANO' , codDepartamento:'15' , codCiudad:'15367'},
    { departamento: 'BOYACA' , ciudad:'JERICO' , codDepartamento:'15' , codCiudad:'15368'},
    { departamento: 'BOYACA' , ciudad:'LABRANZAGRANDE' , codDepartamento:'15' , codCiudad:'15377'},
    { departamento: 'BOYACA' , ciudad:'LA CAPILLA' , codDepartamento:'15' , codCiudad:'15380'},
    { departamento: 'BOYACA' , ciudad:'LA VICTORIA' , codDepartamento:'15' , codCiudad:'15401'},
    { departamento: 'BOYACA' , ciudad:'LA UVITA' , codDepartamento:'15' , codCiudad:'15403'},
    { departamento: 'BOYACA' , ciudad:'VILLA DE LEYVA' , codDepartamento:'15' , codCiudad:'15407'},
    { departamento: 'BOYACA' , ciudad:'MACANAL' , codDepartamento:'15' , codCiudad:'15425'},
    { departamento: 'BOYACA' , ciudad:'MARIPI' , codDepartamento:'15' , codCiudad:'15442'},
    { departamento: 'BOYACA' , ciudad:'MIRAFLORES' , codDepartamento:'15' , codCiudad:'15455'},
    { departamento: 'BOYACA' , ciudad:'MONGUA' , codDepartamento:'15' , codCiudad:'15464'},
    { departamento: 'BOYACA' , ciudad:'MONGUI' , codDepartamento:'15' , codCiudad:'15466'},
    { departamento: 'BOYACA' , ciudad:'MONIQUIRA' , codDepartamento:'15' , codCiudad:'15469'},
    { departamento: 'BOYACA' , ciudad:'MOTAVITA' , codDepartamento:'15' , codCiudad:'15476'},
    { departamento: 'BOYACA' , ciudad:'MUZO' , codDepartamento:'15' , codCiudad:'15480'},
    { departamento: 'BOYACA' , ciudad:'NOBSA' , codDepartamento:'15' , codCiudad:'15491'},
    { departamento: 'BOYACA' , ciudad:'NUEVO COLON' , codDepartamento:'15' , codCiudad:'15494'},
    { departamento: 'BOYACA' , ciudad:'OICATA' , codDepartamento:'15' , codCiudad:'15500'},
    { departamento: 'BOYACA' , ciudad:'OTANCHE' , codDepartamento:'15' , codCiudad:'15507'},
    { departamento: 'BOYACA' , ciudad:'PACHAVITA' , codDepartamento:'15' , codCiudad:'15511'},
    { departamento: 'BOYACA' , ciudad:'PAEZ' , codDepartamento:'15' , codCiudad:'15514'},
    { departamento: 'BOYACA' , ciudad:'PAIPA' , codDepartamento:'15' , codCiudad:'15516'},
    { departamento: 'BOYACA' , ciudad:'PAJARITO' , codDepartamento:'15' , codCiudad:'15518'},
    { departamento: 'BOYACA' , ciudad:'PANQUEBA' , codDepartamento:'15' , codCiudad:'15522'},
    { departamento: 'BOYACA' , ciudad:'PAUNA' , codDepartamento:'15' , codCiudad:'15531'},
    { departamento: 'BOYACA' , ciudad:'PAYA' , codDepartamento:'15' , codCiudad:'15533'},
    { departamento: 'BOYACA' , ciudad:'PAZ DE RIO' , codDepartamento:'15' , codCiudad:'15537'},
    { departamento: 'BOYACA' , ciudad:'PESCA' , codDepartamento:'15' , codCiudad:'15542'},
    { departamento: 'BOYACA' , ciudad:'PISBA' , codDepartamento:'15' , codCiudad:'15550'},
    { departamento: 'BOYACA' , ciudad:'PUERTO BOYACA' , codDepartamento:'15' , codCiudad:'15572'},
    { departamento: 'BOYACA' , ciudad:'QUIPAMA' , codDepartamento:'15' , codCiudad:'15580'},
    { departamento: 'BOYACA' , ciudad:'RAMIRIQUI' , codDepartamento:'15' , codCiudad:'15599'},
    { departamento: 'BOYACA' , ciudad:'RAQUIRA' , codDepartamento:'15' , codCiudad:'15600'},
    { departamento: 'BOYACA' , ciudad:'RONDON' , codDepartamento:'15' , codCiudad:'15621'},
    { departamento: 'BOYACA' , ciudad:'SABOYA' , codDepartamento:'15' , codCiudad:'15632'},
    { departamento: 'BOYACA' , ciudad:'SACHICA' , codDepartamento:'15' , codCiudad:'15638'},
    { departamento: 'BOYACA' , ciudad:'SAMACA' , codDepartamento:'15' , codCiudad:'15646'},
    { departamento: 'BOYACA' , ciudad:'SAN EDUARDO' , codDepartamento:'15' , codCiudad:'15660'},
    { departamento: 'BOYACA' , ciudad:'SAN JOSE DE PARE' , codDepartamento:'15' , codCiudad:'15664'},
    { departamento: 'BOYACA' , ciudad:'SAN LUIS DE GACENO' , codDepartamento:'15' , codCiudad:'15667'},
    { departamento: 'BOYACA' , ciudad:'SAN MATEO' , codDepartamento:'15' , codCiudad:'15673'},
    { departamento: 'BOYACA' , ciudad:'SAN MIGUEL DE SEMA' , codDepartamento:'15' , codCiudad:'15676'},
    { departamento: 'BOYACA' , ciudad:'SAN PABLO DE BORBUR' , codDepartamento:'15' , codCiudad:'15681'},
    { departamento: 'BOYACA' , ciudad:'SANTANA' , codDepartamento:'15' , codCiudad:'15686'},
    { departamento: 'BOYACA' , ciudad:'SANTA MARIA' , codDepartamento:'15' , codCiudad:'15690'},
    { departamento: 'BOYACA' , ciudad:'SANTA ROSA DE VITERBO' , codDepartamento:'15' , codCiudad:'15693'},
    { departamento: 'BOYACA' , ciudad:'SANTA SOFIA' , codDepartamento:'15' , codCiudad:'15696'},
    { departamento: 'BOYACA' , ciudad:'SATIVANORTE' , codDepartamento:'15' , codCiudad:'15720'},
    { departamento: 'BOYACA' , ciudad:'SATIVASUR' , codDepartamento:'15' , codCiudad:'15723'},
    { departamento: 'BOYACA' , ciudad:'SIACHOQUE' , codDepartamento:'15' , codCiudad:'15740'},
    { departamento: 'BOYACA' , ciudad:'SOATA' , codDepartamento:'15' , codCiudad:'15753'},
    { departamento: 'BOYACA' , ciudad:'SOCOTA' , codDepartamento:'15' , codCiudad:'15755'},
    { departamento: 'BOYACA' , ciudad:'SOCHA' , codDepartamento:'15' , codCiudad:'15757'},
    { departamento: 'BOYACA' , ciudad:'SOGAMOSO' , codDepartamento:'15' , codCiudad:'15759'},
    { departamento: 'BOYACA' , ciudad:'SOMONDOCO' , codDepartamento:'15' , codCiudad:'15761'},
    { departamento: 'BOYACA' , ciudad:'SORA' , codDepartamento:'15' , codCiudad:'15762'},
    { departamento: 'BOYACA' , ciudad:'SOTAQUIRA' , codDepartamento:'15' , codCiudad:'15763'},
    { departamento: 'BOYACA' , ciudad:'SORACA' , codDepartamento:'15' , codCiudad:'15764'},
    { departamento: 'BOYACA' , ciudad:'SUSACON' , codDepartamento:'15' , codCiudad:'15774'},
    { departamento: 'BOYACA' , ciudad:'SUTAMARCHAN' , codDepartamento:'15' , codCiudad:'15776'},
    { departamento: 'BOYACA' , ciudad:'SUTATENZA' , codDepartamento:'15' , codCiudad:'15778'},
    { departamento: 'BOYACA' , ciudad:'TASCO' , codDepartamento:'15' , codCiudad:'15790'},
    { departamento: 'BOYACA' , ciudad:'TENZA' , codDepartamento:'15' , codCiudad:'15798'},
    { departamento: 'BOYACA' , ciudad:'TIBANA' , codDepartamento:'15' , codCiudad:'15804'},
    { departamento: 'BOYACA' , ciudad:'TIBASOSA' , codDepartamento:'15' , codCiudad:'15806'},
    { departamento: 'BOYACA' , ciudad:'TINJACA' , codDepartamento:'15' , codCiudad:'15808'},
    { departamento: 'BOYACA' , ciudad:'TIPACOQUE' , codDepartamento:'15' , codCiudad:'15810'},
    { departamento: 'BOYACA' , ciudad:'TOCA' , codDepartamento:'15' , codCiudad:'15814'},
    { departamento: 'BOYACA' , ciudad:'TOGÜI' , codDepartamento:'15' , codCiudad:'15816'},
    { departamento: 'BOYACA' , ciudad:'TOPAGA' , codDepartamento:'15' , codCiudad:'15820'},
    { departamento: 'BOYACA' , ciudad:'TOTA' , codDepartamento:'15' , codCiudad:'15822'},
    { departamento: 'BOYACA' , ciudad:'TUNUNGUA' , codDepartamento:'15' , codCiudad:'15832'},
    { departamento: 'BOYACA' , ciudad:'TURMEQUE' , codDepartamento:'15' , codCiudad:'15835'},
    { departamento: 'BOYACA' , ciudad:'TUTA' , codDepartamento:'15' , codCiudad:'15837'},
    { departamento: 'BOYACA' , ciudad:'TUTAZA' , codDepartamento:'15' , codCiudad:'15839'},
    { departamento: 'BOYACA' , ciudad:'UMBITA' , codDepartamento:'15' , codCiudad:'15842'},
    { departamento: 'BOYACA' , ciudad:'VENTAQUEMADA' , codDepartamento:'15' , codCiudad:'15861'},
    { departamento: 'BOYACA' , ciudad:'VIRACACHA' , codDepartamento:'15' , codCiudad:'15879'},
    { departamento: 'BOYACA' , ciudad:'ZETAQUIRA' , codDepartamento:'15' , codCiudad:'15897'},
    { departamento: 'CALDAS' , ciudad:'MANIZALES' , codDepartamento:'17' , codCiudad:'17001'},
    { departamento: 'CALDAS' , ciudad:'AGUADAS' , codDepartamento:'17' , codCiudad:'17013'},
    { departamento: 'CALDAS' , ciudad:'ANSERMA' , codDepartamento:'17' , codCiudad:'17042'},
    { departamento: 'CALDAS' , ciudad:'ARANZAZU' , codDepartamento:'17' , codCiudad:'17050'},
    { departamento: 'CALDAS' , ciudad:'BELALCAZAR' , codDepartamento:'17' , codCiudad:'17088'},
    { departamento: 'CALDAS' , ciudad:'CHINCHINA' , codDepartamento:'17' , codCiudad:'17174'},
    { departamento: 'CALDAS' , ciudad:'FILADELFIA' , codDepartamento:'17' , codCiudad:'17272'},
    { departamento: 'CALDAS' , ciudad:'LA DORADA' , codDepartamento:'17' , codCiudad:'17380'},
    { departamento: 'CALDAS' , ciudad:'LA MERCED' , codDepartamento:'17' , codCiudad:'17388'},
    { departamento: 'CALDAS' , ciudad:'MANZANARES' , codDepartamento:'17' , codCiudad:'17433'},
    { departamento: 'CALDAS' , ciudad:'MARMATO' , codDepartamento:'17' , codCiudad:'17442'},
    { departamento: 'CALDAS' , ciudad:'MARQUETALIA' , codDepartamento:'17' , codCiudad:'17444'},
    { departamento: 'CALDAS' , ciudad:'MARULANDA' , codDepartamento:'17' , codCiudad:'17446'},
    { departamento: 'CALDAS' , ciudad:'NEIRA' , codDepartamento:'17' , codCiudad:'17486'},
    { departamento: 'CALDAS' , ciudad:'NORCASIA' , codDepartamento:'17' , codCiudad:'17495'},
    { departamento: 'CALDAS' , ciudad:'PACORA' , codDepartamento:'17' , codCiudad:'17513'},
    { departamento: 'CALDAS' , ciudad:'PALESTINA' , codDepartamento:'17' , codCiudad:'17524'},
    { departamento: 'CALDAS' , ciudad:'PENSILVANIA' , codDepartamento:'17' , codCiudad:'17541'},
    { departamento: 'CALDAS' , ciudad:'RIOSUCIO' , codDepartamento:'17' , codCiudad:'17614'},
    { departamento: 'CALDAS' , ciudad:'RISARALDA' , codDepartamento:'17' , codCiudad:'17616'},
    { departamento: 'CALDAS' , ciudad:'SALAMINA' , codDepartamento:'17' , codCiudad:'17653'},
    { departamento: 'CALDAS' , ciudad:'SAMANA' , codDepartamento:'17' , codCiudad:'17662'},
    { departamento: 'CALDAS' , ciudad:'SAN JOSE' , codDepartamento:'17' , codCiudad:'17665'},
    { departamento: 'CALDAS' , ciudad:'SUPIA' , codDepartamento:'17' , codCiudad:'17777'},
    { departamento: 'CALDAS' , ciudad:'VICTORIA' , codDepartamento:'17' , codCiudad:'17867'},
    { departamento: 'CALDAS' , ciudad:'VILLAMARIA' , codDepartamento:'17' , codCiudad:'17873'},
    { departamento: 'CALDAS' , ciudad:'VITERBO' , codDepartamento:'17' , codCiudad:'17877'},
    { departamento: 'CAQUETA' , ciudad:'FLORENCIA' , codDepartamento:'18' , codCiudad:'18001'},
    { departamento: 'CAQUETA' , ciudad:'ALBANIA' , codDepartamento:'18' , codCiudad:'18029'},
    { departamento: 'CAQUETA' , ciudad:'BELEN DE LOS ANDAQUIES' , codDepartamento:'18' , codCiudad:'18094'},
    { departamento: 'CAQUETA' , ciudad:'CARTAGENA DEL CHAIRA' , codDepartamento:'18' , codCiudad:'18150'},
    { departamento: 'CAQUETA' , ciudad:'CURILLO' , codDepartamento:'18' , codCiudad:'18205'},
    { departamento: 'CAQUETA' , ciudad:'EL DONCELLO' , codDepartamento:'18' , codCiudad:'18247'},
    { departamento: 'CAQUETA' , ciudad:'EL PAUJIL' , codDepartamento:'18' , codCiudad:'18256'},
    { departamento: 'CAQUETA' , ciudad:'LA MONTAÑITA' , codDepartamento:'18' , codCiudad:'18410'},
    { departamento: 'CAQUETA' , ciudad:'MILAN' , codDepartamento:'18' , codCiudad:'18460'},
    { departamento: 'CAQUETA' , ciudad:'MORELIA' , codDepartamento:'18' , codCiudad:'18479'},
    { departamento: 'CAQUETA' , ciudad:'PUERTO RICO' , codDepartamento:'18' , codCiudad:'18592'},
    { departamento: 'CAQUETA' , ciudad:'SAN JOSE DE LA FRAGUA' , codDepartamento:'18' , codCiudad:'18610'},
    { departamento: 'CAQUETA' , ciudad:'SAN VICENTE DEL CAGUAN' , codDepartamento:'18' , codCiudad:'18753'},
    { departamento: 'CAQUETA' , ciudad:'SOLANO' , codDepartamento:'18' , codCiudad:'18756'},
    { departamento: 'CAQUETA' , ciudad:'SOLITA' , codDepartamento:'18' , codCiudad:'18785'},
    { departamento: 'CAQUETA' , ciudad:'VALPARAISO' , codDepartamento:'18' , codCiudad:'18860'},
    { departamento: 'CAUCA' , ciudad:'POPAYAN' , codDepartamento:'19' , codCiudad:'19001'},
    { departamento: 'CAUCA' , ciudad:'ALMAGUER' , codDepartamento:'19' , codCiudad:'19022'},
    { departamento: 'CAUCA' , ciudad:'ARGELIA' , codDepartamento:'19' , codCiudad:'19050'},
    { departamento: 'CAUCA' , ciudad:'BALBOA' , codDepartamento:'19' , codCiudad:'19075'},
    { departamento: 'CAUCA' , ciudad:'BOLIVAR' , codDepartamento:'19' , codCiudad:'19100'},
    { departamento: 'CAUCA' , ciudad:'BUENOS AIRES' , codDepartamento:'19' , codCiudad:'19110'},
    { departamento: 'CAUCA' , ciudad:'CAJIBIO' , codDepartamento:'19' , codCiudad:'19130'},
    { departamento: 'CAUCA' , ciudad:'CALDONO' , codDepartamento:'19' , codCiudad:'19137'},
    { departamento: 'CAUCA' , ciudad:'CALOTO' , codDepartamento:'19' , codCiudad:'19142'},
    { departamento: 'CAUCA' , ciudad:'CORINTO' , codDepartamento:'19' , codCiudad:'19212'},
    { departamento: 'CAUCA' , ciudad:'EL TAMBO' , codDepartamento:'19' , codCiudad:'19256'},
    { departamento: 'CAUCA' , ciudad:'FLORENCIA' , codDepartamento:'19' , codCiudad:'19290'},
    { departamento: 'CAUCA' , ciudad:'GUAPI' , codDepartamento:'19' , codCiudad:'19318'},
    { departamento: 'CAUCA' , ciudad:'INZA' , codDepartamento:'19' , codCiudad:'19355'},
    { departamento: 'CAUCA' , ciudad:'JAMBALO' , codDepartamento:'19' , codCiudad:'19364'},
    { departamento: 'CAUCA' , ciudad:'LA SIERRA' , codDepartamento:'19' , codCiudad:'19392'},
    { departamento: 'CAUCA' , ciudad:'LA VEGA' , codDepartamento:'19' , codCiudad:'19397'},
    { departamento: 'CAUCA' , ciudad:'LOPEZ' , codDepartamento:'19' , codCiudad:'19418'},
    { departamento: 'CAUCA' , ciudad:'MERCADERES' , codDepartamento:'19' , codCiudad:'19450'},
    { departamento: 'CAUCA' , ciudad:'MIRANDA' , codDepartamento:'19' , codCiudad:'19455'},
    { departamento: 'CAUCA' , ciudad:'MORALES' , codDepartamento:'19' , codCiudad:'19473'},
    { departamento: 'CAUCA' , ciudad:'PADILLA' , codDepartamento:'19' , codCiudad:'19513'},
    { departamento: 'CAUCA' , ciudad:'PAEZ' , codDepartamento:'19' , codCiudad:'19517'},
    { departamento: 'CAUCA' , ciudad:'PATIA' , codDepartamento:'19' , codCiudad:'19532'},
    { departamento: 'CAUCA' , ciudad:'PIAMONTE' , codDepartamento:'19' , codCiudad:'19533'},
    { departamento: 'CAUCA' , ciudad:'PIENDAMO' , codDepartamento:'19' , codCiudad:'19548'},
    { departamento: 'CAUCA' , ciudad:'PUERTO TEJADA' , codDepartamento:'19' , codCiudad:'19573'},
    { departamento: 'CAUCA' , ciudad:'PURACE' , codDepartamento:'19' , codCiudad:'19585'},
    { departamento: 'CAUCA' , ciudad:'ROSAS' , codDepartamento:'19' , codCiudad:'19622'},
    { departamento: 'CAUCA' , ciudad:'SAN SEBASTIAN' , codDepartamento:'19' , codCiudad:'19693'},
    { departamento: 'CAUCA' , ciudad:'SANTANDER DE QUILICHAO' , codDepartamento:'19' , codCiudad:'19698'},
    { departamento: 'CAUCA' , ciudad:'SANTA ROSA' , codDepartamento:'19' , codCiudad:'19701'},
    { departamento: 'CAUCA' , ciudad:'SILVIA' , codDepartamento:'19' , codCiudad:'19743'},
    { departamento: 'CAUCA' , ciudad:'SOTARA' , codDepartamento:'19' , codCiudad:'19760'},
    { departamento: 'CAUCA' , ciudad:'SUAREZ' , codDepartamento:'19' , codCiudad:'19780'},
    { departamento: 'CAUCA' , ciudad:'SUCRE' , codDepartamento:'19' , codCiudad:'19785'},
    { departamento: 'CAUCA' , ciudad:'TIMBIO' , codDepartamento:'19' , codCiudad:'19807'},
    { departamento: 'CAUCA' , ciudad:'TIMBIQUI' , codDepartamento:'19' , codCiudad:'19809'},
    { departamento: 'CAUCA' , ciudad:'TORIBIO' , codDepartamento:'19' , codCiudad:'19821'},
    { departamento: 'CAUCA' , ciudad:'TOTORO' , codDepartamento:'19' , codCiudad:'19824'},
    { departamento: 'CAUCA' , ciudad:'VILLA RICA' , codDepartamento:'19' , codCiudad:'19845'},
    { departamento: 'CESAR' , ciudad:'VALLEDUPAR' , codDepartamento:'20' , codCiudad:'20001'},
    { departamento: 'CESAR' , ciudad:'AGUACHICA' , codDepartamento:'20' , codCiudad:'20011'},
    { departamento: 'CESAR' , ciudad:'AGUSTIN CODAZZI' , codDepartamento:'20' , codCiudad:'20013'},
    { departamento: 'CESAR' , ciudad:'ASTREA' , codDepartamento:'20' , codCiudad:'20032'},
    { departamento: 'CESAR' , ciudad:'BECERRIL' , codDepartamento:'20' , codCiudad:'20045'},
    { departamento: 'CESAR' , ciudad:'BOSCONIA' , codDepartamento:'20' , codCiudad:'20060'},
    { departamento: 'CESAR' , ciudad:'CHIMICHAGUA' , codDepartamento:'20' , codCiudad:'20175'},
    { departamento: 'CESAR' , ciudad:'CHIRIGUANA' , codDepartamento:'20' , codCiudad:'20178'},
    { departamento: 'CESAR' , ciudad:'CURUMANI' , codDepartamento:'20' , codCiudad:'20228'},
    { departamento: 'CESAR' , ciudad:'EL COPEY' , codDepartamento:'20' , codCiudad:'20238'},
    { departamento: 'CESAR' , ciudad:'EL PASO' , codDepartamento:'20' , codCiudad:'20250'},
    { departamento: 'CESAR' , ciudad:'GAMARRA' , codDepartamento:'20' , codCiudad:'20295'},
    { departamento: 'CESAR' , ciudad:'GONZALEZ' , codDepartamento:'20' , codCiudad:'20310'},
    { departamento: 'CESAR' , ciudad:'LA GLORIA' , codDepartamento:'20' , codCiudad:'20383'},
    { departamento: 'CESAR' , ciudad:'LA JAGUA DE IBIRICO' , codDepartamento:'20' , codCiudad:'20400'},
    { departamento: 'CESAR' , ciudad:'MANAURE' , codDepartamento:'20' , codCiudad:'20443'},
    { departamento: 'CESAR' , ciudad:'PAILITAS' , codDepartamento:'20' , codCiudad:'20517'},
    { departamento: 'CESAR' , ciudad:'PELAYA' , codDepartamento:'20' , codCiudad:'20550'},
    { departamento: 'CESAR' , ciudad:'PUEBLO BELLO' , codDepartamento:'20' , codCiudad:'20570'},
    { departamento: 'CESAR' , ciudad:'RIO DE ORO' , codDepartamento:'20' , codCiudad:'20614'},
    { departamento: 'CESAR' , ciudad:'LA PAZ' , codDepartamento:'20' , codCiudad:'20621'},
    { departamento: 'CESAR' , ciudad:'SAN ALBERTO' , codDepartamento:'20' , codCiudad:'20710'},
    { departamento: 'CESAR' , ciudad:'SAN DIEGO' , codDepartamento:'20' , codCiudad:'20750'},
    { departamento: 'CESAR' , ciudad:'SAN MARTIN' , codDepartamento:'20' , codCiudad:'20770'},
    { departamento: 'CESAR' , ciudad:'TAMALAMEQUE' , codDepartamento:'20' , codCiudad:'20787'},
    { departamento: 'CORDOBA' , ciudad:'MONTERIA' , codDepartamento:'23' , codCiudad:'23001'},
    { departamento: 'CORDOBA' , ciudad:'AYAPEL' , codDepartamento:'23' , codCiudad:'23068'},
    { departamento: 'CORDOBA' , ciudad:'BUENAVISTA' , codDepartamento:'23' , codCiudad:'23079'},
    { departamento: 'CORDOBA' , ciudad:'CANALETE' , codDepartamento:'23' , codCiudad:'23090'},
    { departamento: 'CORDOBA' , ciudad:'CERETE' , codDepartamento:'23' , codCiudad:'23162'},
    { departamento: 'CORDOBA' , ciudad:'CHIMA' , codDepartamento:'23' , codCiudad:'23168'},
    { departamento: 'CORDOBA' , ciudad:'CHINU' , codDepartamento:'23' , codCiudad:'23182'},
    { departamento: 'CORDOBA' , ciudad:'CIENAGA DE ORO' , codDepartamento:'23' , codCiudad:'23189'},
    { departamento: 'CORDOBA' , ciudad:'COTORRA' , codDepartamento:'23' , codCiudad:'23300'},
    { departamento: 'CORDOBA' , ciudad:'LA APARTADA' , codDepartamento:'23' , codCiudad:'23350'},
    { departamento: 'CORDOBA' , ciudad:'LORICA' , codDepartamento:'23' , codCiudad:'23417'},
    { departamento: 'CORDOBA' , ciudad:'LOS CORDOBAS' , codDepartamento:'23' , codCiudad:'23419'},
    { departamento: 'CORDOBA' , ciudad:'MOMIL' , codDepartamento:'23' , codCiudad:'23464'},
    { departamento: 'CORDOBA' , ciudad:'MONTELIBANO' , codDepartamento:'23' , codCiudad:'23466'},
    { departamento: 'CORDOBA' , ciudad:'MOÑITOS' , codDepartamento:'23' , codCiudad:'23500'},
    { departamento: 'CORDOBA' , ciudad:'PLANETA RICA' , codDepartamento:'23' , codCiudad:'23555'},
    { departamento: 'CORDOBA' , ciudad:'PUEBLO NUEVO' , codDepartamento:'23' , codCiudad:'23570'},
    { departamento: 'CORDOBA' , ciudad:'PUERTO ESCONDIDO' , codDepartamento:'23' , codCiudad:'23574'},
    { departamento: 'CORDOBA' , ciudad:'PUERTO LIBERTADOR' , codDepartamento:'23' , codCiudad:'23580'},
    { departamento: 'CORDOBA' , ciudad:'PURISIMA' , codDepartamento:'23' , codCiudad:'23586'},
    { departamento: 'CORDOBA' , ciudad:'SAHAGUN' , codDepartamento:'23' , codCiudad:'23660'},
    { departamento: 'CORDOBA' , ciudad:'SAN ANDRES DE SOTAVENTO' , codDepartamento:'23' , codCiudad:'23670'},
    { departamento: 'CORDOBA' , ciudad:'SAN ANTERO' , codDepartamento:'23' , codCiudad:'23672'},
    { departamento: 'CORDOBA' , ciudad:'SAN BERNARDO DEL VIENTO' , codDepartamento:'23' , codCiudad:'23675'},
    { departamento: 'CORDOBA' , ciudad:'SAN CARLOS' , codDepartamento:'23' , codCiudad:'23678'},
    { departamento: 'CORDOBA' , ciudad:'SAN PELAYO' , codDepartamento:'23' , codCiudad:'23686'},
    { departamento: 'CORDOBA' , ciudad:'TIERRALTA' , codDepartamento:'23' , codCiudad:'23807'},
    { departamento: 'CORDOBA' , ciudad:'VALENCIA' , codDepartamento:'23' , codCiudad:'23855'},
    { departamento: 'CUNDINAMARCA' , ciudad:'AGUA DE DIOS' , codDepartamento:'25' , codCiudad:'25001'},
    { departamento: 'CUNDINAMARCA' , ciudad:'ALBAN' , codDepartamento:'25' , codCiudad:'25019'},
    { departamento: 'CUNDINAMARCA' , ciudad:'ANAPOIMA' , codDepartamento:'25' , codCiudad:'25035'},
    { departamento: 'CUNDINAMARCA' , ciudad:'ANOLAIMA' , codDepartamento:'25' , codCiudad:'25040'},
    { departamento: 'CUNDINAMARCA' , ciudad:'ARBELAEZ' , codDepartamento:'25' , codCiudad:'25053'},
    { departamento: 'CUNDINAMARCA' , ciudad:'BELTRAN' , codDepartamento:'25' , codCiudad:'25086'},
    { departamento: 'CUNDINAMARCA' , ciudad:'BITUIMA' , codDepartamento:'25' , codCiudad:'25095'},
    { departamento: 'CUNDINAMARCA' , ciudad:'BOJACA' , codDepartamento:'25' , codCiudad:'25099'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CABRERA' , codDepartamento:'25' , codCiudad:'25120'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CACHIPAY' , codDepartamento:'25' , codCiudad:'25123'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CAJICA' , codDepartamento:'25' , codCiudad:'25126'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CAPARRAPI' , codDepartamento:'25' , codCiudad:'25148'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CAQUEZA' , codDepartamento:'25' , codCiudad:'25151'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CARMEN DE CARUPA' , codDepartamento:'25' , codCiudad:'25154'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CHAGUANI' , codDepartamento:'25' , codCiudad:'25168'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CHIA' , codDepartamento:'25' , codCiudad:'25175'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CHIPAQUE' , codDepartamento:'25' , codCiudad:'25178'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CHOACHI' , codDepartamento:'25' , codCiudad:'25181'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CHOCONTA' , codDepartamento:'25' , codCiudad:'25183'},
    { departamento: 'CUNDINAMARCA' , ciudad:'COGUA' , codDepartamento:'25' , codCiudad:'25200'},
    { departamento: 'CUNDINAMARCA' , ciudad:'COTA' , codDepartamento:'25' , codCiudad:'25214'},
    { departamento: 'CUNDINAMARCA' , ciudad:'CUCUNUBA' , codDepartamento:'25' , codCiudad:'25224'},
    { departamento: 'CUNDINAMARCA' , ciudad:'EL COLEGIO' , codDepartamento:'25' , codCiudad:'25245'},
    { departamento: 'CUNDINAMARCA' , ciudad:'EL PEÑON' , codDepartamento:'25' , codCiudad:'25258'},
    { departamento: 'CUNDINAMARCA' , ciudad:'EL ROSAL' , codDepartamento:'25' , codCiudad:'25260'},
    { departamento: 'CUNDINAMARCA' , ciudad:'FACATATIVA' , codDepartamento:'25' , codCiudad:'25269'},
    { departamento: 'CUNDINAMARCA' , ciudad:'FOMEQUE' , codDepartamento:'25' , codCiudad:'25279'},
    { departamento: 'CUNDINAMARCA' , ciudad:'FOSCA' , codDepartamento:'25' , codCiudad:'25281'},
    { departamento: 'CUNDINAMARCA' , ciudad:'FUNZA' , codDepartamento:'25' , codCiudad:'25286'},
    { departamento: 'CUNDINAMARCA' , ciudad:'FUQUENE' , codDepartamento:'25' , codCiudad:'25288'},
    { departamento: 'CUNDINAMARCA' , ciudad:'FUSAGASUGA' , codDepartamento:'25' , codCiudad:'25290'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GACHALA' , codDepartamento:'25' , codCiudad:'25293'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GACHANCIPA' , codDepartamento:'25' , codCiudad:'25295'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GACHETA' , codDepartamento:'25' , codCiudad:'25297'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GAMA' , codDepartamento:'25' , codCiudad:'25299'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GIRARDOT' , codDepartamento:'25' , codCiudad:'25307'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GRANADA' , codDepartamento:'25' , codCiudad:'25312'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUACHETA' , codDepartamento:'25' , codCiudad:'25317'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUADUAS' , codDepartamento:'25' , codCiudad:'25320'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUASCA' , codDepartamento:'25' , codCiudad:'25322'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUATAQUI' , codDepartamento:'25' , codCiudad:'25324'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUATAVITA' , codDepartamento:'25' , codCiudad:'25326'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUAYABAL DE SIQUIMA' , codDepartamento:'25' , codCiudad:'25328'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUAYABETAL' , codDepartamento:'25' , codCiudad:'25335'},
    { departamento: 'CUNDINAMARCA' , ciudad:'GUTIERREZ' , codDepartamento:'25' , codCiudad:'25339'},
    { departamento: 'CUNDINAMARCA' , ciudad:'JERUSALEN' , codDepartamento:'25' , codCiudad:'25368'},
    { departamento: 'CUNDINAMARCA' , ciudad:'JUNIN' , codDepartamento:'25' , codCiudad:'25372'},
    { departamento: 'CUNDINAMARCA' , ciudad:'LA CALERA' , codDepartamento:'25' , codCiudad:'25377'},
    { departamento: 'CUNDINAMARCA' , ciudad:'LA MESA' , codDepartamento:'25' , codCiudad:'25386'},
    { departamento: 'CUNDINAMARCA' , ciudad:'LA PALMA' , codDepartamento:'25' , codCiudad:'25394'},
    { departamento: 'CUNDINAMARCA' , ciudad:'LA PEÑA' , codDepartamento:'25' , codCiudad:'25398'},
    { departamento: 'CUNDINAMARCA' , ciudad:'LA VEGA' , codDepartamento:'25' , codCiudad:'25402'},
    { departamento: 'CUNDINAMARCA' , ciudad:'LENGUAZAQUE' , codDepartamento:'25' , codCiudad:'25407'},
    { departamento: 'CUNDINAMARCA' , ciudad:'MACHETA' , codDepartamento:'25' , codCiudad:'25426'},
    { departamento: 'CUNDINAMARCA' , ciudad:'MADRID' , codDepartamento:'25' , codCiudad:'25430'},
    { departamento: 'CUNDINAMARCA' , ciudad:'MANTA' , codDepartamento:'25' , codCiudad:'25436'},
    { departamento: 'CUNDINAMARCA' , ciudad:'MEDINA' , codDepartamento:'25' , codCiudad:'25438'},
    { departamento: 'CUNDINAMARCA' , ciudad:'MOSQUERA' , codDepartamento:'25' , codCiudad:'25473'},
    { departamento: 'CUNDINAMARCA' , ciudad:'NARIÑO' , codDepartamento:'25' , codCiudad:'25483'},
    { departamento: 'CUNDINAMARCA' , ciudad:'NEMOCON' , codDepartamento:'25' , codCiudad:'25486'},
    { departamento: 'CUNDINAMARCA' , ciudad:'NILO' , codDepartamento:'25' , codCiudad:'25488'},
    { departamento: 'CUNDINAMARCA' , ciudad:'NIMAIMA' , codDepartamento:'25' , codCiudad:'25489'},
    { departamento: 'CUNDINAMARCA' , ciudad:'NOCAIMA' , codDepartamento:'25' , codCiudad:'25491'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VENECIA' , codDepartamento:'25' , codCiudad:'25506'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PACHO' , codDepartamento:'25' , codCiudad:'25513'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PAIME' , codDepartamento:'25' , codCiudad:'25518'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PANDI' , codDepartamento:'25' , codCiudad:'25524'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PARATEBUENO' , codDepartamento:'25' , codCiudad:'25530'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PASCA' , codDepartamento:'25' , codCiudad:'25535'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PUERTO SALGAR' , codDepartamento:'25' , codCiudad:'25572'},
    { departamento: 'CUNDINAMARCA' , ciudad:'PULI' , codDepartamento:'25' , codCiudad:'25580'},
    { departamento: 'CUNDINAMARCA' , ciudad:'QUEBRADANEGRA' , codDepartamento:'25' , codCiudad:'25592'},
    { departamento: 'CUNDINAMARCA' , ciudad:'QUETAME' , codDepartamento:'25' , codCiudad:'25594'},
    { departamento: 'CUNDINAMARCA' , ciudad:'QUIPILE' , codDepartamento:'25' , codCiudad:'25596'},
    { departamento: 'CUNDINAMARCA' , ciudad:'APULO' , codDepartamento:'25' , codCiudad:'25599'},
    { departamento: 'CUNDINAMARCA' , ciudad:'RICAURTE' , codDepartamento:'25' , codCiudad:'25612'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SAN ANTONIO DEL TEQUENDAMA' , codDepartamento:'25' , codCiudad:'25645'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SAN BERNARDO' , codDepartamento:'25' , codCiudad:'25649'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SAN CAYETANO' , codDepartamento:'25' , codCiudad:'25653'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SAN FRANCISCO' , codDepartamento:'25' , codCiudad:'25658'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SAN JUAN DE RIO SECO' , codDepartamento:'25' , codCiudad:'25662'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SASAIMA' , codDepartamento:'25' , codCiudad:'25718'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SESQUILE' , codDepartamento:'25' , codCiudad:'25736'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SIBATE' , codDepartamento:'25' , codCiudad:'25740'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SILVANIA' , codDepartamento:'25' , codCiudad:'25743'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SIMIJACA' , codDepartamento:'25' , codCiudad:'25745'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SOACHA' , codDepartamento:'25' , codCiudad:'25754'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SOPO' , codDepartamento:'25' , codCiudad:'25758'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SUBACHOQUE' , codDepartamento:'25' , codCiudad:'25769'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SUESCA' , codDepartamento:'25' , codCiudad:'25772'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SUPATA' , codDepartamento:'25' , codCiudad:'25777'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SUSA' , codDepartamento:'25' , codCiudad:'25779'},
    { departamento: 'CUNDINAMARCA' , ciudad:'SUTATAUSA' , codDepartamento:'25' , codCiudad:'25781'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TABIO' , codDepartamento:'25' , codCiudad:'25785'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TAUSA' , codDepartamento:'25' , codCiudad:'25793'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TENA' , codDepartamento:'25' , codCiudad:'25797'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TENJO' , codDepartamento:'25' , codCiudad:'25799'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TIBACUY' , codDepartamento:'25' , codCiudad:'25805'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TIBIRITA' , codDepartamento:'25' , codCiudad:'25807'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TOCAIMA' , codDepartamento:'25' , codCiudad:'25815'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TOCANCIPA' , codDepartamento:'25' , codCiudad:'25817'},
    { departamento: 'CUNDINAMARCA' , ciudad:'TOPAIPI' , codDepartamento:'25' , codCiudad:'25823'},
    { departamento: 'CUNDINAMARCA' , ciudad:'UBALA' , codDepartamento:'25' , codCiudad:'25839'},
    { departamento: 'CUNDINAMARCA' , ciudad:'UBAQUE' , codDepartamento:'25' , codCiudad:'25841'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VILLA DE SAN DIEGO DE UBATE' , codDepartamento:'25' , codCiudad:'25843'},
    { departamento: 'CUNDINAMARCA' , ciudad:'UNE' , codDepartamento:'25' , codCiudad:'25845'},
    { departamento: 'CUNDINAMARCA' , ciudad:'UTICA' , codDepartamento:'25' , codCiudad:'25851'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VERGARA' , codDepartamento:'25' , codCiudad:'25862'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VIANI' , codDepartamento:'25' , codCiudad:'25867'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VILLAGOMEZ' , codDepartamento:'25' , codCiudad:'25871'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VILLAPINZON' , codDepartamento:'25' , codCiudad:'25873'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VILLETA' , codDepartamento:'25' , codCiudad:'25875'},
    { departamento: 'CUNDINAMARCA' , ciudad:'VIOTA' , codDepartamento:'25' , codCiudad:'25878'},
    { departamento: 'CUNDINAMARCA' , ciudad:'YACOPI' , codDepartamento:'25' , codCiudad:'25885'},
    { departamento: 'CUNDINAMARCA' , ciudad:'ZIPACON' , codDepartamento:'25' , codCiudad:'25898'},
    { departamento: 'CUNDINAMARCA' , ciudad:'ZIPAQUIRA' , codDepartamento:'25' , codCiudad:'25899'},
    { departamento: 'CHOCO' , ciudad:'QUIBDO' , codDepartamento:'27' , codCiudad:'27001'},
    { departamento: 'CHOCO' , ciudad:'ACANDI' , codDepartamento:'27' , codCiudad:'27006'},
    { departamento: 'CHOCO' , ciudad:'ALTO BAUDO' , codDepartamento:'27' , codCiudad:'27025'},
    { departamento: 'CHOCO' , ciudad:'ATRATO' , codDepartamento:'27' , codCiudad:'27050'},
    { departamento: 'CHOCO' , ciudad:'BAGADO' , codDepartamento:'27' , codCiudad:'27073'},
    { departamento: 'CHOCO' , ciudad:'BAHIA SOLANO' , codDepartamento:'27' , codCiudad:'27075'},
    { departamento: 'CHOCO' , ciudad:'BAJO BAUDO' , codDepartamento:'27' , codCiudad:'27077'},
    { departamento: 'CHOCO' , ciudad:'BELEN DE BAJIRA' , codDepartamento:'27' , codCiudad:'27086'},
    { departamento: 'CHOCO' , ciudad:'BOJAYA' , codDepartamento:'27' , codCiudad:'27099'},
    { departamento: 'CHOCO' , ciudad:'EL CANTON DEL SAN PABLO' , codDepartamento:'27' , codCiudad:'27135'},
    { departamento: 'CHOCO' , ciudad:'CARMEN DEL DARIEN' , codDepartamento:'27' , codCiudad:'27150'},
    { departamento: 'CHOCO' , ciudad:'CERTEGUI' , codDepartamento:'27' , codCiudad:'27160'},
    { departamento: 'CHOCO' , ciudad:'CONDOTO' , codDepartamento:'27' , codCiudad:'27205'},
    { departamento: 'CHOCO' , ciudad:'EL CARMEN DE ATRATO' , codDepartamento:'27' , codCiudad:'27245'},
    { departamento: 'CHOCO' , ciudad:'EL LITORAL DEL SAN JUAN' , codDepartamento:'27' , codCiudad:'27250'},
    { departamento: 'CHOCO' , ciudad:'ISTMINA' , codDepartamento:'27' , codCiudad:'27361'},
    { departamento: 'CHOCO' , ciudad:'JURADO' , codDepartamento:'27' , codCiudad:'27372'},
    { departamento: 'CHOCO' , ciudad:'LLORO' , codDepartamento:'27' , codCiudad:'27413'},
    { departamento: 'CHOCO' , ciudad:'MEDIO ATRATO' , codDepartamento:'27' , codCiudad:'27425'},
    { departamento: 'CHOCO' , ciudad:'MEDIO BAUDO' , codDepartamento:'27' , codCiudad:'27430'},
    { departamento: 'CHOCO' , ciudad:'MEDIO SAN JUAN' , codDepartamento:'27' , codCiudad:'27450'},
    { departamento: 'CHOCO' , ciudad:'NOVITA' , codDepartamento:'27' , codCiudad:'27491'},
    { departamento: 'CHOCO' , ciudad:'NUQUI' , codDepartamento:'27' , codCiudad:'27495'},
    { departamento: 'CHOCO' , ciudad:'RIO IRO' , codDepartamento:'27' , codCiudad:'27580'},
    { departamento: 'CHOCO' , ciudad:'RIO QUITO' , codDepartamento:'27' , codCiudad:'27600'},
    { departamento: 'CHOCO' , ciudad:'RIOSUCIO' , codDepartamento:'27' , codCiudad:'27615'},
    { departamento: 'CHOCO' , ciudad:'SAN JOSE DEL PALMAR' , codDepartamento:'27' , codCiudad:'27660'},
    { departamento: 'CHOCO' , ciudad:'SIPI' , codDepartamento:'27' , codCiudad:'27745'},
    { departamento: 'CHOCO' , ciudad:'TADO' , codDepartamento:'27' , codCiudad:'27787'},
    { departamento: 'CHOCO' , ciudad:'UNGUIA' , codDepartamento:'27' , codCiudad:'27800'},
    { departamento: 'CHOCO' , ciudad:'UNION PANAMERICANA' , codDepartamento:'27' , codCiudad:'27810'},
    { departamento: 'HUILA' , ciudad:'NEIVA' , codDepartamento:'41' , codCiudad:'41001'},
    { departamento: 'HUILA' , ciudad:'ACEVEDO' , codDepartamento:'41' , codCiudad:'41006'},
    { departamento: 'HUILA' , ciudad:'AGRADO' , codDepartamento:'41' , codCiudad:'41013'},
    { departamento: 'HUILA' , ciudad:'AIPE' , codDepartamento:'41' , codCiudad:'41016'},
    { departamento: 'HUILA' , ciudad:'ALGECIRAS' , codDepartamento:'41' , codCiudad:'41020'},
    { departamento: 'HUILA' , ciudad:'ALTAMIRA' , codDepartamento:'41' , codCiudad:'41026'},
    { departamento: 'HUILA' , ciudad:'BARAYA' , codDepartamento:'41' , codCiudad:'41078'},
    { departamento: 'HUILA' , ciudad:'CAMPOALEGRE' , codDepartamento:'41' , codCiudad:'41132'},
    { departamento: 'HUILA' , ciudad:'COLOMBIA' , codDepartamento:'41' , codCiudad:'41206'},
    { departamento: 'HUILA' , ciudad:'ELIAS' , codDepartamento:'41' , codCiudad:'41244'},
    { departamento: 'HUILA' , ciudad:'GARZON' , codDepartamento:'41' , codCiudad:'41298'},
    { departamento: 'HUILA' , ciudad:'GIGANTE' , codDepartamento:'41' , codCiudad:'41306'},
    { departamento: 'HUILA' , ciudad:'GUADALUPE' , codDepartamento:'41' , codCiudad:'41319'},
    { departamento: 'HUILA' , ciudad:'HOBO' , codDepartamento:'41' , codCiudad:'41349'},
    { departamento: 'HUILA' , ciudad:'IQUIRA' , codDepartamento:'41' , codCiudad:'41357'},
    { departamento: 'HUILA' , ciudad:'ISNOS' , codDepartamento:'41' , codCiudad:'41359'},
    { departamento: 'HUILA' , ciudad:'LA ARGENTINA' , codDepartamento:'41' , codCiudad:'41378'},
    { departamento: 'HUILA' , ciudad:'LA PLATA' , codDepartamento:'41' , codCiudad:'41396'},
    { departamento: 'HUILA' , ciudad:'NATAGA' , codDepartamento:'41' , codCiudad:'41483'},
    { departamento: 'HUILA' , ciudad:'OPORAPA' , codDepartamento:'41' , codCiudad:'41503'},
    { departamento: 'HUILA' , ciudad:'PAICOL' , codDepartamento:'41' , codCiudad:'41518'},
    { departamento: 'HUILA' , ciudad:'PALERMO' , codDepartamento:'41' , codCiudad:'41524'},
    { departamento: 'HUILA' , ciudad:'PALESTINA' , codDepartamento:'41' , codCiudad:'41530'},
    { departamento: 'HUILA' , ciudad:'PITAL' , codDepartamento:'41' , codCiudad:'41548'},
    { departamento: 'HUILA' , ciudad:'PITALITO' , codDepartamento:'41' , codCiudad:'41551'},
    { departamento: 'HUILA' , ciudad:'RIVERA' , codDepartamento:'41' , codCiudad:'41615'},
    { departamento: 'HUILA' , ciudad:'SALADOBLANCO' , codDepartamento:'41' , codCiudad:'41660'},
    { departamento: 'HUILA' , ciudad:'SAN AGUSTIN' , codDepartamento:'41' , codCiudad:'41668'},
    { departamento: 'HUILA' , ciudad:'SANTA MARIA' , codDepartamento:'41' , codCiudad:'41676'},
    { departamento: 'HUILA' , ciudad:'SUAZA' , codDepartamento:'41' , codCiudad:'41770'},
    { departamento: 'HUILA' , ciudad:'TARQUI' , codDepartamento:'41' , codCiudad:'41791'},
    { departamento: 'HUILA' , ciudad:'TESALIA' , codDepartamento:'41' , codCiudad:'41797'},
    { departamento: 'HUILA' , ciudad:'TELLO' , codDepartamento:'41' , codCiudad:'41799'},
    { departamento: 'HUILA' , ciudad:'TERUEL' , codDepartamento:'41' , codCiudad:'41801'},
    { departamento: 'HUILA' , ciudad:'TIMANA' , codDepartamento:'41' , codCiudad:'41807'},
    { departamento: 'HUILA' , ciudad:'VILLAVIEJA' , codDepartamento:'41' , codCiudad:'41872'},
    { departamento: 'HUILA' , ciudad:'YAGUARA' , codDepartamento:'41' , codCiudad:'41885'},
    { departamento: 'LA GUAJIRA' , ciudad:'RIOHACHA' , codDepartamento:'44' , codCiudad:'44001'},
    { departamento: 'LA GUAJIRA' , ciudad:'ALBANIA' , codDepartamento:'44' , codCiudad:'44035'},
    { departamento: 'LA GUAJIRA' , ciudad:'BARRANCAS' , codDepartamento:'44' , codCiudad:'44078'},
    { departamento: 'LA GUAJIRA' , ciudad:'DIBULLA' , codDepartamento:'44' , codCiudad:'44090'},
    { departamento: 'LA GUAJIRA' , ciudad:'DISTRACCION' , codDepartamento:'44' , codCiudad:'44098'},
    { departamento: 'LA GUAJIRA' , ciudad:'EL MOLINO' , codDepartamento:'44' , codCiudad:'44110'},
    { departamento: 'LA GUAJIRA' , ciudad:'FONSECA' , codDepartamento:'44' , codCiudad:'44279'},
    { departamento: 'LA GUAJIRA' , ciudad:'HATONUEVO' , codDepartamento:'44' , codCiudad:'44378'},
    { departamento: 'LA GUAJIRA' , ciudad:'LA JAGUA DEL PILAR' , codDepartamento:'44' , codCiudad:'44420'},
    { departamento: 'LA GUAJIRA' , ciudad:'MAICAO' , codDepartamento:'44' , codCiudad:'44430'},
    { departamento: 'LA GUAJIRA' , ciudad:'MANAURE' , codDepartamento:'44' , codCiudad:'44560'},
    { departamento: 'LA GUAJIRA' , ciudad:'SAN JUAN DEL CESAR' , codDepartamento:'44' , codCiudad:'44650'},
    { departamento: 'LA GUAJIRA' , ciudad:'URIBIA' , codDepartamento:'44' , codCiudad:'44847'},
    { departamento: 'LA GUAJIRA' , ciudad:'URUMITA' , codDepartamento:'44' , codCiudad:'44855'},
    { departamento: 'LA GUAJIRA' , ciudad:'VILLANUEVA' , codDepartamento:'44' , codCiudad:'44874'},
    { departamento: 'MAGDALENA' , ciudad:'SANTA MARTA' , codDepartamento:'47' , codCiudad:'47001'},
    { departamento: 'MAGDALENA' , ciudad:'ALGARROBO' , codDepartamento:'47' , codCiudad:'47030'},
    { departamento: 'MAGDALENA' , ciudad:'ARACATACA' , codDepartamento:'47' , codCiudad:'47053'},
    { departamento: 'MAGDALENA' , ciudad:'ARIGUANI' , codDepartamento:'47' , codCiudad:'47058'},
    { departamento: 'MAGDALENA' , ciudad:'CERRO SAN ANTONIO' , codDepartamento:'47' , codCiudad:'47161'},
    { departamento: 'MAGDALENA' , ciudad:'CHIVOLO' , codDepartamento:'47' , codCiudad:'47170'},
    { departamento: 'MAGDALENA' , ciudad:'CIENAGA' , codDepartamento:'47' , codCiudad:'47189'},
    { departamento: 'MAGDALENA' , ciudad:'CONCORDIA' , codDepartamento:'47' , codCiudad:'47205'},
    { departamento: 'MAGDALENA' , ciudad:'EL BANCO' , codDepartamento:'47' , codCiudad:'47245'},
    { departamento: 'MAGDALENA' , ciudad:'EL PIÑON' , codDepartamento:'47' , codCiudad:'47258'},
    { departamento: 'MAGDALENA' , ciudad:'EL RETEN' , codDepartamento:'47' , codCiudad:'47268'},
    { departamento: 'MAGDALENA' , ciudad:'FUNDACION' , codDepartamento:'47' , codCiudad:'47288'},
    { departamento: 'MAGDALENA' , ciudad:'GUAMAL' , codDepartamento:'47' , codCiudad:'47318'},
    { departamento: 'MAGDALENA' , ciudad:'NUEVA GRANADA' , codDepartamento:'47' , codCiudad:'47460'},
    { departamento: 'MAGDALENA' , ciudad:'PEDRAZA' , codDepartamento:'47' , codCiudad:'47541'},
    { departamento: 'MAGDALENA' , ciudad:'PIJIÑO DEL CARMEN' , codDepartamento:'47' , codCiudad:'47545'},
    { departamento: 'MAGDALENA' , ciudad:'PIVIJAY' , codDepartamento:'47' , codCiudad:'47551'},
    { departamento: 'MAGDALENA' , ciudad:'PLATO' , codDepartamento:'47' , codCiudad:'47555'},
    { departamento: 'MAGDALENA' , ciudad:'PUEBLOVIEJO' , codDepartamento:'47' , codCiudad:'47570'},
    { departamento: 'MAGDALENA' , ciudad:'REMOLINO' , codDepartamento:'47' , codCiudad:'47605'},
    { departamento: 'MAGDALENA' , ciudad:'SABANAS DE SAN ANGEL' , codDepartamento:'47' , codCiudad:'47660'},
    { departamento: 'MAGDALENA' , ciudad:'SALAMINA' , codDepartamento:'47' , codCiudad:'47675'},
    { departamento: 'MAGDALENA' , ciudad:'SAN SEBASTIAN DE BUENAVISTA' , codDepartamento:'47' , codCiudad:'47692'},
    { departamento: 'MAGDALENA' , ciudad:'SAN ZENON' , codDepartamento:'47' , codCiudad:'47703'},
    { departamento: 'MAGDALENA' , ciudad:'SANTA ANA' , codDepartamento:'47' , codCiudad:'47707'},
    { departamento: 'MAGDALENA' , ciudad:'SANTA BARBARA DE PINTO' , codDepartamento:'47' , codCiudad:'47720'},
    { departamento: 'MAGDALENA' , ciudad:'SITIONUEVO' , codDepartamento:'47' , codCiudad:'47745'},
    { departamento: 'MAGDALENA' , ciudad:'TENERIFE' , codDepartamento:'47' , codCiudad:'47798'},
    { departamento: 'MAGDALENA' , ciudad:'ZAPAYAN' , codDepartamento:'47' , codCiudad:'47960'},
    { departamento: 'MAGDALENA' , ciudad:'ZONA BANANERA' , codDepartamento:'47' , codCiudad:'47980'},
    { departamento: 'META' , ciudad:'VILLAVICENCIO' , codDepartamento:'50' , codCiudad:'50001'},
    { departamento: 'META' , ciudad:'ACACIAS' , codDepartamento:'50' , codCiudad:'50006'},
    { departamento: 'META' , ciudad:'BARRANCA DE UPIA' , codDepartamento:'50' , codCiudad:'50110'},
    { departamento: 'META' , ciudad:'CABUYARO' , codDepartamento:'50' , codCiudad:'50124'},
    { departamento: 'META' , ciudad:'CASTILLA LA NUEVA' , codDepartamento:'50' , codCiudad:'50150'},
    { departamento: 'META' , ciudad:'CUBARRAL' , codDepartamento:'50' , codCiudad:'50223'},
    { departamento: 'META' , ciudad:'CUMARAL' , codDepartamento:'50' , codCiudad:'50226'},
    { departamento: 'META' , ciudad:'EL CALVARIO' , codDepartamento:'50' , codCiudad:'50245'},
    { departamento: 'META' , ciudad:'EL CASTILLO' , codDepartamento:'50' , codCiudad:'50251'},
    { departamento: 'META' , ciudad:'EL DORADO' , codDepartamento:'50' , codCiudad:'50270'},
    { departamento: 'META' , ciudad:'FUENTE DE ORO' , codDepartamento:'50' , codCiudad:'50287'},
    { departamento: 'META' , ciudad:'GRANADA' , codDepartamento:'50' , codCiudad:'50313'},
    { departamento: 'META' , ciudad:'GUAMAL' , codDepartamento:'50' , codCiudad:'50318'},
    { departamento: 'META' , ciudad:'MAPIRIPAN' , codDepartamento:'50' , codCiudad:'50325'},
    { departamento: 'META' , ciudad:'MESETAS' , codDepartamento:'50' , codCiudad:'50330'},
    { departamento: 'META' , ciudad:'LA MACARENA' , codDepartamento:'50' , codCiudad:'50350'},
    { departamento: 'META' , ciudad:'URIBE' , codDepartamento:'50' , codCiudad:'50370'},
    { departamento: 'META' , ciudad:'LEJANIAS' , codDepartamento:'50' , codCiudad:'50400'},
    { departamento: 'META' , ciudad:'PUERTO CONCORDIA' , codDepartamento:'50' , codCiudad:'50450'},
    { departamento: 'META' , ciudad:'PUERTO GAITAN' , codDepartamento:'50' , codCiudad:'50568'},
    { departamento: 'META' , ciudad:'PUERTO LOPEZ' , codDepartamento:'50' , codCiudad:'50573'},
    { departamento: 'META' , ciudad:'PUERTO LLERAS' , codDepartamento:'50' , codCiudad:'50577'},
    { departamento: 'META' , ciudad:'PUERTO RICO' , codDepartamento:'50' , codCiudad:'50590'},
    { departamento: 'META' , ciudad:'RESTREPO' , codDepartamento:'50' , codCiudad:'50606'},
    { departamento: 'META' , ciudad:'SAN CARLOS DE GUAROA' , codDepartamento:'50' , codCiudad:'50680'},
    { departamento: 'META' , ciudad:'SAN JUAN DE ARAMA' , codDepartamento:'50' , codCiudad:'50683'},
    { departamento: 'META' , ciudad:'SAN JUANITO' , codDepartamento:'50' , codCiudad:'50686'},
    { departamento: 'META' , ciudad:'SAN MARTIN' , codDepartamento:'50' , codCiudad:'50689'},
    { departamento: 'META' , ciudad:'VISTA HERMOSA' , codDepartamento:'50' , codCiudad:'50711'},
    { departamento: 'NARIÑO' , ciudad:'PASTO' , codDepartamento:'52' , codCiudad:'52001'},
    { departamento: 'NARIÑO' , ciudad:'ALBAN' , codDepartamento:'52' , codCiudad:'52019'},
    { departamento: 'NARIÑO' , ciudad:'ALDANA' , codDepartamento:'52' , codCiudad:'52022'},
    { departamento: 'NARIÑO' , ciudad:'ANCUYA' , codDepartamento:'52' , codCiudad:'52036'},
    { departamento: 'NARIÑO' , ciudad:'ARBOLEDA' , codDepartamento:'52' , codCiudad:'52051'},
    { departamento: 'NARIÑO' , ciudad:'BARBACOAS' , codDepartamento:'52' , codCiudad:'52079'},
    { departamento: 'NARIÑO' , ciudad:'BELEN' , codDepartamento:'52' , codCiudad:'52083'},
    { departamento: 'NARIÑO' , ciudad:'BUESACO' , codDepartamento:'52' , codCiudad:'52110'},
    { departamento: 'NARIÑO' , ciudad:'COLON' , codDepartamento:'52' , codCiudad:'52203'},
    { departamento: 'NARIÑO' , ciudad:'CONSACA' , codDepartamento:'52' , codCiudad:'52207'},
    { departamento: 'NARIÑO' , ciudad:'CONTADERO' , codDepartamento:'52' , codCiudad:'52210'},
    { departamento: 'NARIÑO' , ciudad:'CORDOBA' , codDepartamento:'52' , codCiudad:'52215'},
    { departamento: 'NARIÑO' , ciudad:'CUASPUD' , codDepartamento:'52' , codCiudad:'52224'},
    { departamento: 'NARIÑO' , ciudad:'CUMBAL' , codDepartamento:'52' , codCiudad:'52227'},
    { departamento: 'NARIÑO' , ciudad:'CUMBITARA' , codDepartamento:'52' , codCiudad:'52233'},
    { departamento: 'NARIÑO' , ciudad:'CHACHAGÜI' , codDepartamento:'52' , codCiudad:'52240'},
    { departamento: 'NARIÑO' , ciudad:'EL CHARCO' , codDepartamento:'52' , codCiudad:'52250'},
    { departamento: 'NARIÑO' , ciudad:'EL PEÑOL' , codDepartamento:'52' , codCiudad:'52254'},
    { departamento: 'NARIÑO' , ciudad:'EL ROSARIO' , codDepartamento:'52' , codCiudad:'52256'},
    { departamento: 'NARIÑO' , ciudad:'EL TABLON DE GOMEZ' , codDepartamento:'52' , codCiudad:'52258'},
    { departamento: 'NARIÑO' , ciudad:'EL TAMBO' , codDepartamento:'52' , codCiudad:'52260'},
    { departamento: 'NARIÑO' , ciudad:'FUNES' , codDepartamento:'52' , codCiudad:'52287'},
    { departamento: 'NARIÑO' , ciudad:'GUACHUCAL' , codDepartamento:'52' , codCiudad:'52317'},
    { departamento: 'NARIÑO' , ciudad:'GUAITARILLA' , codDepartamento:'52' , codCiudad:'52320'},
    { departamento: 'NARIÑO' , ciudad:'GUALMATAN' , codDepartamento:'52' , codCiudad:'52323'},
    { departamento: 'NARIÑO' , ciudad:'ILES' , codDepartamento:'52' , codCiudad:'52352'},
    { departamento: 'NARIÑO' , ciudad:'IMUES' , codDepartamento:'52' , codCiudad:'52354'},
    { departamento: 'NARIÑO' , ciudad:'IPIALES' , codDepartamento:'52' , codCiudad:'52356'},
    { departamento: 'NARIÑO' , ciudad:'LA CRUZ' , codDepartamento:'52' , codCiudad:'52378'},
    { departamento: 'NARIÑO' , ciudad:'LA FLORIDA' , codDepartamento:'52' , codCiudad:'52381'},
    { departamento: 'NARIÑO' , ciudad:'LA LLANADA' , codDepartamento:'52' , codCiudad:'52385'},
    { departamento: 'NARIÑO' , ciudad:'LA TOLA' , codDepartamento:'52' , codCiudad:'52390'},
    { departamento: 'NARIÑO' , ciudad:'LA UNION' , codDepartamento:'52' , codCiudad:'52399'},
    { departamento: 'NARIÑO' , ciudad:'LEIVA' , codDepartamento:'52' , codCiudad:'52405'},
    { departamento: 'NARIÑO' , ciudad:'LINARES' , codDepartamento:'52' , codCiudad:'52411'},
    { departamento: 'NARIÑO' , ciudad:'LOS ANDES' , codDepartamento:'52' , codCiudad:'52418'},
    { departamento: 'NARIÑO' , ciudad:'MAGÜI' , codDepartamento:'52' , codCiudad:'52427'},
    { departamento: 'NARIÑO' , ciudad:'MALLAMA' , codDepartamento:'52' , codCiudad:'52435'},
    { departamento: 'NARIÑO' , ciudad:'MOSQUERA' , codDepartamento:'52' , codCiudad:'52473'},
    { departamento: 'NARIÑO' , ciudad:'NARIÑO' , codDepartamento:'52' , codCiudad:'52480'},
    { departamento: 'NARIÑO' , ciudad:'OLAYA HERRERA' , codDepartamento:'52' , codCiudad:'52490'},
    { departamento: 'NARIÑO' , ciudad:'OSPINA' , codDepartamento:'52' , codCiudad:'52506'},
    { departamento: 'NARIÑO' , ciudad:'FRANCISCO PIZARRO' , codDepartamento:'52' , codCiudad:'52520'},
    { departamento: 'NARIÑO' , ciudad:'POLICARPA' , codDepartamento:'52' , codCiudad:'52540'},
    { departamento: 'NARIÑO' , ciudad:'POTOSI' , codDepartamento:'52' , codCiudad:'52560'},
    { departamento: 'NARIÑO' , ciudad:'PROVIDENCIA' , codDepartamento:'52' , codCiudad:'52565'},
    { departamento: 'NARIÑO' , ciudad:'PUERRES' , codDepartamento:'52' , codCiudad:'52573'},
    { departamento: 'NARIÑO' , ciudad:'PUPIALES' , codDepartamento:'52' , codCiudad:'52585'},
    { departamento: 'NARIÑO' , ciudad:'RICAURTE' , codDepartamento:'52' , codCiudad:'52612'},
    { departamento: 'NARIÑO' , ciudad:'ROBERTO PAYAN' , codDepartamento:'52' , codCiudad:'52621'},
    { departamento: 'NARIÑO' , ciudad:'SAMANIEGO' , codDepartamento:'52' , codCiudad:'52678'},
    { departamento: 'NARIÑO' , ciudad:'SANDONA' , codDepartamento:'52' , codCiudad:'52683'},
    { departamento: 'NARIÑO' , ciudad:'SAN BERNARDO' , codDepartamento:'52' , codCiudad:'52685'},
    { departamento: 'NARIÑO' , ciudad:'SAN LORENZO' , codDepartamento:'52' , codCiudad:'52687'},
    { departamento: 'NARIÑO' , ciudad:'SAN PABLO' , codDepartamento:'52' , codCiudad:'52693'},
    { departamento: 'NARIÑO' , ciudad:'SAN PEDRO DE CARTAGO' , codDepartamento:'52' , codCiudad:'52694'},
    { departamento: 'NARIÑO' , ciudad:'SANTA BARBARA' , codDepartamento:'52' , codCiudad:'52696'},
    { departamento: 'NARIÑO' , ciudad:'SANTACRUZ' , codDepartamento:'52' , codCiudad:'52699'},
    { departamento: 'NARIÑO' , ciudad:'SAPUYES' , codDepartamento:'52' , codCiudad:'52720'},
    { departamento: 'NARIÑO' , ciudad:'TAMINANGO' , codDepartamento:'52' , codCiudad:'52786'},
    { departamento: 'NARIÑO' , ciudad:'TANGUA' , codDepartamento:'52' , codCiudad:'52788'},
    { departamento: 'NARIÑO' , ciudad:'TUMACO' , codDepartamento:'52' , codCiudad:'52835'},
    { departamento: 'NARIÑO' , ciudad:'TUQUERRES' , codDepartamento:'52' , codCiudad:'52838'},
    { departamento: 'NARIÑO' , ciudad:'YACUANQUER' , codDepartamento:'52' , codCiudad:'52885'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CUCUTA' , codDepartamento:'54' , codCiudad:'54001'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'ABREGO' , codDepartamento:'54' , codCiudad:'54003'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'ARBOLEDAS' , codDepartamento:'54' , codCiudad:'54051'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'BOCHALEMA' , codDepartamento:'54' , codCiudad:'54099'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'BUCARASICA' , codDepartamento:'54' , codCiudad:'54109'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CACOTA' , codDepartamento:'54' , codCiudad:'54125'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CACHIRA' , codDepartamento:'54' , codCiudad:'54128'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CHINACOTA' , codDepartamento:'54' , codCiudad:'54172'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CHITAGA' , codDepartamento:'54' , codCiudad:'54174'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CONVENCION' , codDepartamento:'54' , codCiudad:'54206'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'CUCUTILLA' , codDepartamento:'54' , codCiudad:'54223'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'DURANIA' , codDepartamento:'54' , codCiudad:'54239'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'EL CARMEN' , codDepartamento:'54' , codCiudad:'54245'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'EL TARRA' , codDepartamento:'54' , codCiudad:'54250'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'EL ZULIA' , codDepartamento:'54' , codCiudad:'54261'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'GRAMALOTE' , codDepartamento:'54' , codCiudad:'54313'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'HACARI' , codDepartamento:'54' , codCiudad:'54344'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'HERRAN' , codDepartamento:'54' , codCiudad:'54347'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'LABATECA' , codDepartamento:'54' , codCiudad:'54377'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'LA ESPERANZA' , codDepartamento:'54' , codCiudad:'54385'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'LA PLAYA' , codDepartamento:'54' , codCiudad:'54398'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'LOS PATIOS' , codDepartamento:'54' , codCiudad:'54405'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'LOURDES' , codDepartamento:'54' , codCiudad:'54418'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'MUTISCUA' , codDepartamento:'54' , codCiudad:'54480'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'OCAÑA' , codDepartamento:'54' , codCiudad:'54498'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'PAMPLONA' , codDepartamento:'54' , codCiudad:'54518'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'PAMPLONITA' , codDepartamento:'54' , codCiudad:'54520'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'PUERTO SANTANDER' , codDepartamento:'54' , codCiudad:'54553'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'RAGONVALIA' , codDepartamento:'54' , codCiudad:'54599'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'SALAZAR' , codDepartamento:'54' , codCiudad:'54660'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'SAN CALIXTO' , codDepartamento:'54' , codCiudad:'54670'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'SAN CAYETANO' , codDepartamento:'54' , codCiudad:'54673'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'SANTIAGO' , codDepartamento:'54' , codCiudad:'54680'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'SARDINATA' , codDepartamento:'54' , codCiudad:'54720'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'SILOS' , codDepartamento:'54' , codCiudad:'54743'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'TEORAMA' , codDepartamento:'54' , codCiudad:'54800'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'TIBU' , codDepartamento:'54' , codCiudad:'54810'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'TOLEDO' , codDepartamento:'54' , codCiudad:'54820'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'VILLA CARO' , codDepartamento:'54' , codCiudad:'54871'},
    { departamento: 'NORTE DE SANTANDER' , ciudad:'VILLA DEL ROSARIO' , codDepartamento:'54' , codCiudad:'54874'},
    { departamento: 'QUINDIO' , ciudad:'ARMENIA' , codDepartamento:'63' , codCiudad:'63001'},
    { departamento: 'QUINDIO' , ciudad:'BUENAVISTA' , codDepartamento:'63' , codCiudad:'63111'},
    { departamento: 'QUINDIO' , ciudad:'CALARCA' , codDepartamento:'63' , codCiudad:'63130'},
    { departamento: 'QUINDIO' , ciudad:'CIRCASIA' , codDepartamento:'63' , codCiudad:'63190'},
    { departamento: 'QUINDIO' , ciudad:'CORDOBA' , codDepartamento:'63' , codCiudad:'63212'},
    { departamento: 'QUINDIO' , ciudad:'FILANDIA' , codDepartamento:'63' , codCiudad:'63272'},
    { departamento: 'QUINDIO' , ciudad:'GENOVA' , codDepartamento:'63' , codCiudad:'63302'},
    { departamento: 'QUINDIO' , ciudad:'LA TEBAIDA' , codDepartamento:'63' , codCiudad:'63401'},
    { departamento: 'QUINDIO' , ciudad:'MONTENEGRO' , codDepartamento:'63' , codCiudad:'63470'},
    { departamento: 'QUINDIO' , ciudad:'PIJAO' , codDepartamento:'63' , codCiudad:'63548'},
    { departamento: 'QUINDIO' , ciudad:'QUIMBAYA' , codDepartamento:'63' , codCiudad:'63594'},
    { departamento: 'QUINDIO' , ciudad:'SALENTO' , codDepartamento:'63' , codCiudad:'63690'},
    { departamento: 'RISARALDA' , ciudad:'PEREIRA' , codDepartamento:'66' , codCiudad:'66001'},
    { departamento: 'RISARALDA' , ciudad:'APIA' , codDepartamento:'66' , codCiudad:'66045'},
    { departamento: 'RISARALDA' , ciudad:'BALBOA' , codDepartamento:'66' , codCiudad:'66075'},
    { departamento: 'RISARALDA' , ciudad:'BELEN DE UMBRIA' , codDepartamento:'66' , codCiudad:'66088'},
    { departamento: 'RISARALDA' , ciudad:'DOSQUEBRADAS' , codDepartamento:'66' , codCiudad:'66170'},
    { departamento: 'RISARALDA' , ciudad:'GUATICA' , codDepartamento:'66' , codCiudad:'66318'},
    { departamento: 'RISARALDA' , ciudad:'LA CELIA' , codDepartamento:'66' , codCiudad:'66383'},
    { departamento: 'RISARALDA' , ciudad:'LA VIRGINIA' , codDepartamento:'66' , codCiudad:'66400'},
    { departamento: 'RISARALDA' , ciudad:'MARSELLA' , codDepartamento:'66' , codCiudad:'66440'},
    { departamento: 'RISARALDA' , ciudad:'MISTRATO' , codDepartamento:'66' , codCiudad:'66456'},
    { departamento: 'RISARALDA' , ciudad:'PUEBLO RICO' , codDepartamento:'66' , codCiudad:'66572'},
    { departamento: 'RISARALDA' , ciudad:'QUINCHIA' , codDepartamento:'66' , codCiudad:'66594'},
    { departamento: 'RISARALDA' , ciudad:'SANTA ROSA DE CABAL' , codDepartamento:'66' , codCiudad:'66682'},
    { departamento: 'RISARALDA' , ciudad:'SANTUARIO' , codDepartamento:'66' , codCiudad:'66687'},
    { departamento: 'SANTANDER' , ciudad:'BUCARAMANGA' , codDepartamento:'68' , codCiudad:'68001'},
    { departamento: 'SANTANDER' , ciudad:'AGUADA' , codDepartamento:'68' , codCiudad:'68013'},
    { departamento: 'SANTANDER' , ciudad:'ALBANIA' , codDepartamento:'68' , codCiudad:'68020'},
    { departamento: 'SANTANDER' , ciudad:'ARATOCA' , codDepartamento:'68' , codCiudad:'68051'},
    { departamento: 'SANTANDER' , ciudad:'BARBOSA' , codDepartamento:'68' , codCiudad:'68077'},
    { departamento: 'SANTANDER' , ciudad:'BARICHARA' , codDepartamento:'68' , codCiudad:'68079'},
    { departamento: 'SANTANDER' , ciudad:'BARRANCABERMEJA' , codDepartamento:'68' , codCiudad:'68081'},
    { departamento: 'SANTANDER' , ciudad:'BETULIA' , codDepartamento:'68' , codCiudad:'68092'},
    { departamento: 'SANTANDER' , ciudad:'BOLIVAR' , codDepartamento:'68' , codCiudad:'68101'},
    { departamento: 'SANTANDER' , ciudad:'CABRERA' , codDepartamento:'68' , codCiudad:'68121'},
    { departamento: 'SANTANDER' , ciudad:'CALIFORNIA' , codDepartamento:'68' , codCiudad:'68132'},
    { departamento: 'SANTANDER' , ciudad:'CAPITANEJO' , codDepartamento:'68' , codCiudad:'68147'},
    { departamento: 'SANTANDER' , ciudad:'CARCASI' , codDepartamento:'68' , codCiudad:'68152'},
    { departamento: 'SANTANDER' , ciudad:'CEPITA' , codDepartamento:'68' , codCiudad:'68160'},
    { departamento: 'SANTANDER' , ciudad:'CERRITO' , codDepartamento:'68' , codCiudad:'68162'},
    { departamento: 'SANTANDER' , ciudad:'CHARALA' , codDepartamento:'68' , codCiudad:'68167'},
    { departamento: 'SANTANDER' , ciudad:'CHARTA' , codDepartamento:'68' , codCiudad:'68169'},
    { departamento: 'SANTANDER' , ciudad:'CHIMA' , codDepartamento:'68' , codCiudad:'68176'},
    { departamento: 'SANTANDER' , ciudad:'CHIPATA' , codDepartamento:'68' , codCiudad:'68179'},
    { departamento: 'SANTANDER' , ciudad:'CIMITARRA' , codDepartamento:'68' , codCiudad:'68190'},
    { departamento: 'SANTANDER' , ciudad:'CONCEPCION' , codDepartamento:'68' , codCiudad:'68207'},
    { departamento: 'SANTANDER' , ciudad:'CONFINES' , codDepartamento:'68' , codCiudad:'68209'},
    { departamento: 'SANTANDER' , ciudad:'CONTRATACION' , codDepartamento:'68' , codCiudad:'68211'},
    { departamento: 'SANTANDER' , ciudad:'COROMORO' , codDepartamento:'68' , codCiudad:'68217'},
    { departamento: 'SANTANDER' , ciudad:'CURITI' , codDepartamento:'68' , codCiudad:'68229'},
    { departamento: 'SANTANDER' , ciudad:'EL CARMEN DE CHUCURI' , codDepartamento:'68' , codCiudad:'68235'},
    { departamento: 'SANTANDER' , ciudad:'EL GUACAMAYO' , codDepartamento:'68' , codCiudad:'68245'},
    { departamento: 'SANTANDER' , ciudad:'EL PEÑON' , codDepartamento:'68' , codCiudad:'68250'},
    { departamento: 'SANTANDER' , ciudad:'EL PLAYON' , codDepartamento:'68' , codCiudad:'68255'},
    { departamento: 'SANTANDER' , ciudad:'ENCINO' , codDepartamento:'68' , codCiudad:'68264'},
    { departamento: 'SANTANDER' , ciudad:'ENCISO' , codDepartamento:'68' , codCiudad:'68266'},
    { departamento: 'SANTANDER' , ciudad:'FLORIAN' , codDepartamento:'68' , codCiudad:'68271'},
    { departamento: 'SANTANDER' , ciudad:'FLORIDABLANCA' , codDepartamento:'68' , codCiudad:'68276'},
    { departamento: 'SANTANDER' , ciudad:'GALAN' , codDepartamento:'68' , codCiudad:'68296'},
    { departamento: 'SANTANDER' , ciudad:'GAMBITA' , codDepartamento:'68' , codCiudad:'68298'},
    { departamento: 'SANTANDER' , ciudad:'GIRON' , codDepartamento:'68' , codCiudad:'68307'},
    { departamento: 'SANTANDER' , ciudad:'GUACA' , codDepartamento:'68' , codCiudad:'68318'},
    { departamento: 'SANTANDER' , ciudad:'GUADALUPE' , codDepartamento:'68' , codCiudad:'68320'},
    { departamento: 'SANTANDER' , ciudad:'GUAPOTA' , codDepartamento:'68' , codCiudad:'68322'},
    { departamento: 'SANTANDER' , ciudad:'GUAVATA' , codDepartamento:'68' , codCiudad:'68324'},
    { departamento: 'SANTANDER' , ciudad:'GÜEPSA' , codDepartamento:'68' , codCiudad:'68327'},
    { departamento: 'SANTANDER' , ciudad:'HATO' , codDepartamento:'68' , codCiudad:'68344'},
    { departamento: 'SANTANDER' , ciudad:'JESUS MARIA' , codDepartamento:'68' , codCiudad:'68368'},
    { departamento: 'SANTANDER' , ciudad:'JORDAN' , codDepartamento:'68' , codCiudad:'68370'},
    { departamento: 'SANTANDER' , ciudad:'LA BELLEZA' , codDepartamento:'68' , codCiudad:'68377'},
    { departamento: 'SANTANDER' , ciudad:'LANDAZURI' , codDepartamento:'68' , codCiudad:'68385'},
    { departamento: 'SANTANDER' , ciudad:'LA PAZ' , codDepartamento:'68' , codCiudad:'68397'},
    { departamento: 'SANTANDER' , ciudad:'LEBRIJA' , codDepartamento:'68' , codCiudad:'68406'},
    { departamento: 'SANTANDER' , ciudad:'LOS SANTOS' , codDepartamento:'68' , codCiudad:'68418'},
    { departamento: 'SANTANDER' , ciudad:'MACARAVITA' , codDepartamento:'68' , codCiudad:'68425'},
    { departamento: 'SANTANDER' , ciudad:'MALAGA' , codDepartamento:'68' , codCiudad:'68432'},
    { departamento: 'SANTANDER' , ciudad:'MATANZA' , codDepartamento:'68' , codCiudad:'68444'},
    { departamento: 'SANTANDER' , ciudad:'MOGOTES' , codDepartamento:'68' , codCiudad:'68464'},
    { departamento: 'SANTANDER' , ciudad:'MOLAGAVITA' , codDepartamento:'68' , codCiudad:'68468'},
    { departamento: 'SANTANDER' , ciudad:'OCAMONTE' , codDepartamento:'68' , codCiudad:'68498'},
    { departamento: 'SANTANDER' , ciudad:'OIBA' , codDepartamento:'68' , codCiudad:'68500'},
    { departamento: 'SANTANDER' , ciudad:'ONZAGA' , codDepartamento:'68' , codCiudad:'68502'},
    { departamento: 'SANTANDER' , ciudad:'PALMAR' , codDepartamento:'68' , codCiudad:'68522'},
    { departamento: 'SANTANDER' , ciudad:'PALMAS DEL SOCORRO' , codDepartamento:'68' , codCiudad:'68524'},
    { departamento: 'SANTANDER' , ciudad:'PARAMO' , codDepartamento:'68' , codCiudad:'68533'},
    { departamento: 'SANTANDER' , ciudad:'PIEDECUESTA' , codDepartamento:'68' , codCiudad:'68547'},
    { departamento: 'SANTANDER' , ciudad:'PINCHOTE' , codDepartamento:'68' , codCiudad:'68549'},
    { departamento: 'SANTANDER' , ciudad:'PUENTE NACIONAL' , codDepartamento:'68' , codCiudad:'68572'},
    { departamento: 'SANTANDER' , ciudad:'PUERTO PARRA' , codDepartamento:'68' , codCiudad:'68573'},
    { departamento: 'SANTANDER' , ciudad:'PUERTO WILCHES' , codDepartamento:'68' , codCiudad:'68575'},
    { departamento: 'SANTANDER' , ciudad:'RIONEGRO' , codDepartamento:'68' , codCiudad:'68615'},
    { departamento: 'SANTANDER' , ciudad:'SABANA DE TORRES' , codDepartamento:'68' , codCiudad:'68655'},
    { departamento: 'SANTANDER' , ciudad:'SAN ANDRES' , codDepartamento:'68' , codCiudad:'68669'},
    { departamento: 'SANTANDER' , ciudad:'SAN BENITO' , codDepartamento:'68' , codCiudad:'68673'},
    { departamento: 'SANTANDER' , ciudad:'SAN GIL' , codDepartamento:'68' , codCiudad:'68679'},
    { departamento: 'SANTANDER' , ciudad:'SAN JOAQUIN' , codDepartamento:'68' , codCiudad:'68682'},
    { departamento: 'SANTANDER' , ciudad:'SAN JOSE DE MIRANDA' , codDepartamento:'68' , codCiudad:'68684'},
    { departamento: 'SANTANDER' , ciudad:'SAN MIGUEL' , codDepartamento:'68' , codCiudad:'68686'},
    { departamento: 'SANTANDER' , ciudad:'SAN VICENTE DE CHUCURI' , codDepartamento:'68' , codCiudad:'68689'},
    { departamento: 'SANTANDER' , ciudad:'SANTA BARBARA' , codDepartamento:'68' , codCiudad:'68705'},
    { departamento: 'SANTANDER' , ciudad:'SANTA HELENA DEL OPON' , codDepartamento:'68' , codCiudad:'68720'},
    { departamento: 'SANTANDER' , ciudad:'SIMACOTA' , codDepartamento:'68' , codCiudad:'68745'},
    { departamento: 'SANTANDER' , ciudad:'SOCORRO' , codDepartamento:'68' , codCiudad:'68755'},
    { departamento: 'SANTANDER' , ciudad:'SUAITA' , codDepartamento:'68' , codCiudad:'68770'},
    { departamento: 'SANTANDER' , ciudad:'SUCRE' , codDepartamento:'68' , codCiudad:'68773'},
    { departamento: 'SANTANDER' , ciudad:'SURATA' , codDepartamento:'68' , codCiudad:'68780'},
    { departamento: 'SANTANDER' , ciudad:'TONA' , codDepartamento:'68' , codCiudad:'68820'},
    { departamento: 'SANTANDER' , ciudad:'VALLE DE SAN JOSE' , codDepartamento:'68' , codCiudad:'68855'},
    { departamento: 'SANTANDER' , ciudad:'VELEZ' , codDepartamento:'68' , codCiudad:'68861'},
    { departamento: 'SANTANDER' , ciudad:'VETAS' , codDepartamento:'68' , codCiudad:'68867'},
    { departamento: 'SANTANDER' , ciudad:'VILLANUEVA' , codDepartamento:'68' , codCiudad:'68872'},
    { departamento: 'SANTANDER' , ciudad:'ZAPATOCA' , codDepartamento:'68' , codCiudad:'68895'},
    { departamento: 'SUCRE' , ciudad:'SINCELEJO' , codDepartamento:'70' , codCiudad:'70001'},
    { departamento: 'SUCRE' , ciudad:'BUENAVISTA' , codDepartamento:'70' , codCiudad:'70110'},
    { departamento: 'SUCRE' , ciudad:'CAIMITO' , codDepartamento:'70' , codCiudad:'70124'},
    { departamento: 'SUCRE' , ciudad:'COLOSO' , codDepartamento:'70' , codCiudad:'70204'},
    { departamento: 'SUCRE' , ciudad:'COROZAL' , codDepartamento:'70' , codCiudad:'70215'},
    { departamento: 'SUCRE' , ciudad:'CHALAN' , codDepartamento:'70' , codCiudad:'70230'},
    { departamento: 'SUCRE' , ciudad:'EL ROBLE' , codDepartamento:'70' , codCiudad:'70233'},
    { departamento: 'SUCRE' , ciudad:'GALERAS' , codDepartamento:'70' , codCiudad:'70235'},
    { departamento: 'SUCRE' , ciudad:'GUARANDA' , codDepartamento:'70' , codCiudad:'70265'},
    { departamento: 'SUCRE' , ciudad:'LA UNION' , codDepartamento:'70' , codCiudad:'70400'},
    { departamento: 'SUCRE' , ciudad:'LOS PALMITOS' , codDepartamento:'70' , codCiudad:'70418'},
    { departamento: 'SUCRE' , ciudad:'MAJAGUAL' , codDepartamento:'70' , codCiudad:'70429'},
    { departamento: 'SUCRE' , ciudad:'MORROA' , codDepartamento:'70' , codCiudad:'70473'},
    { departamento: 'SUCRE' , ciudad:'OVEJAS' , codDepartamento:'70' , codCiudad:'70508'},
    { departamento: 'SUCRE' , ciudad:'PALMITO' , codDepartamento:'70' , codCiudad:'70523'},
    { departamento: 'SUCRE' , ciudad:'SAMPUES' , codDepartamento:'70' , codCiudad:'70670'},
    { departamento: 'SUCRE' , ciudad:'SAN BENITO ABAD' , codDepartamento:'70' , codCiudad:'70678'},
    { departamento: 'SUCRE' , ciudad:'SAN JUAN DE BETULIA' , codDepartamento:'70' , codCiudad:'70702'},
    { departamento: 'SUCRE' , ciudad:'SAN MARCOS' , codDepartamento:'70' , codCiudad:'70708'},
    { departamento: 'SUCRE' , ciudad:'SAN ONOFRE' , codDepartamento:'70' , codCiudad:'70713'},
    { departamento: 'SUCRE' , ciudad:'SAN PEDRO' , codDepartamento:'70' , codCiudad:'70717'},
    { departamento: 'SUCRE' , ciudad:'SINCE' , codDepartamento:'70' , codCiudad:'70742'},
    { departamento: 'SUCRE' , ciudad:'SUCRE' , codDepartamento:'70' , codCiudad:'70771'},
    { departamento: 'SUCRE' , ciudad:'SANTIAGO DE TOLU' , codDepartamento:'70' , codCiudad:'70820'},
    { departamento: 'SUCRE' , ciudad:'TOLUVIEJO' , codDepartamento:'70' , codCiudad:'70823'},
    { departamento: 'TOLIMA' , ciudad:'IBAGUE' , codDepartamento:'73' , codCiudad:'73001'},
    { departamento: 'TOLIMA' , ciudad:'ALPUJARRA' , codDepartamento:'73' , codCiudad:'73024'},
    { departamento: 'TOLIMA' , ciudad:'ALVARADO' , codDepartamento:'73' , codCiudad:'73026'},
    { departamento: 'TOLIMA' , ciudad:'AMBALEMA' , codDepartamento:'73' , codCiudad:'73030'},
    { departamento: 'TOLIMA' , ciudad:'ANZOATEGUI' , codDepartamento:'73' , codCiudad:'73043'},
    { departamento: 'TOLIMA' , ciudad:'ARMERO' , codDepartamento:'73' , codCiudad:'73055'},
    { departamento: 'TOLIMA' , ciudad:'ATACO' , codDepartamento:'73' , codCiudad:'73067'},
    { departamento: 'TOLIMA' , ciudad:'CAJAMARCA' , codDepartamento:'73' , codCiudad:'73124'},
    { departamento: 'TOLIMA' , ciudad:'CARMEN DE APICALA' , codDepartamento:'73' , codCiudad:'73148'},
    { departamento: 'TOLIMA' , ciudad:'CASABIANCA' , codDepartamento:'73' , codCiudad:'73152'},
    { departamento: 'TOLIMA' , ciudad:'CHAPARRAL' , codDepartamento:'73' , codCiudad:'73168'},
    { departamento: 'TOLIMA' , ciudad:'COELLO' , codDepartamento:'73' , codCiudad:'73200'},
    { departamento: 'TOLIMA' , ciudad:'COYAIMA' , codDepartamento:'73' , codCiudad:'73217'},
    { departamento: 'TOLIMA' , ciudad:'CUNDAY' , codDepartamento:'73' , codCiudad:'73226'},
    { departamento: 'TOLIMA' , ciudad:'DOLORES' , codDepartamento:'73' , codCiudad:'73236'},
    { departamento: 'TOLIMA' , ciudad:'ESPINAL' , codDepartamento:'73' , codCiudad:'73268'},
    { departamento: 'TOLIMA' , ciudad:'FALAN' , codDepartamento:'73' , codCiudad:'73270'},
    { departamento: 'TOLIMA' , ciudad:'FLANDES' , codDepartamento:'73' , codCiudad:'73275'},
    { departamento: 'TOLIMA' , ciudad:'FRESNO' , codDepartamento:'73' , codCiudad:'73283'},
    { departamento: 'TOLIMA' , ciudad:'GUAMO' , codDepartamento:'73' , codCiudad:'73319'},
    { departamento: 'TOLIMA' , ciudad:'HERVEO' , codDepartamento:'73' , codCiudad:'73347'},
    { departamento: 'TOLIMA' , ciudad:'HONDA' , codDepartamento:'73' , codCiudad:'73349'},
    { departamento: 'TOLIMA' , ciudad:'ICONONZO' , codDepartamento:'73' , codCiudad:'73352'},
    { departamento: 'TOLIMA' , ciudad:'LERIDA' , codDepartamento:'73' , codCiudad:'73408'},
    { departamento: 'TOLIMA' , ciudad:'LIBANO' , codDepartamento:'73' , codCiudad:'73411'},
    { departamento: 'TOLIMA' , ciudad:'MARIQUITA' , codDepartamento:'73' , codCiudad:'73443'},
    { departamento: 'TOLIMA' , ciudad:'MELGAR' , codDepartamento:'73' , codCiudad:'73449'},
    { departamento: 'TOLIMA' , ciudad:'MURILLO' , codDepartamento:'73' , codCiudad:'73461'},
    { departamento: 'TOLIMA' , ciudad:'NATAGAIMA' , codDepartamento:'73' , codCiudad:'73483'},
    { departamento: 'TOLIMA' , ciudad:'ORTEGA' , codDepartamento:'73' , codCiudad:'73504'},
    { departamento: 'TOLIMA' , ciudad:'PALOCABILDO' , codDepartamento:'73' , codCiudad:'73520'},
    { departamento: 'TOLIMA' , ciudad:'PIEDRAS' , codDepartamento:'73' , codCiudad:'73547'},
    { departamento: 'TOLIMA' , ciudad:'PLANADAS' , codDepartamento:'73' , codCiudad:'73555'},
    { departamento: 'TOLIMA' , ciudad:'PRADO' , codDepartamento:'73' , codCiudad:'73563'},
    { departamento: 'TOLIMA' , ciudad:'PURIFICACION' , codDepartamento:'73' , codCiudad:'73585'},
    { departamento: 'TOLIMA' , ciudad:'RIOBLANCO' , codDepartamento:'73' , codCiudad:'73616'},
    { departamento: 'TOLIMA' , ciudad:'RONCESVALLES' , codDepartamento:'73' , codCiudad:'73622'},
    { departamento: 'TOLIMA' , ciudad:'ROVIRA' , codDepartamento:'73' , codCiudad:'73624'},
    { departamento: 'TOLIMA' , ciudad:'SALDAÑA' , codDepartamento:'73' , codCiudad:'73671'},
    { departamento: 'TOLIMA' , ciudad:'SAN ANTONIO' , codDepartamento:'73' , codCiudad:'73675'},
    { departamento: 'TOLIMA' , ciudad:'SAN LUIS' , codDepartamento:'73' , codCiudad:'73678'},
    { departamento: 'TOLIMA' , ciudad:'SANTA ISABEL' , codDepartamento:'73' , codCiudad:'73686'},
    { departamento: 'TOLIMA' , ciudad:'SUAREZ' , codDepartamento:'73' , codCiudad:'73770'},
    { departamento: 'TOLIMA' , ciudad:'VALLE DE SAN JUAN' , codDepartamento:'73' , codCiudad:'73854'},
    { departamento: 'TOLIMA' , ciudad:'VENADILLO' , codDepartamento:'73' , codCiudad:'73861'},
    { departamento: 'TOLIMA' , ciudad:'VILLAHERMOSA' , codDepartamento:'73' , codCiudad:'73870'},
    { departamento: 'TOLIMA' , ciudad:'VILLARRICA' , codDepartamento:'73' , codCiudad:'73873'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'CALI' , codDepartamento:'76' , codCiudad:'76001'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ALCALA' , codDepartamento:'76' , codCiudad:'76020'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ANDALUCIA' , codDepartamento:'76' , codCiudad:'76036'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ANSERMANUEVO' , codDepartamento:'76' , codCiudad:'76041'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ARGELIA' , codDepartamento:'76' , codCiudad:'76054'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'BOLIVAR' , codDepartamento:'76' , codCiudad:'76100'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'BUENAVENTURA' , codDepartamento:'76' , codCiudad:'76109'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'GUADALAJARA DE BUGA' , codDepartamento:'76' , codCiudad:'76111'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'BUGALAGRANDE' , codDepartamento:'76' , codCiudad:'76113'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'CAICEDONIA' , codDepartamento:'76' , codCiudad:'76122'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'CALIMA' , codDepartamento:'76' , codCiudad:'76126'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'CANDELARIA' , codDepartamento:'76' , codCiudad:'76130'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'CARTAGO' , codDepartamento:'76' , codCiudad:'76147'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'DAGUA' , codDepartamento:'76' , codCiudad:'76233'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'EL AGUILA' , codDepartamento:'76' , codCiudad:'76243'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'EL CAIRO' , codDepartamento:'76' , codCiudad:'76246'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'EL CERRITO' , codDepartamento:'76' , codCiudad:'76248'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'EL DOVIO' , codDepartamento:'76' , codCiudad:'76250'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'FLORIDA' , codDepartamento:'76' , codCiudad:'76275'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'GINEBRA' , codDepartamento:'76' , codCiudad:'76306'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'GUACARI' , codDepartamento:'76' , codCiudad:'76318'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'JAMUNDI' , codDepartamento:'76' , codCiudad:'76364'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'LA CUMBRE' , codDepartamento:'76' , codCiudad:'76377'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'LA UNION' , codDepartamento:'76' , codCiudad:'76400'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'LA VICTORIA' , codDepartamento:'76' , codCiudad:'76403'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'OBANDO' , codDepartamento:'76' , codCiudad:'76497'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'PALMIRA' , codDepartamento:'76' , codCiudad:'76520'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'PRADERA' , codDepartamento:'76' , codCiudad:'76563'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'RESTREPO' , codDepartamento:'76' , codCiudad:'76606'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'RIOFRIO' , codDepartamento:'76' , codCiudad:'76616'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ROLDANILLO' , codDepartamento:'76' , codCiudad:'76622'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'SAN PEDRO' , codDepartamento:'76' , codCiudad:'76670'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'SEVILLA' , codDepartamento:'76' , codCiudad:'76736'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'TORO' , codDepartamento:'76' , codCiudad:'76823'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'TRUJILLO' , codDepartamento:'76' , codCiudad:'76828'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'TULUA' , codDepartamento:'76' , codCiudad:'76834'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ULLOA' , codDepartamento:'76' , codCiudad:'76845'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'VERSALLES' , codDepartamento:'76' , codCiudad:'76863'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'VIJES' , codDepartamento:'76' , codCiudad:'76869'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'YOTOCO' , codDepartamento:'76' , codCiudad:'76890'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'YUMBO' , codDepartamento:'76' , codCiudad:'76892'},
    { departamento: 'VALLE DEL CAUCA' , ciudad:'ZARZAL' , codDepartamento:'76' , codCiudad:'76895'},
    { departamento: 'ARAUCA' , ciudad:'ARAUCA' , codDepartamento:'81' , codCiudad:'81001'},
    { departamento: 'ARAUCA' , ciudad:'ARAUQUITA' , codDepartamento:'81' , codCiudad:'81065'},
    { departamento: 'ARAUCA' , ciudad:'CRAVO NORTE' , codDepartamento:'81' , codCiudad:'81220'},
    { departamento: 'ARAUCA' , ciudad:'FORTUL' , codDepartamento:'81' , codCiudad:'81300'},
    { departamento: 'ARAUCA' , ciudad:'PUERTO RONDON' , codDepartamento:'81' , codCiudad:'81591'},
    { departamento: 'ARAUCA' , ciudad:'SARAVENA' , codDepartamento:'81' , codCiudad:'81736'},
    { departamento: 'ARAUCA' , ciudad:'TAME' , codDepartamento:'81' , codCiudad:'81794'},
    { departamento: 'CASANARE' , ciudad:'YOPAL' , codDepartamento:'85' , codCiudad:'85001'},
    { departamento: 'CASANARE' , ciudad:'AGUAZUL' , codDepartamento:'85' , codCiudad:'85010'},
    { departamento: 'CASANARE' , ciudad:'CHAMEZA' , codDepartamento:'85' , codCiudad:'85015'},
    { departamento: 'CASANARE' , ciudad:'HATO COROZAL' , codDepartamento:'85' , codCiudad:'85125'},
    { departamento: 'CASANARE' , ciudad:'LA SALINA' , codDepartamento:'85' , codCiudad:'85136'},
    { departamento: 'CASANARE' , ciudad:'MANI' , codDepartamento:'85' , codCiudad:'85139'},
    { departamento: 'CASANARE' , ciudad:'MONTERREY' , codDepartamento:'85' , codCiudad:'85162'},
    { departamento: 'CASANARE' , ciudad:'NUNCHIA' , codDepartamento:'85' , codCiudad:'85225'},
    { departamento: 'CASANARE' , ciudad:'OROCUE' , codDepartamento:'85' , codCiudad:'85230'},
    { departamento: 'CASANARE' , ciudad:'PAZ DE ARIPORO' , codDepartamento:'85' , codCiudad:'85250'},
    { departamento: 'CASANARE' , ciudad:'PORE' , codDepartamento:'85' , codCiudad:'85263'},
    { departamento: 'CASANARE' , ciudad:'RECETOR' , codDepartamento:'85' , codCiudad:'85279'},
    { departamento: 'CASANARE' , ciudad:'SABANALARGA' , codDepartamento:'85' , codCiudad:'85300'},
    { departamento: 'CASANARE' , ciudad:'SACAMA' , codDepartamento:'85' , codCiudad:'85315'},
    { departamento: 'CASANARE' , ciudad:'SAN LUIS DE PALENQUE' , codDepartamento:'85' , codCiudad:'85325'},
    { departamento: 'CASANARE' , ciudad:'TAMARA' , codDepartamento:'85' , codCiudad:'85400'},
    { departamento: 'CASANARE' , ciudad:'TAURAMENA' , codDepartamento:'85' , codCiudad:'85410'},
    { departamento: 'CASANARE' , ciudad:'TRINIDAD' , codDepartamento:'85' , codCiudad:'85430'},
    { departamento: 'CASANARE' , ciudad:'VILLANUEVA' , codDepartamento:'85' , codCiudad:'85440'},
    { departamento: 'PUTUMAYO' , ciudad:'MOCOA' , codDepartamento:'86' , codCiudad:'86001'},
    { departamento: 'PUTUMAYO' , ciudad:'COLON' , codDepartamento:'86' , codCiudad:'86219'},
    { departamento: 'PUTUMAYO' , ciudad:'ORITO' , codDepartamento:'86' , codCiudad:'86320'},
    { departamento: 'PUTUMAYO' , ciudad:'PUERTO ASIS' , codDepartamento:'86' , codCiudad:'86568'},
    { departamento: 'PUTUMAYO' , ciudad:'PUERTO CAICEDO' , codDepartamento:'86' , codCiudad:'86569'},
    { departamento: 'PUTUMAYO' , ciudad:'PUERTO GUZMAN' , codDepartamento:'86' , codCiudad:'86571'},
    { departamento: 'PUTUMAYO' , ciudad:'LEGUIZAMO' , codDepartamento:'86' , codCiudad:'86573'},
    { departamento: 'PUTUMAYO' , ciudad:'SIBUNDOY' , codDepartamento:'86' , codCiudad:'86749'},
    { departamento: 'PUTUMAYO' , ciudad:'SAN FRANCISCO' , codDepartamento:'86' , codCiudad:'86755'},
    { departamento: 'PUTUMAYO' , ciudad:'SAN MIGUEL' , codDepartamento:'86' , codCiudad:'86757'},
    { departamento: 'PUTUMAYO' , ciudad:'SANTIAGO' , codDepartamento:'86' , codCiudad:'86760'},
    { departamento: 'PUTUMAYO' , ciudad:'VALLE DEL GUAMUEZ' , codDepartamento:'86' , codCiudad:'86865'},
    { departamento: 'PUTUMAYO' , ciudad:'VILLAGARZON' , codDepartamento:'86' , codCiudad:'86885'},
    { departamento: 'ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA' , ciudad:'SAN ANDRES' , codDepartamento:'88' , codCiudad:'88001'},
    { departamento: 'ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA' , ciudad:'PROVIDENCIA' , codDepartamento:'88' , codCiudad:'88564'},
    { departamento: 'AMAZONAS' , ciudad:'LETICIA' , codDepartamento:'91' , codCiudad:'91001'},
    { departamento: 'AMAZONAS' , ciudad:'EL ENCANTO' , codDepartamento:'91' , codCiudad:'91263'},
    { departamento: 'AMAZONAS' , ciudad:'LA CHORRERA' , codDepartamento:'91' , codCiudad:'91405'},
    { departamento: 'AMAZONAS' , ciudad:'LA PEDRERA' , codDepartamento:'91' , codCiudad:'91407'},
    { departamento: 'AMAZONAS' , ciudad:'LA VICTORIA' , codDepartamento:'91' , codCiudad:'91430'},
    { departamento: 'AMAZONAS' , ciudad:'MIRITI - PARANA' , codDepartamento:'91' , codCiudad:'91460'},
    { departamento: 'AMAZONAS' , ciudad:'PUERTO ALEGRIA' , codDepartamento:'91' , codCiudad:'91530'},
    { departamento: 'AMAZONAS' , ciudad:'PUERTO ARICA' , codDepartamento:'91' , codCiudad:'91536'},
    { departamento: 'AMAZONAS' , ciudad:'PUERTO NARIÑO' , codDepartamento:'91' , codCiudad:'91540'},
    { departamento: 'AMAZONAS' , ciudad:'PUERTO SANTANDER' , codDepartamento:'91' , codCiudad:'91669'},
    { departamento: 'AMAZONAS' , ciudad:'TARAPACA' , codDepartamento:'91' , codCiudad:'91798'},
    { departamento: 'GUAINIA' , ciudad:'INIRIDA' , codDepartamento:'94' , codCiudad:'94001'},
    { departamento: 'GUAINIA' , ciudad:'BARRANCO MINAS' , codDepartamento:'94' , codCiudad:'94343'},
    { departamento: 'GUAINIA' , ciudad:'MAPIRIPANA' , codDepartamento:'94' , codCiudad:'94663'},
    { departamento: 'GUAINIA' , ciudad:'SAN FELIPE' , codDepartamento:'94' , codCiudad:'94883'},
    { departamento: 'GUAINIA' , ciudad:'PUERTO COLOMBIA' , codDepartamento:'94' , codCiudad:'94884'},
    { departamento: 'GUAINIA' , ciudad:'LA GUADALUPE' , codDepartamento:'94' , codCiudad:'94885'},
    { departamento: 'GUAINIA' , ciudad:'CACAHUAL' , codDepartamento:'94' , codCiudad:'94886'},
    { departamento: 'GUAINIA' , ciudad:'PANA PANA' , codDepartamento:'94' , codCiudad:'94887'},
    { departamento: 'GUAINIA' , ciudad:'MORICHAL' , codDepartamento:'94' , codCiudad:'94888'},
    { departamento: 'GUAVIARE' , ciudad:'SAN JOSE DEL GUAVIARE' , codDepartamento:'95' , codCiudad:'95001'},
    { departamento: 'GUAVIARE' , ciudad:'CALAMAR' , codDepartamento:'95' , codCiudad:'95015'},
    { departamento: 'GUAVIARE' , ciudad:'EL RETORNO' , codDepartamento:'95' , codCiudad:'95025'},
    { departamento: 'GUAVIARE' , ciudad:'MIRAFLORES' , codDepartamento:'95' , codCiudad:'95200'},
    { departamento: 'VAUPES' , ciudad:'MITU' , codDepartamento:'97' , codCiudad:'97001'},
    { departamento: 'VAUPES' , ciudad:'CARURU' , codDepartamento:'97' , codCiudad:'97161'},
    { departamento: 'VAUPES' , ciudad:'PACOA' , codDepartamento:'97' , codCiudad:'97511'},
    { departamento: 'VAUPES' , ciudad:'TARAIRA' , codDepartamento:'97' , codCiudad:'97666'},
    { departamento: 'VAUPES' , ciudad:'PAPUNAUA' , codDepartamento:'97' , codCiudad:'97777'},
    { departamento: 'VAUPES' , ciudad:'YAVARATE' , codDepartamento:'97' , codCiudad:'97889'},
    { departamento: 'VICHADA' , ciudad:'PUERTO CARREÑO' , codDepartamento:'99' , codCiudad:'99001'},
    { departamento: 'VICHADA' , ciudad:'LA PRIMAVERA' , codDepartamento:'99' , codCiudad:'99524'},
    { departamento: 'VICHADA' , ciudad:'SANTA ROSALIA' , codDepartamento:'99' , codCiudad:'99624'},
    { departamento: 'VICHADA' , ciudad:'CUMARIBO' , codDepartamento:'99' , codCiudad:'99773'},


]

const indicativosFijo = [
    { label: '601 -Cundinamarca y Bogotá D.C.', value: "601" },
    { label: '602 - Cauca, Nariño y Valle.', value: "602" },
    { label: '604 - Antioquia, Córdoba y Chocó.', value: "604" },
    { label: '605 - Atlántico, Bolívar, César, La Guajira, Magdalena y Sucre.', value: "605" },
    { label: '606 - Caldas, Quindío y Risaralda.', value: "606" },
    { label: '607 - Arauca, Norte de Santander y Santander.', value: "607" },
    { label: '608 - Amazonas, Boyacá, Casanare, Caquetá, Guaviare, Guainía, Huila, Meta, Tolima, Putumayo, San Andrés, Vaupés y Vichada.', value: "608" },
]

export {departamentos,ciudades,indicativosFijo}