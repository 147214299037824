import React from 'react'
import { Card, CardMedia } from '@mui/material'
import home from './home.png'

export const ImgHome = (props) => {

    return (<Card {...props} >
        <CardMedia
            component='img'
            alt='Imagen Home'
            image={home}
            //sx={{ maxHeight: '31rem'}}
        />
    </Card>)
}
