import React, {useState} from 'react'
import {Avatar, Grid, Typography} from '@mui/material'
import './Index.css'

export const BirthdayAvatar = ({src, nombre, dia, margin, cumpleEstaSemana}) => {
  const [isPulsing, setIsPulsing] = useState(true)

  const togglePulse = () => {
    setIsPulsing(!isPulsing)
  }

  return (
    <Grid
      className={cumpleEstaSemana ? 'pulse-animation' : ''}
      onClick={togglePulse} // Cambiar a la acción que activa la animación
      sx={{marginTop: margin, justifyContent: 'center', alignItems: 'center'}}
      item
    >
      <Typography fontSize={13}>{nombre}</Typography>
      <Typography fontSize={13}>Día:{dia}</Typography>
      <Avatar sx={{width: 100, height: 100}} alt='Persona' src={src} />
    </Grid>
  )
}

export default BirthdayAvatar
