import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {Grid, TextField, Autocomplete} from '@mui/material'
import {SucessModal, ErrorModal} from '../ComponentsUtilies/AlertsModal/Alerts'
import {TitleFirts} from '../../ComponentsUtilies/TitleFirts'
import {CreateProcess} from '../../services/processService'
import useOptions from './useOptions'
import Label from '../../ComponentsUtilies/Label'
import Save from '../../ComponentsUtilies/Save'

const required = 'Este campo es obligatorio!'

export default function Create({setLoad, close, setIsLoading, user, setOption}) {
  const {
    register,
    handleSubmit,
    control,
    formState: {errors}
  } = useForm()

  const options = useOptions()

  const onSubmit = (values) => {
    setIsLoading(true)
    values.proveedorUUID = user.supplier ?? '520d1ddd-e0a7-4ddc-8c25-c187d3e4f338'
    CreateProcess(values)
      .then(() => {
        SucessModal('Operacion exitosa', 'El proceso se gurdo currectamente.')
        setLoad(true)
        setIsLoading(false)
        setOption('masivo')
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error)
        ErrorModal('No se ejecuto la operacion', error)
      })
  }

  return (
    <Grid>
      <TitleFirts>Crear proceso</TitleFirts>

      <Grid p={3} container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('NombreProceso', {required})}
            label={<Label>Nombre del proceso *</Label>}
            helperText={errors.NombreProceso?.message}
            error={errors.NombreProceso}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            {...register('descripcion', {required})}
            label={<Label>Descripcion *</Label>}
            helperText={errors.descripcion?.message}
            error={errors.descripcion}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name='direccionId'
            rules={{required}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={options.managements}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label>Direccion *</Label>}
                    helperText={errors.direccionId?.message}
                    error={errors.direccionId}
                  />
                )}
                onChange={(_, data) => onChange(data.value)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name='jefaturaId'
            rules={{required}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={options.headships}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label>Jefatura *</Label>}
                    helperText={errors.jefaturaId?.message}
                    error={errors.jefaturaId}
                  />
                )}
                onChange={(_, data) => onChange(data.value)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name='procesoId'
            rules={{required}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={options.processes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label>Proceso padre</Label>}
                    helperText={errors.procesoId?.message}
                    error={errors.procesoId}
                  />
                )}
                onChange={(_, data) => onChange(data.value)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name='Tipo'
            rules={{required}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {value: 'Operativo', label: 'Operativo'},
                  {value: 'Administrativo', label: 'Administrativo'}
                ]}
                isOptionEqualToValue={(option, value) =>
                  option.value === value || value === ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label>Tipo de proceso *</Label>}
                    helperText={errors.Tipo?.message}
                    error={errors.Tipo}
                  />
                )}
                onChange={(_, data) => onChange(data.value)}
              />
            )}
          />
        </Grid>
      </Grid>
      <Save onSave={handleSubmit(onSubmit)} onCancel={close} />
    </Grid>
  )
}
