import React from 'react'
import Typography from '@mui/material/Typography/Typography'

export const TitleFirts = ({children}) => {
  return (
    <Typography color='primary' fontSize={33} variant='h1' marginBottom={1}>
      {children}
    </Typography>
  )
}

export default TitleFirts
