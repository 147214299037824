import React, {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import {Create} from './Create'
import {getDocumentsProcess} from '../../services/documents'
import {GetProcess} from '../../services/processService'
import {getAlertModal} from '../../hooks/getAlertModal'
import {Head} from '../../ComponentsUtilies/Head'
import {Autocomplete, TextField, Box, Typography, Button, Grid} from '@mui/material'
import {useAppContext} from '../../context/DirectoryContex'
import ListAll from './ListAll'
import {eliminarDuplicadosPorClaves, comparadorPorClaves} from '../../utils'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import Info from '../../ComponentsUtilies/Info'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import {CheckList} from './CheckList'
import {Folders} from './Folders'

const styles = {
  width: 196,
  height: 35,
  marginBottom: 2,
  marginTop: 5
}

const Label = ({Icon, children}) => {
  return (
    <Box display='flex' alignItems='center' minHeight='2.3rem'>
      <Grid sx={{padding: '0 6px'}}>{Icon}</Grid>
      <Typography fontSize={18}>{children}</Typography>
    </Box>
  )
}

const gatFolders = (documents) => {
  if (!documents) return []
  return documents.reduce(
    (paths, doc) => {
      const folder = doc.carpeta
      if (paths.includes(folder)) return paths
      paths.push(folder)
      return paths
    },
    ['/']
  )
}

export const Documents = () => {
  const initialFilters = [
    {id: 'nombre', value: ''},
    {id: 'categoriaNme', value: ''},
    {id: 'estado', value: true},
    {id: 'carpeta', value: '/'}
  ]

  const {processId} = useParams()
  const navigate = useNavigate()
  const {documents, setDocuments} = useAppContext()
  const [process, setProcess] = useState({})
  const [load, setLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [filters, setFilters] = useState([...initialFilters])
  const [showChecks, setShowChecks] = useState(false)
  const [folders, setFolders] = useState([])

  const onSearch = (id, value) => {
    setFilters((state) => {
      state[id] = {...state[id], value: value}
      return [...state]
    })
  }

  useEffect(() => {
    setIsLoading(true)

    let categorias = []
    GetProcess(processId)
      .then((data) => {
        setProcess(data)
        getDocumentsProcess(processId)
          .then((dataDocument) => {
            setFolders(gatFolders(dataDocument))
            dataDocument.forEach((element) => {
              element.categoriaNme = element.doc_categoria.nombre
              categorias.push({
                label: element.doc_categoria.nombre,
                value: element.doc_categoria.nombre
              })
            })
            categorias = eliminarDuplicadosPorClaves(categorias, ['value'])
            categorias.sort(comparadorPorClaves(['value']))
            setCategoriesOptions(categorias)
            dataDocument.forEach((element) => {
              element.doc_categoriaName = element?.doc_categoria?.nombre
            })
            setDocuments(dataDocument)
            setLoad(false)
          })
          .catch((error) => console.error(error))
          .finally(() => setIsLoading(false))
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }, [load])

  return (
    <Grid container padding='1rem 2rem 0 2rem'>
      <Grid item>
        <ArrowBackIosNewOutlinedIcon
          color='primary'
          sx={{cursor: 'pointer'}}
          fontSize='large'
          onClick={() => navigate(-1)}
        />
      </Grid>

      <Grid item xs={12}>
        <Head>Documentación</Head>
      </Grid>

      <Grid item xs={12}>
        <Typography color='secondary' fontSize={24}>
          {process.nombre}
        </Typography>
      </Grid>

      <Grid container xs={12} md={12} sx={{marginBottom: 2, marginTop: 1}}>
        <Grid item xs={12} sm={3} md={2}>
          <Info
            onClick={() => {
              onSearch(2, true)
              onSearch(1, '')
            }}
            Icon={<InsertDriveFileOutlinedIcon color='success' />}
            isSelected={filters[2].value === true}
            total={documents.filter((element) => element.estado === true).length}
          >
            Documentos activos
          </Info>
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <Info
            onClick={() => {
              onSearch(2, false)
              onSearch(1, '')
            }}
            Icon={<InsertDriveFileOutlinedIcon color='warning' />}
            isSelected={filters[2].value === false}
            total={documents.filter((element) => element.estado === false).length}
          >
            Documentos inactivos
          </Info>
        </Grid>
        {categoriesOptions.map((category, idx) => {
          return (
            <Grid key={idx} item xs={12} sm={3} md={2}>
              <Info
                onClick={() => onSearch(1, category.value)}
                Icon={<InsertDriveFileOutlinedIcon />}
                isSelected={filters[1].value === category.value}
                total={
                  documents.filter((element) => element.categoriaNme === category.value)
                    .length
                }
              >
                {category.value}
              </Info>
            </Grid>
          )
        })}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label={<Label Icon={<></>}>Nombre del documento</Label>}
              onChange={({target}) => onSearch(0, target.value)}
              value={filters[0].value}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              options={categoriesOptions}
              onInputChange={(_, value) => onSearch(1, value)}
              value={{value: filters[1].value, label: filters[1].value}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<Label Icon={<></>}>Categoria del documento</Label>}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={6}
            md={2}
            container
            display='flex'
            alignItems='flex-end'
            sx={{maxHeight: '80%'}}
          >
            <Grid item xs={6}>
              <CleaningServicesIcon
                onClick={() => setFilters([...initialFilters])}
                fontSize='large'
                sx={{cursor: 'pointer'}}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button onClick={() => setShowChecks((s) => !s)}>Check list</Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Button
          sx={styles}
          variant='contained'
          color='success'
          onClick={getAlertModal(Create, {
            setLoad,
            folders,
            processId: process.id,
            processName: process.nombre,
            setIsLoading
          })}
        >
          Ingresar documento
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Folders
          folders={folders}
          path={filters[3].value}
          setFolder={(folder) => onSearch(3, folder)}
        />
      </Grid>

      <Grid
        container
        xs={12}
        sx={{margin: '1px 0 0 1px', maxHeight: '55vh', overflow: 'auto'}}
      >
        {showChecks ? (
          <CheckList processId={processId} />
        ) : (
          folders.length > 0 && (
            <ListAll
              columnFilters={filters}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setLoad={setLoad}
              folders={folders}
            />
          )
        )}
      </Grid>
    </Grid>
  )
}
