import {createContext, useState, useContext} from 'react'

import {useSuppliers} from './useSuppliers'

export const DirectoryContext = createContext()

export const DirectoryProvider = ({children}) => {
  const initialCounts = {
    inactive: 0,
    active: 0,
    man: 0,
    woman: 0
  }

  const state = {
    suppliers: useSuppliers()
  }

  const [infoUser, setInfoUser] = useState()
  const [employee, setEmployee] = useState(null)
  const [employees, setEmployees] = useState([])
  const [leaders, setLeaders] = useState([])
  const [process, setProcess] = useState(null)
  const [processes, setProcesses] = useState([])
  const [documents, setDocuments] = useState([])
  const [counts, setCounts] = useState(initialCounts)
  const [optionHoliday, setOptionHoliday] = useState('')
  const [processOptions, setProcessOptions] = useState([])
  const [option, setOption] = useState('masivo')
  const [isLoading, setIsLoading] = useState(false)
  const [auditors, setAuditors] = useState([])
  const [directors, setDirectos] = useState([])
  const [user, setUser] = useState(null)

  return (
    <DirectoryContext.Provider
      value={{
        state,
        infoUser,
        setInfoUser,
        employee,
        setEmployee,
        employees,
        setEmployees,
        leaders,
        setLeaders,
        process,
        setProcess,
        processes,
        setProcesses,
        documents,
        setDocuments,
        counts,
        setCounts,
        optionHoliday,
        setOptionHoliday,
        processOptions,
        setProcessOptions,
        option,
        setOption,
        isLoading,
        setIsLoading,
        auditors,
        setAuditors,
        directors,
        setDirectos,
        user,
        setUser
      }}
    >
      {children}
    </DirectoryContext.Provider>
  )
}

export const useAppContext = () => useContext(DirectoryContext)
export const useAppState = (name) => {
  const {state} = useContext(DirectoryContext)
  if (!name) return state
  return state?.[name]
}
