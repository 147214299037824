import React, {useState, useEffect} from 'react'
import {Autocomplete, TextField, Typography, Button, Grid} from '@mui/material'
import {Head} from '../../ComponentsUtilies/Head'
import {getAlertModal} from '../../hooks/getAlertModal'
import {Create} from './Create'
import {ListAll} from './ListAll'
import Info from '../../ComponentsUtilies/Info'
import {Detail} from './Detail'
import {useAppContext} from '../../context/DirectoryContex'
import {IconPeople} from '../../media/IconPeopleP'
import {IconPeopleI} from '../../media/IconPeopleI'
import {IconMan} from '../../media/IconMan'
import {IconWoman} from '../../media/IconWoman'
import {IconUser} from '../../media/IconUser3'
import {IconDoc} from '../../media/IconDoc'
import {IconLeader} from '../../media/IconLeader'
import {IconProcess} from '../../media/IconProcess'
import {GetEmployeesAll} from '../../services/employeesService'
import {ProcessAll} from '../../services/processService'
import {Label} from '../../ComponentsUtilies/Label'
import HolidaysOptions from './HolidaysOptions'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import ListAllHolidays from './ListAllHolidays'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import {ExportToCsv} from 'export-to-csv'
import Birthdays from './Birthdays'

export const Employees = () => {
  const initialFilters = [
    {id: 'activo', value: '1'},
    {id: 'sexo', value: ''},
    {id: 'nombre', value: ''},
    {id: 'cedula', value: ''},
    {id: 'lider', value: ''},
    {id: 'proceso', value: ''}
  ]

  const {
    employee,
    setEmployees,
    leaders,
    setLeaders,
    counts,
    user,
    setCounts,
    optionHoliday,
    setOptionHoliday,
    processOptions,
    setProcessOptions,
    employees,
    option,
    setOption,
    isLoading,
    setIsLoading
  } = useAppContext()
  const [load, setLoad] = useState(true)
  const [filters, setFilters] = useState([...initialFilters])

  useEffect(() => {
    if (!isLoading && load) {
      setLoad(false)
      setIsLoading(true)
      GetEmployeesAll(user.supplier ?? '')
        .then((data) => {
          setEmployees(data.employees)
          setCounts(data.counts)
          setLeaders(
            Object.keys(data.leaders).map((leader) => ({
              label: data.leaders[leader],
              value: leader
            }))
          )
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false))
      ProcessAll()
        .then((data) => {
          setProcessOptions(
            data.map((proces) => ({
              label: proces.nombre,
              value: proces.id
            }))
          )
        })
        .catch((error) => console.error(error))
    }
  }, [load, user])

  const onSearch = (id, value) => {
    setFilters((state) => {
      state[id] = {...state[id], value: value || ''}
      return [...state]
    })
    setOption('masivo')
  }

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    filename: 'Empleados Nutreaxity',
    useKeysAsHeaders: false,
    headers: [
      'Nombre Completo',
      'Cedula',
      'Proceso',
      'Cargo',
      'Fecha de ingreso',
      'Pep',
      'Celular',
      'Nombre de contacto',
      'Celular de contacto',
      'Fecha de nacimiento',
      'Lider',
      'Correo Axity',
      'Correo Nutresa',
      'Estado'
    ]
  }

  const handleExportData = () => {
    const getProcess = (all) => {
      if (!Array.isArray(all)) return ''
      if (all.length === 0) return ''
      if (all.length === 1) return all[0].nombre
      if (all.length === 2) return all.find((e) => e.id !== 47).nombre
      const processName = all.find((e) => e.id === 47)?.nombre
      if (processName) return processName
      return all[0].nombre
    }

    console.log(employees)


    csvExporter.generateCsv(
      employees.reduce((dataCSV, element) => {
        dataCSV.push({
          nombre: element.nombreCompleto,
          cedula: element.cedula,
          proceso: getProcess(element.procesos),
          cargo: element.cargo.nombre,
          fechaIngreso: element.fechaIngreso,
          pep: element.administracionPeps.reduce((peps, {pep}) => {
            if (peps === '') return pep
            return peps + ', ' + pep
          }, ''),
          celular: "'" + element.celular,
          nombreContactoEmergencia: element.nombreContactoEmergencia,
          celularContactoEmergencia: "'" + element.celularContactoEmergencia,
          fechaNacimiento: element.fechaNacimiento,
          liderName: element.liderName ?? '',
          correoAxity: element.correoAxity,
          correoNutresa: element.correoNutresa,
          estado: element.estado === 1 ? 'ACTIVO' : 'INACTIVO'
          
        })
        return dataCSV
      }, [])
    )
  }

  const csvExporter = new ExportToCsv(csvOptions)

  return (
    <>
      <Grid container padding='1rem 2rem 0 2rem' alignItems='flex-end'>
        <Grid item xs={12}>
          <Head>Base de datos empleados</Head>
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <Grid container sx={{height: '100%'}} spacing={1}>
            <Grid item xs={12}>
              <Typography color='secondary' fontSize={24}>
                Tipo de consulta
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{width: '100%', fontWeight: 600}}
                onClick={() => setOption('individual')}
                color={option === 'individual' ? 'primary' : 'background'}
              >
                Individual
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{width: '100%', fontWeight: 600}}
                onClick={() => setOption('masivo')}
                color={option === 'masivo' ? 'primary' : 'background'}
              >
                Masivo
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{width: '100%', fontWeight: 600}}
                onClick={() => {
                  setOption('fechasEspecial')
                  setOptionHoliday('')
                }}
                color={option === 'fechasEspecial' ? 'primary' : 'background'}
              >
                Fechas especiales
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={6} sm={true}>
              <Info
                Icon={<IconPeople />}
                onClick={() => {
                  onSearch(0, '1')
                  filters[1].value = ''
                }}
                isSelected={filters[0].value === '1'}
                total={counts.active}
              >
                Empleados activos
              </Info>
            </Grid>
            <Grid item xs={6} sm={true}>
              <Info
                Icon={<IconMan />}
                onClick={() => onSearch(1, 'MASCULINO')}
                isSelected={filters[1].value === 'MASCULINO'}
                total={counts.man}
              >
                Cantidad hombres
              </Info>
            </Grid>
            <Grid item xs={6} sm={true}>
              <Info
                Icon={<IconWoman />}
                onClick={() => onSearch(1, 'FEMENINO')}
                isSelected={filters[1].value === 'FEMENINO'}
                total={counts.woman}
              >
                Cantidad mujeres
              </Info>
            </Grid>
            <Grid item xs={6} sm={true}>
              <Info
                Icon={<IconPeopleI />}
                onClick={() => onSearch(0, '0')}
                isSelected={filters[0].value === '0'}
                total={counts.inactive}
              >
                Personal retirado
              </Info>
            </Grid>
          </Grid>
        </Grid>
        {option === 'masivo' || option === 'individual' ? (
          <Grid item xs={12} sx={{marginTop: 2}}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={2.5}>
                <TextField
                  label={<Label Icon={<IconUser />}>Nombre completo</Label>}
                  onChange={({target}) => onSearch(2, target.value)}
                  value={filters[2].value}
                />
              </Grid>

              <Grid item xs={6} md={2.5}>
                <TextField
                  label={<Label Icon={<IconDoc />}>Número documento</Label>}
                  onChange={({target}) => onSearch(3, target.value)}
                  value={filters[3].value}
                />
              </Grid>

              <Grid item xs={6} md={2.5}>
                <Autocomplete
                  disablePortal
                  options={leaders}
                  onInputChange={(_, value) => onSearch(4, value)}
                  value={{value: filters[4].value, label: filters[4].value}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<Label Icon={<IconLeader />}>Líder</Label>}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={2.5}>
                <Autocomplete
                  disablePortal
                  options={processOptions}
                  onInputChange={(_, value) => onSearch(5, value)}
                  value={{value: filters[5].value, label: filters[5].value}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<Label Icon={<IconProcess />}>Proceso</Label>}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={6}
                md={2}
                container
                display='flex'
                alignItems='flex-end'
                sx={{maxHeight: '80%'}}
              >
                <Grid item xs={4}>
                  <CleaningServicesIcon
                    onClick={() => setFilters([...initialFilters])}
                    fontSize='large'
                    sx={{cursor: 'pointer'}}
                  />
                </Grid>

                <Grid item xs={4}>
                  <SimCardDownloadOutlinedIcon
                    fontSize='large'
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleExportData()}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Button
                    onClick={getAlertModal(Create, {setLoad, user, setOption})}
                    sx={{maxWidth: '80%'}}
                    color='success'
                  >
                    <Typography>Crear</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        <Grid
          container
          xs={12}
          sx={{margin: '1rem 0 0 1rem', maxHeight: '60vh', overflow: 'auto'}}
        >
          {option === 'individual' && Boolean(employee) ? (
            <Detail setLoad={setLoad} setOption={setOption} />
          ) : option === 'masivo' || option === 'individual' ? (
            <ListAll
              setOption={setOption}
              columnFilters={filters}
              isLoading={isLoading}
            />
          ) : option === 'fechasEspecial' && optionHoliday === '' ? (
            <HolidaysOptions />
          ) : (option === 'fechasEspecial' && optionHoliday === 'madres') ||
            (option === 'fechasEspecial' && optionHoliday === 'madresPadres') ||
            (option === 'fechasEspecial' && optionHoliday === 'padres') ||
            (option === 'fechasEspecial' && optionHoliday === 'hombres') ||
            (option === 'fechasEspecial' && optionHoliday === 'mujeres') ? (
            <ListAllHolidays
              setOption={setOption}
              columnFiltersMothers={filters}
              isLoading={isLoading}
            />
          ) : option === 'fechasEspecial' && optionHoliday === 'cumpleanos' ? (
            <Birthdays />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  )
}