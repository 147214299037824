import React, {useEffect, useState} from 'react'
import {Grid, TextField, Button, Autocomplete, Card, CardMedia} from '@mui/material'
import {TitleFirts} from '../ComponentsUtilies/Titles'
import {useForm, Controller} from 'react-hook-form'
import {getCategorys} from '../../services/categoryService'
import {UpdateProduct, getProduct} from '../../services/productService'
import {AlertSuccess} from '../ComponentsUtilies/AlertsModal/Alerts'
import {AlertError} from '../ComponentsUtilies/AlertsModal/Alerts'

export const Edit = ({setLoad, id, employees, close}) => {
  console.log(setLoad, " props destructurado")
  const [category, setCategory] = useState(0)
  const [image, setImage] = useState('')
  const [data2, setData2] = useState('')
  const [product, setProduct] = useState('')
  const [categorysOptions, setCategorysOptions] = useState('')
  const [categoryDefault, setCategoryDefault] = useState('')
  const [personaDefault, setPersonaDefault] = useState('')
  const [personasOptions, setPersonasOptions] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    control,
    formState: {errors},
    setValue
  } = useForm()

  useEffect(() => {
    categorysGet()
    employeesGet()
    productGet()
  }, [])

  const productGet = async () => {
    try {
      const res = await getProduct(id)
      setCategoryDefault({value: res.categoria.IdCategoria, label: res.categoria.nombre})
      setPersonaDefault({
        value: res.empleado.IdEmpleado,
        label:
          res.empleado.primerNombre +
          ' ' +
          res.empleado.primerApellido +
          ' ' +
          res.empleado.segundoApellido +
          ' - ' +
          res.empleado.cedula
      })
      setProduct(res)
      setValue('nombre', res.nombre)
      setValue('cantidad', res.cantidad)
      setValue('marca', res.marca)
      setValue('placa', res.placa)
      setValue('serial', res.serial)
      setValue('descripcion', res.descripcion)
      setValue('tipo', res.tipo)
      setValue('capacidad', res.capacidad)
      setValue('empleadoIdEmpleado', {
        value: res.empleado.IdEmpleado,
        label:
          res.empleado.primerNombre +
          ' ' +
          res.empleado.primerApellido +
          ' ' +
          res.empleado.segundoApellido +
          ' - ' +
          res.empleado.cedula
      })
      setValue('categoriaIdCategoria', {
        value: res.categoria.IdCategoria,
        label: res.categoria.nombre
      })
    } catch (error) {
      console.error(error)
      AlertError('Error de carga productos', error)
    }
  }

  const categorysGet = async () => {
    try {
      const res = await getCategorys()
      setCategory(res)
      const optionsCategorias = []
      res.forEach((categoria) => {
        optionsCategorias.push({label: categoria.nombre, value: categoria.IdCategoria})
      })
      setCategorysOptions(optionsCategorias)
    } catch (error) {
      console.error(error)
      AlertError('Error de carga categorias', error)
    }
  }

  const employeesGet = async () => {
    try {
      const EmpleadosOptions = []

      employees.forEach((persona) => {
        EmpleadosOptions.push({
          value: persona.IdEmpleado,
          label:
            persona.primerNombre +
            ' ' +
            persona.primerApellido +
            ' ' +
            persona.segundoApellido +
            ' - ' +
            persona.cedula
        })
      })
      setPersonasOptions(EmpleadosOptions)
    } catch (error) {
      console.error(error)
      AlertError('Error de carga empleados', error)
    }
  }

  const onSubmit = (valores) => {
    console.log("valores", valores)
    const DataForm = valores
    const {categoriaIdCategoria, empleadoIdEmpleado} = valores
    const idEmpleado = empleadoIdEmpleado
    const idCategoria = categoriaIdCategoria
    console.log(personasOptions, " nueva personasOptions")
    const employeeFilter = personasOptions.filter((e) => e.IdEmpleado == idEmpleado.value)
    console.log(idEmpleado, "idEmpleado")
    console.log("empleado IdEmpleado", employeeFilter)
    const categoryFilter = category.filter((e) => e.IdCategoria == idCategoria.value)
    DataForm.categoriaIdCategoria = categoryFilter[0].IdCategoria
    DataForm.empleadoIdEmpleado = idEmpleado.value
    DataForm.foto = data2

    const config = {
      headers: {'Content-Type': 'multipart/form-data'}
    }
    UpdateProduct(id, DataForm, config)
      .then((res) => {
        setLoad(true)
        if (res.IdProducto != null) {
        
          close()
          AlertSuccess()
         
        } else {
          AlertError()
        }
      })
      .catch((error) => console.error(error))
    reset()
  }

  const encodeFileAsBase64URL = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('loadend', () => {
        resolve(reader.result)
      })
      reader.readAsDataURL(file)
    })
  }

  const uploadImage = async (e) => {
    let archivos = Array.from(e.files)
    const image = await encodeFileAsBase64URL(e.files[0])
    setImage(image)
    setData2(archivos)
  }

  return (
    <>
      {product != null && category != null ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <TitleFirts>Editar articulo</TitleFirts>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4}>
                <Controller
                  name='categoriaIdCategoria'
                  rules={{required: true}}
                  control={control}
                  render={({field: {onChange}}) => (
                    <Autocomplete
                      disablePortal
                      id='combo-box-demo'
                      onChange={(e, v) => {
                        setCategoryDefault({
                          label: e.target.textContent,
                          value: e.target.value
                        })
                        setValue('categoriaIdCategoria', v)
                      }}
                      options={categorysOptions}
                      value={categoryDefault}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Categoria'
                          helperText={
                            errors.categoriaIdCategoria?.type === 'required' &&
                            'Este campo es obligatorio!'
                          }
                          error={errors.categoriaIdCategoria}
                          onChange={(_, data) => {
                            setCategoryDefault(data)
                            onChange(data)
                            return data
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Nombre producto'
                  InputLabelProps={{shrink: true}}
                  {...register('nombre', {required: true})}
                  onChange={(e) => {}}
                  helperText={
                    errors.nombre?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.nombre}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  InputLabelProps={{shrink: true}}
                  id='outlined-basic'
                  label='Cantidad'
                  {...register('cantidad', {required: true, pattern: /^([0-9])*$/})}
                  helperText={
                    (errors.cantidad?.type === 'required' &&
                      'Este campo es obligatorio!') ||
                    (errors.cantidad?.type === 'pattern' &&
                      'En este campo son solo permitidas numeros!')
                  }
                  error={
                    (errors.cantidad?.type === 'required' ||
                      errors.cantidad?.type === 'pattern') &&
                    true
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  InputLabelProps={{shrink: true}}
                  label='Marca'
                  {...register('marca')}
                  helperText={
                    errors.marca?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.marca}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('marca')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  InputLabelProps={{shrink: true}}
                  id='outlined-basic'
                  label='Placa'
                  {...register('placa')}
                  helperText={
                    errors.placa?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.placa}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('placa')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  InputLabelProps={{shrink: true}}
                  id='outlined-basic'
                  label='Serial'
                  {...register('serial')}
                  helperText={
                    errors.serial?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.serial}
                  onChange={(e) => {
                    // console.log(e.target.value, "serial")
                    if (e.target.value.length != 0) {
                      //   console.log("entre if serial clear errores")
                      clearErrors('serial')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  InputLabelProps={{shrink: true}}
                  label='Descripcion'
                  {...register('descripcion')}
                  helperText={
                    errors.descripcion?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.descripcion}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      //     console.log("entre if serial clear errores")
                      clearErrors('descripcion')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  InputLabelProps={{shrink: true}}
                  label='Tipo'
                  {...register('tipo')}
                  helperText={
                    errors.tipo?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.tipo}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      // console.log("entre if serial clear errores")
                      clearErrors('tipo')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  InputLabelProps={{shrink: true}}
                  label='Capacidad'
                  {...register('capacidad')}
                  helperText={
                    errors.capacidad?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.capacidad}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      //    console.log("entre if serial clear errores")
                      clearErrors('capacidad')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Controller
                  name='empleadoIdEmpleado'
                  rules={{required: true}}
                  control={control}
                  render={({field: {onChange}}) => (
                    <Autocomplete
                      disablePortal
                      id='combo-box-demo'
                      options={personasOptions}
                      value={personaDefault}
                      onChange={(e, v) => {
                        setPersonaDefault({
                          value: e.target.value,
                          label: e.target.textContent
                        })
                        setValue('empleadoIdEmpleado', v)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id='empleadoIdEmpleado'
                          name='empleadoIdEmpleado'
                          label='Empleado'
                          onChange={(_, data) => {
                            setPersonaDefault(data)

                            onChange(data)
                            return data
                          }}
                          helperText={
                            errors.empleadoIdEmpleado?.type === 'required' &&
                            'Este campo es obligatorio!'
                          }
                          error={errors.empleadoIdEmpleado}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Card sx={{width: 760, marginLeft: 2, objectFit: 'cover', marginTop: 3}}>
                  <CardMedia
                    component='img'
                    height='340'
                    image={image != '' ? image : ''}
                  />
                </Card>

                <Button
                  variant='contained'
                  type='submit'
                  component='label'
                  color='secondary'
                  sx={{
                    marginTop: 1,
                    marginLeft: 2,
                    height: 50,
                    float: 'left'
                  }}
                >
                  Varios archivos
                  <input
                    type='file'
                    onChange={(e) => uploadImage(e.target)}
                    hidden
                    multiple
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='success'
            type='submit'
            sx={{marginTop: 5, width: 100, marginLeft: 3}}
          >
            Enviar
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{marginTop: 5, width: 112, marginLeft: 3}}
            onClick={() => close()}
          >
            Cancelar
          </Button>
        </form>
      ) : null}
    </>
  )
}
