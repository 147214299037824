export const mekeTree = (processes) => {
  const dataTree = processes.reduce(
    ({roots, branchs}, process, idx) => {
      if (!Boolean(process.estado)) return {roots, branchs}
      const dad = process.procesoId
      if (!branchs[dad] && !roots[dad]) {
        branchs[dad] = []
        if (!dad || process.id === dad) roots[dad] = idx
      }
      branchs[dad].push(idx)
      return {roots, branchs}
    },
    {roots: {}, branchs: {}}
  )
  return [dataTree.roots, dataTree.branchs]
}
