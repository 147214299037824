import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid, TextField, Button, Typography, Autocomplete } from '@mui/material'
import { EditHeadships, GetHeadships } from '../../services/headshipsService'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { GetAllAuditors } from '../../services/auditorsService'

export const Edit = ({ setLoad, id, close }) => {

  const { register, handleSubmit, reset, setValue, control, formState: { errors } } = useForm()

  const [headship, setHeadship] = useState(null)
  const [auditors, setAuditors] = useState([])

  useEffect(() => {
    GetHeadships(id)
      .then(data => {
        setHeadship({ ...data, nombre: data.nombreJefatura })
        setValue('nombre', data.nombreJefatura)
        setValue('descripcion', data.descripcion)
        setValue('interventor', data.interventore.nombre)
        setValue('auditor', {
          lavel: data.interventore.nombre,
          value: data.interventore.idInterventor
        })
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Operacion con errores', error)
      })
  }, [id, setValue, setHeadship])

  useEffect(() => {
    GetAllAuditors()
      .then(data => setAuditors(data))
      .catch(error => {
        console.error(error)
        ErrorModal('Error al cargar interventores.', error)
      })
  }, [setAuditors])

  const onSubmit = (values) => {
    const data = {
      nombreJefatura: values.nombre,
      descripcion: values.descripcion,
      interventorId: values.auditor.value
    }
    EditHeadships(headship.id, { ...headship, ...data })
      .then(() => {
        setLoad(true)
        reset()
        close()
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Operacion con errores', error)
      })
  }

  if (!headship) return <></>

  return <Grid >
    <Typography color='primary' variant='h5' marginBottom={1}>
      Editar Jefatura
    </Typography>
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '80%' }}
          label='Nombre *'
          {...register('nombre', { required: true })}
          value={headship.nombre}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setHeadship((s) => ({ ...s, nombre: e.target.value }))}
          helperText={(errors.nombre?.type === 'required' && 'Este campo es obligatorio!')}
          error={errors.nombre?.type === 'required'}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '80%' }}
          label='Descripcion*'
          {...register('descripcion', { required: true })}
          value={headship.descripcion}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setHeadship((s) => ({ ...s, descripcion: e.target.value }))}
          helperText={(errors.nombre?.type === 'required' && 'Este campo es obligatorio!')}
          error={errors.nombre?.type === 'required'}
        />
      </Grid>

      <Grid item xs={12} >
        <Controller
          name='auditor'
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) =>
            <Autocomplete
              disablePortal
              options={auditors.map(a => ({ label: a.nombre, value: a.idInterventor }))}
              defaultValue={headship.interventore.nombre}
              renderInput={(params) =>
                <TextField
                  sx={{ width: '80%' }}
                  {...register('interventor', { required: true })}
                  {...params}
                  label='Interventor *'
                  helperText={(errors.interventor?.type === 'required' && 'Este campo es obligatorio!')}
                  error={(errors.interventor?.type === 'required') && true}
                />
              }
              onChange={(_, data) => {
                onChange(data)
                return data
              }}
            />
          }
        />
      </Grid>

    </Grid>
    <Grid container justifyContent='space-evenly' padding='1rem 9%' >
      <Button color='success' onClick={handleSubmit(onSubmit)} >Enviar</Button>
      <Button color='error' onClick={close}>Cancelar</Button>
    </Grid>
  </Grid>
}

export default Edit