import axios from 'axios'

const URL = 'https://0yets2xo9j.execute-api.us-east-1.amazonaws.com'
//const URL = 'http://localhost:4000'

export const ProcessAll = async () => {
    const requestProcess = await axios.get(URL + '/process/all')
    const dataProcess = await requestProcess.data
    return dataProcess
}

export const GetProcess = async (id) => {
    const requestProcess = await axios.get(URL + '/process/' + id)
    const dataProcess = await requestProcess.data
    return dataProcess
}

export const UpdateStateProcess = async (id) => {
    const requestProcess = await axios.put(URL + '/process/updateState/' + id)
    const dataProcess = await requestProcess.data
    return dataProcess
}

export const CreateProcess = async (object) => {
    const request = await axios.post(
        URL + '/process/create',
        object,
        { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    const data = await request.data
    return data
}

export const EditProcess = async (id, object) => {
    console.log(object, "object")
    const request = await axios.put(
        URL + '/process/update/' + id,
        object,
        { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    const data = await request.data
    return data
}

export const procesEployees = async (id) => {
    const requestProcess = await axios.get(URL + '/process/employees/' + id)
    return requestProcess.data
}

export const getSubprocessOption = async () => {
    const request = await axios.get(URL + "/subprocess/all")
    if (request.status !== 200) throw Error(request.data)
    return request.data.map(sp => ({ value: sp.IdSubProceso, label: sp.Nombre }))
}


export const CreateSubProcess = async (object) => {
    const request = await axios.post(URL + '/subprocess/create', object)
    return request.data
}

export const EditSubProcess = async (object) => {
    const request = await axios.put(URL + '/subprocess/edit/', object)
    return request.data
}
