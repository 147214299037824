import {useEffect, useState} from 'react'
import axios from 'axios'
import {config} from '../config'

const URL = `${config.URL_API}/suppliers/all`
let isOpen = true

export const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState([])
  const [load, setLoad] = useState(true)

  const getSuppliers = async () => {
    try {
      isOpen = false
      setLoad(null)
      const {data} = await axios.get(URL)
      setSuppliers(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
      isOpen = true
    }
  }

  useEffect(() => {
    if (isOpen && load) getSuppliers()
  }, [load])

  return [suppliers, () => setLoad(true), load === null]
}
