const categoriasPrincipales = [
    {label:'SOPORTE WINDOWS' , value:'SOPORTE WINDOWS' },
    {label:'SOPORTE MICROSOFT OFFICE' , value:'SOPORTE MICROSOFT OFFICE' },
    {label:'SOPORTE SAP' , value:'SOPORTE SAP' },
    {label:'SOPORTE APLICACIONES' , value:'SOPORTE APLICACIONES' },
    {label:'SOPORTE IMPRESORAS' , value:'SOPORTE IMPRESORAS' },
    {label:'SOPORTE SALAS DE REUNIÓN' , value:'SOPORTE SALAS DE REUNIÓN' },    
]

const categoriasSecundarias = [
    {categoria:'SOPORTE WINDOWS' , subCategoria:'Formateo sistema operativo ' , codigo:'NUT201003006023' } ,
    {categoria:'SOPORTE WINDOWS' , subCategoria:'Instalación Imagen Windows 10 - 11' , codigo:'NUT201003006023' } ,
    {categoria:'SOPORTE WINDOWS' , subCategoria:'Instalación de Actualizaciones Windows Update' , codigo:'NUT201003006023' } ,
    {categoria:'SOPORTE MICROSOFT OFFICE' , subCategoria:'Instalación Office' , codigo:'NUT201003006017' } ,
    {categoria:'SOPORTE MICROSOFT OFFICE' , subCategoria:'Instalación Visio' , codigo:'NUT201003006017' } ,
    {categoria:'SOPORTE MICROSOFT OFFICE' , subCategoria:'Instalación Access' , codigo:'NUT201003006017' } ,
    {categoria:'SOPORTE MICROSOFT OFFICE' , subCategoria:'Instalación Power Bi' , codigo:'NUT201003006017' } ,
    {categoria:'SOPORTE SAP' , subCategoria:'Instalación y configuración SAP' , codigo:'NUT201001006007' } ,
    {categoria:'SOPORTE SAP' , subCategoria:'Instalación Analisys For Office' , codigo:'NUT201001006007' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación y configuración VPN Forticlient' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación WA' , codigo:'NUT201003006035' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Spaceman' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación y soporte NETSKOPE' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación y Configuración Antivirus Sycmantec' , codigo:'NUT201003006004' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Avaya Softphone' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación Adobe Reader' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación y configuración Carbonite Cloud Backup' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE APLICACIONES' , subCategoria:'Instalación Desktop Central' , codigo:'NUT201003006022' } ,
    {categoria:'SOPORTE IMPRESORAS' , subCategoria:'Instalacion y configuracion Impresoras' , codigo:'' } ,
    {categoria:'SOPORTE IMPRESORAS' , subCategoria:'Cambio tonner' , codigo:'NUT201003005002' } ,
    {categoria:'SOPORTE IMPRESORAS' , subCategoria:'Cambio unidad de Imagen' , codigo:'NUT201003005002' } ,
    {categoria:'SOPORTE IMPRESORAS' , subCategoria:'Soporte por atasco de papel' , codigo:'NUT201003005002' } ,
    {categoria:'SOPORTE SALAS DE REUNIÓN' , subCategoria:'Instalación VideoBeam' , codigo:'NUT201003005002' } ,
    {categoria:'SOPORTE SALAS DE REUNIÓN' , subCategoria:'Soporte reuniones Meet - Teams' , codigo:'NUT201003005002' } ,
    {categoria:'SOPORTE SALAS DE REUNIÓN' , subCategoria:'Soporte sonido' , codigo:'NUT201003005002' } ,

]

export{
    categoriasPrincipales,
    categoriasSecundarias
}