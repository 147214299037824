import React, { useState, useRef, useEffect } from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import CleanIcon from '@mui/icons-material/CleaningServicesOutlined'

export const Signature = ({ onChange, name }) => {
  const { palette } = useTheme()
  const canvasRef = useRef(null)
  const containersRef = useRef(null)
  const [signature, setSignature] = useState(null)

  const handleOnChange = ({ target }) => {
    const value = target.isEmpty() ? null : target.toDataURL()
    onChange({ target: { name, value } })
  }

  useEffect(() => {
    if (containersRef.current && canvasRef.current) {
      const { width, height } = containersRef.current.getBoundingClientRect()
      const canvas = canvasRef.current
      canvas.width = width
      canvas.height = height * 0.66
      const signaturePad = new window.SignaturePad(canvas, {
        minWidth: 0.3,
        maxWidth: 2,
        penColor: palette.primary.main
      })
      signaturePad.addEventListener("endStroke", handleOnChange)
      setSignature(signaturePad)
    }
  }, [])

  const handleClear = () => signature.clear()

  return (
    <Box ref={containersRef} width='100%' paddingTop='3px' >
      <Box
        component='canvas'
        ref={canvasRef}
      />
      <IconButton
        color='error'
        onClick={handleClear}
        sx={{ position: 'absolute', top: 0, right: 0 }}
        aria-label="delete" size="small"
      >
        <CleanIcon fontSize="inherit" />
      </IconButton>
    </Box>
  )
}

export default Signature