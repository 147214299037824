import React , { useState, useEffect} from 'react'
import Label from '../../ComponentsUtilies/Label'
import { GetAllAuditors } from '../../services/auditorsService';
import {
    TextField,
    Typography,
    Button,
    Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { Head } from "../../ComponentsUtilies/Head";
import { ListAuditors } from './ListAll';
import Info from '../../ComponentsUtilies/Info';
import { IconPeople } from '../../media/IconPeopleP'
import { AlertModalFormAuditor } from './Create';
import { IconUser } from '../../media/IconUser3'
import { IconDoc } from '../../media/IconDoc'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { getAlertModal } from '../../hooks/getAlertModal';
import { ExportToCsv } from 'export-to-csv';
import { useAppContext } from "../../context/DirectoryContex";
import { useTheme } from "@emotion/react";


const Auditors = () => {

    const initialFilters = [
        { id: "nombre", value: "" },
        { id: "cedula", value: "" },
        { id: "estado", value: 1},
    ];


    const { auditors, setAuditors } = useAppContext();
    const [load,setLoad]=useState(false)
    const [isLoading,setIsLoading]=useState(false) 
    const [filters, setFilters] = useState([...initialFilters]);

    useEffect(()=>{
        setIsLoading(true)
        GetAllAuditors().then((data)=>{setAuditors(data);setIsLoading(false)}).catch(error=>setIsLoading(false))
        setLoad(false)
    },[load])

    const navigate = useNavigate();

    const onSearch = (id, value) => {
        setFilters((state) => {
            state[id] = { ...state[id], value: value };
            return [...state];
        });
    };

    const csvOptions = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ["Nombre Completo","Cedula","Estado"]
    };
    
    const handleExportData = () => {
    
        let dataCSV = []
    
        auditors.forEach(element => {
          dataCSV.push({
            nombreCompleto:element.nombre,
            cedula:element.cedula,
            estado:element.estado == "1" ? "ACTIVO" : "INACTIVO" 

          }
          )
        });
    
        csvExporter.generateCsv(dataCSV);
    };
    
    const csvExporter = new ExportToCsv(csvOptions);

  return (
    <Grid container padding="1rem 2rem 0 2rem">
      <Grid item>
        <ArrowBackIosNewOutlinedIcon
          color="primary"
          sx={{ cursor: "pointer" }}
          fontSize="large"
          onClick={() => navigate(-1)}
        />
      </Grid>

      <Grid item xs={12}>
        <Head>Interventores</Head>
      </Grid>

      <Grid container xs={12} md={12} sx={{ marginBottom: 2, marginTop: 1 }}>
        <Grid item xs={12} sm={3} md={2}>
          <Info
            onClick={() => {
              onSearch(2, 1);
              onSearch(1, "");
              onSearch(0, "");
            }}
            Icon={<IconPeople />}
            isSelected={filters[2].value === 1}
            total={
                auditors.filter((element) => element.estado === 1).length
            }
          >
            Interventores activos
          </Info>
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <Info
            onClick={() => {
              onSearch(2, 0);
              onSearch(1, "");
              onSearch(0, "");
            }}
            Icon={<IconPeople  />}
            isSelected={filters[2].value === 0}
            total={
                auditors.filter((element) => element.estado === 0).length
            }
          >
            Interventores inactivos
          </Info>
        </Grid>
        
      </Grid>

      <Grid item xs={12} sx={{marginTop:2}}>
            <Grid container spacing={1}>

                <Grid item xs={6} md={2.5} >
                    <TextField
                        label={<Label Icon={<IconUser />}>Nombre completo</Label>}
                        onChange={({ target }) => onSearch(0, target.value)}
                        value={filters[0].value}
                    />
                </Grid>

                <Grid item xs={6} md={2.5} >
                    <TextField
                        label={<Label Icon={<IconDoc />}>Número documento</Label>}
                        onChange={({ target }) => onSearch(1, target.value)}
                        value={filters[1].value}
                    />
                </Grid>


                <Grid item xs={6} md={2}
                    container
                    display='flex'
                    alignItems='flex-end'
                    sx={{ maxHeight: '80%' }}
                >
                    
                    

                    <Grid item xs={4}>
                        <CleaningServicesIcon
                            onClick={() => setFilters([...initialFilters])}
                            fontSize="large"
                            sx={{cursor:"pointer"}}
                        />    
                    </Grid>
                    
                    <Grid item xs={4}>
                        <SimCardDownloadOutlinedIcon
                            fontSize="large"
                            sx={{cursor:"pointer"}}
                            onClick={()=>handleExportData()}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Button
                            onClick={getAlertModal(AlertModalFormAuditor, { setLoad,setIsLoading })}
                            sx={{ maxWidth: '80%' }}
                            color='success'
                        >
                            <Typography >Crear</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

            <Grid
                container
                xs={12}
                sx={{ margin: "1px 0 0 1px", maxHeight: "55vh", overflow: "auto" }}
            >
                <ListAuditors
                columnFilters={filters}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setLoad={setLoad}
                ></ListAuditors>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Auditors