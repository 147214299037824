import Swal from "sweetalert2"

const AlertError = ({title,text,html}) =>{
 return(  
    Swal.fire({
        icon: 'error',
        title: title,
        html:html,
        text: text,
    })
 )
}

export{AlertError};