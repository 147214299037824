import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LoginPanicButtonForm } from '../../components/LoginPanicButton/LoginPanicButtonForm';
import { Container } from '@mui/material';
import image from '../../assets/avenue-gf74fbc8d5_1920.jpg'


const LoginPanicButtonPage = () => {
  return (
    <>
        <Container maxWidth={false}
             sx={{ position:"absolute",left:0, height:"100vh", backgroundImage: `url(${image})`,opacity: 0.6  }}
        >
            <LoginPanicButtonForm />
            
        </Container>
    </>
  );
}

export {LoginPanicButtonPage};
