import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TitleFirts } from '../ComponentsUtilies/Titles'
import { getAppsProcess } from '../../services/appsService'
import { GetProcess } from '../../services/processService'
import { Item } from './Item'
import { Create } from './Create'
import { useNavigate } from "react-router-dom"
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import { makeStyles } from '@mui/styles'
import { Container, Typography, Button, Grid } from '@mui/material'
import { getAlertModal } from '../../hooks/getAlertModal'

const Styles = makeStyles({
  buttonStyle: {
    width: 196,
    height: 35,
    marginBottom: 6,
    marginTop: 5
  }
})

export const Apps = () => {

  const { processId } = useParams()
  const [apps, setApps] = useState([])
  const [process, setProcess] = useState({})
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getAppsProcess(processId)
      .then(data => {
        setApps(data)
        setLoad(false)
      })
      .catch(error => console.error(error))
  }, [load, processId, setLoad, setApps])

  useEffect(() => {
    GetProcess(processId)
      .then(data => setProcess(data))
      .catch(error => console.error(error))

  }, [processId, setProcess])

  return (
    <>
      <TitleFirts>
        <Grid item xs={12}
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          sx={{ marginTop: "70px" }}
        >
          <ArrowBackIosNewOutlinedIcon
            sx={{
              color: "#3C1053",
              cursor: "pointer",
            }}
            fontSize="large"
            onClick={() => navigate(-1)}
          />
        </Grid>
        Aplicaciones
      </TitleFirts>
      <Grid container ms={2} alignItems='center' justifyContent='estart' spacing={3}  >
        <Grid container>
          <Button
            variant='contained'
            color='success'
            className={Styles().buttonStyle}
            onClick={getAlertModal(Create,{ setLoad, processId })}
            sx={{ marginBottom: 2 }}
          >Crear</Button>
        </Grid>
        <Grid container alignItems='center' justifyContent='center' spacing={3} >
          <Typography ms={8} variant='h6' component='h2'>
            {process.NombreProceso}
          </Typography>
        </Grid>
      </Grid>
      <Container sx={{ width: '100%', overflow: 'hidden', minHeight: "calc(100vh - 287px)" }} >
        <Grid container spacing={1}  >
          <section className="carousel"  >
            <div className="carousel__container" style={{ overflowX: "hidden", overflowY: "hidden" }}>
              <div className="carousel__container" >
                {apps.map(app => <Item key={app.id} data={app} setLoad={setLoad} />)}
              </div>
            </div>
          </section>
        </Grid>
      </Container>
    </>
  )
}

export default Apps