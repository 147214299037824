import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid} from "@mui/material"
import  { useNavigate } from 'react-router-dom';
import "./PanicButtom.css"
import { DirectoryContext } from '../../context/DirectoryContex';
import { OptionsPanicButtomContex } from '../../context/OptionsPanicButtomContex';


const PanicButtomRequest= (props) => {
  const valueContext = React.useContext(DirectoryContext);
  const OptionsPanicValueContext = React.useContext(OptionsPanicButtomContex);
 
  let navigate = useNavigate();
  
  
  const showOptions = ()=>{
    console.log("hola")
    OptionsPanicValueContext.SetVisibleFirstLevel("none");
    OptionsPanicValueContext.SetVisiblePremium("");
    OptionsPanicValueContext.SetVisibleSecondLevel("");
    OptionsPanicValueContext.SetVisibleNormal("");
    
  }

  let buttonClass = "panicButtomRequest";
  if(props.rol === "Technicians" ){
    buttonClass = "panicButtomRequestTechnicians";
  }

  return (
    <Grid container spacing={0}
    direction="column"
    alignItems="center"
      sx={{display:OptionsPanicValueContext.visibleFirstLevel}}
     >
      <Grid item xs={12} sm={12} md={12} lg={12}
        >
        
        <Card  elevation={6} 
        
          sx={
            { 
                width: {xs:300 ,sm:550, md:500, lg:500},
                minHeight:300,
                marginTop:16,
                background: "rgba(255, 255, 255, 0.164)",
                borderRadius: 20,
                boxShadow: "0px 0px 30px rgb(0, 0, 0)",
               
            }
          }
        >
          <CardContent  
            sx={{display:"flex",justifyContent:"center"}}>
            <button onClick={showOptions} className={buttonClass}>{props.welcomeMessage}</button>   
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export {PanicButtomRequest}