import React from 'react'
import { CardMedia } from '@mui/material'
import iconUser from './iconUser.svg'

export const IconUser = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconUser}
    />)
}
export default IconUser 