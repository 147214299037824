import React, {useMemo} from 'react'
import MaterialReactTable from 'material-react-table'
import {Typography, Grid, Box} from '@mui/material'
import {useAppContext} from '../../context/DirectoryContex'
import {useTheme} from '@emotion/react'

const cellProps = {
  color: 'black',
  fontSize: 14,
  noWrap: true,
  margin: '0.1rem'
}

export const ListAll = ({setOption, columnFilters}) => {
  const {palette} = useTheme()
  //const { employees, isLoading } = useInfo()
  const {employees, setEmployee, isLoading} = useAppContext()

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        id: 'id',
        maxSize: 2,
        minSize: 1,
        header: <Typography></Typography>,
        Cell: ({row}) => (
          <Typography {...cellProps} sx={{paddingLeft: 1}}>
            {row.index + 1}.
          </Typography>
        )
      },
      {
        accessorKey: 'estado',
        id: 'activo'
      },
      {
        accessorKey: 'sexo',
        id: 'sexo'
      },
      {
        accessorKey: 'nombreCompleto',
        id: 'nombre',
        header: <Typography>Nombre completo</Typography>,
        Cell: ({cell}) => <Typography {...cellProps}>{cell.getValue()}</Typography>
      },
      {
        accessorKey: 'cedula',
        id: 'cedula',
        maxSize: 9,
        minSize: 3,
        header: <Typography>Cédula</Typography>,
        Cell: ({cell}) => <Typography {...cellProps}>{cell.getValue()}</Typography>
      },
      {
        accessorKey: 'liderName',
        id: 'lider',
        maxSize: 9,
        minSize: 3,
        header: <Typography>Líder</Typography>,
        Cell: ({cell}) => <Typography {...cellProps}>{cell.getValue()}</Typography>
      },
      {
        accessorKey: 'procesos',
        id: 'proceso',
        header: <Typography>Procesos</Typography>,
        Cell: ({cell}) => (
          <Box>
            {cell.getValue().map((process) => (
              <Typography key={process.id} {...cellProps}>
                {process.nombre}
              </Typography>
            ))}
          </Box>
        ),
        filterFn: ({original: {procesos}}, _, filter) =>
          filter === '' || procesos?.map((p) => p.NombreProceso).includes(filter)
      }
    ],
    []
  )

  return (
    <Grid item xs={12}>
      <MaterialReactTable
        columns={columns}
        data={employees}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        initialState={{
          isLoading: true,
          columnVisibility: {sexo: false, activo: false}
        }}
        state={{isLoading, columnFilters}}
        muiTableBodyProps={{
          sx: {
            borderCollapse: 'separate',
            border: `0.6rem solid ${palette.background.main}`
          }
        }}
        muiTableBodyRowProps={({row}) => ({
          onClick: () => {
            setOption('individual')
            setEmployee(row.original)
          },
          sx: {
            cursor: 'pointer',
            borderCollapse: 'separate',
            backgroundColor: row.index % 2 === 0 ? 'white' : palette.background.main
          }
        })}
        muiTableBodyCellProps={{sx: {padding: '0', border: 'none'}}}
      />
    </Grid>
  )
}

export default ListAll
