import { SvgIcon } from '@mui/material'
export const IconDocument = (props) => {
    return (<SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;document text&#34;">
                <g id="Group">
                    <path id="Vector" d="M18 5.28486V13.8546C18 16.4255 16.5833 18.1394 13.2778 18.1394H5.72222C2.41667 18.1394 1 16.4255 1 13.8546V5.28486C1 2.71394 2.41667 1 5.72222 1H13.2778C16.5833 1 18 2.71394 18 5.28486Z" stroke="#6CE6FE" strokeWidth="1.05923" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_2" opacity="0.4" d="M11.8611 3.14243V4.85638C11.8611 5.79904 12.7111 6.57032 13.75 6.57032H15.6389" stroke="#6CE6FE" strokeWidth="1.05923" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_3" opacity="0.4" d="M5.72222 10.4267H9.5" stroke="#6CE6FE" strokeWidth="1.05923" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_4" opacity="0.4" d="M5.72222 13.8546H13.2778" stroke="#6CE6FE" strokeWidth="1.05923" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </g>
        </svg>
    </SvgIcon>)
}