import React, {useState, useEffect, useRef} from 'react'
import {Grid, Typography, Autocomplete, TextField, Button} from '@mui/material'
import {useTheme} from '@emotion/react'
import Ellipse from '../../ComponentsUtilies/Ellipse'
import {useAppContext} from '../../context/DirectoryContex'
import BirthdayAvatar from './BirthdayAvatar/Index'
import {comparadorPorClaves} from '../../utils'
import {Label} from '../../ComponentsUtilies/Label'
import {IconUser} from '../../media/IconUser3'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import {ExportToCsv} from 'export-to-csv'
import Polygon from '../../ComponentsUtilies/Poligon'
import Star from '../../ComponentsUtilies/Star'

const Birthdays = () => {
  const getCurrentMonth = () => {
    // Obtener la fecha actual
    const currentDate = new Date()

    // Obtener el mes actual (los meses en JavaScript son indexados desde 0)
    const currentMonth = currentDate.getMonth()

    // Lista de nombres de meses
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ]

    // Obtener el nombre del mes actual

    return {
      string: monthNames[currentMonth],
      number: currentMonth + 1
    }
  }

  const calcularEdad = (fechaNacimiento) => {
    const fechaNacimientoObj = new Date(fechaNacimiento)
    const hoy = new Date()

    let edad = hoy.getFullYear() - fechaNacimientoObj.getFullYear()

    // Restamos un año si aún no se ha alcanzado el día y mes de nacimiento en este año
    if (
      hoy.getMonth() < fechaNacimientoObj.getMonth() ||
      (hoy.getMonth() === fechaNacimientoObj.getMonth() &&
        hoy.getDate() < fechaNacimientoObj.getDate())
    ) {
      edad--
    }

    return edad
  }

  function cumpleaniosEnMismaSemana(fechaNacimiento) {
    const fechaNacimientoObjDia = fechaNacimiento.split('T')[0].split('-')[2]
    const fechaNacimientoObjMes = fechaNacimiento.split('T')[0].split('-')[1]
    const fechaNacimientoObjAno = fechaNacimiento.split('T')[0].split('-')[0]

    const fechaNacimientoObj = new Date(
      fechaNacimientoObjAno,
      fechaNacimientoObjMes,
      fechaNacimientoObjDia
    )

    const diaSemanaNacimiento = fechaNacimientoObj.getDay() // 0 (domingo) a 6 (sábado)

    const hoy = new Date() // Fecha actual proporcionada

    const diasHastaLunes = (hoy.getDay() + 6) % 7 // Calcula los días hasta el próximo lunes

    const inicioSemana = new Date(hoy)
    inicioSemana.setDate(hoy.getDate() - diasHastaLunes)
    inicioSemana.setHours(0, 0, 0, 0)

    const finSemana = new Date(inicioSemana)
    finSemana.setDate(inicioSemana.getDate() + 6)
    finSemana.setHours(23, 59, 59, 999)

    return (
      fechaNacimientoObj.getMonth() === inicioSemana.getMonth() + 1 &&
      fechaNacimientoObj.getDate() >= inicioSemana.getDate() &&
      fechaNacimientoObj.getDate() <= finSemana.getDate() &&
      diaSemanaNacimiento >= 0 &&
      diaSemanaNacimiento <= 6 // Agregar esta condición para verificar si es el mismo día
    )
  }

  const {palette} = useTheme()
  const [setScreenWidth] = useState(window.innerWidth)
  const [month, setMonth] = useState({
    number: getCurrentMonth().number,
    string: getCurrentMonth().string
  })
  const gridRef = useRef(null)
  const {employees} = useAppContext()

  let InitialmonthBirthdays = employees

  InitialmonthBirthdays.filter((element) => element.estado == '1').forEach((element) => {
    console.log(element)
    console.log(element.fechaNacimiento)
    const options = {timeZone: 'UTC'}
    const dateString = element.fechaNacimiento
    const dateObj = new Date(dateString)
    const monthInitial = dateObj.toLocaleDateString('en-US', {
      month: 'numeric',
      ...options
    })
    const dayInitial = dateObj.toLocaleDateString('en-US', {day: 'numeric', ...options})

    console.log(dateString)
    console.log(dateObj)
    console.log(monthInitial)
    console.log(dayInitial)
    element.anos = calcularEdad(element.fechaNacimiento)
    element.mesNacimiento = monthInitial
    element.diaNacimiento = Number(dayInitial)
    element.cumpleEstaSemana = cumpleaniosEnMismaSemana(element.fechaNacimiento)
  })

  InitialmonthBirthdays = InitialmonthBirthdays.filter((element) => {
    return element.mesNacimiento == month.number && element.estado == '1'
  }).sort(comparadorPorClaves(['diaNacimiento']))

  const [monthBirthdays, setMonthBirthdays] = useState(InitialmonthBirthdays)

  const updateScreenWidth = (width) => {
    setScreenWidth(width)
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width
        updateScreenWidth(newWidth)

        // Aquí puedes realizar acciones adicionales en respuesta al cambio de tamaño
      }
    })

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const generateRandomSize = () => {
    return Math.floor(Math.random() * 4) + 8 + 'px' // Tamaño aleatorio entre 20px y 70px
  }

  const generateRandomSizeNumber = () => {
    return Math.floor(Math.random() * 4) + 8 // Tamaño aleatorio entre 20px y 70px
  }

  const generateRandomAlpha = () => {
    return Math.random() * 0.5 + 0.5 // Genera un número aleatorio entre 0.5 y 1
  }
  const generateRandomColor = () => {
    const colors = ['#FF7272', '#C670FF']
    const randomColor = colors[Math.floor(Math.random() * colors.length)]
    return randomColor // Agrega el valor de alfa al color
  }

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const generateRandomPosition = () => {
    const randomX = Math.floor(Math.random() * (100 - 10)) + '%'
    const randomY = Math.floor(Math.random() * (100 - 10)) + '%'
    return {left: randomX, top: randomY}
  }

  const changeMonth = (value, _) => {
    let InitialmonthBirthdays = employees

    InitialmonthBirthdays.forEach((element) => {
      const options = {timeZone: 'UTC'}
      const dateString = element.fechaNacimiento
      const dateObj = new Date(dateString)
      const monthInitial = dateObj.toLocaleDateString('en-US', {
        month: 'numeric',
        ...options
      })
      const dayInitial = dateObj.toLocaleDateString('en-US', {day: 'numeric', ...options})
      element.anos = calcularEdad(element.fechaNacimiento)
      element.mesNacimiento = monthInitial
      element.diaNacimiento = Number(dayInitial)
      element.cumpleEstaSemana = cumpleaniosEnMismaSemana(element.fechaNacimiento)
    })

    InitialmonthBirthdays = InitialmonthBirthdays.filter((element) => {
      return element.mesNacimiento == Number(value.value) + 1 && element.estado == '1'
    }).sort(comparadorPorClaves(['diaNacimiento']))

    setMonthBirthdays(InitialmonthBirthdays)
    setMonth({
      number: value.value,
      string: value.label
    })
  }

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [
      'Nombre Completo',
      'Cedula',
      'Proceso',
      'Fecha nacimiento',
      'Mes',
      'Día',
      'Años',
      'Cumple esta semana'
    ]
  }

  const handleExportData = () => {
    let dataCSV = []

    employees
      .filter((element) => element.estado === 1)
      .forEach((element) => {
        const dateString = element.fechaNacimiento
        const dateObj = new Date(dateString)
        const monthInitial = dateObj.getMonth() + 1
        const dayInitial = dateObj.getDate() + 1

        dataCSV.push({
          nombreCompleto: element.nombreCompleto,
          cedula: element.cedula,
          processName: element.processName,
          fechaNacimiento: element.fechaNacimiento,
          mesCumpleanos: monthInitial,
          diaCumpleanos: dayInitial,
          anos: calcularEdad(element.fechaNacimiento),
          cumpleEstaSemana: cumpleaniosEnMismaSemana(element.fechaNacimiento)
            ? 'SI'
            : 'NO'
        })
      })

    csvExporter.generateCsv(dataCSV)
  }

  const csvExporter = new ExportToCsv(csvOptions)

  return (
    <>
      <Grid item xs={12} sx={{marginTop: 2}}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Autocomplete
              disablePortal
              options={[
                {value: '0', label: 'Enero'},
                {value: '1', label: 'Febrero'},
                {value: '2', label: 'Marzo'},
                {value: '3', label: 'Abril'},
                {value: '4', label: 'Mayo'},
                {value: '5', label: 'Junio'},
                {value: '6', label: 'Julio'},
                {value: '7', label: 'Agosto'},
                {value: '8', label: 'Septiembre'},
                {value: '9', label: 'Octubre'},
                {value: '10', label: 'Noviembre'},
                {value: '11', label: 'Diciembre'}
              ]}
              onChange={(_, value) => changeMonth(value, _)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<Label Icon={<IconUser />}>Mes de cumpleaños</Label>}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sx={{display: 'flex', justifyContent: 'flex-end', paddingRight: 2}}
          >
            <Button
              startIcon={<SimCardDownloadOutlinedIcon style={{fontSize: '30px'}} />}
              onClick={() => handleExportData()}
              color={'background'}
              sx={{width: '200px', fontWeight: 600, marginBottom: 2}}
            >
              Descargar plantilla completa
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography color='primary' fontSize={24}>
          Cumpleañeros {month.string}
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          border: `3px solid ${palette.disabled.main}`,
          borderRadius: '20px',
          marginTop: 2,
          padding: '0.3rem 0 0.3rem 1rem',
          overflow: 'hidden',
          position: 'relative'
        }}
        direction='row'
        justifyContent='center'
        ref={gridRef}
      >
        {[...Array(30)].map((_, index) => {
          return (
            <>
              <Grid key={index} item>
                <Ellipse
                  key={index}
                  size={generateRandomSize()}
                  color={generateRandomColor()}
                  position={generateRandomPosition()}
                  opacity={generateRandomAlpha()}
                />
              </Grid>
              <Polygon
                size={generateRandomSizeNumber()}
                color={generateRandomColor()}
                rotation={index * ((2 * Math.PI) / 50)}
                position={generateRandomPosition()}
                opacity={generateRandomAlpha()}
              />

              <Star
                size={generateRandomSizeNumber()}
                color={generateRandomColor()}
                rotation={index * ((2 * Math.PI) / 50)}
                position={generateRandomPosition()}
                opacity={generateRandomAlpha()}
                branches={getRandomInt(3, 12)}
              />
            </>
          )
        })}

        {monthBirthdays.map((person, index) => {
          const margin = (index + 1) % 2 === 0 ? '150px' : ''
          return (
            <BirthdayAvatar
              key={index}
              margin={margin}
              src={person.foto}
              nombre={person.nombre}
              dia={person.diaNacimiento}
              cumpleEstaSemana={person.cumpleEstaSemana}
            />
          )
        })}
      </Grid>
    </>
  )
}

export default Birthdays
