import React from 'react'


const Polygon = ({ size, color, rotation, position, opacity  }) => {
    const sides = 6; // Número de lados del polígono
    const radius = size / 2; // Radio del polígono

    const poligonStyle = {
        width: size,
        height: size,
        borderRadius: '50%',
        position: 'absolute',
        zIndex: '-1', // Pone el círculo detrás de los demás componentes
        ...position,
        opacity: opacity,
        transform: `rotate(${rotation}rad)`
    };
    

    const points = Array.from({ length: sides }).map((_, index) => {
        const angle = (index * 2 * Math.PI) / sides + rotation; // Aplicar rotación
        const x = radius + radius * Math.cos(angle);
        const y = radius + radius * Math.sin(angle);
        return `${x},${y}`;
    });

    const pointsString = points.join(' ');

    return (
        <svg viewBox={`0 0 ${size} ${size}`} style={poligonStyle}>
        <polygon points={pointsString} fill={color} />
        </svg>
    );
};
  
export default Polygon;