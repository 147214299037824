import React from 'react'
import { Typography, Grid } from '@mui/material'
import { LogoNutresa } from '../media/LogoNutresa'

export const Head = (props) => {
    return <Grid container justifyContent='space-between' >
        <Grid item>
            <Typography fontSize={32} >
                {props.children}
            </Typography>
        </Grid>
        <Grid item><LogoNutresa /></Grid>
    </Grid>
}

export default Head