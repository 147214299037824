import axios from "axios"
import { Buffer } from "buffer";

export const getDataUserADS = async(user)=>{
   let auth = "Basic " + Buffer.from("admin" + ":" + "NutresaAxity2022%*").toString("base64");
   const request= await axios.post(`https://r9m9489j99.execute-api.us-east-1.amazonaws.com/getDataUserADS/getDataUserADS`,{
      user:user,
   },
   /*{
      "headers":{
         "Authorization":auth,
         "Access-Control-Allow-Origin":"*"
      }
   }*/)
   const response= await request.data
   return response
}