import React from 'react'
import { CardMedia } from '@mui/material'
import iconClose from './iconClose.svg'

export const IconClose = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconClose}
    />)
}
export default IconClose 