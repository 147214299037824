import { useEffect,useState,useMemo } from "react";
import * as React from 'react';
import { TitleTickects } from "../ComponentsUtilies/Titles"
import {GetAllAuditors} from "../../services/auditorsService"
import MaterialReactTable from "material-react-table"
import { Button,Box,Grid } from "@mui/material";
import { queryTickects } from "../../services/queryTickects";
import { DirectoryContext } from "../../context/DirectoryContex";
import withReactContent from 'sweetalert2-react-content'
import { AlertError } from "../ComponentsUtilies/AlertsModal/Alerts";
import Swal from "sweetalert2";
import { CommentTickectModal } from "../TechniciansButton/CommentTickect";
import { CloseTickectModal } from "../TechniciansButton/CloseTickect";
import { queryOneTickects } from "../../services/queryTickects";
import { Loading,AlertSuccessHTML } from "../ComponentsUtilies/AlertsModal/Alerts";
import { isArray } from 'lodash';
import { queryTickectsBMC,queryCommentsTickectsBMC } from "../../services/casosBMC";


const SweetAlert = withReactContent(Swal);

const MyTickects=(props)=>{
    const [tikects, setTikects]=useState([])
    const [load,setLoad]=useState(false) 
    const valueContext = JSON.parse(localStorage.getItem("InforUser"));

    const LoadingModal = () => {
        Loading("Estamos buscando tu caso");
    }

    const SuccessModal = (caso,comentarios) => {
      /*let casoHtml = `
      <p style="text-align:left">
        <strong>
          Fecha creación
        </strong>:${caso.CREATIONDATE._text}
      </p>
      <p style="text-align:left">
        <strong>
          Solicitante
        </strong>:${caso.REPORTEDBY._text}
      </p>
      <p style="text-align:left">
        <strong>
          Resolutor
        </strong>:${caso.OWNER._text}
      </p>
      <p style="text-align:left">
        <strong>
          Descripción
        </strong>:${caso.DESCRIPTION._text}
      </p>
      <p style="text-align:left">
        <strong>
          Descripción larga
        </strong>:${caso.DESCRIPTION_LONGDESCRIPTION._text}
      </p>
      <p style="text-align:left">
        <strong>
          Estado
        </strong>:${caso.STATUS._text == "INPROG" ? "En progreso" : caso.STATUS._text}
      </p>`

      if(caso?.WORKLOG){
        casoHtml += 
        `<br>
        <p style="text-align:left">
          <strong>
            Notas:
          </strong>
        </p>`

        if(isArray(caso?.WORKLOG)){
          caso?.WORKLOG.forEach(element => {
            casoHtml +=
            `<p style="text-align:left">
              <strong>
                Fecha creación
              </strong>:${element.CREATEDATE._text}
            </p>
            <p style="text-align:left">
              <strong>
                Creador
              </strong>:${element.CREATEBY._text}
            </p>
            <p style="text-align:left">
              <strong>
                Titulo nota
              </strong>:${element.DESCRIPTION._text}
            </p>
            <p style="text-align:left">
              <strong>
                Descripción
              </strong>:${element.DESCRIPTION_LONGDESCRIPTION._text}
            </p><br>`  
          });
        }else{
          casoHtml +=
          `<p style="text-align:left">
            <strong>
              Fecha creación
            </strong>:${caso?.WORKLOG.CREATEDATE._text}
          </p>
          <p style="text-align:left">
            <strong>
              Creador
            </strong>:${caso?.WORKLOG.CREATEBY._text}
          </p>
          <p style="text-align:left">
            <strong>
              Titulo nota
            </strong>:${caso?.WORKLOG.DESCRIPTION._text}
          </p>
          <p style="text-align:left">
            <strong>
              Descripción
            </strong>:${caso?.WORKLOG.DESCRIPTION_LONGDESCRIPTION._text}
          </p>`
        }

      }*/

      let casoHtml = `
      <p style="text-align:left">
        <strong>
          Fecha creación
        </strong>:${caso["Reported Date"]}
      </p>
      <p style="text-align:left">
        <strong>
          Solicitante
        </strong>:${caso["First Name"]+ " " + caso["Last Name"]}
      </p>
      <p style="text-align:left">
        <strong>
          Resolutor
        </strong>:${caso["Assigned Group"]}
      </p>
      <p style="text-align:left">
        <strong>
          Descripción
        </strong>:${caso.Description}
      </p>
      
      <p style="text-align:left">
        <strong>
          Estado
        </strong>:${caso.Status}
      </p>  
      `;

      if(comentarios){
        
        comentarios.forEach(caso => {
          casoHtml += `
        <br>
        <p style="text-align:left">
          <strong>
            Comentario
          </strong>
        </p>
        
        <p style="text-align:left">
          <strong>
            Fecha del comentario
          </strong>:${caso.values["Submit Date"]}
        </p>
        <p style="text-align:left">
          <strong>
            Reportado por:
          </strong>:${caso.values.Submitter}
        </p>
        <p style="text-align:left">
          <strong>
            Descripción
          </strong>:${caso.values.Description}
        </p>
        <p style="text-align:left">
          <strong>
            Descripción detallada
          </strong>:${caso.values["Detailed Description"]}
        </p>
        
        <br>
        `  
        });
        
      }

      AlertSuccessHTML(casoHtml,`Caso ${caso["Incident Number"]}`);
    }

    
    const searchTicket = (ticket) =>{
       
        LoadingModal();
        /*queryOneTickects(
            {
                "key":"TICKETID",
                "value":ticket
            }
        ).then((response) =>{
           
          let data = response.data; 
          let SR = data["soapenv:Envelope"]["soapenv:Body"].QueryIBMSRTUSEGUIMResponse.IBMSRTUSEGUIMSet?.SR;
          if(SR){
            SuccessModal(SR);
          }else{
            AlertError("Caso no encontrado","El caso buscado no fue encontrado");
          }
        
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });*/
        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
        
        queryTickectsBMC(`q='Incident Number'="${ticket}"`)
        .then((response) =>{
            console.log(response)
            let data = response?.data?.data?.entries[0]?.values; 
            let SR = data;
            if(SR){
              queryCommentsTickectsBMC(ticket)
              .then((responseComments)=>{
                SuccessModal(SR,responseComments?.data?.data?.entries);
              })
              .catch(()=>{
                AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
              })

            }else{
              AlertError("Caso no encontrado","El caso buscado no fue encontrado");
            }
            
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });

    
    }

    useEffect(()=>{

        queryTickectsBMC(`q='Customer Login ID'="daniela"`)
        .then((response)=>{
          console.log(response.data.data.entries)
          let tickectsResponse = [];
          try {
            response.data.data.entries.forEach(element => {
              console.log(element)
              tickectsResponse.push({
                  CREATIONDATE:element.values["Reported Date"],
                  REPORTEDBY:element.values["First Name"]+ " " + element.values["Last Name"],
                  DESCRIPTION:element.values.Description,
                  TICKETID:element.values["Incident Number"],
                  STATUS:element.values["Status"]
              })
            });
            console.log("tickectsResponse",tickectsResponse);
            setTikects(tickectsResponse);  
          } catch (error) {
            AlertError("No hay casos abiertos","No hay casos abiertos");
            console.log(error);
          }
         
          
        })
        .catch((error)=>{
          AlertError("Ocurrio un error","Por favor vuelva a intentarlo");
          
        })
        setLoad(false)

        /*console.log(valueContext)
        console.log(localStorage.getItem("infoUser"))
        let tickectsResponse = [];
        console.log(String(valueContext[0].sAMAccountName).substring(0,4).toUpperCase() + String(valueContext[0].sAMAccountName).substring(4,String(valueContext[0].sAMAccountName).length).toLowerCase())
        queryTickects({
            "key": props.rol === "Technicians" ? "OWNER" : "REPORTEDBY",
            "value":props.rol === "Technicians" ? valueContext[0].sAMAccountName.toUpperCase() : String(valueContext[0].sAMAccountName).substring(0,4).toUpperCase() + String(valueContext[0].sAMAccountName).substring(4,String(valueContext[0].sAMAccountName).length).toLowerCase()
        }).then((data)=>{
            console.log("data",data)
            try {
              data.data["soapenv:Envelope"]["soapenv:Body"]["QueryIBMSRTUSEGUIMResponse"]["IBMSRTUSEGUIMSet"].SR.forEach(element => {
                tickectsResponse.push({
                    CREATIONDATE:element.CREATIONDATE._text,
                    REPORTEDBY:element.REPORTEDBY._text,
                    DESCRIPTION:element.DESCRIPTION._text,
                    TICKETID:element.TICKETID._text,
                    STATUS:element.STATUS._text,
                })
              });  
            } catch (error) {
              console.log("error",error)
              let SR = data.data["soapenv:Envelope"]["soapenv:Body"]["QueryIBMSRTUSEGUIMResponse"]["IBMSRTUSEGUIMSet"].SR 
              if(SR){
                tickectsResponse.push({
                  CREATIONDATE:SR.CREATIONDATE._text,
                  REPORTEDBY:SR.REPORTEDBY._text,
                  DESCRIPTION:SR.DESCRIPTION._text,
                  TICKETID:SR.TICKETID._text,
                  STATUS:SR.STATUS._text,
                })
              }else{
                AlertError("No hay casos abiertos","No hay casos abiertos");
              }
            }
           
            //setTikects(tickectsResponse)  
            
        })
        .catch(error=>{
          console.log("error2",error)
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        })
        //console.log(tikects)
        setLoad(false)*/
    },[load]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "CREATIONDATE",
                header: "Fecha Creación"
            },
          {
            accessorKey: 'TICKETID', 
            header: 'SR',
            
          },
          {
            accessorKey: 'DESCRIPTION', 
            header: 'Descripción',
            
          },
          {
            accessorKey: 'REPORTEDBY', 
            header: 'Reportado por',
            },
          {
            accessorKey: 'STATUS', 
            header: 'Estado', 
          },
          {
            accessorFn: (row) => `${row.TICKETID}`,
            accessorKey: 'ACCIONES', 
            header: 'Acciones',
            Cell: ({ renderedCellValue, row }) => (
            
            <>
              
              <Grid>
                <Button variant="contained" 
                  onClick={()=>CommentTickectModal(row.original.TICKETID)}
                  sx={{
                    backgroundColor: 'rgba(60,16,83,1)',
                    margin:1
                  }}
                >
                  Comentar {renderedCellValue} 
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={()=>CloseTickectModal(row.original.TICKETID,"Cancelar")}
                  variant="contained" 
                  sx={{
                    backgroundColor: 'rgba(120,60,189,1)',
                    margin:1
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              { props.rol === "Technicians" ?
              <Grid>
                <Button 
                  onClick={()=>CloseTickectModal(row.original.TICKETID,"Cerrar")}
                  variant="contained" 
                  sx={{
                    backgroundColor: 'rgba(4,214,255,1)',
                    margin:1
                  }}
                >
                  Cerrar
                </Button>
              </Grid>:""
              }
            </>
            )
          },
          {
            accessorFn: (row) => `${row.TICKETID}`,
            accessorKey: 'DETALLES', 
            header: 'Ver detalles',
            Cell: ({ renderedCellValue, row }) => (
            <>
              
              <Grid>
                <Button variant="contained" 
                  onClick={()=>searchTicket(row.original.TICKETID)}
                  sx={{
                    backgroundColor: 'rgba(60,16,83,1)',
                    margin:1
                  }}
                >
                  Ver detalles
                </Button>
              </Grid>
              
            </>
            )
          }
        
          
        ],
        [],
    );
    
 
  return(
    <>
        
        {
        tikects != "" ? 
        <MaterialReactTable 
        columns={columns}
        data={tikects}
        /> : <MaterialReactTable 
        columns={columns}
        data={0}
        />
        }
    </>
  )  
}

const MyTickectsModal = (rol) => {
    //Swal.close()
    SweetAlert.fire({
        customClass:"classFormModal",
        title:"Casos",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top",
      allowOutsideClick: true,
      showCloseButton: true,
      html: <MyTickects rol={rol} />
      
    })
  }

export {MyTickectsModal}