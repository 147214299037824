import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Grid, TextField, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"
import { CreateMargenPep } from "../../services/margenesPep"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useState } from "react";
const Alert = withReactContent(Swal)

const AlertModalFormMargen = ({ load }) => {
  const [value, setValue] = useState()
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const onSubmit = (valores) => {
    //const idCategory=categoria.IdCategoria
    const envio = {
      "pep": valores.pep,
      "ingresos": valores.ingresos,
      "costos": valores.costos,
      "fecha": valores.fecha
    }

    CreateMargenPep(envio).then(r => {
      console.log(r)
      if (r.status != 500) {
        AlertSuccess()
        load(true)

      }
    }).catch(error => console.log(error))


    reset()

  }

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
          <TitleFirts>Agregar margen pep</TitleFirts>
          <Grid container spacing={1}>

            <Grid item sm={12}>
              <Autocomplete
                sx={{ marginTop: 2, width: 850, marginLeft: 3.2 }}
                disablePortal
                id="combo-box-demo"
                options={[{ label: "pep-prueba1", value: "pep-prueba1" }]}

                renderInput={(params) =>
                  <TextField {...params}
                    id="outlined-basic"
                    label="Pep" {...register("pep", { required: true })}
                    variant="outlined" s
                    helperText={(errors.pep?.type === 'required' && "Este campo es obligatorio!")}
                    error={
                      (errors.pep?.type === 'required')
                      && true
                    } />
                }
              />
            </Grid>

            <Grid item sm={12}>
              <TextField id="outlined-basic" label="Ingreso" {...register("ingresos", { required: true })} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.ingresos?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.ingresos?.type === 'required')
                  && true
                } />

            </Grid>


            <Grid item sm={12}>
              <TextField id="outlined-basic" label="Costo" {...register("costos", { required: true })} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.costos?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.costos?.type === 'required')
                  && true
                } />

            </Grid>



            <Grid item sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Fecha"
                  inputFormat="MM/DD/YYYY"
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} id="outlined-basic" label="fecha" {...register("fecha", { required: true })} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.fecha?.type === 'required' && "Este campo es obligatorio!")}
                    error={
                      (errors.fecha?.type === 'required')
                      && true
                    } />}
                />


              </LocalizationProvider>
            </Grid>


          </Grid>




        </Grid>
        <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
        <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
      </form>
    </>
  )
}

export const ModalCreateMargenPep = (load) => {
  Alert.fire({
    width: "970px",
    showConfirmButton: false,
    showCancelButton: false,
    position: "top",
    html: <AlertModalFormMargen load={load} />
  })
}