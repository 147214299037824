import axios from "axios"

export const GetProductsAll=async()=>{
   const request= await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/products/all")
   const data= await request.data
   return data
}

export const getProduct=async(id)=>{
  const request= await axios.get( "https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/products/"+id)
  const data= await request.data
  return data
}

export const createProducts=async(object, config)=>{
   console.log(object, " service valores")
   const request=await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/products/create", object, config)
   const data= await request.data
   return data
}

export const updateStateProduct=async(id)=>{
    const request=await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/products/updateState/"+id)
    const data= await request.data
    return data
}


export const UpdateProduct=async(id, object,config)=>{
    const request=await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/products/update/"+id, object,config)
    const data= await request.data
    return data   
}