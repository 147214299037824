import React from 'react'
import { Card, CardMedia } from '@mui/material'
import login from './login.png'

export const ImgLogin = (props) => {

    return (<Card {...props} >
        <CardMedia
            component='img'
            alt='Imagen Login'
            image={login}
            sx={{ maxHeight: '31rem'}}
        />
    </Card>)
}
