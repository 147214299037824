import axios from "axios";
import {UpdateStateAdminPep} from "../../services/adminPepService"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"

export const UpdateAdminPepState=(id, load)=>{
 
   UpdateStateAdminPep(id).then(data=>{
       if(data.Id != null ){
         AlertSuccess()
          load(true)
       }
    }).catch(error=>{console.log(error)}) 


}