import React from 'react'
import {TreeNode} from 'react-organizational-chart'
import {Sheet} from './Sheet'

export const getBranchs = (processes, branchs, dadIdx, show, setShow, isRoot) => {
  if (!processes) return []
  const dad = processes[dadIdx].id

  const sobprocessBranche = processes[dadIdx].subprocesos.map((sub) => (
    <TreeNode
      key={sub.IdSubProceso}
      label={
        <Sheet
          process={{
            nombre: sub.Nombre,
            descripcion: sub.Descripcion
          }}
          show={false}
          setShow={() => {}}
          isDad={false}
        />
      }
    />
  ))

  const branch = branchs[dad]

  if (!branch) return sobprocessBranche

  return branch.reduce(
    (sheets, processIdx) => {
      const process = processes[processIdx]
      if (processIdx === dadIdx) return sheets
      const haveChildren = Object.keys(branchs).includes(String(process.id))
      sheets.push(
        <TreeNode
          key={process.id}
          label={
            <Sheet
              process={process}
              show={show[processIdx]}
              setShow={(state) => setShow((s) => ({...s, [processIdx]: state}))}
              isDad={haveChildren || sobprocessBranche.length > 0}
            />
          }
        >
          {show &&
            show[processIdx] &&
            getBranchs(processes, branchs, processIdx, show, setShow, false)}
        </TreeNode>
      )
      return sheets
    },
    isRoot ? [] : sobprocessBranche ?? []
  )
}
