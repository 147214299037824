import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LoginPanicButtonForm } from '../../components/LoginPanicButton/LoginPanicButtonForm';
import { Container } from '@mui/material';
import image from '../../assets/avenue-gf74fbc8d5_1920.jpg'
import { PanicPremiumOptions } from '../../components/PanicButtom/PanicPremiumOptions';
import { PanicNormalOptions } from '../../components/PanicButtom/PanicNormalOptions';
import { PanicButtomRequest } from '../../components/PanicButtom/PanicButtomRequest';
import { OptionsPanicButtomProvider } from '../../context/OptionsPanicButtomContex';
import { OptionsPanicButtomContex } from '../../context/OptionsPanicButtomContex';
import { PanicButton} from '../../components/PanicButtom/PanicButtom';

let welcomeMessage = ``;
let createTicketmessage = ``;


const CasosBMCPage = () => {

  console.log("hola",localStorage.getItem("InforUser"))

  welcomeMessage = `Bienvenido Daniela has click para gestionar tus casos`;
  createTicketmessage = `Daniela has click para crear un caso en BMC`;
  //welcomeMessage = `Bienvenido ${JSON.parse(localStorage.getItem("InforUser"))[0].givenName} has click para gestionar tus casos`;
  //createTicketmessage = `${JSON.parse(localStorage.getItem("InforUser"))[0].givenName} has click para crear un caso`;

  return (
    <>
        <OptionsPanicButtomProvider>
        <Container maxWidth={false}
            sx={{ position:"absolute",
            left:0, minHeight:"100vh",
            background: "linear-gradient(180deg, rgba(60,16,83,1) 0%, rgba(120,60,189,1) 35%, rgba(4,214,255,1) 100%)", 
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column"}}
        >
            <PanicPremiumOptions backgroundColor={"#783CBD"} rol={"Technicians"} BMC={true} />
            <PanicButton createTicketmessage={createTicketmessage} rol={"Technicians"} BMC={true}  />
            <PanicButtomRequest  welcomeMessage={welcomeMessage}  rol={"Technicians"} BMC={true} />
            <PanicNormalOptions  backgroundColor={"#783CBD"} rol={"Technicians"} BMC={true}  />
            
        </Container>
        </OptionsPanicButtomProvider>
    </>
  );
}

export {CasosBMCPage};