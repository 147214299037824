import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {ProcessAll} from '../../services/processService'
import {TableProcess} from './ListProcess'
import {Head} from '../../ComponentsUtilies/Head'
import {Autocomplete, TextField, Typography, Button, Grid} from '@mui/material'
import Info from '../../ComponentsUtilies/Info'
import {IconOrganization, IconOrganizationInactive} from '../../media/IconOrganization'
import {IconUser} from '../../media/IconUser3'
import {IconLeader} from '../../media/IconLeader'
import {getAlertModal} from '../../hooks/getAlertModal'
import Create from './Create'
import {useAppContext} from '../../context/DirectoryContex'
import {eliminarDuplicadosPorClaves, comparadorPorClaves} from '../../utils'
import Detail from './Detail'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import SubProcessesFormAdd from './CreateSubProcesses'
import Label from '../../ComponentsUtilies/Label'

const initialCounts = {
  inactive: 0,
  active: 0,
  man: 0,
  woman: 0
}

const initialFilters = [
  {id: 'estado', value: '1'},
  {id: 'NombreProceso', value: ''},
  {id: 'jefaturaName', value: ''},
  {id: 'direccionName', value: ''},
  {id: 'tipo', value: ''},
  {id: 'subproceso', value: ''}
]

export const Process = () => {
  const {process, user, processes, setProcesses} = useAppContext()
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const [option, setOption] = useState('masivo')
  const [counts, setCounts] = useState({...initialCounts})
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState([...initialFilters])
  const [headships, setHeadships] = useState([])
  const [managements, setManagements] = useState([])

  const onSearch = (id, value) => {
    setFilters((state) => {
      state[id] = {...state[id], value: value || ''}
      return [...state]
    })
    setOption('masivo')
  }

  useEffect(() => {
    setIsLoading(true)
    ProcessAll()
      .then((data) => {
        setCounts({
          inactive: data.filter((element) => element.estado === 0).length,
          active: data.filter((element) => element.estado === 1).length,
          operativos: data.filter(
            (element) => element.Tipo === 'Operativo' && element.estado === 1
          ).length,
          administrativo: data.filter(
            (element) => element.Tipo === 'Administrativo' && element.estado === 1
          ).length
        })

        let headshipsOptions = []
        data.forEach((element) => {
          element.jefaturaName = element.jefatura.nombreJefatura
          headshipsOptions.push({
            label: element.jefatura.nombreJefatura,
            value: element.jefatura.nombreJefatura
          })
        })

        headshipsOptions = eliminarDuplicadosPorClaves(headshipsOptions, ['value'])
        headshipsOptions.sort(comparadorPorClaves(['value']))

        let managementsOptions = []
        data.forEach((element) => {
          element.direccionName = element.direccion.nombreDireccion
          managementsOptions.push({
            label: element.direccion.nombreDireccion,
            value: element.direccion.nombreDireccion
          })
        })

        managementsOptions = eliminarDuplicadosPorClaves(managementsOptions, ['value'])
        managementsOptions.sort(comparadorPorClaves(['value']))

        setProcesses(data)
        setHeadships(headshipsOptions)
        setManagements(managementsOptions)
        setIsLoading(false)
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false)
      })
    setLoad(false)
  }, [load])

  return (
    <>
      <Grid container padding='1rem 2rem 0 2rem'>
        <Grid item xs={12}>
          <Head>Procesos</Head>
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <Grid container sx={{height: '100%'}} spacing={1}>
            <Grid item xs={12}>
              <Typography color='secondary' fontSize={24}>
                Tipo de consulta
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{width: '100%', fontWeight: 600}}
                onClick={() => setOption('individual')}
                color={option === 'individual' ? 'primary' : 'background'}
              >
                Individual
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{width: '100%', fontWeight: 600}}
                onClick={() => setOption('masivo')}
                color={option === 'masivo' ? 'primary' : 'background'}
              >
                Masivo
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                Icon={<IconOrganization />}
                onClick={() => onSearch('0', '1')}
                isSelected={filters[0].value === '1' || filters[0].value === ''}
                total={counts.active}
              >
                Procesos activos
              </Info>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                Icon={<IconOrganization />}
                onClick={() => onSearch('4', 'Operativo')}
                isSelected={filters[4].value === 'Operativo'}
                total={counts.operativos}
              >
                Operaciones
              </Info>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                Icon={<IconOrganization />}
                onClick={() => onSearch('4', 'Administrativo')}
                isSelected={filters[4].value === 'Administrativo'}
                total={counts.administrativo}
              >
                Administrativos
              </Info>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                Icon={<IconOrganizationInactive />}
                onClick={() => onSearch('0', '0')}
                isSelected={filters[0].value === '0'}
                total={counts.inactive}
              >
                Procesos inactivos
              </Info>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} md={2} m={2} ml={0}>
              {user && user.authorization < 5 && (
                <Button
                  sx={{width: '100%', fontWeight: 600}}
                  onClick={getAlertModal(Create, {
                    setLoad,
                    user,
                    setIsLoading,
                    setOption
                  })}
                  color='success'
                >
                  <Typography>Crear Proceso</Typography>
                </Button>
              )}
            </Grid>

            <Grid item xs={6} md={2} m={2} ml={0}>
              {user && user.authorization < 5 && (
                <Button
                  sx={{width: '100%', fontWeight: 600}}
                  onClick={getAlertModal(SubProcessesFormAdd, {
                    setLoad,
                    setIsLoading,
                    setOption,
                    processes
                  })}
                  color='success'
                >
                  <Typography>Crear Sub-Proceso</Typography>
                </Button>
              )}
            </Grid>
            <Grid item xs={6} md={2} m={2} ml={0}>
              {option === 'masivo' && user && user.authorization < 5 && (
                <Button
                  sx={{width: '100%', fontWeight: 600}}
                  onClick={() => navigate(`/Organigrama/Procesos`)}
                  color='success'
                >
                  <Typography>Organigrama</Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label={<Label Icon={<IconUser />}>Proceso</Label>}
                onChange={({target}) => onSearch(1, target.value)}
                value={filters[1].value}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                disablePortal
                options={managements}
                onInputChange={(_, value) => onSearch(3, value)}
                value={{value: filters[3].value, label: filters[3].value}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label Icon={<IconLeader />}>Dirección</Label>}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                disablePortal
                options={headships}
                onInputChange={(_, value) => onSearch(2, value)}
                value={{value: filters[2].value, label: filters[2].value}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label Icon={<IconLeader />}>Jefactura</Label>}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label={<Label Icon={<IconUser />}>Sub-Proceso</Label>}
                onChange={({target}) => onSearch('5', target.value)}
                value={filters[5].value}
              />
            </Grid>

            <Grid
              item
              xs={6}
              md={2}
              container
              display='flex'
              alignItems='flex-end'
              sx={{maxHeight: '80%'}}
            >
              <Grid item xs={4}>
                <CleaningServicesIcon
                  onClick={() => setFilters([...initialFilters])}
                  fontSize='large'
                  sx={{cursor: 'pointer'}}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sx={{margin: '1rem 0 0 1rem', maxHeight: '55vh', overflow: 'auto'}}
        >
          {option === 'individual' && process ? (
            <Detail
              setIsLoading={setIsLoading}
              setOption={setOption}
              setLoad={setLoad}
            ></Detail>
          ) : (
            <TableProcess
              setOption={setOption}
              columnFilters={filters}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setLoad={setLoad}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
