import {updateStateProduct} from "../../services/productService"
import {AlertSuccess} from "../ComponentsUtilies/AlertsModal/Alerts"
import { AlertError } from "../ComponentsUtilies/AlertsModal/Alerts"

export const UpdateStateProduct=(id,load)=>{
    console.log(id, "valor")
   updateStateProduct(id).then(res=>{
      if(res.IdProducto != null){
         AlertSuccess()
         load(true)  
      }else{
        console.log(res)
      }
   }).catch(error=>AlertError(error))
}