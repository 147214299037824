import { SvgIcon } from '@mui/material'
export const IconLeader = (props) => {
    return (<SvgIcon {...props} >
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;louder&#34;">
                <g id="Group">
                    <path id="Vector_1" opacity="0.4" d="M18.9947 6.54599C18.8952 6.53533 18.7954 6.53533 18.6959 6.54599C17.0911 6.47468 15.8217 5.15353 15.8217 3.52969C15.8217 1.86276 17.1745 0.5 18.8514 0.5C20.5178 0.5 21.8802 1.85196 21.8811 3.52811C21.8698 5.15424 20.5985 6.47471 18.9947 6.54599Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_2" opacity="0.4" d="M22.6778 15.0953L22.6775 15.0955C21.5921 15.8223 20.0955 16.1669 18.6195 16.0989C18.9414 15.2072 19.1096 14.2504 19.1199 13.2567L19.1199 13.2567V13.2515C19.1199 12.2117 18.9362 11.2082 18.5785 10.2896C20.0765 10.215 21.5722 10.5598 22.667 11.2864L22.6686 11.2875C23.529 11.8538 23.8912 12.5522 23.8923 13.1867C23.8935 13.8218 23.5333 14.5232 22.6778 15.0953Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_3" opacity="0.4" d="M5.70803 6.54599C5.60854 6.53533 5.50875 6.53533 5.40926 6.54599C3.80548 6.47471 2.5341 5.15423 2.52285 3.52809C2.52372 1.85194 3.88614 0.5 5.55254 0.5C7.22942 0.5 8.58222 1.86276 8.58222 3.52969C8.58222 5.15353 7.31286 6.47468 5.70803 6.54599Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_4" opacity="0.4" d="M1.72403 11.2865L1.72404 11.2865L1.72643 11.2849C2.7508 10.5956 4.16965 10.2466 5.59427 10.2821C5.24612 11.2031 5.06322 12.2046 5.06322 13.2517C5.06322 14.2567 5.23718 15.2214 5.55891 16.1185C4.15292 16.1449 2.76386 15.7902 1.72299 15.1063C0.86207 14.5338 0.5 13.8325 0.5 13.1967C0.5 12.5608 0.862364 11.8591 1.72403 11.2865Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_5" d="M12.3863 16.439C12.2713 16.4279 12.1517 16.4285 12.0347 16.4391C10.0723 16.3582 8.50792 14.742 8.50792 12.7508C8.50792 10.7055 10.1537 9.04941 12.2094 9.04941C14.2538 9.04941 15.9108 10.7064 15.9108 12.7508C15.9108 14.7424 14.3586 16.3571 12.3863 16.439Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_6" d="M8.67665 23.5348L8.67482 23.5336C7.85878 22.9931 7.51557 22.323 7.51557 21.7139C7.51557 21.1043 7.85921 20.4307 8.67616 19.8841C9.63374 19.2478 10.9209 18.9147 12.2307 18.9147C13.5411 18.9147 14.8212 19.248 15.7654 19.883L15.7654 19.883L15.7683 19.885C16.5843 20.4254 16.9276 21.0955 16.9276 21.7047C16.9276 22.3144 16.5838 22.9881 15.7665 23.5348C14.8156 24.1706 13.5318 24.5038 12.2216 24.5038C10.9114 24.5038 9.62758 24.1706 8.67665 23.5348Z" fill="#6CE6FE" stroke="#C8C8C6" />
                </g>
            </g>
        </svg>
    </SvgIcon>)
}