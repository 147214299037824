import React, {useState} from 'react'
import {Grid, TextField, Button, Autocomplete, Alert} from '@mui/material'
import {useForm, Controller} from 'react-hook-form'
import {departamentos, ciudades} from '../../departamentoCiudades'
import InputAdornment from '@mui/material/InputAdornment'
import Avatar from '@mui/material/Avatar'
import {getParameters, createEmploye} from '../../services/employeesService'
import {AlertSuccess} from '../ComponentsUtilies/AlertsModal/Alerts'
import {AlertError} from '../Navbar/AlertError'
import {TitleFirts} from '../ComponentsUtilies/Titles'
import {MuiTelInput} from 'mui-tel-input'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import ChildFriendlyOutlinedIcon from '@mui/icons-material/ChildFriendlyOutlined'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import GiteIcon from '@mui/icons-material/Gite'
import {GetAllAdminPep} from '../../services/adminPepService'
import {primerLetraMayusculaCadaPalabra} from '../../utils'
import CircularProgress from '@mui/material/CircularProgress'
import {GetEmployeesAll} from '../../services/employeesService'
import {useAppContext} from '../../context/DirectoryContex'

export const Create = ({setLoad, close, user, setOption}) => {
  const [Pep, setPep] = useState('')
  const [EnvioImagen, setEnvioImagen] = useState('')
  const [citiesOptions, setCitiesOptions] = useState([])
  const [positionsOptions, setPositionsOptions] = useState([])
  const [directorsOptions, setDirectorsOptions] = useState([])
  const [auditorsOptions, setAuditorsOptions] = useState([])
  const [resetCityValue, setResetCityValue] = useState('')
  const [fileValidate, setFileValidate] = useState(false)
  const [personImage, setpersonImage] = useState('')
  const [employeesOptions, setEmployeesOptions] = useState([])
  const [procesoOptions, setProcesoOptions] = useState([])
  const [subProcesoOptions, setSubProcesoOptions] = useState([])
  const [displayLoading, setDisplayLoading] = useState('none')
  const [sendError, setSendError] = useState([false, ''])
  const {employee, setEmployees, leaders, setLeaders, setCounts} = useAppContext()

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    watch,
    clearErrors,
    setError,
    setValue
  } = useForm()

  const tieneMembresia = watch('tieneMembresia')

  React.useEffect(() => {
    GetPeps()
    GetOptionsSelect()
  }, [])

  const GetOptionsSelect = () => {
    getParameters(user.supplier ?? '')
      .then((res) => {
        let positions = []
        let directors = []
        let auditors = []
        let employees = []
        let employeesProcess = []
        let employeesSubProcess = []
        const positionsData = res[0]
        const directorsData = res[1]
        const auditorsData = res[2]
        const employeesData = res[3]
        const employeesProcessData = res[4]
        const employeesSubProcessData = res[5]

        employeesData.forEach((v) => {
          employees.push({
            label: v.primerNombre + ' ' + v.primerApellido + '-' + v.cedula,
            value: v.cedula
          })
        })

        positionsData.forEach((value) => {
          positions.push({
            label: value.nombre,
            value: value.idCargo
          })
        })

        directorsData.forEach((va) => {
          directors.push({
            label: va.nombre,
            value: va.idDirector
          })
        })

        auditorsData.forEach((v) => {
          auditors.push({
            label: v.nombre,
            value: v.idInterventor
          })
        })

        employeesProcessData.forEach((v) => {
          employeesProcess.push({
            value: v.IdProceso,
            label: v.NombreProceso
          })
        })

        employeesSubProcessData.forEach((v) => {
          employeesSubProcess.push({
            value: v.IdSubProceso,
            label: v.Nombre
          })
        })

        setProcesoOptions(employeesProcess)
        setPositionsOptions(positions)
        setDirectorsOptions(directors)
        setAuditorsOptions(auditors)
        setEmployeesOptions(employees)
        setSubProcesoOptions(employeesSubProcess)
      })
      .catch((error) => {
        console.error(error)
        AlertError({
          title: 'Oops...',
          html: `Algo fallo si el error persiste comunicate con el administrador!
      <br><br>
      ${error}`
        })
      })
  }

  const GetPeps = () => {
    const PepsData = []
    GetAllAdminPep()
      .then((res) => {
        res.forEach((v) => {
          PepsData.push({label: v.pep, value: v.Id})
        })
        setPep(PepsData)
      })
      .catch((error) => {
        console.error(error)
        AlertError({
          title: 'Oops...',
          html: `Algo fallo si el error persiste comunicate con el administrador!
      <br><br>
      ${error}`
        })
      })
  }

  const uploadImage = (event, value) => {
    let file = event.target.files[0]
    let imageType = /image.*/
    if (!file.type.match(imageType)) {
      setFileValidate(true)
    } else {
      setFileValidate(false)
    }
    setEnvioImagen(event.target.files[0])
    let reader = new FileReader()
    reader.onload = fileOnload
    reader.readAsDataURL(file)
  }

  const fileOnload = (e) => {
    let result = e.target.result
    setpersonImage(result)
  }

  const handleChange = (event, value) => {
    setResetCityValue('')
    let filteredCities = []
    let filteredCitiesOptions = []
    filteredCities = ciudades.filter(
      (element) => element.codDepartamento === value?.value
    )
    if (filteredCities) {
      filteredCities.forEach((element) => {
        filteredCitiesOptions.push({
          label: `${element.codCiudad} - ${element.ciudad}`,
          value: element.codCiudad
        })
      })
    }
    setCitiesOptions(filteredCitiesOptions)
  }

  const [phone, setPhone] = React.useState('57')
  const [phoneContacto, setPhoneContacto] = React.useState('57')
  const [labelfijo, setLabelfijo] = React.useState([
    'Indicador teléfono fijo',
    'Teléfono fijo'
  ])
  const [fijoRequerido, setFijoRequerido] = React.useState(false)

  const cambioFijo = (event, data) => {
    const telefonoFijo = document.getElementsByName('telefonoFijo')
    if (telefonoFijo[0].value !== '' || watch('indicadorFijo')) {
      setFijoRequerido(true)
      setLabelfijo(['Indicador teléfono fijo *', 'Teléfono fijo *'])
      if (telefonoFijo[0].value === '') {
        setError('telefonoFijo', {type: 'required'})
      } else if (telefonoFijo[0].value.length !== 7) {
        setError('telefonoFijo', {type: 'minLength'})
      } else if (!/^[0-9]+$/i.test(telefonoFijo[0].value)) {
        setError('telefonoFijo', {type: 'pattern'})
      } else {
        clearErrors('telefonoFijo')
      }
      if (!watch('indicadorFijo')) {
        setError('indicadorFijo', {type: 'required'})
      } else {
        clearErrors('indicadorFijo')
      }
    } else {
      setFijoRequerido(false)
      setLabelfijo(['Indicador teléfono fijo', 'Teléfono fijo'])
      clearErrors('indicadorFijo')
      clearErrors('telefonoFijo')
    }
  }

  const [valCarnetNutresa, setValCarnetNutresa] = React.useState([
    false,
    'Número carnet Nutresa'
  ])

  const cambioCarnetNutresa = (event, data) => {
    const numeroCarnetNutresa = document.getElementsByName('numeroCarnetNutresa')
    if (data?.value === 'SI') {
      setValCarnetNutresa([true, 'Número carnet Nutresa *'])
      setError('numeroCarnetNutresa', {type: 'required'})
    } else {
      setValCarnetNutresa([false, 'Número carnet Nutresa'])
      numeroCarnetNutresa[0].value = ''
      clearErrors('numeroCarnetNutresa')
    }
  }

  const [valCarnetSnacksAxity, setCarnetSnacksAxity] = React.useState([
    false,
    'Número carnet snacks Axity'
  ])

  const cambioCarnetSnacksAxity = (event, data) => {
    const numeroCarnetSnacksAxity = document.getElementsByName('numeroCarnetSnacksAxity')
    if (data?.value === 'SI') {
      setCarnetSnacksAxity([true, 'Número carnet snacks Axity *'])
      setError('numeroCarnetSnacksAxity', {type: 'required'})
    } else {
      setCarnetSnacksAxity([false, 'Número carnet snacks Axity'])
      numeroCarnetSnacksAxity[0].value = ''
      clearErrors('numeroCarnetSnacksAxity')
    }
  }
  const onSubmit = (values) => {
    let roles = ''
    setDisplayLoading('')
    setSendError([false, ''])
    values.rol.forEach((e) => {
      roles += e.value + ','
    })
    roles = roles.substring(0, roles.length - 1)

    const pepEnvio = values.pep.map((vd) => vd.value)
    const procesoEnvio = values.procesos.map((vd) => vd.value)
    const subProcesoEnvio = values.subProcesos.map((vd) => vd.value)

    values.email = values.email.toLowerCase()
    values.correoAxity = values.correoAxity.toLowerCase()
    values.foto = EnvioImagen
    values.eps = values.eps.value
    values.carnetNutresa = values.carnetNutresa.value
    values.carnetSnack = values.carnetSnack.value
    values.ciudad = values.ciudad.value
    values.departamento = values.departamento.value
    values.directoreIdDirector = values.directoreIdDirector?.value
    values.estadoCivil = values.estadoCivil.value
    values.hijos = values.hijos.value
    values.indicadorTelefono = values.indicadorFijo?.value
    values.indicadorFijo = values.indicadorFijo?.value
    values.telefono = values.telefonoFijo
    values.interventoreIdInterventor = values.interventoreIdInterventor?.value
    values.lider = values.lider?.value
    values.nivelAcademico = values.nivelAcademico.value
    values.rh = values.rh.value
    values.sexo = values.sexo.value
    values.teletrabajo = values.teletrabajo.value === 'SI' ? 1 : 0
    values.cargoIdCargo = values.cargoIdCargo.value
    values.rol = roles
    values.costoEmpleado = values.costoempleado
    values.peps = JSON.stringify(pepEnvio)
    values.procesos = JSON.stringify(procesoEnvio)
    values.subprocesos = JSON.stringify(subProcesoEnvio)
    values.primerNombre = primerLetraMayusculaCadaPalabra(values.primerNombre)
    values.primerApellido = primerLetraMayusculaCadaPalabra(values.primerApellido)
    values.segundoApellido = primerLetraMayusculaCadaPalabra(values?.segundoApellido)
    values.segundoNombre = primerLetraMayusculaCadaPalabra(values?.segundoNombre)

    values.proveedorUUID = user.supplier ?? '520d1ddd-e0a7-4ddc-8c25-c187d3e4f338'

    createEmploye(values)
      .then((response) => {
        if (response.status !== 500) {
          close()
          AlertSuccess()
          setLoad(true)
          GetEmployeesAll(user.supplier)
            .then((data) => {
              setOption('masivo')
              setEmployees(data.employees)
              setCounts(data.counts)
              console.log(data.leaders, 'leaders')
              setLeaders(
                Object.keys(data.leaders).map((leader) => ({
                  label: data.leaders[leader],
                  value: leader
                }))
              )
              setLeaders(
                data.employees.map((leader) => ({
                  label: leader.nombreCompleto,
                  value: leader.cedula
                }))
              )
              console.log(leaders)
              console.log(data.employees)
              console.log('employee', employee)
            })
            .catch((error) => {
              setDisplayLoading('none')
              try {
                setSendError([true, error.data.message.errors[0].message])
              } catch (err) {
                setSendError([true, err])
              }
            })
        } else {
          setDisplayLoading('none')
          try {
            setSendError([true, response.data.message.errors[0].message])
          } catch (error) {
            setSendError([true, JSON.stringify(response)])
          }
        }
      })
      .catch((e) => {
        console.error(e)
        setDisplayLoading('none')
        try {
          setSendError([true, e.response.data.message.errors[0].message])
        } catch (error) {
          setSendError([true, JSON.stringify(e)])
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        component='form'
        justify='flex-start'
        alignItems='flex-start'
        spacing={3}
      >
        <Grid item xs={12}>
          <TitleFirts>Crear empleado</TitleFirts>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('primerNombre', {required: true, pattern: /^[A-Za-z| ]+$/i})}
            label='Primer nombre *'
            helperText={
              (errors.primerNombre?.type === 'required' &&
                'Este campo es obligatorio!') ||
              (errors.primerNombre?.type === 'pattern' &&
                'En este campo son solo permitidas letras!')
            }
            error={errors.primerNombre}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('segundoNombre', {pattern: /^[A-Za-z]+$/i})}
            label='Segundo nombre'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            helperText={
              errors.segundoNombre?.type === 'pattern' &&
              'En este campo son solo permitidas letras!'
            }
            error={errors.segundoNombre?.type === 'pattern'}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('primerApellido', {required: true, pattern: /^[A-Za-z]+$/i})}
            label='Primer apellido *'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            helperText={
              (errors.primerApellido?.type === 'required' &&
                'Este campo es obligatorio!') ||
              (errors.primerApellido?.type === 'pattern' &&
                'En este campo son solo permitidas letras!')
            }
            error={errors.primerApellido}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('segundoApellido', {pattern: /^[A-Za-z]+$/i})}
            label='Segundo apellido'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            helperText={
              errors.segundoApellido?.type === 'pattern' &&
              'En este campo son solo permitidas letras!'
            }
            error={errors.segundoApellido?.type === 'pattern'}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            type='date'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CalendarMonthOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('fechaNacimiento', {required: true})}
            label='Fecha nacimiento *'
            helperText={
              errors.fechaNacimiento?.type === 'required' && 'Este campo es obligatorio!'
            }
            error={errors.fechaNacimiento}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='sexo'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'FEMENINO', value: 'FEMENINO'},
                  {label: 'MASCULINO', value: 'MASCULINO'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Sexo *'
                    helperText={
                      errors.sexo?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.sexo}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='rh'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'AB+', value: 'AB+'},
                  {label: 'AB-', value: 'AB-'},
                  {label: 'A+', value: 'A+'},
                  {label: 'A-', value: 'A-'},
                  {label: 'B+', value: 'B+'},
                  {label: 'B-', value: 'B-'},
                  {label: 'O+', value: 'O+'},
                  {label: 'O-', value: 'O-'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='RH *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <HealthAndSafetyIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.rh?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.rh}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data.value
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='eps'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'COOSALUD EPS-S', value: 'COOSALUD EPS-S'},
                  {label: 'NUEVA EPS', value: 'NUEVA EPS'},
                  {label: 'MUTUAL SER', value: 'MUTUAL SER'},
                  {label: 'ALIANSALUD EPS', value: 'ALIANSALUD EPS'},
                  {label: 'SALUD TOTAL EPS S.A.', value: 'SALUD TOTAL EPS S.A.'},
                  {label: 'EPS SANITAS', value: 'EPS SANITAS'},
                  {label: 'EPS SURA', value: 'EPS SURA'},
                  {label: 'FAMISANAR', value: 'FAMISANAR'},
                  {
                    label: 'SERVICIO OCCIDENTAL DE SALUD EPS SOS',
                    value: 'SERVICIO OCCIDENTAL DE SALUD EPS SOS'
                  },
                  {label: 'SALUD MIA', value: 'SALUD MIA'},
                  {label: 'COMFENALCO VALLE', value: 'COMFENALCO VALLE'},
                  {label: 'COMPENSAR EPS', value: 'COMPENSAR EPS'},
                  {
                    label: 'EPM - EMPRESAS PUBLICAS DE MEDELLIN',
                    value: 'EPM - EMPRESAS PUBLICAS DE MEDELLIN'
                  },
                  {
                    label:
                      'FONDO DE PASIVO SOCIAL DE FERROCARRILES NACIONALES DE COLOMBIA',
                    value:
                      'FONDO DE PASIVO SOCIAL DE FERROCARRILES NACIONALES DE COLOMBIA'
                  },
                  {label: 'CAJACOPI ATLANTICO', value: 'CAJACOPI ATLANTICO'},
                  {label: 'CAPRESOCA', value: 'CAPRESOCA'},
                  {label: 'COMFACHOCO', value: 'COMFACHOCO'},
                  {
                    label: 'COMFAMILIAR DE LA GUAJIRA',
                    value: 'COMFAMILIAR DE LA GUAJIRA'
                  },
                  {label: 'COMFAORIENTE', value: 'COMFAORIENTE'},
                  {
                    label: 'EPS FAMILIAR DE COLOMBIA (Antes ComfaSucre)',
                    value: 'EPS FAMILIAR DE COLOMBIA (Antes ComfaSucre)'
                  },
                  {label: 'ASMET SALUD', value: 'ASMET SALUD'},
                  {label: 'ECOOPSOS ESS EPS-S', value: 'ECOOPSOS ESS EPS-S'},
                  {label: 'EMSSANAR E.S.S.', value: 'EMSSANAR E.S.S.'},
                  {label: 'CAPITAL SALUD EPS-S', value: 'CAPITAL SALUD EPS-S'},
                  {label: 'SAVIA SALUD EPS', value: 'SAVIA SALUD EPS'},
                  {label: 'DUSAKAWI EPSI', value: 'DUSAKAWI EPSI'},
                  {
                    label: 'ASOCIACION INDIGENA DEL CAUCA EPSI',
                    value: 'ASOCIACION INDIGENA DEL CAUCA EPSI'
                  },
                  {label: 'ANAS WAYUU EPSI', value: 'ANAS WAYUU EPSI'},
                  {label: 'MALLAMAS EPSI', value: 'MALLAMAS EPSI'},
                  {label: 'PIJAOS SALUD EPSI', value: 'PIJAOS SALUD EPSI'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='EPS *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <GiteIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.eps?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.eps}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='nivelAcademico'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'PREESCOLAR', value: 'PREESCOLAR'},
                  {label: 'PRIMARIA', value: 'PRIMARIA'},
                  {label: 'BACHILLERATO', value: 'BACHILLERATO'},
                  {label: 'TECNICA', value: 'TECNICA'},
                  {label: 'TECNOLOGIA', value: 'TECNOLOGIA'},
                  {label: 'UNIVERSITARIA', value: 'UNIVERSITARIA'},
                  {label: 'ESPECIALIZACION', value: 'ESPECIALIZACION'},
                  {label: 'POSTGRADO', value: 'POSTGRADO'},
                  {label: 'MAESTRIA', value: 'MAESTRIA'},
                  {label: 'DOCTORADO', value: 'DOCTORADO'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <SchoolOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    label='Nivel académico *'
                    helperText={
                      errors.nivelAcademico?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.nivelAcademico}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('tituloObtenido')}
            label='Titulo obtenido'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SchoolOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='estadoCivil'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'Soltero(a)', value: 'Soltero(a)'},
                  {label: 'Casado(a)', value: 'Casado(a)'},
                  {
                    label: 'Unión libre o unión de hecho',
                    value: 'Unión libre o unión de hecho'
                  }
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Estado civil *'
                    helperText={
                      errors.estadoCivil?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.estadoCivil}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='hijos'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'SI', value: 'SI'},
                  {label: 'NO', value: 'NO'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='¿Tiene hijos? *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <ChildFriendlyOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.hijos?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.hijos}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('nombreContactoEmergencia', {
              required: true,
              pattern: /^[A-Za-z ]+$/i
            })}
            label='Nombres y apellidos del contacto de emergencia *'
            helperText={
              (errors.nombreContactoEmergencia?.type === 'required' &&
                'Este campo es obligatorio!') ||
              (errors.nombreContactoEmergencia?.type === 'pattern' &&
                'En este campo son solo permitidas letras!')
            }
            error={errors.nombreContactoEmergencia}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='celularContactoEmergencia'
            rules={{
              required: true,
              pattern: /^\+57[0-9]+$/i,
              maxLength: 13,
              minLength: 12
            }}
            control={control}
            render={({field: {onChange}}) => (
              <MuiTelInput
                name='celularContactoEmergencia'
                label='Celular de Contacto de emergencia *'
                defaultCountry='co'
                value={phoneContacto}
                helperText={
                  (errors.celularContactoEmergencia?.type === 'required' &&
                    'Este campo es obligatorio!') ||
                  (errors.celularContactoEmergencia?.type === 'pattern' &&
                    'En este campo son solo permitidos caracteres numericos!') ||
                  (errors.celularContactoEmergencia?.type === 'maxLength' &&
                    'Un celular debe tener 10 caracteres numericos!') ||
                  (errors.celularContactoEmergencia?.type === 'minLength' &&
                    'Un celular debe tener 10 caracteres numericos!')
                }
                error={errors.celularContactoEmergencia}
                onChange={(_, data) => {
                  setPhoneContacto(data.numberValue === null ? '57' : data.numberValue)
                  onChange(data.numberValue === null ? '57' : data.numberValue)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BadgeOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('cedula', {required: true, pattern: /^[0-9]+$/i})}
            label='Cedula *'
            helperText={
              (errors.cedula?.type === 'required' && 'Este campo es obligatorio!') ||
              (errors.cedula?.type === 'pattern' &&
                'En este campo son solo permitidos caracteres numericos!')
            }
            error={errors.cedula}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            type='date'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CalendarMonthOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('fechaIngreso', {required: 'Este campo es obligatorio!'})}
            label='Fecha Ingreso *'
            helperText={errors?.fechaIngreso?.message}
            error={Boolean(errors?.fechaIngreso)}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='procesos'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                multiple
                options={procesoOptions}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value || value === ''
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Procesos'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <AccountTreeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.procesos?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.procesos}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='subProcesos'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                multiple
                options={subProcesoOptions}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value || value === ''
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Sub-Procesos'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <AccountTreeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.subProcesos?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.subProcesos}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='cargoIdCargo'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={positionsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Cargo *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <EngineeringOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.cargoIdCargo?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.cargoIdCargo}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='lider'
            control={control}
            rules={{required: 'Este campo es obligatorio!'}}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={employeesOptions}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value || value === ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Líder'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={errors.lider?.message}
                    error={errors.lider}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='interventoreIdInterventor'
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={auditorsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Interventor'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.interventoreIdInterventor?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.interventoreIdInterventor}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='directoreIdDirector'
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={directorsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Director'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.directoreIdDirector?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.directoreIdDirector}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='teletrabajo'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'SI', value: 'SI'},
                  {label: 'NO', value: 'NO'}
                ]}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value || value === ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Teletrabajo *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <HomeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.teletrabajo?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.teletrabajo}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='departamento'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={departamentos}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value || value === ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Departamento *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <LocationOnOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.departamento?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.departamento}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  handleChange(_, data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='ciudad'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={citiesOptions}
                value={resetCityValue}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value || value === ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Ciudad *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <LocationOnOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.ciudad?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.ciudad}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  setResetCityValue(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='celular'
            rules={{
              required: true,
              pattern: /^\+57[0-9]+$/i,
              maxLength: 13,
              minLength: 12
            }}
            control={control}
            render={({field: {onChange}}) => (
              <MuiTelInput
                label='Celular *'
                defaultCountry='co'
                value={phone}
                helperText={
                  (errors.celular?.type === 'required' && 'Este campo es obligatorio!') ||
                  (errors.celular?.type === 'pattern' &&
                    'En este campo son solo permitidos caracteres numericos!') ||
                  (errors.celular?.type === 'maxLength' &&
                    'Un celular debe tener 10 caracteres numericos!') ||
                  (errors.celular?.type === 'minLength' &&
                    'Un celular debe tener 10 caracteres numericos!')
                }
                error={errors.celular}
                onChange={(_, data) => {
                  setPhone(data.numberValue === null ? '57' : data.numberValue)
                  onChange(data.numberValue === null ? '57' : data.numberValue)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='indicadorFijo'
            rules={{required: fijoRequerido}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: '601 -Cundinamarca y Bogotá D.C.', value: '601'},
                  {label: '602 - Cauca, Nariño y Valle.', value: '602'},
                  {label: '604 - Antioquia, Córdoba y Chocó.', value: '604'},
                  {
                    label:
                      '605 - Atlántico, Bolívar, César, La Guajira, Magdalena y Sucre.',
                    value: '605'
                  },
                  {label: '606 - Caldas, Quindío y Risaralda.', value: '606'},
                  {
                    label: '607 - Arauca, Norte de Santander y Santander.',
                    value: '607'
                  },
                  {
                    label:
                      '608 - Amazonas, Boyacá, Casanare, Caquetá, Guaviare, Guainía, Huila, Meta, Tolima, Putumayo, San Andrés, Vaupés y Vichada.',
                    value: '608'
                  }
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={labelfijo[0]}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <LocalPhoneOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.indicadorFijo?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.indicadorFijo}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  cambioFijo(_, data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LocalPhoneOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('telefonoFijo', {
              required: fijoRequerido,
              pattern: /^[0-9]+$/i,
              maxLength: 7,
              minLength: 7
            })}
            label={labelfijo[1]}
            onChange={(event) => cambioFijo(event)}
            helperText={
              (errors.telefonoFijo?.type === 'required' &&
                'Este campo es obligatorio!') ||
              (errors.telefonoFijo?.type === 'pattern' &&
                'En este campo son solo permitidos caracteres numericos!') ||
              (errors.telefonoFijo?.type === 'maxLength' &&
                'Un teléfono fijo debe tener 7 caracteres numericos!') ||
              (errors.telefonoFijo?.type === 'minLength' &&
                'Un teléfono fijo debe tener 7 caracteres numericos!')
            }
            error={errors.telefonoFijo}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('usuarioRedAxity', {required: true})}
            label='Usuario de red Axity *'
            helperText={
              errors.usuarioRedAxity?.type === 'required' && 'Este campo es obligatorio!'
            }
            error={errors.usuarioRedAxity}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('correoAxity', {
              required: true,
              pattern:
                /^(([^<>()[\]\.,:\s@\']+(\.[^<>()[\]\.,:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,:\s@\']+\.)+[^<>()[\]\.,:\s@\']{2,})$/
            })}
            label='Correo Axity *'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            helperText={
              (errors.correoAxity?.type === 'pattern' &&
                'Debe ingresar una dirección de correo valida!') ||
              (errors.correoAxity?.type === 'required' && 'Este campo es obligatorio!')
            }
            error={errors.correoAxity}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='carnetSnack'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'SI', value: 'SI'},
                  {label: 'NO', value: 'NO'}
                ]}
                name='carnetSnack'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'¿Tiene carnet de snacks Axity? *'}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <BadgeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.carnetSnack?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.carnetSnack}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  cambioCarnetSnacksAxity(_, data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BadgeOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('numeroCarnetSnack', {required: valCarnetSnacksAxity[0]})}
            label={valCarnetSnacksAxity[1]}
            onChange={(event) => {
              if (event.target.value.length > 0) {
                setCarnetSnacksAxity([true, 'Número carnet snacks Axity'])
                clearErrors('numeroCarnetSnacksAxity')
                setValue('numeroCarnetSnack', event.target.value)
              }
            }}
            helperText={
              errors.numeroCarnetSnacksAxity?.type === 'required' &&
              'Este campo es obligatorio!'
            }
            error={errors.numeroCarnetSnacksAxity}
            name='numeroCarnetSnacksAxity'
            disabled={!valCarnetSnacksAxity[0]}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('usuarioRedNutresa')}
            label='Usuario de red Nutresa'
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('correoNutresa', {
              pattern:
                /^(([^<>()[\]\.,:\s@\']+(\.[^<>()[\]\.,:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,:\s@\']+\.)+[^<>()[\]\.,:\s@\']{2,})$/
            })}
            label='Correo Nutresa'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            helperText={
              errors.correoNutresa?.type === 'pattern' &&
              'Debe ingresar una dirección de correo valida!'
            }
            error={errors.correoNutresa?.type === 'pattern'}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='carnetNutresa'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'SI', value: 'SI'},
                  {label: 'NO', value: 'NO'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'¿Tiene carnet de Nutresa? *'}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <BadgeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.carnetNutresa?.type === 'required' &&
                      'Este campo es obligatorio!'
                    }
                    error={errors.carnetNutresa}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  cambioCarnetNutresa(_, data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BadgeOutlinedIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('numeroCarnetNutresa', {required: valCarnetNutresa[0]})}
            label={valCarnetNutresa[1]}
            helperText={
              errors.numeroCarnetNutresa?.type === 'required' &&
              'Este campo es obligatorio!'
            }
            error={errors.numeroCarnetNutresa}
            name='numeroCarnetNutresa'
            disabled={!valCarnetNutresa[0]}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AttachMoneyIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('salario', {required: true, pattern: /^[0-9]+$/i})}
            label='Salario *'
            helperText={
              (errors.salario?.type === 'required' && 'Este campo es obligatorio!') ||
              (errors.salario?.type === 'pattern' &&
                'En este campo son solo permitidos caracteres numericos!')
            }
            error={errors.salario}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AttachMoneyIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('valorDelServicio', {pattern: /^[0-9]+$/i})}
            label='Valor del servicio *'
            name='valorDelServicio'
            helperText={
              (errors.valorDelServicio?.type === 'required' &&
                'Este campo es obligatorio!') ||
              (errors.valorDelServicio?.type === 'pattern' &&
                'En este campo son solo permitidos caracteres numericos!')
            }
            error={errors.valorDelServicio}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            name='costoempleado'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AttachMoneyIcon style={{color: '#3C1053'}} />
                </InputAdornment>
              )
            }}
            {...register('costoempleado', {required: true, pattern: /^[0-9]+$/i})}
            label='Costo empleado *'
            helperText={
              (errors.costoempleado?.type === 'required' &&
                'Este campo es obligatorio!') ||
              (errors.costoempleado?.type === 'pattern' &&
                'En este campo son solo permitidos caracteres numericos!')
            }
            error={errors.costoempleado}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='pep'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                multiple
                options={Pep}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Pep *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <AccountTreeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.pep?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.pep}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='rol'
            rules={{required: true}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                name='rol'
                disablePortal
                multiple
                options={[
                  {label: 'Administrativo', value: '1'},
                  {label: 'Técnico', value: '2'},
                  {label: 'Colaborador', value: '3'}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Rol *'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <AccountTreeOutlinedIcon style={{color: '#3C1053'}} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                    helperText={
                      errors.rol?.type === 'required' && 'Este campo es obligatorio!'
                    }
                    error={errors.rol}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data)
                  return data
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('email', {
              required: true,
              pattern:
                /^(([^<>()[\]\.,:\s@\']+(\.[^<>()[\]\.,:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,:\s@\']+\.)+[^<>()[\]\.,:\s@\']{2,})$/
            })}
            label='Correo personal *'
            name='email'
            InputProps={{startAdornment: <PersonOutlineOutlinedIcon />}}
            helperText={errors.email?.message}
            error={Boolean(errors.email)}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            name='tieneMembresia'
            rules={{
              validate: (value) =>
                typeof value === 'boolean' || 'Este campo es obligatorio!'
            }}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={[
                  {label: 'SI', value: true},
                  {label: 'NO', value: false}
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'¿Tiene membresia Axity? *'}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <BadgeOutlinedIcon />
                    }}
                    helperText={errors.tieneMembresia?.message}
                    error={Boolean(errors.tieneMembresia)}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data.value)
                  if (!data.value) {
                    setValue('serialMembresia', null)
                    clearErrors('serialMembresia')
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            {...register('serialMembresia', {
              required: tieneMembresia ? 'Este campo es obligatorio!' : false
            })}
            disabled={!tieneMembresia}
            InputProps={{startAdornment: <BadgeOutlinedIcon />}}
            label='Serial Membresia'
            helperText={errors?.serialMembresia?.message}
            error={Boolean(errors.serialMembresia)}
          />
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{
            marginTop: 2
          }}
        >
          <Button component='label' sx={{marginTop: 5, width: 200, marginLeft: 4}}>
            Cargar foto de persona
            <input
              {...register('foto', {required: true, pattern: /\.(jpg|png|gif)$/i})}
              hidden
              accept='image/*'
              type='file'
              onChange={(event, value) => uploadImage(event, value)}
            />
          </Button>
          <Avatar
            sx={{marginTop: 5, width: 150, marginLeft: 4, height: 150}}
            alt='Persona'
            src={personImage}
          />
          {errors.foto?.type === 'required' && (
            <Alert sx={{width: 500}} severity='error'>
              Debes cargar una imagen!
            </Alert>
          )}
          {fileValidate && (
            <Alert sx={{width: 500}} severity='error'>
              En este campo son permitidas las imagenes jpg,png o gif!
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <CircularProgress
            sx={{
              display: displayLoading
            }}
          />
          {sendError[0] ? (
            <Alert severity='error'>Ocurrio un error: {sendError[1]}</Alert>
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      <Button
        type='submit'
        color='success'
        sx={{marginTop: 5, marginBottom: 5, width: 100, marginLeft: 3}}
        onClick={() => {
          console.log(errors)
        }}
      >
        Crear
      </Button>
      <Button
        color='error'
        sx={{marginTop: 5, marginBottom: 5, width: 100, marginLeft: 3}}
        onClick={close}
      >
        Cancelar
      </Button>
    </form>
  )
}

export default Create
