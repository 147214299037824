import {Typography, Grid} from '@mui/material'
import MaterialReactTable from 'material-react-table'
import {useMemo} from 'react'
import {useTheme} from '@emotion/react'
import {useAppContext} from '../../context/DirectoryContex'

const cellProps = {
  color: 'black',
  fontSize: 14,
  noWrap: true,
  margin: '0.1rem'
}

export const TableProcess = ({setOption, columnFilters, isLoading}) => {
  const {palette} = useTheme()
  const {setProcess, processes} = useAppContext()
  const columns = useMemo(
    () => [
      {
        accessorKey: 'IdProceso',
        header: 'Id Proceso',
        key: 'IdProceso',
        maxSize: 2,
        minSize: 1,
        Cell: ({row}) => (
          <Typography {...cellProps} pl={1}>
            {row.index + 1}.
          </Typography>
        )
      },
      {
        id: 'NombreProceso',
        accessorKey: 'NombreProceso',
        header: <Typography>Nombre Proceso</Typography>,
        Cell: ({cell}) => <Typography {...cellProps}>{cell.getValue()}</Typography>
      },
      {
        id: 'direccionName',
        accessorKey: 'direccionName',
        header: <Typography>Dirección</Typography>,
        Cell: ({cell}) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      },
      {
        id: 'jefaturaName',
        accessorKey: 'jefaturaName',
        header: <Typography>Jefatura</Typography>,
        Cell: ({cell}) => (
          <Typography sx={{marginLeft: 2}} {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      },

      {
        id: 'subproceso',
        header: <Typography>Sub-procesos</Typography>,
        Cell: ({row}) => (
          <>
            {row?.original?.subprocesos.map((ele) => {
              return (
                <Typography sx={{marginLeft: 2}} {...cellProps} noWrap={false}>
                  {ele?.Nombre}
                </Typography>
              )
            })}
          </>
        )
      },

      {
        id: 'tipo',
        accessorKey: 'Tipo',
        header: <Typography>Tipo</Typography>,
        Cell: ({cell}) => (
          <Typography sx={{marginLeft: 2}} {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      },

      {
        id: 'estado',
        accessorKey: 'estado'
      }
    ],
    []
  )

  return (
    <Grid item xs={12}>
      <MaterialReactTable
        columns={columns}
        data={processes}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        initialState={{
          isLoading: true,
          columnVisibility: {estado: false}
        }}
        state={{isLoading, columnFilters}}
        muiTableBodyProps={{
          sx: {
            borderCollapse: 'separate',
            border: `0.6rem solid ${palette.background.main}`
          }
        }}
        muiTableBodyRowProps={({row}) => ({
          onClick: () => {
            setOption('individual')
            setProcess(row.original)
          },
          sx: {
            cursor: 'pointer',
            borderCollapse: 'separate',
            backgroundColor: row.index % 2 === 0 ? 'white' : palette.background.main
          }
        })}
        muiTableBodyCellProps={{sx: {padding: '0', border: 'none'}}}
      />
    </Grid>
  )
}
