import axios from "axios"

export const GetAllAdminPep=async()=>{
   const request=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/adminpep/all")
   const data= await request.data
   return data
}



export const CreateAdminPep=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/adminpep/create", object)
    const data= await response.data
    return data
}

export const UpdateStateAdminPep=async(id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/adminpep/"+id)
    const data= await response.data
    return data
}

export const UpdateAdminPep=async(object,id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/adminpep/update/"+id,object)
    const data= await response.data
    return data
}

export const GetAdminPep=async(id)=>{
  const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/adminpep/"+id)    
  const data = await response.data
  return data
}