import React from 'react'
import {Box, Typography, Grid} from '@mui/material'

export const Label = ({Icon, children}) => {
  return (
    <Box display='flex' alignItems='center' minHeight='2.3rem'>
      <Grid sx={{padding: '0 6px'}}>{Icon}</Grid>
      <Typography fontSize={18} color='GrayText'>
        {children}
      </Typography>
    </Box>
  )
}

export default Label
