
import { Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { TitleFirts } from "../ComponentsUtilies/Titles";
import {
  AlertSuccess,
  AlertErrorHTML,
} from "../ComponentsUtilies/AlertsModal/Alerts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Auth } from "aws-amplify";
const Alert = withReactContent(Swal);

const NewPassword = ({ usuario }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
   const updatedUser = await Auth.completeNewPassword(usuario, data.contrasena)
    .then(value=>AlertSuccess())
    .catch(error=>AlertErrorHTML(`- La contraseña debe tener un mínimo de 8 caracteres.</p>
    <p>- La contraseña debe contener al menos un número.</p>
    <p>- La contraseña debe contener al menos una mayúscula.</p>
    <p>- La contraseña debe contener al menos una minúscula.</p>
    <p>- La contraseña debe contener al menos un caracter especial.</p>
    <p>${error}</p>`))
      
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <TitleFirts>Por favor ingresa una nueva contraseña</TitleFirts>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Contraseña nueva"
                type="password"
                {...register("contrasena", { required: true })}
                sx={{ marginTop: 2,  width:"90%" }}
                helperText={
                  errors.contrasena?.type === "required" &&
                  "Este campo es obligatorio!"
                }
                error={errors.contrasena?.type === "required" && true}
              />
            </Grid>

            
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="success"
          sx={{ marginTop: 5,marginBottom: 1, width: 100, marginLeft: 3 }}
          type="submit"
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ marginTop: 5,marginBottom: 1, width: 112, marginLeft: 3 }}
          onClick={() => Alert.close()}
        >
          Cancelar
        </Button>
      </form>
    </>
  );
};

export { NewPassword };


