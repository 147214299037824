export const mekeTree = (empleados, processId) => {
  const dataTree = empleados.reduce(
    (acum, employee, idx, allEmployees) => {
      // Si el empleado esta inactivo no se incluye
      if (!Boolean(employee.estado)) return acum
      let {root, leaders, subprocess} = acum
      const lider = employee.lider

      // agregar sobprocesos
      employee.subprocesos.forEach((sp) => {
        if (!subprocess) return
        if (sp.procesoIdProceso !== processId) return
        const subproceso = subprocess.find((s) => s.id === sp.IdSubProceso)

        // se agrega el empleado a un listado para cada supproceso
        if (!subproceso) {
          subprocess.push({
            id: sp.IdSubProceso,
            nombre: sp.Nombre,
            descripcion: sp.Descripcion,
            employees: [idx]
          })
        } else subproceso.employees.push(idx)
      })

      // si la cedula del lider no esta en el acumulado se agrega
      if (!leaders[lider]) {
        leaders[lider] = []
        // si la el linder no esta dentro de los empleados el empleado es el rood
        if (!allEmployees.find((e) => e.cedula.toString() === lider)) root = idx
      }
      // buscar el root y guardarlo en el estado
      if (employee.cedula.toString() === lider) root = idx
      // agregar el index del empleado actual a su respectivo lider
      if (root !== idx) leaders[lider].push(idx)
      return {root, leaders, subprocess}
    },
    {root: null, leaders: {}, subprocess: []}
  )
  return [dataTree.root, dataTree.leaders, dataTree.subprocess]
}
