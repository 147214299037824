import React from 'react'
import { CardMedia } from '@mui/material'
import iconMan from './iconMan.svg'

export const IconMan = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconMan}
    />)
}
export default IconMan 