import React from 'react'
import { Controller } from 'react-hook-form'
import { Grid, TextField, Autocomplete } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export const Followup = ({ collaborators, previous, register, control, errors }) => {

  return (<Grid container spacing={1}>

    <Grid item xs={12} >
      <TextField sx={{ width: { xs: '80%', md: '90%' } }}
        label='Compromiso *'
        multiline
        rows={5}
        {...register(`${previous}.comentario`, { required: 'Este campo es obligatorio!' })}
        helperText={errors.comentario?.message}
        error={errors.comentario}
      />
    </Grid>

    <Grid item xs={12} md={6} >
      <Controller
        name={`${previous}.proximaReunion`}
        rules={{ required: 'Este campo es obligatorio!' }}
        control={control}
        render={({ field: { onChange, value, name } }) =>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label='Proxima reunion *'
              onChange={(moment) => {
                onChange({ target: { name, value: moment?.format('YYYY-MM-DD HH:mm') } })
              }}
              renderInput={(params) => {
                params.inputProps.value = value?.replaceAll('-', '/')
                params.inputProps.placeholder = 'aaaa/mm/dd hh:mm'
                return <TextField sx={{ width: '80%' }}
                  {...params}
                  helperText={errors.proximaReunion?.message}
                  error={errors.proximaReunion}
                />
              }}
            />
          </LocalizationProvider>
        }
      />
    </Grid>

    <Grid item xs={12} md={6} >
      <TextField sx={{ width: '80%' }}
        label='Lugar *'
        {...register(`${previous}.lugar`, { required: 'Este campo es obligatorio!' })}
        helperText={errors.lugar?.message}
        error={errors.lugar}
      />
    </Grid>

    <Grid item xs={12} md={6} >
      <Controller
        name={`${previous}.responsables`}
        rules={{ required: 'Este campo es obligatorio!' }}
        control={control}
        render={({ field: { onChange, name } }) =>
          <Autocomplete
            disablePortal
            multiple
            options={collaborators}
            onChange={(_, data) => onChange(data)}
            renderInput={(params) =>
              <TextField sx={{ width: '80%' }}
                {...params}
                label='Responsables *'
                helperText={errors.responsables?.message}
                error={errors.responsables}
              />
            }
          />
        }
      />
    </Grid>

  </Grid>)
}

export default Followup
