import axios from "axios";


export const GetAllPositions=async()=>{
    const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/positions/all")
    const data= await response.data
    return data
}

export const CreatePositions=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/positions/create", object)
    const data= await response.data
    return data
}

export const UpdateStatePositions=async(id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/positions/"+id)
    const data= await response.data
    return data
}

export const UpdatePositions=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/positions/update",object)
    const data= await response.data
    return data
}

export const GetPositions=async(id)=>{
  const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/positions/"+id)    
  const data = await response.data
  return data
}