import * as React from 'react'
import './items.css'
import { Typography } from '@mui/material'
import { Edit } from './Edit'
import EditIcon from '@mui/icons-material/Edit'
import { getAlertModal } from '../../hooks/getAlertModal'

export const Item = ({ data, setLoad }) => {
  const { nombre, descripcion, imagen, enlace } = data

  const handleHover = (el) => {
    let todosInformes = el.target.parentNode.getElementsByClassName('carousel-item')
    todosInformes = Array.from(todosInformes)
    todosInformes.forEach((ele) => {
      ele.style.opacity = '0.3'
    })
    el.target.style.opacity = '1'

  }

  const handleOut = (el) => {
    let todosInformes = el.target.parentNode.getElementsByClassName('carousel-item')
    todosInformes = Array.from(todosInformes)
    todosInformes.forEach((ele) => {
      ele.style.opacity = '1'
    })

    el.target.style.style.opacity = '1'
  }

  const goTopage = (el, enlace) => {
    if (
      !Array.from(el.target?.classList).find(
        (element) => element === 'editIconItem'
      )
    ) {
      window.open(enlace, '_blank')
    }
  }

  return (
    <>
      <div
        className='carousel-item'
        style={{
          display: 'flex !important',
          width: '220px',
          height: '370px',
        }}
        onMouseOver={(e) => handleHover(e)}
        onMouseOut={(e) => handleOut(e)}
        onClick={(e) => goTopage(e, enlace)}
      >
        <div
          className='card text-center m-1'
          style={{
            width: '220px',
            height: '370px',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '10px 10px 10px 10px',
            opacity: 1,
          }}
        >
          <div
            className='card-header p-0'
            style={{
              height: '141px',
              backgroundColor: 'gray',
              backgroundImage: `url('${imagen}')`,
              backgroundSize: 'cover',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          ></div>
          <div
            className='card-body p-0'
            style={{ height: '65px', backgroundColor: 'rgb(60,16,83)' }}
          >
            <Typography
              ms={8}
              variant='h6'
              component='h2'
              sx={{ textAlign: 'center', color: 'white' }}
            >
              {nombre}
            </Typography>

            <span style={{ float: 'right' }} className='mr-2 iconoFavorito'>
              <svg
                className='iconoFavorito'
                width='20'
                height='20'
                viewBox='0 0 39 39'
              >
                <g className='iconoFavorito'>
                  <path
                    className='iconoFavorito'
                    fill='green'
                    data-name='Icon material-favorite'
                    d='M13.773,24.269l-1.562-1.422C6.663,17.816,3,14.5,3,10.425A5.868,5.868,0,0,1,8.925,4.5a6.452,6.452,0,0,1,4.848,2.252A6.452,6.452,0,0,1,18.621,4.5a5.868,5.868,0,0,1,5.925,5.925c0,4.072-3.663,7.391-9.211,12.432Z'
                    transform='translate(1263 27.5)'
                  />
                  <g
                    className='iconoFavorito'
                    data-name='Elipse 1'
                    transform='translate(1257 22)'
                    fill='none'
                    stroke='green'
                    strokeWidth='2'
                  >
                    <circle
                      className='iconoFavorito'
                      cx='19.5'
                      cy='19.5'
                      r='19.5'
                      stroke='none'
                    />
                    <circle
                      className='iconoFavorito'
                      cx='19.5'
                      cy='19.5'
                      r='18.5'
                      fill='none'
                    />
                  </g>
                </g>
              </svg>
            </span>
          </div>
          <div className='card-footer text-muted' style={{ height: '89px' }}>
            <Typography
              ms={8}
              variant='h6'
              sx={{ textAlign: 'center', color: 'black', fontSize: '15px' }}
            >
              {descripcion}
            </Typography>
            <span
              style={{
                position: 'absolute',
                right: '2px',
                bottom: '12px',
                zIndex: 3,
              }}
              className='editIconItem'
            >
              <EditIcon
                className='editIconItem'
                onClick={(e) => {
                  e.stopPropagation()
                  getAlertModal(Edit, { setLoad, data })(e)
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Item
