import axios from "axios";
import {UpdateStatePositions} from "../../services/positionsService"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"

export const UpdatePositionsState=(id, load)=>{
 
   UpdateStatePositions(id).then(data=>{
       if(data.idCargo != null ){
         AlertSuccess()
          load(true)
       }
    }).catch(error=>{console.log(error)}) 


}