import { SvgIcon } from "@mui/material";
export const IconFather = (props) => {
  return (
    
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width={`${props.width}pt`}
        height={`${props.height}pt`}
        viewBox="0 0 71.000000 70.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)"
          fill={props.color}
          stroke="none"
        >
          <path d="M28 624 c-22 -7 -23 -37 -3 -54 8 -7 20 -30 26 -51 19 -62 49 -83 128 -87 61 -4 70 -2 98 22 18 15 37 43 44 64 6 20 17 40 23 44 14 9 36 -10 36 -31 0 -23 35 -73 62 -87 29 -17 125 -17 158 -1 14 7 36 35 51 65 14 29 31 58 37 64 7 7 12 20 12 30 0 25 -54 32 -194 23 -133 -8 -134 -8 -326 -1 -74 3 -143 2 -152 0z m226 -14 c44 -10 56 -23 56 -62 0 -70 -46 -108 -129 -108 -69 0 -111 36 -118 101 -6 49 4 64 47 72 44 8 104 7 144 -3z m349 -1 c21 -5 41 -17 44 -25 10 -27 -9 -87 -37 -116 -25 -25 -36 -28 -89 -28 -81 0 -112 25 -118 97 -6 55 -1 61 51 74 47 11 106 10 149 -2z" />
          <path d="M249 362 c-138 -92 -138 -93 -159 -67 -10 12 -10 18 0 30 7 9 19 14 27 11 7 -3 13 -1 13 4 0 17 -29 11 -50 -10 -26 -26 -25 -42 6 -71 22 -21 37 -24 112 -27 68 -2 95 1 122 15 33 17 37 17 70 1 54 -28 177 -25 221 5 42 28 48 59 17 81 -25 18 -48 21 -48 6 0 -5 6 -7 13 -4 8 3 20 -2 27 -11 10 -12 10 -18 -1 -31 -18 -22 -53 -10 -112 37 -58 46 -90 56 -128 42 -15 -6 -33 -7 -40 -2 -22 14 -62 10 -90 -9z" />
        </g>
      </svg>
    
  );
};
