import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Tree, TreeNode} from 'react-organizational-chart'
import {Typography, Grid, Box, Card, CardContent} from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import {procesEployees} from '../../../services/processService'
import {TitleFirts} from '../../ComponentsUtilies/Titles'
import {EmployeeTree} from './EmployeeTree'
import {SubprocesTree} from './SubprocesTree'
import {mekeTree} from './mekeTree'

export const EmployeesFlowchart = () => {
  const {processId} = useParams()
  const [show, setShow] = useState({})
  const [employees, setEmployees] = useState([])
  const [leaders, setLeaders] = useState({})
  const [root, setRoot] = useState(null)
  const [process, setProcess] = useState('')
  const [management, setManagement] = useState({})
  const [headship, setHeadship] = useState({})
  const [subprocess, setSubprocess] = useState([])
  const navigate = useNavigate()
  const backPage = () => navigate(-1)

  useEffect(() => {
    procesEployees(processId)
      .then((data) => {
        if (!data) return
        setEmployees(data.empleados)
        setProcess(data.NombreProceso)
        setManagement({
          name: data.direccion.nombreDireccion,
          director: data.direccion.directore.nombre
        })
        setHeadship({
          name: data.jefatura.nombreJefatura,
          auditor: data.jefatura.interventore.nombre
        })
        const [newRoot, newLeaders, newSubprocess] = mekeTree(data.empleados, data.id)

        setRoot(newRoot)
        setLeaders(newLeaders)
        setSubprocess(newSubprocess)
        setShow((s) => ({...s, [newRoot]: true}))
      })
      .catch((error) => console.error(error))
  }, [processId])

  useEffect(() => {
    let xy = null
    const handleMouseDown = (event) => {
      const {x, y} = event
      xy = {x, y}
    }
    const handleMouseMove = (event) => {
      if (xy) {
        const {x, y} = event
        window.scrollBy(xy.x - x, xy.y - y)
        xy = {x, y}
      }
    }
    const handleMouseSeup = () => {
      xy = null
    }
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseSeup)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseSeup)
    }
  }, [])

  const GetChildren = (idx, employyesSubp, spId) => {
    const getChildIds = (id) => leaders[employees[id].cedula.toString()]
    if (!employyesSubp) return []
    const children = getChildIds(idx)
    if (children) {
      return children.reduce((acum, id) => {
        if (!employyesSubp.includes(id)) return acum
        const swowId = `${spId}E${id}`
        acum.push(
          <TreeNode
            key={id}
            label={
              <EmployeeTree
                employee={employees[id]}
                idx={id}
                show={show[swowId]}
                setShow={(state) => setShow((s) => ({...s, [swowId]: state}))}
                isLoader={Boolean(getChildIds(id))}
              />
            }
          >
            {show && show[swowId] && GetChildren(id, employyesSubp, spId)}
          </TreeNode>
        )
        return acum
      }, [])
    } else return []
  }

  return (
    <>
      <TitleFirts sx={{marginTop: '-70px'}}>
        <Grid
          item
          xs={12}
          container
          direction='row'
          alignItems='center'
          sx={{marginTop: '70px'}}
        >
          <ArrowBackIosNewOutlinedIcon
            sx={{
              color: '#3C1053',
              cursor: 'pointer'
            }}
            fontSize='large'
            onClick={() => backPage()}
          />
        </Grid>
        Organigrama
      </TitleFirts>
      <Grid
        item
        xs={12}
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Typography xs={12} variant='h6' component='h2'>
          {process}
        </Typography>
      </Grid>

      {employees.length > 0 ? (
        <Tree
          lineWidth='6px'
          lineColor='rgb(60, 16, 83)'
          lineBorderRadius='20px'
          label={
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Card
                sx={{
                  boxShadow: '0px 9px 20px rgb(60, 16, 83)',
                  backgroundColor: 'rgba(120, 60, 189, 0.4)'
                }}
                raised={true}
              >
                <CardContent sx={{padding: '6px'}}>
                  <Box>
                    <Typography sx={{fontWeight: 'bold'}}>{management.name}</Typography>
                    <Typography color='black' sx={{fontSize: 12, marginBottom: '0'}}>
                      {management.director}
                    </Typography>
                    <Typography sx={{fontWeight: 'bold'}}>{headship.name}</Typography>
                    <Typography color='black' sx={{fontSize: 12, marginBottom: '0'}}>
                      {headship.auditor}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          }
        >
          <TreeNode
            key={root}
            label={
              <EmployeeTree
                employee={employees[root]}
                idx={root}
                show={show[root]}
                setShow={(state) => setShow((s) => ({...s, [root]: state}))}
                isLoader={true}
              />
            }
          >
            {subprocess &&
              show &&
              show[root] &&
              subprocess.reduce((acum, sp) => {
                const spId = `sp${sp.id}`
                acum.push(
                  <SubprocesTree
                    key={sp.id}
                    sp={sp}
                    show={show[spId]}
                    setShow={(state) => setShow((s) => ({...s, [spId]: state}))}
                  >
                    {show && show[spId] && GetChildren(root, sp.employees, spId)}
                  </SubprocesTree>
                )
                return acum
              }, [])}
          </TreeNode>
        </Tree>
      ) : (
        <></>
      )}
    </>
  )
}
