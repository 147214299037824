import { Grid, TextField, Button,InputAdornment,FormLabel, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"
import { TitleFirts, TitleSecond } from "../ComponentsUtilies/Titles"
import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'
import { useForm } from "react-hook-form"
import { UpdateCategory,GetCategory } from "../../services/categoryService"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts.js"
import {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"


const Alert = withReactContent(Swal)

export const AlertModalForm = ({id,load}) => {
  
  const { register, handleSubmit, reset ,formState: { errors }, setValue} = useForm()
  const [marcaState,setMarca]=useState()
  const [placaState, setPlaca]=useState()
  const [serialState,setSerial]=useState()
  const [categoria,setCategoria]=useState('')
  useEffect(()=>{
    GetCategory(id).then(res=>{
      console.log(res,"categoria obtenida")
      let campos=res.validaciones.validaciones.campos
       campos.forEach(e=>{
         const {nombre,requerido}=e
         if(nombre == "marca"){
          
          setMarca(requerido)
         }else if(nombre == "serial"){
           setSerial(requerido) 
         }else{
             setPlaca(requerido)
         }
       })

      setCategoria(res)
    }).catch(error=>console.log("errores",error))         
  },[])

  const onSubmit = (valores) => {
    const {marca,serial,placa,tipo,capacidad}=valores
    const validaciones={
       "validaciones":{
         "campos":[{"nombre":"marca","requerido":marca},{"nombre":"serial", "requerido":serial},{"nombre":"placa","requerido":placa},{"nombre":"tipo", "requerido":tipo},{"nombre":"capacidad","requerido":capacidad}]
       }
    }
    const envioEditar={
       "idCategory": id,
       "nombreCategoria": valores.nombreCategoria,
       "validaciones":validaciones
    }
    
    
    UpdateCategory(envioEditar).then(res => {
    
      if (res.IdCategoria != null) {
        Alert.close()
        load(true)
        AlertSuccess();
        //navigate("/Categorias")
       

      }
    }).catch(error => console.log(error))
    
    reset()
    
  }

  console.log(categoria, "categoria obtend")
  console.log(marcaState, "marca")
  return (

  
    <>  {categoria != null ?  
      
     <form onSubmit={handleSubmit(onSubmit)}>
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
      <TitleFirts>Editar Categoria</TitleFirts>
      <Grid container spacing={1}>
      
        <Grid item sm={12}>
          <TextField id="outlined-basic" label="Editar categoria"
           
           InputLabelProps={{ shrink: true }}
             {...register("nombreCategoria",{required: true}, {Value: categoria.nombre})} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }}  helperText={(errors.nombreCategoria?.type === 'required' && "Este campo es obligatorio!") }
                error={
                  (errors.nombreCategoria?.type === 'required')
                  && true
                   }  
          value={categoria.nombre}
          onChange={(e)=>setCategoria({"nombre": e.target.value})}   
          autoFocus
         />

        </Grid>
        <Grid item sm={12}>
              <TitleSecond>Validaciones</TitleSecond>
            </Grid>

            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Marca: es requerido?</FormLabel>
                
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="marca"
                  value={marcaState}  
                  onChange={(e)=>{setMarca(e.target.value

                  )}}   
                >
                  <FormControlLabel value="si" control={<Radio />}  {...register("marca",{required: true} )} label="Si"  helperText={(errors.marca?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.marca?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />}  {...register("marca",{required: true})} label="No"  helperText={(errors.marca?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.marca?.type === 'required')
                  && true
                }/>
               
               
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Serial: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("serial")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("serial",{required: true})} label="Si"  helperText={(errors.serial?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.serial?.type === 'required')
                  && true
                }/>
                  <FormControlLabel value="no" control={<Radio />} {...register("serial",{required: true})}  label="No"  helperText={(errors.serial?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.serial?.type === 'required')
                  && true
                }/>
               
               
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Placa: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("placa")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("placa",{required: true})} label="Si" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />} {...register("placa",{required: true})} label="No" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
               
               
                </RadioGroup>
              </FormControl>
            </Grid>
      
          
            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Tipo: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("tipo")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("tipo",{required: true})} label="Si" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />} {...register("tipo",{required: true})} label="No" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
               
               
                </RadioGroup>
              </FormControl>
            </Grid>




            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Capacidad: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("capacidad")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("capacidad",{required: true})} label="Si" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />} {...register("capacidad",{required: true})} label="No" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
               
               
                </RadioGroup>
              </FormControl>
            </Grid>
       

      </Grid>




    </Grid>
    <Button variant="contained" color="success" type="submit" sx={{ marginTop: 5, width: 100, marginLeft: 3 }}>Enviar</Button>
    <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
  </form> :''}
     
    </>
  )
}


export const FormModalCategory = (id,load) => {
  
  //const navigate=useNavigate()
 return(
    Alert.fire({
      width: "970px",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top",
      html: <AlertModalForm id={id} load={load} /*navigate={navigate}*/ />
    })
 )
}