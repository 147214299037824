import axios from "axios";

const URL = "https://0yets2xo9j.execute-api.us-east-1.amazonaws.com";
// const URL = "http://localhost:4000";

export const HeadshipsAll = async () => {
    const request = await axios.get(URL + "/headships/all");
    return request.data;
}
export const GetHeadships = async (id) => {
    const request = await axios.get(URL + "/headships/" + id);
    return request.data;
}

export const UpdateStateHeadships = async (id) => {
    const request = await axios.post(URL + "/headships/updateState/" + id);
    return request.data;
}

export const CreateHeadships = async (object) => {
    const request = await axios.post(URL + "/headships/create", object);
    return request.data;
}

export const EditHeadships = async (id, object) => {
    const request = await axios.post(URL + "/headships/edit/" + id, object);
    return request.data;
}