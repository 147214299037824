import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Home } from './components/Home'
import { Employees } from './components/Employees'
import { Categorys } from './components/Categories/Categorys'
import { Products } from './components/Products/ProductList'
import ViewerDocument from './components/Documents/ViewerDocument'
import { ListPositions } from './components/Positions/ListPositions'
import { ListAdminPep } from './components/AdministrationPep/ListAdminPep'
import { ListMargenesPep } from './components/MargenesPep/ListAdminPep'
import { LoginPanicButtonPage } from './pages/LoginPanicButton/LoginPanicButtonPage'
import { PanicButtonPage } from './pages/PanicButton/PanicButtonPage'
import { DirectoryProvider } from './context/DirectoryContex'
import { Login } from './components/Login'
import { ProtectedRoute } from './components/ComponentsUtilies/ProtectedRoute/ProtectedRoute'
import { TechniciansButtonPage } from './pages/TechniciansButton/TechniciansButtonPage'
import { Process } from './components/Processes/Process'
import { CasosBMCPage } from './pages/CasosBMC/CasosBMCPagePage'
import { BMCPanicButtonPage } from './pages/CasosBMC/BMCPanicButtonPage'
import { Headships } from './components/Headships'
import { Managements } from './components/Managements'
import { Documents } from './components/Documents'
import { EmployeesFlowchart } from './components/Processes/EmployeesFlowchart'
import { ProcessFlowchart } from './components/Processes/ProcessFlowchart'
import Applications from './components/Apps'
import { Menu } from './components/Menu'
import { Commitment } from './components/Commitment'
import Auditors from './components/Auditors'
import Directors from './components/Directors'
import { InitAmplify } from './components/AmplifyAws/AmplifyAws'
import {Suppliers} from './components/Suppliers'

const App = () => {

  InitAmplify()

  return (
    <DirectoryProvider>
      <Menu>
        <Routes >
          <Route path='/Login' element={<Login />} />
          <Route path='/Documento/ver/:id' element={<ViewerDocument />} />
          <Route path='/' element={<ProtectedRoute security={10}><Home /></ProtectedRoute>} />
          <Route path='/Articulos' element={<ProtectedRoute><Products /></ProtectedRoute>} />
          <Route path='/Categorias' element={<ProtectedRoute><Categorys /></ProtectedRoute>} />
          <Route path='/Empleados' element={<ProtectedRoute><Employees /></ProtectedRoute>} />
          <Route path='/Procesos' element={<ProtectedRoute  security={10}><Process /></ProtectedRoute>} />
          <Route path='/Auditores' element={<ProtectedRoute><Auditors /></ProtectedRoute>} />
          <Route path='/Posiciones' element={<ProtectedRoute><ListPositions /></ProtectedRoute>} />
          <Route path='/AdministracionPep' element={<ProtectedRoute><ListAdminPep /></ProtectedRoute>} />
          <Route path='/MargenPep' element={<ProtectedRoute><ListMargenesPep /></ProtectedRoute>} />
          <Route path='/LoginPanicButtom' element={<ProtectedRoute><LoginPanicButtonPage /></ProtectedRoute>} />
          <Route path='/PanicButtom' element={<ProtectedRoute><PanicButtonPage /></ProtectedRoute>} />
          <Route path='/Directores' element={<ProtectedRoute><Directors /></ProtectedRoute>} />
          <Route path='/Tecnicos' element={<ProtectedRoute><TechniciansButtonPage /></ProtectedRoute>} />
          <Route path='/CasosBMC' element={<ProtectedRoute><CasosBMCPage /></ProtectedRoute>} />
          <Route path='/CasosUsuariosBMC' element={<ProtectedRoute><BMCPanicButtonPage /></ProtectedRoute>} />
          <Route path='/Jefaturas' element={<ProtectedRoute><Headships /></ProtectedRoute>} />
          <Route path='/Direcciones' element={<ProtectedRoute><Managements /></ProtectedRoute>} />
          <Route path='/Documentos/:processId' element={<ProtectedRoute security={10} ><Documents /></ProtectedRoute>} />
          <Route path='/Organigrama/Empleados/:processId' element={<ProtectedRoute security={10}><EmployeesFlowchart /></ProtectedRoute>} />
          <Route path='/Organigrama/Procesos' element={<ProtectedRoute security={10}><ProcessFlowchart /></ProtectedRoute>} />
          <Route path='/Aplicaciones/:processId' element={<ProtectedRoute><Applications /></ProtectedRoute>} />
          <Route path='/Compromisos/:employeeId' element={<ProtectedRoute><Commitment /></ProtectedRoute>} />
          <Route path='/Proveedores' element={<ProtectedRoute security={1} ><Suppliers /></ProtectedRoute>} />
        </Routes>
      </Menu>
    </DirectoryProvider>
  )
}

export default App
