import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {TextField, Grid} from '@mui/material'
import {TitleFirts} from '../../ComponentsUtilies/TitleFirts'
import Label from '../../ComponentsUtilies/Label'
import Save from '../../ComponentsUtilies/Save'
import {onSubmit} from './onSubmit'

const required = 'Este campo es obligatorio!'
const inOp = {
  uuid: 'uuid',
  name: 'nombre',
  description: 'descripcion'
}

export const Form = ({supplier, title, reload, close}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors}
  } = useForm()

  useEffect(() => {
    register(inOp.uuid)
    for (let i in inOp) setValue(i, supplier?.[i])
  })

  const record = (name) => {
    return {
      ...register(name, {required}),
      defaultValue: supplier?.[name],
      helperText: errors[name]?.message,
      error: errors[name]
    }
  }

  return (
    <Grid container p={3} spacing={3}>
      <Grid item xs={12}>
        <TitleFirts>{title}</TitleFirts>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label={<Label>Nombre</Label>} {...record(inOp.name)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label={<Label>Descripcion</Label>} {...record(inOp.description)} />
      </Grid>
      <Save onSave={handleSubmit((v) => onSubmit(v).then(reload))} onCancel={close} />
    </Grid>
  )
}
