import Swal from 'sweetalert2'

export const AlertError = (title, text) => {
  Swal.close()
  Swal.fire({
    icon: 'error',
    title: title ?? 'Error inesperado',
    text: text ?? 'La operacion no se pudo ejecutar'
  })
}

export const AlertSuccess = (text, title) => {
  Swal.close()
  Swal.fire({
    icon: 'success',
    title: title ?? 'Operacion exitosa',
    text: text ?? 'La operacion se ejecuto correctamente'
  })
}

export const ConfirmModal = (title, text, action) => {
  Swal.fire({
    icon: 'warning',
    title,
    text,
    showCancelButton: true,
    confirmButtonColor: 'rgba(6, 213, 172, 1)',
    cancelButtonColor: 'rgba(244, 54, 76, 1)',
    confirmButtonText: 'Eliminar',
    cancelButtonText: 'Cancelar'
  })
    .then((result) => {
      if (result.isConfirmed) return action()
      else Swal.close()
    })
    .catch(() => Swal.close())
}
