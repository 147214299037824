import { Typography, Grid } from '@mui/material'
import { ImgHome } from '../../media/ImgHome'
import { LogoNutresa } from '../../media/LogoNutresa'

export const Home = () => {
  return (
    <Grid container spacing={0}
      direction='column'
      alignItems='start'
      margin='2rem 0 0 4rem'
      width='90%'
    >
      <Grid container justifyContent='space-between' item xs={12} >
        <Typography variant='h4' color='primary'>Portal del administrador</Typography>
        <LogoNutresa sx={{marginRight: '2rem'}} />
      </Grid>
      <Grid item width='100%' xs={12} >
        <ImgHome />
      </Grid>
    </Grid>
  )
}



export default Home
