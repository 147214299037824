import { Grid, TextField, Button, FormLabel, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"
import { TitleFirts, TitleSecond } from "../ComponentsUtilies/Titles"
import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'
import { useForm } from "react-hook-form"
import { AddCategory } from "../../services/categoryService"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"
const Alert = withReactContent(Swal)


export const AlertModalForm = ({ load }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const onSubmit = (valores) => {
    //const idCategory=categoria.IdCategoria
    console.log(valores)
    const {marca,serial,placa,tipo,capacidad}=valores
    const validaciones={
       "validaciones":{
         "campos":[{"nombre":"marca","requerido":marca},{"nombre":"serial", "requerido":serial},{"nombre":"placa","requerido":placa},{"nombre":"tipo", "requerido":tipo},{"nombre":"capacidad","requerido":capacidad}]
       }
    }
    console.log("marca", marca)
    console.log("serial", serial)
    console.log("placa", placa)
    const envio = {
      "nombreCategoria": valores.nombreCategoria,
      "validaciones":validaciones
    }
    console.log("envio de data", envio)
    AddCategory(envio).then(r => {
      console.log(r)
      if (r.status != 500) {
        AlertSuccess()
        load(true)

      }
    }).catch(error => console.log(error))


    reset()

  }
  console.log(errors)
  return (

    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
          <TitleFirts>Agregar Categoria</TitleFirts>
          <Grid container spacing={1}>

            <Grid item sm={12}>
              <TextField id="outlined-basic" label="Nombre categoria" {...register("nombreCategoria", { required: true })} variant="outlined" sx={{ marginTop: 2, width: 850, marginLeft: -2 }} helperText={(errors.nombreCategoria?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.nombreCategoria?.type === 'required')
                  && true
                } />

            </Grid>
            <Grid item sm={12}>
              <TitleSecond>Validaciones</TitleSecond>
            </Grid>

            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Marca: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="marca"
                 
                >
                  <FormControlLabel value="si" control={<Radio />}  {...register("marca",{required: true} )} label="Si"  helperText={(errors.marca?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.marca?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />}  {...register("marca",{required: true})} label="No"  helperText={(errors.marca?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.marca?.type === 'required')
                  && true
                }/>
               
               
               
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Serial: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("serial")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("serial",{required: true})} label="Si"  helperText={(errors.serial?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.serial?.type === 'required')
                  && true
                }/>
                  <FormControlLabel value="no" control={<Radio />} {...register("serial",{required: true})}  label="No"  helperText={(errors.serial?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.serial?.type === 'required')
                  && true
                }/>
               
               
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Placa: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("placa")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("placa",{required: true})} label="Si" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && <><p>error</p></>
                } />
                  <FormControlLabel value="no" control={<Radio />} {...register("placa",{required: true})} label="No" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
               
               
                </RadioGroup>
              </FormControl>
            </Grid>



            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Tipo: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("tipo")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("tipo",{required: true})} label="Si" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />} {...register("tipo",{required: true})} label="No" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
               
               
                </RadioGroup>
              </FormControl>
            </Grid>




            <Grid item sm={4}>
              <FormControl>
                <FormLabel>Capacidad: es requerido?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...register("capacidad")}
                >
                  <FormControlLabel value="si" control={<Radio />} {...register("capacidad",{required: true})} label="Si" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
                  <FormControlLabel value="no" control={<Radio />} {...register("capacidad",{required: true})} label="No" helperText={(errors.placa?.type === 'required' && "Este campo es obligatorio!")}
                error={
                  (errors.placa?.type === 'required')
                  && true
                } />
               
               
                </RadioGroup>
              </FormControl>
            </Grid>

          </Grid>




        </Grid>
        <Button variant="contained" color="success" sx={{ marginTop: 5, width: 100, marginLeft: 3 }} type="submit">Enviar</Button>
        <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => Alert.close()}>Cancelar</Button>
      </form>
    </>
  )
}


export const ModalCreateCategoryForm = (load) => {
  Alert.fire({
    width: "970px",
    showConfirmButton: false,
    showCancelButton: false,
    position: "top",
    html: <AlertModalForm load={load} />
  })
}
