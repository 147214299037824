import React from 'react'
import {Box, Card, CardContent, Typography, Button, Grid} from '@mui/material'
// import {useAppContext} from '../../../context/DirectoryContex'

export const Sheet = ({process, show, setShow, isDad}) => {
  // const {user} = useAppContext()

  return (
    <Grid
      container
      spacing={1}
      justifyContent='center'
      alignItems='center'
      alignContent='center'
      wrap='wrap'
    >
      <Grid item>
        <Card
          sx={{
            boxShadow: '0px 9px 20px rgb(60, 16, 83)',
            backgroundColor: 'rgba(120, 60, 189, 0.4)',
            minHeight: 220,
            maxWidth: 400,
            minWidth: 200
          }}
          raised={true}
        >
          <CardContent padding={2} sx={{cursor: 'pointer'}}>
            <Box>
              <Typography fontSize={15}>{process.nombre}</Typography>
              <Typography color='black' mt={1} fontSize={12}>
                {process.descripcion}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        {isDad && (
          <Button
            variant='contained'
            sx={{height: 20}}
            onClick={(event) => {
              setShow(!show)
              setTimeout(() => {
                const {x, y} = event.target.getBoundingClientRect()
                window.scrollBy({
                  left: x - window.innerWidth / 2,
                  top: y - window.innerHeight / 2,
                  behavior: 'smooth'
                })
              }, 100)
            }}
          >
            {show ? 'Ver menos' : 'Ver mas'}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
