import { useEffect,useState,useMemo } from "react";
import { TitleFirts } from "../ComponentsUtilies/Titles"
import {GetAllMargenesPep} from "../../services/margenesPep"
import MaterialReactTable from "material-react-table"
import { Button } from "@mui/material";
import {ModalCreateMargenPep} from "./CrearMargenPep"
import {UpdateMargenPepState} from "./UpdateState"
import {ModalEditMargenPep} from "./EditMargenPep"


export const ListMargenesPep=()=>{
    const [margenPep, setMargenPep]=useState()
    const [load,setLoad]=useState(false) 
    useEffect(()=>{
      GetAllMargenesPep().then(data=>setMargenPep(data)).catch(error=>console.log(error))
        setLoad(false)
    },[load])

    const columns = useMemo(
        () => [
            {
                accessorKey: "Id",
                header: "Id"
            },
            {
              accessorKey: "pep",
              header: "Pep"
          },
         
          {
            accessorKey: 'costos', 
            header: 'Costos',
            
          },
          {
            accessorKey: 'ingresos', 
            header: 'Ingresos',
            
          },
          {
            accessorKey: 'fecha', 
            header: 'fecha',
            Cell : ({cell})=>String(cell.getValue()).substring(0,10)
          } , 
         
          {
            accessorKey: 'estado', 
            header: 'Estado',
            Cell : ({cell})=>cell.getValue() == 1 ? <Button variant="text" color="success">Activo</Button> : <Button variant="text" color="error">Inactivo</Button>
          },
          {
            accessorFn: (row) => row.estado ?  <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 108, marginBottom:1}} onClick={()=>ModalEditMargenPep(setLoad,row.Id)}>Editar </Button>
            <Button variant="contained" color="error" sx={{ marginLeft: 1 }}  onClick={()=>UpdateMargenPepState(row.Id, setLoad)}  >Inactivar</Button> 
            </>  : <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 90, marginBottom:1}} onClick={()=>ModalEditMargenPep(setLoad,row.Id)}>Editar </Button>
             <Button variant="contained" color="success" sx={{ marginLeft: 1 }} onClick={()=>UpdateMargenPepState(row.Id, setLoad)}  >Activar</Button></>
                
                      , //alternate way
            header: 'Acciones',
           
           
           
            
          }
        
          
        ],
        [],
      );
 
  return(
    <>
      <TitleFirts>Margen de peps</TitleFirts>
      <Button variant="contained" color="success" sx={{ width: 196,
      height: 35,
      marginBottom:1}} onClick={()=>ModalCreateMargenPep(setLoad)}>Crear</Button>
      {
        margenPep != null ? 
      <MaterialReactTable 
       columns={columns}
       data={margenPep}
      /> : <MaterialReactTable 
      columns={columns}
      data={0}
     />
         }
    </>
  )  
}