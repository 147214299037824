import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid, TextField, Button, Typography, Autocomplete } from '@mui/material'
import { EditManagements, GetManagements } from '../../services/managementsService'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { GetAlldirectors } from '../../services/directorsService'

export const Edit = ({ setLoad, id, close }) => {

  const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()
  const [management, setManagement] = useState(null)
  const [directors, setDirectors] = useState([])

  useEffect(() => {
    GetManagements(id)
      .then(data => {
        setManagement({ ...data, nombre: data.nombreDireccion })
        setValue('nombre', data.nombreDireccion)
        setValue('descripcion', data.descripcion)
        setValue('directore', data.directore.nombre)
        setValue('director', {
          lavel: data.directore.nombre,
          value: data.directore.idDirector
        })
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Error al optener la direccion', error)
      })
  }, [id, setValue, setManagement])

  useEffect(() => {
    GetAlldirectors().then(data => setDirectors(data))
      .catch(error => {
        console.error(error)
        ErrorModal('Error al optener los directores', error)
      })
  }, [setDirectors])

  const onSubmit = (values) => {
    const data = {
      nombreDireccion: values.nombre,
      descripcion: values.descripcion,
      directorId: values.director.value
    }
    EditManagements(management.id, { ...management, ...data })
      .then(() => {
        setLoad(true)
        reset()
        close()
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Operacion con errores', error)
      })
  }

  if (!management) return <></>

  return <Grid >
    <Typography color='primary' variant='h5' marginBottom={1}>
      Editar Direccion
    </Typography>
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '85%' }}
          label='Nombre *'
          {...register('nombre', { required: true })}
          value={management.nombre}
          InputLabelProps={{ shrink: true }}
          helperText={(errors.nombre?.type === 'required' && 'Este campo es obligatorio!')}
          error={(errors.nombre?.type === 'required') && true}
          onChange={(e) => setManagement((s) => ({ ...s, nombre: e.target.value }))}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '85%' }}
          label='Descripcion *'
          {...register('descripcion', { required: true })}
          helperText={(errors.descripcion?.type === 'required' && 'Este campo es obligatorio!')}
          error={(errors.descripcion?.type === 'required') && true}
          value={management.descripcion}
          onChange={(e) => setManagement((s) => ({ ...s, descripcion: e.target.value }))}
        />
      </Grid>

      <Grid item xs={12} >
        <Controller
          name='director'
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) =>
            <Autocomplete
              disablePortal
              options={directors.map(a => ({ label: a.nombre, value: a.idDirector }))}
              defaultValue={management.directore.nombre}
              renderInput={(params) =>
                <TextField
                  sx={{ width: '85%' }}
                  {...register('directore', { required: true })}
                  {...params}
                  label='Director *'
                  helperText={(errors.directore?.type === 'required' && 'Este campo es obligatorio!')}
                  error={(errors.directore?.type === 'required') && true}
                />
              }
              onChange={(_, data) => onChange(data)}
            />
          }
        />
      </Grid>

    </Grid>
    <Grid container justifyContent='space-evenly' padding='1rem 9%' >
      <Button color='success' onClick={handleSubmit(onSubmit)} >Enviar</Button>
      <Button color='error' onClick={close}>Cancelar</Button>
    </Grid>
  </Grid>
}

export default Edit