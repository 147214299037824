import React, { useEffect, useState } from 'react'
import { Grid, Typography, TextField, Button, Autocomplete } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { CreateHeadships } from '../../services/headshipsService'
import { GetAllAuditors } from '../../services/auditorsService'

export const Create = ({ setLoad, close }) => {

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm()
  const [auditors, setAuditors] = useState([])

  useEffect(() => {
    GetAllAuditors().then(data => setAuditors(data))
  }, [setAuditors])

  const onSubmit = (values) => {
    const data = {
      nombreJefatura: values.nombre,
      descripcion: values.descripcion,
      interventorId: values.auditor.value
    }

    CreateHeadships(data)
      .then(() => {
        setLoad(true)
        reset()
        close()
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Operacion con errores', error)
      })
  }

  return (<Grid >
    <Typography color='primary' variant='h5' marginBottom={1}>
      Agregar Jefatura
    </Typography>
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '80%' }}
          label='Nombre *'
          {...register('nombre', { required: true })}
          helperText={(errors.nombre?.type === 'required' && 'Este campo es obligatorio!')}
          error={(errors.nombre?.type === 'required') && true}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '80%' }}
          label='Descripcion *'
          {...register('descripcion', { required: true })}
          helperText={(errors.descripcion?.type === 'required' && 'Este campo es obligatorio!')}
          error={(errors.descripcion?.type === 'required') && true}
        />
      </Grid>

      <Grid item xs={12} >
        <Controller
          name='auditor'
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) =>
            <Autocomplete
              disablePortal
              options={auditors.map(a => ({ label: a.nombre, value: a.idInterventor }))}
              renderInput={(params) =>
                <TextField
                  sx={{ width: '80%' }}
                  {...register('interventor', { required: true })}
                  {...params}
                  label='Interventor *'
                  helperText={(errors.interventor?.type === 'required' && 'Este campo es obligatorio!')}
                  error={(errors.interventor?.type === 'required') && true}
                />
              }
              onChange={(_, data) => {
                onChange(data)
                return data
              }}
            />
          }
        />
      </Grid>

    </Grid>
    <Grid container justifyContent='space-evenly' padding='1rem 9%' >
      <Button color='success' onClick={handleSubmit(onSubmit)} >Enviar</Button>
      <Button color='error' onClick={close}>Cancelar</Button>
    </Grid>
  </Grid>)
}

export default Create