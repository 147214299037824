
import * as React from 'react';
import { Button,Box,Grid,TextField,InputAdornment,Autocomplete} from "@mui/material";
import { TitleFirts } from '../ComponentsUtilies/Titles'; 
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2"
import { useForm,Controller  } from "react-hook-form"
import { createTicket } from '../../services/createTicket';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { Loading,AlertSuccess,AlertError } from '../ComponentsUtilies/AlertsModal/Alerts';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import Textarea from '@mui/joy/Textarea';
import { commentTickets } from '../../services/commentTickets';
import { queryOneTickects } from '../../services/queryTickects';
import { changeStateTicket } from '../../services/changeStateTicket';
import { queryTickectsBMC,commentTicketBMC,createTicketBMC,updateTicketBMC } from '../../services/casosBMC';
const SweetAlert = withReactContent(Swal);

const CloseTickect=(props)=>{
    const { register, handleSubmit,control,formState: { errors },watch,clearErrors,setError,setValue } = useForm();
    const LoadingModal = () => {
        Loading("Por favor espere...");
    }

    const SuccessModal = () => {
        if(props.type === "Cerrar"){
          AlertSuccess(`Caso cerrado exitosamente`,`Caso cerrado`);
        }else if(props.type === "Cancelar"){
          AlertSuccess(`Caso cancelado exitosamente`,`Caso cancelado`);
        }
    }
    
    const sendProblem = (formData) =>{
        LoadingModal();
        queryTickectsBMC(`q='Incident Number'="${formData.numeroTicket}"`)
        .then((response) =>{
            console.log(response)
            let data = response?.data?.data?.entries[0]?.values; 
            let SR = data;
            if(SR){
              commentTicketBMC(formData.descripcionComentario,formData.descripcionComentario,formData.numeroTicket)
              .then((responseComment) =>{
                console.log("response",response)
                console.log(response?.data?.data?.entries[0]?.values["Request ID"])
                let body = "";
                if(props.type === "Cerrar"){
                  body = {
                    "Status":"Resolved",
                    "Status_Reason": "No Further Action Required",
                  };
                }else{
                  body = {
                    "Status":"Cancelled",
                    "Status_Reason": "No Further Action Required",
                  };
                } 
                updateTicketBMC(body,response?.data?.data?.entries[0]?.values["Request ID"])
                .then((resp)=>{
                  SuccessModal(response?.data?.data?.entries[0]?.values["Incident Number"]);
                  let message = `Hola Daniela,\nal caso generado con ID ${formData.numeroTicket} fue cerrado con el siguiente comentario:\n${formData.descripcionComentario}`
                  sendWhatsMessage(message,"320 4982189")
                  .then((response) =>{
                        console.log(response);
                  })
                  .catch((err) =>{
                        console.log(err);
                  });
                })
                .catch((errr)=>{
                  AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
                })
                
                
              })
              .catch((err) =>{
                AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
              });
            }else{
              AlertError("Caso no encontrado","El caso buscado no fue encontrado");
            }
            
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });

        /*queryOneTickects(
          {
              "key":"TICKETID",
              "value":formData.numeroTicket
          }
        ).then((response) =>{
            console.log(response)
            let data = response.data; 
            console.log(data)
            let SR = data["soapenv:Envelope"]["soapenv:Body"].QueryIBMSRTUSEGUIMResponse.IBMSRTUSEGUIMSet?.SR;
            if(SR){
              commentTickets(
                  {
                    RECORDKEY: formData.numeroTicket,
                    DESCRIPTION: "Comentario de cierre" ,
                    DESCRIPTION_LONGDESCRIPTION: formData.descripcionComentario,
                    LOGTYPE: "CLIENTNOTE",
                    CREATEBY: JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName
                  }
              ).then((response) =>{
                changeStateTicket({
                  "TICKETID":formData.numeroTicket,
                  "CLASS":"SR",
                  "CHANGEBY":JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName,
                  "STATUS":props.type === "Cerrar" ?
                  "RESOLVED"
                  :props.type === "Cancelar" ?
                  "CANCELLED"
                  :""
                   
                }).then((response) =>{
                  SuccessModal();
                })
                .catch((err) =>{
                  AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
                });
              })
              .catch((err) =>{
                AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
              });
            }else{
              AlertError("Caso no encontrado","El caso buscado no fue encontrado");
            }
          
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });*/
        
    } 
    
    const onSubmit = (data) => {
        sendProblem(data)
    };

    if(props.sr !== ""){
      setValue("numeroTicket",props.sr)
    }
    return (
        <>
             
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
         <Grid container spacing={1}>
         
         <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
          <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><ConfirmationNumberIcon style={{color:'#3C1053'}} /></InputAdornment>),
              }}
              {...register("numeroTicket", { required: true})}
              label="Número de caso a cerrar*"
              variant="outlined"
              defaultValue={props.sr}
              disabled={props.sr ? true : false}
              
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              helperText={(errors.numeroTicket?.type === 'required' && "Este campo es obligatorio!") }
              error={
                (errors.numeroTicket?.type === 'required' )
                && true
              }
            /> 
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
         
            <Textarea  
              
              placeholder={
                props.type === "Cerrar" ?
                  "Ingresa el comentario de cierre"
                :props.type === "Cancelar" ?
                  "Ingresa el motivo de cancelación"
                :""
              }
              {...register("descripcionComentario", { required: true})}
              size="lg"
              variant="outlined"
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              error={
                (errors.descripcionComentario?.type === 'required')
                && true
              }
            />
            </Grid>
            </Grid>
          </Grid>
          
         
          
        </Grid>

     
        
      </Grid>
        <Button 
            variant="contained"
            color="success" 
            sx={{ marginTop: 5, width: 120, marginLeft: 3 }}
            onClick={handleSubmit(onSubmit)}
        >
        {
          props.type === "Cerrar" ?
            "Cerrar"
          :props.type === "Cancelar" ?
            "Cancelar"
          :""
        }
        </Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => SweetAlert.close()}>Cerrar</Button>
    
        </>
    )
}







const CloseTickectModal = (sr,type) => {
    SweetAlert.fire({
      customClass:"classFormModal",
      title:type === "Cerrar" ?
      "Cerrar caso"
      :type === "Cancelar" ?
      "Cancelar caso"
      :"",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top",
      allowOutsideClick: true,
      showCloseButton: true,
      html: <CloseTickect sr={sr} type={type} />
      
    })
  }

export {CloseTickectModal,CloseTickect}