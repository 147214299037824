import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { Auth } from 'aws-amplify'
import Visibility from '@mui/icons-material/Visibility'
import {
    Grid, Typography, TextField, Button, Box,
    InputAdornment, IconButton
} from '@mui/material'
import { AlertError } from '../ComponentsUtilies/AlertsModal/Alerts'
import { getAlertModal } from '../../hooks/getAlertModal'
import { ImgLogin } from '../../media/ImgLogin'
import { LogoNutresa } from '../../media/LogoNutresa'
import { IconUser } from '../../media/IconUser'
import { IconLock } from '../../media/IconLock'
import { LogoAxity } from '../../media/LogoAxity'
import { InitAmplify } from '../AmplifyAws/AmplifyAws'
import { NewPassword } from './NewPassword'
import ForgotPassword from './ForgotPassword'

export const Login = () => {
    const { gradient, shadows } = useTheme()
    const [showPasword, setShowPasword] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm()
    const navigate = useNavigate()

    useEffect(() => { validateLogin() }, [])

    const validateLogin = () => {
        Auth.currentAuthenticatedUser()
            .then(() => navigate("/"))
            .catch((error) => console.error(error))
    }

    const onSubmit = async (data) => {
        try {
            InitAmplify()
            let user = await Auth.signIn(data.usuario, data.contrasena);
            console.log(user)
            //props.onLoginSuccess(); // Llamar a esta función cuando el inicio de sesión sea exitoso
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                getAlertModal(NewPassword, { usuario: user })()
            } else {
                navigate("/")
            }
        } catch (error) {
            console.error(error)
            AlertError('Error al iniciar sesión:' + error, "Error al iniciar sesión");
        }
    }

    return (<Grid
        container
        sx={{ minWidth: '21rem' }}
    >
        <Grid item xs={12} sm={6} md={4}
            minHeight='100vh'
            sx={{ backgroundImage: gradient.uva }}
        >
            <Grid
                container
                spacing={1}
                paddingTop={1}
                paddingBottom={5}
                direction='column'
                justifyContent='space-between'
                alignItems='center'
                align='center'
                minHeight='100vh'
            >
                <Grid item xs={12} width='70%' >
                    <LogoAxity />
                </Grid>

                <Grid item xs={12} width='70%' >
                    <Typography
                        variant='h1'
                        fontSize={33}
                        color='white'
                        onClick={() => getAlertModal(NewPassword, { usuario: "" })}
                    >Bienvenido</Typography>
                    <Box
                        sx={{ backgroundImage: gradient.gris }}
                        width='50%'
                        height='0.36rem'
                        borderRadius={2}
                    ></Box>
                </Grid>

                <Grid item xs={12} width='70%' >
                    <TextField
                        label={<Grid container >
                            <Grid item xs={1.5} ><IconUser /></Grid>
                            <Grid item xs={true} >
                                <Typography
                                    fontSize={18}
                                    color='white'
                                    sx={{ paddingLeft: '4px' }}
                                >Nombre de usuario</Typography>
                            </Grid>

                        </Grid>}
                        InputProps={{ sx: { border: 'none', boxShadow: shadows[3] } }}
                        {...register('usuario', { required: true })}
                        helperText={(errors.usuario?.type === 'required' && 'Este campo es obligatorio!')}
                        error={(errors.usuario?.type === 'required') && true}
                    />
                </Grid>

                <Grid item xs={12} width='70%' >
                    <TextField
                        label={<Grid marginLeft={1} >
                            <IconLock />
                            <Typography
                                fontSize={18}
                                color='white'
                                variant='strong'
                                marginLeft='0.6rem'
                            >Contraseña</Typography>
                        </Grid>}
                        type={showPasword ? 'tesxt' : 'password'}
                        {...register('contrasena', { required: true })}
                        InputProps={{
                            sx: { border: 'none', overflow: 'hidden', boxShadow: shadows[3] },
                            endAdornment: (<InputAdornment position="end">
                                <IconButton onClick={() => setShowPasword((s) => !s)} ><Visibility /></IconButton>
                            </InputAdornment>),
                        }}
                        helperText={(errors.contrasena?.type === 'required' && 'Este campo es obligatorio!')}
                        error={(errors.contrasena?.type === 'required') && true}
                    />
                </Grid>

                <Grid item xs={12} width='70%' >
                    <Button fullWidth color='secondary' style={{ padding: 2 }} onClick={handleSubmit(onSubmit)}>
                        <Typography
                            variant='strong'
                            component='strong'
                            fontSize={18}
                            color='white'

                        > Continuar </Typography>
                    </Button>
                </Grid>

                <Typography
                    variant='strong'
                    component='strong'
                    fontSize={18}
                    color='white'
                    sx={{ cursor: "pointer" }}
                    onClick={getAlertModal(ForgotPassword, {})}
                > Olvidé mi contraseña</Typography>

            </Grid>
        </Grid >
        <Grid item xs={12} md={8} paddingLeft={9} >
            <Grid container justifyContent='flex-end' sx={{ padding: '2rem 2rem 1rem' }} >
                <LogoNutresa />
            </Grid>
            <ImgLogin />
        </Grid>
    </Grid >)
}

export default Login