import React, { useEffect, useState } from 'react'
import { Grid, TextField, Button, Typography, Autocomplete } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { CreateManagements } from '../../services/managementsService'
import { GetAlldirectors } from '../../services/directorsService'

export const Create = ({ setLoad, close }) => {

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm()
  const [directors, setDirectors] = useState([])

  useEffect(() => {
    GetAlldirectors().then(data => setDirectors(data))
  }, [setDirectors])

  const onSubmit = (values) => {

    const data = {
      nombreDireccion: values.nombre,
      descripcion: values.descripcion,
      directorId: values.director.value
    }

    CreateManagements(data)
      .then(() => {
        setLoad(true)
        reset()
        close()
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Operacion con errores', error)
      })
  }

  return (<Grid >
    <Typography color='primary' variant='h5' marginBottom={1}>
      Agregar Direccion
    </Typography>
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '85%' }}
          label='Nombre *'
          {...register('nombre', { required: true })}
          helperText={(errors.nombre?.type === 'required' && 'Este campo es obligatorio!')}
          error={(errors.nombre?.type === 'required') && true}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          sx={{ width: '85%' }}
          label='Descripcion *'
          {...register('descripcion', { required: true })}
          helperText={(errors.descripcion?.type === 'required' && 'Este campo es obligatorio!')}
          error={(errors.descripcion?.type === 'required') && true}
        />
      </Grid>

      <Grid item xs={12} >
        <Controller
          name='director'
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) =>
            <Autocomplete
              disablePortal
              options={directors.map(a => ({ label: a.nombre, value: a.idDirector }))}
              renderInput={(params) =>
                <TextField
                  sx={{ width: '85%' }}
                  label='Director *'
                  {...register('directore', { required: true })}
                  {...params}
                  helperText={(errors.directore?.type === 'required' && 'Este campo es obligatorio!')}
                  error={(errors.directore?.type === 'required') && true}
                />
              }
              onChange={(_, data) => onChange(data)}
            />
          }
        />
      </Grid>

    </Grid>
    <Grid container justifyContent='space-evenly' padding='1rem 9%' >
      <Button color='success' onClick={handleSubmit(onSubmit)} >Enviar</Button>
      <Button color='error' onClick={close}>Cancelar</Button>
    </Grid>
  </Grid>)
}
