import { Paper,TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { TitleFirts } from "../ComponentsUtilies/Titles"
import {ModalCreateCategoryForm} from "../Categories/CategoryModal"
import {FormModalCategory} from "./EditCategory"
import {UpdateStateCategory} from "./UpdateStateCategory"
import MaterialReactTable from "material-react-table"
import { useMemo } from "react"
const Styles = makeStyles({
    buttonStyle: {
      width: 196,
      height: 35,
      marginBottom:10
    }
})



export const TableCategorys = ({ Title,BodyTable,load }) => {
    //load(true)
     const StyleClasses = Styles()
 
     //POR SI quiero otro color  muiTableHeadCellProps: { sx: { color: 'green' } }, 
     const columns = useMemo(
        () => [
          {
            accessorKey: 'IdCategoria', 
            header: 'Id categoria',
            
          },
          {
            accessorKey: 'nombre', 
            header: 'Nombre',
            
          },
          {
            accessorKey: 'estado', 
            header: 'Estado',
            Cell : ({cell})=>cell.getValue() == 1 ? <Button variant="text" color="success">Activo</Button> : <Button variant="text" color="error">Inactivo</Button>
          },
          {
            accessorFn: (row) => row.estado ?  <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 90}} onClick={()=>FormModalCategory(row.IdCategoria,load)}>Editar </Button>
            <Button variant="contained" color="error" sx={{ marginLeft: 1 }}    onClick={()=>UpdateStateCategory(row.IdCategoria,load)}>Inactivar</Button> 
            </>  : <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 90}} onClick={()=>FormModalCategory(row.IdCategoria,load)}>Editar </Button>
             <Button variant="contained" color="success" sx={{ marginLeft: 1 }}  onClick={()=>UpdateStateCategory(row.IdCategoria,load)}>Activar</Button></>
                
                      , //alternate way
            id: "age", 
            header: "Age",
            header: 'Acciones',
           
           
           
            
          }
        
          
        ],
        [],
      );

    return (
        <>
            
           
         
            <TitleFirts>{Title}</TitleFirts>
            <Button variant="contained" color="success" className={StyleClasses.buttonStyle} onClick={()=>ModalCreateCategoryForm(load)}>Crear</Button>
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
            <MaterialReactTable  
                columns={columns}
                 data={BodyTable}
              />
           
            </Paper>
            
        </>
    )
}
