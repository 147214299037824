import React from 'react'
import {Typography, Grid} from '@mui/material'
import {texProps} from '.'

export const ProductItem = ({product}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs>
        <Typography fontSize={14}>Elemento:</Typography>
        <Typography {...texProps}>{product?.nombre}</Typography>
      </Grid>
      <Grid item xs>
        <Typography fontSize={14}>Descripción:</Typography>
        <Typography {...texProps}>{product?.descripcion}</Typography>
      </Grid>
      <Grid item xs>
        <Typography fontSize={14}>Tipo:</Typography>
        <Typography {...texProps}>{product?.tipo}</Typography>
      </Grid>
      <Grid item xs>
        <Typography fontSize={14}>Marca:</Typography>
        <Typography {...texProps}>{product?.marca}</Typography>
      </Grid>
      <Grid item xs>
        <Typography fontSize={14}>Serial:</Typography>
        <Typography {...texProps}>{product?.serial}</Typography>
      </Grid>
      <Grid item xs>
        <Typography fontSize={14}>Placa:</Typography>
        <Typography {...texProps}>{product?.placa}</Typography>
      </Grid>
      <Grid item xs>
        <Typography fontSize={14}>Cantidad:</Typography>
        <Typography {...texProps}>{product?.cantidad}</Typography>
      </Grid>
    </Grid>
  )
}
