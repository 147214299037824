import axios from 'axios'

const URL = 'https://0yets2xo9j.execute-api.us-east-1.amazonaws.com'
//const URL = 'http://localhost:4000'

export const createApp = async (body) => {
  const request = await axios.post(
    URL + '/app/create',
    body,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  )
  if (request.status !== 200) throw Error(request.data)
  return request.data
}

export const updateAppState = async (id) => {
  const request = await axios.put(URL + '/app/updateState/' + id)
  return request.data
}

export const editApp = async (body, id) => {

  let request = null
  if (body.doc) {
    request = await axios.put(
      URL + '/app/editFile/' + id,
      body,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  } else {
    request = await axios.put(URL + '/app/editData/' + id, body)
  }

  return request.data
}

export const getAppsProcess = async (id) => {
  const request = await axios.get(URL + '/app/process/all/' + id)
  return request.data
}