import { useEffect, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import ImageIcon from '@mui/icons-material/Image'
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  InputAdornment,
} from '@mui/material'
import { editDocument, getCategoriesAll } from '../../services/documents'
import {
  SucessModal,
  ErrorModal,
} from '../ComponentsUtilies/AlertsModal/Alerts'
import { ProcessAll, getSubprocessOption } from '../../services/processService'

export const Edit = ({ setLoad, document, folders, close, setIsLoading }) => {

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()
  const [file, setFile] = useState(null)
  const [carpeta, setCapeta] = useState('')
  const [categories, setCategories] = useState([])
  const [process, setProcess] = useState([])
  const [subprocess, setSubprocess] = useState([])
  const [foldersOption, setFoldersOption] = useState([])

  useEffect(() => {
    if (!folders) return
    const options = folders.map((folder) => ({ label: folder, value: folder }))
    setFoldersOption(options)
  }, [folders])

  useEffect(() => {

    setValue('procesoId', document.proceso.Idproceso)
    setValue('IdSubproceso', document.subproceso.IdSubProceso)
    setValue('nombre', document.nombre)
    setValue('categoriaId', document.doc_categoria.id)
    setValue('carpeta', document.carpeta)
    setValue('descripcion', document.descripcion)

    getCategoriesAll()
      .then((data) => setCategories(data.map(c => ({ label: c.nombre, value: c.id }))))
      .catch((error) => ErrorModal('Error al cargar las categoria', error))

    ProcessAll()
      .then((data) => setProcess(data.map(p => ({ value: p.id, label: p.NombreProceso }))))
      .catch((error) => ErrorModal('Error al cargar los procesos', error))

    getSubprocessOption()
      .then((data) => setSubprocess(data))
      .catch((error) => ErrorModal('Error al cargar los procesos', error))

  }, [document, setValue])

  const onSubmit = (values) => {

    values.doc = file
    values.carpeta = !carpeta ? '/' : carpeta[0] === '/' ? carpeta : '/' + carpeta
    setIsLoading(true)

    editDocument(values, document.id)
      .then(() => {
        reset()
        close()
        setLoad(false)
        setLoad(true)
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch((error) => {
        setLoad(false)
        ErrorModal("Error al guardar proceso", error)
      })
      .finally(() => setIsLoading(false) && setLoad(false))
  }

  return (
    <Grid>

      <Typography color='primary' variant='h5' marginBottom={1}>
        Editar Documento
      </Typography>

      <Grid container spacing={3}>

        <Grid item xs={12} md={6} >
          <Controller
            name='procesoId'
            rules={{ required: 'Este campo es obligatorio!' }}
            control={control}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal
                options={process}
                defaultValue={document.proceso.NombreProceso}
                renderInput={(params) => (
                  <TextField
                    label='Proceso *'
                    {...params}
                    helperText={errors.procesoId?.message}
                    error={errors.procesoId}
                  />
                )}
                onChange={(_, data) => onChange(data.value || '')}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} >
          <Controller
            name='IdSubproceso'
            rules={{ required: 'Este campo es obligatorio!' }}
            control={control}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal
                options={subprocess}
                defaultValue={document.subproceso.Nombre}
                onChange={(_, data) => {
                  if (!data) return
                  setCapeta('/' + data.label)
                  onChange(data.value || '')
                }}
                renderInput={(params) => (
                  <TextField
                    label='Subproceso *'
                    {...params}
                    helperText={errors.IdSubproceso?.message}
                    error={errors.IdSubproceso}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            type='file'
            label='Documento *'
            InputProps={{
              startAdornment: <InputAdornment position='start'><ImageIcon /></InputAdornment>
            }}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label='Nombre documento *'
            {...register('nombre', { required: 'Este campo es obligatorio!' })}
            helperText={errors.nombre?.message}
            error={errors.nombre}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name='categoriaId'
            rules={{ required: 'Este campo es obligatorio!' }}
            control={control}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal
                options={categories}
                defaultValue={document.doc_categoria.nombre}
                onChange={(_, data) => onChange(data.value || '')}
                renderInput={(params) => (
                  <TextField
                    label='Categoria *'
                    {...params}
                    helperText={errors.categoriaId?.message}
                    error={errors.categoriaId}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            options={[
              ...foldersOption,
              { label: carpeta, value: carpeta }
            ]}
            defaultValue={document.carpeta}
            onInputChange={(event, value) => {
              if (event && event.type !== 'blur') setCapeta(value)
            }}
            inputValue={carpeta || document.carpeta}
            getOptionSelected={() => true}
            renderInput={(params) => (
              <TextField
                label='Carpeta *'
                {...params}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='Descripcion del documento *'
            multiline
            rows={3}
            {...register('descripcion', { required: 'Este campo es obligatorio!' })}
            helperText={errors.descripcion?.message}
            error={errors.descripcion}
          />
        </Grid>

      </Grid>

      <Grid container justifyContent='space-evenly' padding='1rem 9%'>
        <Button color='success' onClick={handleSubmit(onSubmit)}>
          Enviar
        </Button>
        <Button color='error' onClick={close}>
          Cancelar
        </Button>
      </Grid>

    </Grid>
  )
}

export default Edit
