import React from 'react'


const Ellipse = ({ color, size,position,opacity }) => {
    const circleStyle = {
        width: size,
        height: size,
        backgroundColor: color,
        borderRadius: '50%',
        position: 'absolute',
        zIndex: '-1', // Pone el círculo detrás de los demás componentes
        ...position,
        opacity: opacity
    };
    
    return <div className="circleEllipse" style={circleStyle}></div>;
}

export default Ellipse