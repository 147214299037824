import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid} from "@mui/material"
import  { useNavigate } from 'react-router-dom';
import "./PanicButtom.css"
import { DirectoryContext } from '../../context/DirectoryContex';
import { OptionsPanicButtomContex } from '../../context/OptionsPanicButtomContex';
import { MyCreateTickectsModal } from './GenerateTickect';
import { GenerateTickectTechniciansModal } from '../TechniciansButton/GenerateTickectTechnicians';
import { GenerateTickectBMCModal } from '../BMCButton/GenerateTickectBMC';


const PanicButton= (props) => {
  const valueContext = React.useContext(DirectoryContext);
  const OptionsPanicValueContext = React.useContext(OptionsPanicButtomContex);
  const options = [
    { label: 'Problema de SAP', value: "Problema de SAP" },
    { label: 'Problema aplicaciones colaborativas', value: "Problema aplicaciones colaborativas" },
  ]
 
  let navigate = useNavigate();
  console.log("3",valueContext?.infoUser)
  console.log(OptionsPanicValueContext)

  let buttonClass = "panicButtom";
  if(props.rol === "Technicians" ){
    buttonClass = "panicButtomTechnicians";
  }
  
  return (
    
    <Grid container spacing={0}
    direction="column"
    alignItems="center"
      sx={{display:OptionsPanicValueContext.visibleSecondLevel}}
     >
      <Grid item xs={12} sm={12} md={12} lg={12}
        >
        <Card  elevation={6} 
        
          sx={
            { 
                width: {xs:300 ,sm:550, md:500, lg:500},
                minHeight:300,
                marginTop:3,
                background: "rgba(255, 255, 255, 0.164)",
                borderRadius: 20,
                boxShadow: "0px 0px 30px rgb(0, 0, 0)",
               
            }
          }
        >
          <CardContent  
            sx={{display:"flex",justifyContent:"center",
            fontSize: { xs:14 ,sm:20, md:25, lg:25 }}}>
              <button onClick={props.rol === "Technicians" && !props.BMC ? GenerateTickectTechniciansModal : props.rol === "Technicians" && props.BMC  ? GenerateTickectBMCModal : props.rol === "User" && props.BMC  ? GenerateTickectBMCModal : MyCreateTickectsModal} className={buttonClass}>{props.createTicketmessage}</button>   
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export {PanicButton}