import React from 'react'
import {Button, Grid} from '@mui/material'
import {Head} from '../../ComponentsUtilies/Head'
import {ListTable} from './ListTable'
import {getAlertModal} from '../../hooks/getAlertModal'
import {Form} from './Form'
import {useAppState} from '../../context/DirectoryContex'

export const Suppliers = () => {
  const [, reload] = useAppState('suppliers')

  return (
    <Grid container padding='1rem 2rem 0 2rem'>
      <Head>Proveedores</Head>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button color='success' onClick={reload}>
            Recargar
          </Button>
        </Grid>

        <Grid item xs={4}>
          <Button
            color='success'
            onClick={getAlertModal(Form, {
              title: 'Crear proveedor',
              supplier: null,
              reload: reload
            })}
          >
            Crear
          </Button>
        </Grid>

        <Grid item xs={12}>
          <ListTable />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Suppliers
