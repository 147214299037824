import { useEffect, useState, useMemo } from 'react'
import { TitleFirts } from '../ComponentsUtilies/Titles'
import MaterialReactTable from 'material-react-table'
import { Button, Grid } from '@mui/material'
import { Create } from './Create'
import { Edit } from './Edit'
import { HeadshipsAll, UpdateStateHeadships } from '../../services/headshipsService'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'
import { getAlertModal } from '../../hooks/getAlertModal'

const UpdateEstado = (id, load) => {
  UpdateStateHeadships(id)
    .then(() => {
      load(true)
      SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
    }).catch(error => {
      console.error(error)
      ErrorModal('Operacion con errores', error)
    })
}

export const Headships = () => {

  const [headships, setHeadships] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    HeadshipsAll()
      .then(data => setHeadships(data))
      .catch(error => console.error(error))
    setLoad(false)
  }, [load, setLoad, setHeadships])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Id'
      },
      {
        accessorKey: 'nombreJefatura',
        header: 'Nombre',

      },
      {
        accessorKey: 'interventore.nombre',
        header: 'Interventor',

      },
      {
        accessorKey: 'descripcion',
        header: 'Descripcion',

      },
      {
        header: 'Estado',
        accessorKey: 'estado',
        Cell: ({ cell }) => cell.getValue() === 1
          ? <Button variant='text' color='success'>Activo</Button>
          : <Button variant='text' color='error'>Inactivo</Button>
      },
      {
        header: 'Acciones',
        accessorFn: (row) => <Grid container direction='column' spacing={0.5} >
          <Grid item>
            <Button
              onClick={getAlertModal(Edit, { setLoad, id: row.id })}
            >Editar </Button>
          </Grid>
          <Grid item>{row.estado
            ? <Button color='error' onClick={() => UpdateEstado(row.id, setLoad)}  >Inactivar</Button>
            : <Button color='success' onClick={() => UpdateEstado(row.id, setLoad)}  >Activar</Button>
          }</Grid>
        </Grid>
      }
    ],
    [setLoad],
  )

  return (
    <>
      <TitleFirts>Jefaturas</TitleFirts>
      <Button color='success' sx={{
        width: 196,
        height: 35,
        marginBottom: 1
      }} onClick={getAlertModal(Create, { setLoad })}>Crear</Button>
      {
        headships ?
          <MaterialReactTable
            columns={columns}
            data={headships}
          /> : <MaterialReactTable
            columns={columns}
            data={0}
          />
      }
    </>
  )
}

export default Headships