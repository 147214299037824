import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Box, Typography, Grid, Button} from '@mui/material'
import {useTheme} from '@emotion/react'
import {getAlertModal} from '../../hooks/getAlertModal'
import Info from '../../ComponentsUtilies/Info'
import {useAppContext} from '../../context/DirectoryContex'
import {IconMan} from '../../media/IconMan'
import {IconWoman} from '../../media/IconWoman'
import {IconPeople} from '../../media/IconPeopleP'
import {procesEployees} from '../../services/processService'
import Edit from './Edit'
import {UpdateStateProcessV} from './UpdateState'
import {IconOrganization} from '../../media/IconOrganization'
import SubProcessesFormEdit from './EditSubProcesses'

const texProps = {
  color: 'black',
  fontSize: 12,
  fontWeight: 500
}

export const Detail = ({display, setLoad, setIsLoading, setOption}) => {
  const initialCounts = {
    empleados: [],
    active: 0,
    man: 0,
    woman: 0,
    headship: '',
    managements: ''
  }

  const navigate = useNavigate()
  const {palette} = useTheme()
  const {process, user, processes} = useAppContext()
  const [counts, setCounts] = useState(initialCounts)
  const [showEmployeesByProcess, setShowEmployeesByProcess] = useState({
    men: 'none',
    women: 'none'
  })

  useEffect(() => {
    procesEployees(process.id)
      .then((data) => {
        let hombres = data.empleados.filter(
          (element) => element.estado === 1 && element.sexo === 'MASCULINO'
        ).length
        let mujeres = data.empleados.filter(
          (element) => element.estado === 1 && element.sexo === 'FEMENINO'
        ).length
        let equipo = data.empleados.filter((element) => element.estado === 1).length

        setCounts({
          empleados: data.empleados,
          active: equipo,
          man: hombres,
          woman: mujeres,
          headship: data?.jefatura?.nombreJefatura,
          managements: data?.direccion?.nombreDireccion
        })
      })
      .catch((error) => console.error(error))
  }, [])

  if (!process) return <Box>Cargando...</Box>

  return (
    <Grid container sx={{display}}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12} md={4}>
          <Grid item>
            <Typography fontSize={24}>{process.nombre}</Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={12}>{process.Tipo}</Typography>
          </Grid>
        </Grid>

        <Grid>
          <Grid container sx={{marginTop: 1}}>
            <Grid container spacing={2} justifyContent='flex-start'>
              <Grid item>
                {user && user.authorization < 5 && (
                  <Button
                    color='secondary'
                    onClick={getAlertModal(Edit, {
                      setLoad: setLoad,
                      user: user,
                      IdProceso: process.id,
                      setIsLoading: setIsLoading,
                      setOption: setOption
                    })}
                  >
                    Editar
                  </Button>
                )}
              </Grid>

              <Grid item>
                <Button
                  color='secondary'
                  onClick={() => navigate(`/Documentos/${process.id}`)}
                >
                  Documentación
                </Button>
              </Grid>

              <Grid item>
                <Button
                  color='secondary'
                  onClick={() => navigate(`/Organigrama/Empleados/${process.id}`)}
                >
                  Organigrama
                </Button>
              </Grid>

              <Grid item>
                {user &&
                  user.authorization < 5 &&
                  (process.estado === 1 ? (
                    <Button
                      variant='contained'
                      color='error'
                      onClick={() =>
                        UpdateStateProcessV(process.id, setIsLoading, setOption, setLoad)
                      }
                    >
                      Inactivar
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      color='success'
                      onClick={() =>
                        UpdateStateProcessV(process.id, setIsLoading, setOption, setLoad)
                      }
                    >
                      Activar
                    </Button>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          border: `3px solid ${palette.disabled.main}`,
          borderRadius: '20px',
          marginTop: 2,
          padding: '0.3rem 0 0.3rem 1rem'
        }}
      >
        <Grid container sx={{marginTop: 2}}>
          <Grid item xs={12}>
            <Typography fontSize={20}>Descripción del proceso:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography noWrap fontSize={16} {...texProps}>
              {process.descripcion}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography noWrap fontSize={16}>
              Proceso padre:{' '}
              <Typography component='span' {...texProps}>
                {process.proceso.nombre}
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            container
            sx={{
              padding: '1rem 0',
              backgroundColor: palette.background.main,
              marginTop: 2
            }}
            display='flex'
            alignItems='end'
            justifyContent='flex-start'
          >
            <Grid item xs={12} md={6}>
              <Typography noWrap fontSize={14}>
                Dirección:{' '}
                <Typography component='span' {...texProps}>
                  {counts.managements}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography noWrap fontSize={14}>
                Jefatura:
                <Typography component='span' {...texProps}>
                  {counts.headship}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Typography fontSize={20}>Equipo del proceso</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid key={1} item xs={6} md={3}>
                <Info
                  Icon={<IconPeople />}
                  isSelected={true}
                  total={counts.active}
                  onClick={() => navigate(`/Organigrama/${process.id}`)}
                >
                  Equipo
                </Info>
              </Grid>
              <Grid key={2} item xs={6} md={3}>
                <Info
                  Icon={<IconMan />}
                  isSelected={true}
                  total={counts.man}
                  onClick={() => setShowEmployeesByProcess({men: '', women: 'none'})}
                >
                  Cantidad hombres
                </Info>
              </Grid>
              <Grid key={3} item xs={6} md={3}>
                <Info
                  Icon={<IconWoman />}
                  isSelected={true}
                  total={counts.woman}
                  onClick={() => setShowEmployeesByProcess({men: 'none', women: ''})}
                >
                  Cantidad mujeres
                </Info>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{marginTop: 4}}>
            <Typography fontSize={20}>Sub-procesos</Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container>
              {process.subprocesos.map((ele, idx) => (
                <Grid key={idx} item xs={6} md={3}>
                  <Info
                    Icon={<IconOrganization />}
                    isSelected={true}
                    total={
                      counts.empleados.filter((elem) => {
                        try {
                          return elem?.subprocesos.find(
                            (elemSubProcess) =>
                              elemSubProcess.IdSubProceso === ele.IdSubProceso
                          )
                        } catch (e) {
                          return false
                        }
                      }).length
                    }
                    buttonOptions={true}
                    funtions={getAlertModal(SubProcessesFormEdit, {
                      setLoad: setLoad,
                      IdProceso: ele.IdSubProceso,
                      setIsLoading: setIsLoading,
                      setOption: setOption,
                      processes,
                      subProcess: ele
                    })}
                  >
                    {ele.Nombre}
                  </Info>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {counts.empleados
            .filter((element) => element.sexo === 'MASCULINO')
            .map((ele, idx) => (
              <Grid key={idx} container sx={{display: showEmployeesByProcess.men}}>
                <Typography noWrap fontSize={14}>
                  <Typography component='span' {...texProps}>
                    • {ele.nombreCompleto} - {ele.cargo.nombre}
                  </Typography>
                </Typography>
              </Grid>
            ))}

          {counts.empleados
            .filter((element) => element.sexo === 'FEMENINO')
            .map((ele, idx) => (
              <Grid key={idx} container sx={{display: showEmployeesByProcess.women}}>
                <Typography noWrap fontSize={14}>
                  <Typography component='span' {...texProps}>
                    • {ele.nombreCompleto} - {ele.cargo.nombre}
                  </Typography>
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Detail
