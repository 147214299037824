import React from 'react'
import { CardMedia } from '@mui/material'
import iconOrganization from './iconOrganization.svg'
import iconOrganizationInactive from './iconOrganizationInactive.svg'

const IconOrganization = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconOrganization}
    />)
}


const IconOrganizationInactive = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconOrganizationInactive}
    />)
}

export {IconOrganization,IconOrganizationInactive} 