import {UpdateState} from "../../services/categoryService.js"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"


export const UpdateStateCategory=(id,load)=>{
    
    UpdateState(id).then(res=>{
        console.log(res, " aber")
        if(res.IdCategoria != null){
            console.log("entre a las 200")
           AlertSuccess()
           load(true)
        }
    }).catch(error=>console.log(error))
  
   
}


