import axios from "axios"

export const GetAllMargenesPep=async()=>{
   const request=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/marginpep/all")
   const data= await request.data
   return data
}



export const CreateMargenPep=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/marginpep/create", object)
    const data= await response.data
    return data
}

export const UpdateStateMargenPep=async(id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/marginpep/"+id)
    const data= await response.data
    return data
}

export const UpdateMargenPep=async(object,id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/marginpep/update/"+id,object)
    const data= await response.data
    return data
}

export const GetMargenPep=async(id)=>{
  const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/marginpep/"+id)    
  const data = await response.data
  return data
}