import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Typography, Grid, TextField, Button } from '@mui/material'
import { SucessModal, ErrorModal } from '../../ComponentsUtilies/AlertsModal/Alerts'
import { createCheckList } from '../../../services/documents'

export const Create = ({ processId, chequeoId, setLoad, close }) => {

    const { handleSubmit, register, formState: { errors } } = useForm()
    const [steps, setSteps] = useState([''])

    const onSubmit = (values) => {
        values.procesoId = processId
        values.chequeoId = chequeoId || null
        values.pasos = JSON.stringify(steps || [])
        setLoad && setLoad(false)
        createCheckList(values)
            .then(() => {
                setLoad && setLoad(true)
                close && close()
                SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
            })
            .catch((error) => {
                ErrorModal('Error al guardar proceso', error);
            })
    }

    return <Grid>

        <Typography color='primary' variant='h5' marginBottom={1}>
            Agregar check list
        </Typography>

        <Grid container spacing={3}>

            <Grid item xs={12} md={6} >
                <TextField
                    label='Nombre *'
                    {...register('nombre', { required: 'Este campo es obligatorio!' })}
                    helperText={errors.nombre?.message}
                    error={errors.nombre}
                />
            </Grid>

            <Grid item xs={12} md={6} >
                <TextField
                    label='Descripción *'
                    multiline
                    rows={3}
                    {...register('descripcion', { required: 'Este campo es obligatorio!' })}
                    helperText={errors.descripcion?.message}
                    error={errors.descripcion}
                />
            </Grid>

            <Grid item xs={12} >
                <Typography>Paso a paso</Typography>

                {steps.map((_, idx) =>
                    <TextField
                        key={idx}
                        label={`Paso ${idx + 1}`}
                        onChange={(e) => setSteps(s => {
                            s[idx] = e.target.value
                            return [...s]
                        })}
                    />
                )}
            </Grid>

            <Grid item xs={12}>
                <Grid
                    container
                    spacing={1}
                    alignContent='space-evenly'
                >
                    <Grid item >
                        <Button
                            color='success'
                            onClick={() => setSteps(s => {
                                s.push('')
                                return [...s]
                            })}
                        >+</Button>
                    </Grid>
                    <Grid item >
                        <Button
                            color='error'
                            onClick={() => setSteps(s => {
                                s.pop()
                                return [...s]
                            })}
                        >-</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent='space-evenly' padding='1rem 9%'>
                <Button color='success' onClick={handleSubmit(onSubmit)}>
                    Enviar
                </Button>
                <Button color='error' onClick={close}>
                    Cancelar
                </Button>
            </Grid>

        </Grid>
    </Grid>
}

export default Create