import axios from "axios"
import { Buffer } from "buffer";


const sendWhatsMessage = async (message,phoneSend)  =>{
    
    console.log(message)
    let auth = "Basic " + Buffer.from("admin" + ":" + "NutresaAxity2022%*").toString("base64");
    const request = await axios.get("https://3bmjwh36e7.execute-api.us-east-1.amazonaws.com/default/webHookWhatsApp",
    { params : {message: message,phoneSend:phoneSend} }
    /*,
    {
        "headers":{
           "Authorization":auth
        }
     }*/)
    const data = await request.data
    return data
}

export {sendWhatsMessage}