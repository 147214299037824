import * as React from 'react';
import { Grid  } from "@mui/material"
import "./PanicButtom.css"
import { OptionsPanicButtomContex } from '../../context/OptionsPanicButtomContex';
import { DirectoryContext } from '../../context/DirectoryContex';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { createTicket } from '../../services/createTicket';
import { Loading,AlertSuccess,AlertError } from '../ComponentsUtilies/AlertsModal/Alerts';
import { MyTickectsModal } from './MyTickects';
import { SearchTickectModal } from './SearchTickect';
import { MyCreateTickectsModalPQRS } from './GenerateTickectPQRS';
import { MyCreateTickectsModalInternet } from './GenerateTickectInternet';
import { MyCreateTickectsModalEquipo } from './GenerateTickectEquipo';

const PanicPremiumOptions= (props) => {

    const OptionsPanicValueContext = React.useContext(OptionsPanicButtomContex);
    const valueContext = React.useContext(DirectoryContext);
    
    
    
    const showTickectsModal = ()=>{
        //Loading("Estamos consultando tus casos");
        MyTickectsModal(props.rol);
    }
    const LoadingModal = () => {
        Loading("Estamos buscando ayuda");
    }

    const SuccessModal = (SR) => {
        AlertSuccess(`Por favor espérala el caso generado fue el ${SR}`,"La ayuda llegará pronto");
    }

    const getAffecteddate = ()=>{
        let date = new Date();
        let day = date.getDate();
    
        if(String(day).length === 1) day = "0" + day;
        var month = (date.getMonth()+1);
        if(String(month).length === 1) month = "0" + month;
        let hour = (date.getHours()-1)
        if(String(hour).length === 1) hour = "0" + hour ;
        if(hour === -1) hour = "00" ;
        let minute = (date.getMinutes())
        if(String(minute).length === 1) minute = "0" + minute;
        let seconds = (date.getSeconds())
        if(String(seconds).length === 1) seconds = "0" + seconds;
        let AFFECTEDDATE = date.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + seconds ;
        
        return AFFECTEDDATE;
    }
    
    const sendProblem = (type) =>{
        LoadingModal();
        createTicket(
            {
                REPORTEDBY: JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName,
                AFFECTEDPERSON: "SISS" ,
                DESCRIPTION: `${type} - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`,
                PLUSPCUSTOMER: "NUTRESA",
                CLASSSTRUCTUREID: "NUT101003009163",
                EXTERNALSYSTEM: "EMAIL",
                STATUS: "INPROG",
                "CONSECAM":"",
                "AFFECTEDDATE":getAffecteddate(),
                "REPORTDATE":getAffecteddate(),
                "OWNER":"Exssjlgaviri",
                "OWNERGROUP":"C-NUT-COL-CENTRO_DE_ADMIN_DE_DATOS_MAESTROS",
                "DESCRIPTION_LONGDESCRIPTION": ` ${type} - usuario ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`
            }
        ).then((response) =>{
           
            
            let data = JSON.parse(response.data.data); 
            let SR = data.data["soapenv:Envelope"]["soapenv:Body"].CreateIBMSRTUSEGUIMResponse.SRMboKeySet.SR.TICKETID._text;
            SuccessModal(SR);
            let message = `Hola ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName},\nPronto resolveremos tu "${type}" el caso generado fue el ${SR}`
            sendWhatsMessage(message,JSON.parse(localStorage.getItem("InforUser"))[0].mobile)
            .then((response) =>{
                console.log(response);
            })
            .catch((err) =>{
                console.log(err);
            });
        })
        .catch((err) =>{
            AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });
        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
    } 
    return (
    
    <Grid container spacing={0} direction="column"
    alignItems="center"
    sx={{display:OptionsPanicValueContext.visiblePremium}}>
    
    <Grid container spacing={0}
    direction="row"
    alignItems="center"
    sx={{fontWeight: 300,
    marginTop: 10,
    fontSize: { xs:14 ,sm:20, md:25, lg:25 },
    fontFamily: "Bahnschrift Condensed", 
    background: props.backgroundColor,
    height: 70,
    borderRadius: 8,   
    width: "100%"}}
    >   
       
        {
            props.rol === "User" ?
            <Grid item xs={2.4} container spacing={0}
                direction="column"
                alignItems="center"
            >   
                <p sx={{textAlign:"center"}} onClick={()=>MyCreateTickectsModalEquipo()} className='item-premium' >Problema equipo</p>
            </Grid>
            :""
        }

        <Grid item xs={props.rol === "User" ? 2.4 : 6} container spacing={0}
            direction="column"
            alignItems="center"
        >   
            <p onClick={showTickectsModal} className='item-premium' >Mis casos</p>
        </Grid>

        <Grid item xs={props.rol === "User" ? 2.4 : 6} container spacing={0}
            direction="column"
            alignItems="center"
        >   
            <p onClick={SearchTickectModal} className='item-premium' >Buscar caso</p>
        </Grid>

        {
            props.rol === "User" ?
            <Grid item xs={2.4} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >   
                <p onClick={()=>MyCreateTickectsModalInternet()}  className='item-premium'>Problema internet</p>
            </Grid>:""
        }

        {
            props.rol === "User" ?
            <Grid item xs={2.4} container spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >   
                <p onClick={MyCreateTickectsModalPQRS}  className='item-premium'>PQRS</p>
            </Grid>:""
        }
      
    </Grid>
    </Grid>
  );
}





export {PanicPremiumOptions}