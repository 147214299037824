import { Amplify } from "aws-amplify"

const userPoolId = 'us-east-1_N4qIYxVhj';
const clientId = '1keppsaeo29gbuq1hjfbossni3';
const region = 'us-east-1';

export const InitAmplify = () => {
  try {
    Amplify.configure({
      Auth: {
        userPoolId: userPoolId,
        region: region,
        userPoolWebClientId: clientId,
      },
      oauth: {
        domain: 'proyectonutresa.auth.us-east-1.amazoncognito.com',
        redirectSignIn: 'http://localhost:3000/', // URL de redirección después de iniciar sesión
        responseType: 'token', // Código de autorización (Authorization Code)
        scope: ['email', 'openid','aws.cognito.signin.user.admin'], // Alcances requeridos
        clientId: '1keppsaeo29gbuq1hjfbossni3', // ID de cliente de la app
      },
      
    });

  } catch (error) {
    console.log(error)
  }

}