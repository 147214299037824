import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import ImageIcon from '@mui/icons-material/Image'
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  InputAdornment
} from '@mui/material'
import {createDocument, getCategoriesAll} from '../../services/documents'
import {ProcessAll, getSubprocessOption} from '../../services/processService'
import {SucessModal, ErrorModal} from '../ComponentsUtilies/AlertsModal/Alerts'

export const Create = ({
  setLoad,
  processId,
  processName,
  folders,
  close,
  setIsLoading
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: {errors}
  } = useForm()
  const [file, setFile] = useState(null)
  const [carpeta, setCapeta] = useState('')
  const [process, setProcess] = useState([])
  const [subprocess, setSubprocess] = useState([])
  const [categories, setCategories] = useState([])
  const [foldersOption, setFoldersOption] = useState([])

  useEffect(() => {
    if (!folders) return
    const options = folders.map((folder) => ({label: folder, value: folder}))
    setFoldersOption(options)
  }, [folders])

  useEffect(() => {
    setValue('procesoId', processId)

    getCategoriesAll()
      .then((data) => setCategories(data.map((c) => ({label: c.nombre, value: c.id}))))
      .catch((error) => ErrorModal('Error al cargar las categoria', error))

    ProcessAll()
      .then((data) =>
        setProcess(data.map((p) => ({value: p.id, label: p.NombreProceso})))
      )
      .catch((error) => ErrorModal('Error al cargar los procesos', error))

    getSubprocessOption()
      .then((data) => setSubprocess(data))
      .catch((error) => ErrorModal('Error al cargar los subprocesos', error))
  }, [])

  const onSubmit = (values) => {
    values.doc = file
    values.carpeta = !carpeta ? '/' : carpeta[0] === '/' ? carpeta : '/' + carpeta
    setIsLoading(true)
    createDocument(values)
      .then(() => {
        reset()
        close()
        setLoad(false)
        setLoad(true)
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch((error) => {
        setLoad(false)
        ErrorModal('Error al guardar documento', error)
      })
      .finally(() => setIsLoading(false) && setLoad(false))
  }

  return (
    <Grid>
      <Typography color='primary' variant='h5' marginBottom={1}>
        Agregar Documento
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name='procesoId'
            rules={{required: 'Este campo es obligatorio!'}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={process}
                defaultValue={processName}
                onChange={(_, data) => onChange(data.value || '')}
                renderInput={(params) => (
                  <TextField
                    label='Proceso *'
                    {...params}
                    helperText={errors.procesoId?.message}
                    error={errors.procesoId}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name='IdSubproceso'
            rules={{required: 'Este campo es obligatorio!'}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={subprocess}
                onChange={(_, data) => {
                  if (!data) return
                  setCapeta('/' + data.label)
                  onChange(data.value || '')
                }}
                renderInput={(params) => (
                  <TextField
                    label='Subproceso *'
                    {...params}
                    helperText={errors.IdSubproceso?.message}
                    error={errors.IdSubproceso}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            type='file'
            label='Documento *'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <ImageIcon />
                </InputAdornment>
              )
            }}
            {...register('documento', {required: 'Este campo es obligatorio!'})}
            onChange={(e) => {
              console.log(e.target.files[0])
              setFile(e.target.files[0])
            }}
            helperText={errors.documento?.message}
            error={errors.documento}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label='Nombre documento *'
            {...register('nombre', {required: 'Este campo es obligatorio!'})}
            helperText={errors.nombre?.message}
            error={errors.nombre}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name='categoriaId'
            rules={{required: 'Este campo es obligatorio!'}}
            control={control}
            render={({field: {onChange}}) => (
              <Autocomplete
                disablePortal
                options={categories}
                onChange={(_, data) => onChange(data.value || '')}
                renderInput={(params) => (
                  <TextField
                    label='Categoria *'
                    {...params}
                    helperText={errors.categoriaId?.message}
                    error={errors.categoriaId}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            options={[...foldersOption, {label: carpeta, value: carpeta}]}
            onInputChange={(event, value) => {
              if (event && event.type !== 'blur') setCapeta(value)
            }}
            inputValue={carpeta}
            getOptionSelected={() => true}
            renderInput={(params) => <TextField label='Carpeta *' {...params} />}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='Descripcion del documento *'
            multiline
            rows={3}
            {...register('descripcion', {required: 'Este campo es obligatorio!'})}
            helperText={errors.descripcion?.message}
            error={errors.descripcion}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent='space-evenly' padding='1rem 9%'>
        <Button color='success' onClick={handleSubmit(onSubmit)}>
          Enviar
        </Button>
        <Button color='error' onClick={close}>
          Cancelar
        </Button>
      </Grid>
    </Grid>
  )
}

export default Create
