import { SvgIcon } from '@mui/material'
export const IconPeople = (props) => {
    return (<SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;people&#34;">
                <g id="Group">
                    <path id="Vector" d="M14.0733 5.41269C13.9968 5.40487 13.92 5.40487 13.8434 5.41269C12.7531 5.35479 11.8482 4.3926 11.8482 3.15398C11.8482 1.8777 12.8193 0.884369 13.9629 0.884369C15.0992 0.884369 16.0768 1.86965 16.0776 3.15246C16.0694 4.39312 15.1628 5.35481 14.0733 5.41269Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_2" d="M16.716 12.1477L16.7158 12.1479C15.9796 12.6701 14.9724 12.9344 13.9606 12.915C14.1744 12.2431 14.2859 11.5277 14.2932 10.7877L14.2932 10.7877V10.7828C14.2932 10.007 14.1716 9.25536 13.9333 8.55997C14.9588 8.53635 15.9656 8.80081 16.7085 9.32304L16.7085 9.32304L16.7101 9.32416C17.3179 9.74799 17.5685 10.2656 17.5693 10.7321C17.5701 11.1992 17.3208 11.7193 16.716 12.1477Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_3" d="M4.23067 5.41269C4.15413 5.40487 4.07732 5.40487 4.00078 5.41269C2.91051 5.35479 2.00555 4.3926 2.00555 3.15398C2.00555 1.86811 2.97662 0.884369 4.1112 0.884369C5.25592 0.884369 6.2259 1.86927 6.2259 3.15398C6.2259 4.3926 5.32094 5.35479 4.23067 5.41269Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_4" d="M1.35993 9.32306L1.35993 9.32307L1.36233 9.32136C2.05361 8.82864 3.0027 8.56297 3.97307 8.55859C3.74181 9.25389 3.62109 10.003 3.62109 10.783C3.62109 11.5306 3.7356 12.2507 3.94883 12.9255C2.99315 12.9146 2.06273 12.6456 1.35891 12.1559C0.75046 11.7272 0.5 11.2072 0.5 10.7398C0.5 10.2722 0.750741 9.75189 1.35993 9.32306Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_5" d="M9.17754 13.1755C9.08882 13.1671 8.99806 13.1677 8.90972 13.1756C7.55461 13.1105 6.4309 11.9173 6.43029 10.3907C6.43874 8.8158 7.62807 7.59278 9.05166 7.59278C10.4674 7.59278 11.6634 8.81663 11.664 10.3883C11.6556 11.9175 10.5392 13.1095 9.17754 13.1755Z" fill="#6CE6FE" stroke="#C8C8C6" />
                    <path id="Vector_6" d="M6.50879 18.77L6.50878 18.77C7.18612 19.2497 8.1064 19.5046 9.05166 19.5046C9.99689 19.5046 10.9171 19.2498 11.5945 18.7701L6.50879 18.77ZM6.50879 18.77L6.50695 18.7687M6.50879 18.77L6.50695 18.7687M6.50695 18.7687C5.93219 18.3656 5.69519 17.8702 5.69519 17.4231C5.69519 16.9757 5.93253 16.4775 6.50831 16.0693C7.19069 15.5891 8.11359 15.3344 9.05845 15.3344C10.0038 15.3344 10.9212 15.5893 11.5934 16.0682L11.5934 16.0682M6.50695 18.7687L11.5934 16.0682M11.5934 16.0682L11.5964 16.0703M11.5934 16.0682L11.5964 16.0703M11.5964 16.0703C12.1711 16.4735 12.4081 16.9688 12.4081 17.4159M11.5964 16.0703L12.4081 17.4159M12.4081 17.4159C12.4081 17.8634 12.1707 18.3618 11.5945 18.77L12.4081 17.4159Z" fill="#6CE6FE" stroke="#C8C8C6" />
                </g>
            </g>
        </svg>
    </SvgIcon>)
}
export default IconPeople