import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Tree, TreeNode} from 'react-organizational-chart'
import {Typography, Grid, Box, Card, CardContent} from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import {TitleFirts} from '../../ComponentsUtilies/Titles'
import {Sheet} from './Sheet'
import {mekeTree} from './mekeTree'
import {ProcessAll} from '../../../services/processService'
import {getBranchs} from './getBranchs'

export const ProcessFlowchart = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState({})
  const [branchs, setBranchs] = useState({})
  const [roots, setRoots] = useState([])
  const [processes, setProcesses] = useState([])

  useEffect(() => {
    ProcessAll()
      .then((allProcesses) => {
        setProcesses(allProcesses)
        const [newRoots, newBranchs] = mekeTree(allProcesses)
        setRoots(newRoots)
        setBranchs(newBranchs)
        Object.keys(newRoots).forEach((r) => setShow((s) => ({...s, [r]: false})))
      })
      .catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    let xy = null
    const handleMouseDown = (event) => {
      const {x, y} = event
      xy = {x, y}
    }
    const handleMouseMove = (event) => {
      if (xy) {
        const {x, y} = event
        window.scrollBy(xy.x - x, xy.y - y)
        xy = {x, y}
      }
    }
    const handleMouseSeup = () => {
      xy = null
    }
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseSeup)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseSeup)
    }
  }, [])

  return (
    <Box mt={3} mb={3}>
      <Grid container alignItems='center'>
        <ArrowBackIosNewOutlinedIcon
          sx={{
            color: '#3C1053',
            cursor: 'pointer'
          }}
          fontSize='large'
          onClick={() => navigate(-1)}
        />
      </Grid>
      {
        <Tree
          lineWidth='6px'
          lineColor='rgb(60, 16, 83)'
          lineBorderRadius='20px'
          label={
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Card
                sx={{
                  boxShadow: '0px 9px 20px rgb(60, 16, 83)',
                  backgroundColor: 'rgba(120, 60, 189, 0.4)'
                }}
              >
                <CardContent sx={{padding: '6px'}}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    Mapa de procesos Axity
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          }
        >
          {Object.entries(roots).map(([id, idx]) => (
            <TreeNode
              key={id}
              label={
                <Sheet
                  process={processes[idx]}
                  show={show[id]}
                  setShow={(state) => setShow((s) => ({...s, [id]: state}))}
                  isDad
                />
              }
            >
              {show &&
                show[id] &&
                getBranchs(processes, branchs, idx, show, setShow, true)}
            </TreeNode>
          ))}
        </Tree>
      }
    </Box>
  )
}
