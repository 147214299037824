import React from 'react'
import { CardMedia } from '@mui/material'
import iconWoman from './iconWoman.svg'

export const IconWoman = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='Imagen Login'
        image={iconWoman}
    />)
}
export default IconWoman 