import axios from "axios"


const loginBMC = async (usuario,contrasena)  =>{
    let reponse = "";
    console.log(usuario,contrasena)
    const params = new URLSearchParams();
    params.append('username', usuario);
    params.append('password', contrasena);
    reponse = await axios.post('https://mobility325-dsom-rest.trybmc.com/api/jwt/login', params)
    .then((resp)=>{
        console.log("resp",resp.data)
        return(
            {
                data:resp.data,
                error:""
            }
        )
        


    }).catch((err)=>{
        console.log("err",err.data[0].messageText)
        return(
            {
            data:"",
            error:err.data[0].messageText
            }
        )
    }  
    );

    return reponse;
}

const createTicketBMC = async (categoria1,categoria2,categoria3,fallaDescripcion)  =>{
    console.log(categoria1,categoria2,categoria3,fallaDescripcion)
    let reponse = "";
    const params = new URLSearchParams();
    params.append('username', 'Allen');
    params.append('password', 'Password_1234');
    let reponse2 = await axios.post('https://mobility325-dsom-rest.trybmc.com/api/jwt/login', params)
    .then((resp)=>{

        let body = {
        
            "values": {
            "z1D_Action":"CREATE",
            "Contact_Company":"Nutresa",
            "First_Name":"Daniela",
            "Last_Name":"Durán",
            "Login_ID":"Daniela",
            "Detailed_Decription":fallaDescripcion,
            "Description":fallaDescripcion,
            "Impact":2000,
            "Urgency":4000,
            "Service_Type":1,
            "Reported Source":1000,
            "Status": "New",
            "Categorization Tier 1":categoria2,
            "Categorization Tier 2":categoria1,
            "Categorization Tier 3":categoria3
            }
                
        }
        console.log(resp.data)
        reponse = axios.post(
        'https://mobility325-dsom-rest.trybmc.com/api/arsys/v1/entry/HPD:IncidentInterface_Create?fields=values(Incident Number, Request ID)',
        body,
        {
            "headers":{
               "Authorization":resp.data
            }
        }
        ).then((res) => {
            console.log(res)
        return(
            {
                data:res,
                error:""
            }
        )
        }).catch((err) => {
            console.log(err)
        return(
            {
            data:"",
            error:err.response.data
            }
        )
        })


    }).catch((err)=>{
        return(
            {
            data:"",
            error:err
            }
        )
    }  
    );

    return reponse;
}

const updateTicketBMC = async (valores,id)  =>{
    let reponse = "";
    const params = new URLSearchParams();
    params.append('username', 'Allen');
    params.append('password', 'Password_1234');
    let reponse2 = await axios.post('https://mobility325-dsom-rest.trybmc.com/api/jwt/login', params)
    .then((resp)=>{

        let body = {
        
            "values": valores
                
        }
        console.log(resp.data)
        reponse = axios.put(
        `https://mobility325-dsom-rest.trybmc.com/api/arsys/v1/entry/HPD:IncidentInterface/${id}?fields=values(*)`,
        body,
        {
            "headers":{
               "Authorization":resp.data
            }
        }
        ).then((res) => {
            console.log(res)
        return(
            {
                data:res,
                error:""
            }
        )
        }).catch((err) => {
            console.log(err)
        return(
            {
            data:"",
            error:err.response.data
            }
        )
        })


    }).catch((err)=>{
        return(
            {
            data:"",
            error:err
            }
        )
    }  
    );

    return reponse;
}

const queryTickectsBMC=async(query)=>{
    
    let reponse = "";
    const params = new URLSearchParams();
    params.append('username', 'Allen');
    params.append('password', 'Password_1234');
    let reponse2 = await axios.post('https://mobility325-dsom-rest.trybmc.com/api/jwt/login', params)
    .then((resp)=>{
        
        reponse = axios.get(
        `https://mobility325-dsom-rest.trybmc.com/api/arsys/v1/entry/HPD:IncidentInterface?${query}&fields=values(*)`,
        {
            "headers":{
               "Authorization":resp.data
            }
        }
        ).then((res) => {
            console.log(res)
        return(
            {
                data:res,
                error:""
            }
        )
        }).catch((err) => {
            console.log(err)
        return(
            {
            data:"",
            error:err.response.data
            }
        )
        })


    }).catch((err)=>{
        return(
            {
            data:"",
            error:err
            }
        )
    }  
    );

    return reponse;

}

const queryCommentsTickectsBMC=async(query)=>{
    
    let reponse = "";
    const params = new URLSearchParams();
    params.append('username', 'Allen');
    params.append('password', 'Password_1234');
    let reponse2 = await axios.post('https://mobility325-dsom-rest.trybmc.com/api/jwt/login', params)
    .then((resp)=>{
        
        reponse = axios.get(
        `https://mobility325-dsom-rest.trybmc.com/api/arsys/v1/entry/HPD:WorkLog?q='Incident Number'="${query}"&fields=values(*)`,
        {
            "headers":{
               "Authorization":resp.data
            }
        }
        ).then((res) => {
            console.log(res)
        return(
            {
                data:res,
                error:""
            }
        )
        }).catch((err) => {
            console.log(err)
        return(
            {
            data:"",
            error:err.response.data
            }
        )
        })


    }).catch((err)=>{
        return(
            {
            data:"",
            error:err
            }
        )
    }  
    );

    return reponse;

}

const commentTicketBMC = async (descripcion,longDescripcion,id)  =>{
    let reponse = "";
    const params = new URLSearchParams();
    params.append('username', 'Allen');
    params.append('password', 'Password_1234');
    let reponse2 = await axios.post('https://mobility325-dsom-rest.trybmc.com/api/jwt/login', params)
    .then((resp)=>{

        let body = {
        
            "values": {

                "Description":descripcion ,
                "Detailed Description": longDescripcion,
                "Incident Number": id,
                "Work Log Type": "General Information",
                "Secure Work Log": "No"
            }
                
        }
        console.log(resp.data)
        reponse = axios.post(
        'https://mobility325-dsom-rest.trybmc.com/api/arsys/v1/entry/HPD:WorkLog?fields=values(Work Log ID)',
        body,
        {
            "headers":{
               "Authorization":resp.data
            }
        }
        ).then((res) => {
            console.log(res)
        return(
            {
                data:res,
                error:""
            }
        )
        }).catch((err) => {
            console.log(err)
        return(
            {
            data:"",
            error:err.response.data
            }
        )
        })


    }).catch((err)=>{
        return(
            {
            data:"",
            error:err
            }
        )
    }  
    );

    return reponse;
}


export {createTicketBMC,queryTickectsBMC,commentTicketBMC,loginBMC,updateTicketBMC,queryCommentsTickectsBMC}