import React from 'react'
import { CardMedia } from '@mui/material'
import iconPeople from './iconPeopleI.svg'

export const IconPeopleI = (props) => {
    return (<CardMedia
        {...props}
        component='img'
        alt='PeopleI'
        image={iconPeople}
    />)
}
export default IconPeopleI 
