import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useTheme } from '@emotion/react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppContext } from '../context/DirectoryContex'

export const Info = ({ Icon, total, onClick, isSelected, children, minWidth, buttonOptions, funtions }) => {
    const { palette } = useTheme()
    const { user } = useAppContext()

    return <Grid container
        paddingBottom={0}
        paddingLeft={1}
        paddingRight={1}
        paddingTop={1}
    >
        <Box
            backgroundColor={palette.background.main}
            padding={1}
            border={`1px solid ${palette.primary.main}`}
            borderRadius='10px'
            height='100%'
            sx={{ cursor: 'pointer', minHeight: "70px", minWidth: minWidth ? minWidth : "90%" }}
            onClick={onClick}
        >
            <Grid container >
                <Grid container>
                    <Grid item xs={3}  >{Icon}</Grid>
                    <Grid item xs={9} >
                        <Typography textAlign='center' >{total}</Typography>
                        <Box
                            backgroundColor={isSelected ? palette.success.main : palette.secondary.main}
                            sx={{
                                margin: 'auto',
                                width: '90%',
                                borderRadius: '5px',
                                height: '5px'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}  >
                    <Typography textAlign='center'>{children}</Typography>
                </Grid>

                {buttonOptions && user.authorization < 5 &&
                    <Grid container item xs={12} justifyContent="space-between" alignItems="center"  >
                        <Grid item xs={2}  ><EditIcon item onClick={funtions} /></Grid>
                        {!total && <Grid item xs={2} ><DeleteIcon item /></Grid>}
                    </Grid>
                }

            </Grid>
        </Box>
    </Grid>
}

export default Info