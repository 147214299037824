import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {ThemeProvider} from '@mui/material/styles'
import {DirectoryProvider} from '../context/DirectoryContex'
import {axity} from '../themes/axity'

/**
 * Emvuelve un componente dentro de un modal con el tema de MUI
 * @param {Component} Element Componente para renderizar
 * @param {Object} props props para pasar a Element
 * @returns {function} Funcion que renderiza a Element
 */
export const getAlertModal = (Element, props) => {
  console.log("alert modal del sweet", props)
  return () => {
    const Alert = withReactContent(Swal)
    Alert.fire({
      width: '80%',
      showConfirmButton: false,
      showCancelButton: false,
      position: 'top',
      allowOutsideClick: false,
      html: (
        <ThemeProvider theme={axity}>
          <DirectoryProvider>
            <Element {...props} close={Alert.close} />
          </DirectoryProvider>
        </ThemeProvider>
      )
    })
  }
}
