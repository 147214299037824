import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Box, Typography, Avatar, Grid, Button} from '@mui/material'
import {useTheme} from '@emotion/react'
import {getAlertModal} from '../../../hooks/getAlertModal'
import {useAppContext, useAppState} from '../../../context/DirectoryContex'
import {Edit} from '../Edit'
import {UpdateState} from './UpdateState'
import {ProductItem} from './ProductItem'
import {IconSexo} from './IconSexo'

export const texProps = {
  color: 'black',
  fontSize: 12,
  fontWeight: 500
}

export const Detail = ({display, setLoad, setOption}) => {
  const navigate = useNavigate()
  const {palette} = useTheme()
  const {employee, user, leaders} = useAppContext()
  const [suppliers] = useAppState('suppliers')

  if (!employee) return <Box>Cargando...</Box>

  return (
    <Grid container sx={{display}}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item>
          <Avatar sx={{width: 100, height: 100}} alt='Persona' src={employee.foto} />
        </Grid>
        <Grid item>
          <Typography fontSize={24}>{employee.nombre}</Typography>
          <Typography fontSize={20}>RH: {employee.rh}</Typography>
        </Grid>
        <Grid item>
          <IconSexo sexo={employee.sexo} />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          border: `3px solid ${palette.disabled.main}`,
          borderRadius: '20px',
          marginTop: 2,
          padding: '0.3rem 0 0.3rem 1rem'
        }}
      >
        <Grid container sx={{marginTop: 1}}>
          <Grid item xs={12}>
            <Typography fontSize={20}>Información personal</Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4} md={2}>
              <Typography fontSize={14}>Cédula:</Typography>
              <Typography {...texProps}>{employee.cedula}</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Typography fontSize={14}>Celular:</Typography>
              <Typography {...texProps}>{employee.celular}</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Typography fontSize={14}>Fecha Nacimiento:</Typography>
              <Typography {...texProps}>
                {employee.fechaNacimiento.split('T')[0]}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Typography fontSize={14}>EPS:</Typography>
              <Typography {...texProps}>{employee.eps}</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Typography fontSize={14}>Ubicación:</Typography>
              <Typography {...texProps}>{employee.ciudad}</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Typography fontSize={14}>Nivel Académico:</Typography>
              <Typography {...texProps}>{employee.nivelAcademico}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{padding: '1rem 0', backgroundColor: palette.background.main}}
          display='flex'
          alignItems='end'
          justifyContent='flex-start'
        >
          <Grid item xs={12} md={3}>
            <Typography fontSize={14}>Contacto de emergencia: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography noWrap fontSize={14}>
              Nombre:{' '}
              <Typography component='span' {...texProps}>
                {employee.nombreContactoEmergencia}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography fontSize={14}>
              Celular:{' '}
              <Typography component='span' {...texProps}>
                {employee.celularContactoEmergencia}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{marginTop: 1}}>
          <Grid item xs={12}>
            <Typography fontSize={20}>Información empresa</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography fontSize={14}>Líder:</Typography>
            <Typography {...texProps}>{employee.liderName}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography fontSize={14}>Salario:</Typography>
            <Typography {...texProps}>{employee.salario}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography fontSize={14}>Correos:</Typography>
            <Typography fontSize={13}>
              Axity:{' '}
              <Typography component='span' {...texProps}>
                {employee.correoAxity}
              </Typography>
            </Typography>
            <Typography noWrap component='span' fontSize={13}>
              Nutresa:{' '}
              <Typography component='span' {...texProps}>
                {employee.correoNutresa}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography fontSize={14}>Usuarios de red:</Typography>
            <Typography fontSize={13}>
              Axity:{' '}
              <Typography component='span' {...texProps}>
                {employee.usuarioRedAxity}
              </Typography>
            </Typography>
            <Typography component='span' fontSize={13}>
              Nutresa:{' '}
              <Typography component='span' {...texProps}>
                {employee.usuarioRedNutresa}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography fontSize={14}>Pep asignado:</Typography>
            {employee.administracionPeps.map((pep) => (
              <Typography {...texProps}>{pep.pep}</Typography>
            ))}
          </Grid>
        </Grid>
        <Grid container sx={{marginTop: 1}}>
          <Grid item xs={12}>
            <Typography fontSize={19}>Elementos asignados</Typography>
          </Grid>
          <Grid item xs={12}>
            {Array.isArray(employee.productos) &&
              employee.productos.map((product) => <ProductItem product={product} />)}
          </Grid>
        </Grid>
        <Grid container sx={{marginTop: 1}}>
          <Grid item xs={12}>
            <Typography fontSize={19}>Acciones</Typography>
          </Grid>
          <Grid container spacing={2} justifyContent='flex-start'>
            <Grid item>
              <Button
                color='secondary'
                onClick={getAlertModal(Edit, {
                  employeeId: employee.id,
                  leaders,
                  user,
                  suppliers,
                  setLoad,
                  setOption
                })}
              >
                Editar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color='secondary'
                onClick={() => navigate(`/Compromisos/${employee.id}`)}
              >
                Compromisos
              </Button>
            </Grid>
            <Grid item>
              <UpdateState id={employee.id} isActive={employee.estado} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Detail
