import axios from "axios";
import {UpdateStateMargenPep} from "../../services/margenesPep"
import { AlertSuccess } from "../ComponentsUtilies/AlertsModal/Alerts"

export const UpdateMargenPepState=(id, load)=>{
 
   UpdateStateMargenPep(id).then(data=>{
       if(data.Id != null ){
         AlertSuccess()
          load(true)
       }
    }).catch(error=>{console.log(error)}) 


}