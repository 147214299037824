import axios from "axios";


export const GetAllAuditors=async()=>{
    const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/auditors/all")
    const data= await response.data
    return data
}

export const CreateAuditors=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/auditors/create", object)
    const data= await response.data
    return data
}

export const UpdateStateAuditors=async(id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/auditors/"+id)
    const data= await response.data
    return data
}

export const UpdateAuditors=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/auditors/update",object)
    const data= await response.data
    return data
}

export const GetAuditor=async(id)=>{
  const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/auditors/"+id)    
  const data = await response.data
  return data
}