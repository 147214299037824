import React, { useState, useEffect,useRef }  from 'react'
import { Grid } from '@mui/material'
import { useTheme } from "@emotion/react";
import HolidaysIcon from './HolidaysIcon';
import { IconFather } from '../../media/IconFather';
import { IconWomenHoliday } from '../../media/IconWomenHoliday';
import Ellipse from '../../ComponentsUtilies/Ellipse';
import { useAppContext } from '../../context/DirectoryContex'

const HolidaysOptions = () => {

    const { palette } = useTheme();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const gridRef = useRef(null);
   
  
  const updateScreenWidth = (width) => {
    setScreenWidth(width);
  };

  useEffect(() => {

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        updateScreenWidth(newWidth)
        
        // Aquí puedes realizar acciones adicionales en respuesta al cambio de tamaño
      }
    });

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };

    
  }, []);

  const generateRandomSize = () => {
    return Math.floor(Math.random() * 4) + 8 + "px"; // Tamaño aleatorio entre 20px y 70px
  };

  const generateRandomAlpha = () => {
    return Math.random() * 0.5 + 0.5; // Genera un número aleatorio entre 0.5 y 1
  };
  const generateRandomColor = () => {
    const colors = ["#FF7272", "#C670FF"];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    return randomColor; // Agrega el valor de alfa al color
  };

  const generateRandomPosition = () => {
    const randomX = Math.floor(Math.random() * (100 - 10)) + "%";
    const randomY = Math.floor(Math.random() * (100 - 10)) + "%";
    return { left: randomX, top: randomY };
  };


  return (
    <Grid
        container
        sx={{
          border: `3px solid ${palette.disabled.main}`,
          borderRadius: "20px",
          marginTop: 2,
          padding: "0.3rem 0 0.3rem 1rem",
          overflow: "hidden",
          position: "relative"
        }}
        direction="row"
        justifyContent="center"
        ref={gridRef}
        
      >
        {
          [...Array(60)].map((_, index) =>{
            return(
              <Grid key={index} item >
                <Ellipse key={index} size={generateRandomSize()} color={generateRandomColor()} position={generateRandomPosition()} opacity={generateRandomAlpha()} />
              </Grid>
            )
          }
        )}
        
        <HolidaysIcon 
          marginTop={screenWidth < 1050 ? "0" : "100"}
          text="Cumpleaños"
          topText="50" 
          selectedOption={"cumpleanos"}
          />
        <HolidaysIcon 
          text="Dia del padre"
          topText="60"
          icon={<IconFather color="blue" width="45"/>}
          selectedOption={"padres"}
        />
        <HolidaysIcon marginTop={screenWidth < 1050 ? "0" : "100"}
          text="Dia del hombre"
          topText="60"
          icon={<IconFather color="blue" width="45"/>}
          selectedOption={"hombres"}
        />
        <HolidaysIcon 
          text="Dia de la madre"
          topText="65"
          icon={<IconWomenHoliday color="red" width="45"/>}
          selectedOption={"madres"}  
        />
        <HolidaysIcon 
          marginTop={screenWidth < 1050 ? "0" : "100"}
          text="Dia de la mujer"
          topText="60"
          icon={<IconWomenHoliday color="red" width="45"/>}
          selectedOption={"mujeres"}  
        />
        <HolidaysIcon 
          text="Dia del niño"
          topText="50"
          selectedOption={"madresPadres"}
        />
    
    </Grid>
    
  )
}

export default HolidaysOptions