import axios from "axios";


export const GetAlldirectors=async()=>{
    const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/directors/all")
    const data= await response.data
    return data
}

export const Createdirectors=async(object)=>{
    const response= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/directors/create", object)
    const data= await response.data
    return data
}

export const UpdateStatedirectors=async(id)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/directors/"+id)
    const data= await response.data
    return data
}

export const Updatedirectors=async(object)=>{
    const response= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/directors/update",object)
    const data= await response.data
    return data
}

export const Getdirectors=async(id)=>{
  const response=await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/directors/"+id)    
  const data = await response.data
  return data
}