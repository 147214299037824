import React, {useEffect, useState} from 'react'
import ReactFileViewer from 'react-file-viewer'
import {useParams} from 'react-router-dom'
import {getDocument} from '../../services/documents'
import './viewerDocument.css'

export default function ViewerDocument() {
  const {id} = useParams()
  const [fileUrl, setfileUrl] = useState(null)
  const [fileType, setFileType] = useState('')

  useEffect(() => {
    console.log(id)
    getDocument(id)
      .then((document) => {
        setfileUrl(document.url)
        setFileType(document.url.split('.').pop().toLowerCase())
      })
      .catch((error) => console.error(error))
  }, [])

  return <ReactFileViewer fileType={fileType} filePath={fileUrl} />
}
