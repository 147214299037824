import React from 'react'
import { CardMedia } from '@mui/material'
import iconOpen from './iconOpen.svg'

export const IconOpen = (props) => {
    return (<CardMedia
        {...props}      
        component='img'
        alt='Imagen Login'
        image={iconOpen}
    />)
}
export default IconOpen 