import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ImageIcon from '@mui/icons-material/Image'
import { Grid, TextField, Button, Typography, InputAdornment } from '@mui/material'
import { editApp } from '../../services/appsService'
import { SucessModal, ErrorModal } from '../ComponentsUtilies/AlertsModal/Alerts'

export const Edit = ({ setLoad, data, close }) => {

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
  const [file, setFile] = useState(null)

  useEffect(() => {
    setValue('nombre', data.nombre)
    setValue('descripcion', data.descripcion)
    setValue('enlace', data.enlace)
  }, [data, setValue])

  const onSubmit = (values) => {
    values.doc = file
    values.procesoId = data.procesoIdProceso
    editApp(values, data.id)
      .then(() => {
        setLoad(true)
        reset()
        close()
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch(error => {
        console.error(error)
        ErrorModal('Error al guardar aplicación', error)
      })
  }

  return <Grid>
    <Typography color='primary' variant='h5' marginBottom={1}>
      Agregar aplicación
    </Typography>
    <Grid container spacing={3}>

      <Grid item xs={12} md={6} >
        <TextField
          sx={{ width: '85%' }}
          type='file'
          label='Imagen '
          InputProps={{
            startAdornment: <InputAdornment position='start'><ImageIcon /></InputAdornment>
          }}
          {...register('imagen')}
          onChange={(e) => setFile(e.target.files[0])}
        />
      </Grid>

      <Grid item xs={12} md={6} >
        <TextField
          sx={{ width: '85%' }}
          label='Nombre aplicación *'
          {...register('nombre', { required: true })}
          helperText={(errors.nombre?.type === 'required' && 'Este campo es obligatorio!')}
          error={errors.nombre?.type === 'required'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          sx={{ width: '85%' }}
          label='Enlace de la aplicación *'
          {...register('enlace', { required: true })}
          helperText={(errors.enlace?.type === 'required' && 'Este campo es obligatorio!')}
          error={errors.enlace?.type === 'required'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          sx={{ width: '85%' }}
          label='Descripcion de la aplicación *'
          {...register('descripcion', { required: true })}
          helperText={(errors.descripcion?.type === 'required' && 'Este campo es obligatorio!')}
          error={errors.descripcion?.type === 'required'}
        />
      </Grid>

    </Grid>
    <Grid container justifyContent='space-evenly' padding='1rem 9%' >
      <Button color='success' onClick={handleSubmit(onSubmit)} >Enviar</Button>
      <Button color='error' onClick={close}>Cancelar</Button>
    </Grid>
  </Grid>
}

export default Edit
