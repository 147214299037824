import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, TextField, Button, Autocomplete , Alert  } from "@mui/material"
import { useForm,Controller  } from "react-hook-form"
import InputAdornment from '@mui/material/InputAdornment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { TitleLogin } from '../ComponentsUtilies/Titles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { validateLoginDirectory } from '../../services/validateLoginDirectory';
import  { useNavigate } from 'react-router-dom';
import { WrongLogin } from '../ComponentsUtilies/AlertsModal/Alerts';
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2"
import { getInfoDirectory } from '../../services/validateLoginDirectory';
import { DirectoryContext } from '../../context/DirectoryContex';
import { Loading } from '../ComponentsUtilies/AlertsModal/Alerts';


const SweetAlert = withReactContent(Swal)

const LoginPanicButtonForm = () => {
  const { register, handleSubmit,control,formState: { errors },watch,clearErrors,setError } = useForm();
  let navigate = useNavigate();
  const valueContext = React.useContext(DirectoryContext);
  const onSubmit = (data) => {
    LoadingModal();
    validateLoginDirectory({
      user:data.usuario,
      password:data.contrasena,
    })
    .then((response)=>{
      SweetAlert.close()
      valueContext.setInfoUser(response)
      console.log(response)
      localStorage.setItem("InforUser", JSON.stringify(response) )
      return navigate("PanicButtom"); 
      
      
    })
    .catch((e)=>{
      wrongLoginModal()
      console.log(e)
    })
    
  };


  return (
    <Grid container spacing={0}
    direction="column"
    alignItems="center"
    
     >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card  elevation={6} 
        
          sx={
            { 
              width: {xs:300 ,sm:550, md:600, lg:600},
              minHeight:400,
              marginTop:12,
              opacity: 1,
              background: "rgba(250, 250, 250, 1)",
             
              
            }
          }
        >
          <CardContent >
              <Grid container direction="column" justify="center" alignItems="center" spacing={1} >
                <TitleLogin >Ingresar</TitleLogin>
                <Grid container justify="center" alignItems="center" item spacing={1}>
                  <Grid item container direction="column" justify="center" alignItems="center" xs={12} sm={12} md={12} lg={12} >
                    <TextField 
                      InputProps={{
                        startAdornment: (<InputAdornment position="start"><PersonOutlineOutlinedIcon style={{color:'#000'}} /></InputAdornment>),
                      }}
                      {...register("usuario", { required: true })}
                      label="Usuario *"
                      variant="outlined"
                      sx={{ marginTop: 5,
                        width: "90%",
                      }} 
                      helperText={(errors.usuario?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.usuario?.type === 'required')
                        && true
                      }
                    />
                  </Grid>
                  <Grid container direction="column" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                    <TextField 
                      type="password"
                      {...register("contrasena", { required: true })}
                      label="Contraseña *"
                      InputProps={{
                        startAdornment: (<LockOutlinedIcon position="start"><LockOutlinedIcon style={{color:'#000'}}/></LockOutlinedIcon>),
                       
                      }}
                      variant="outlined"
                      sx={{ marginTop: 5,
                        
                        width: "90%",
                        
                      }} 
                      helperText={ (errors.contrasena?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.contrasena?.type === 'required')
                        && true
                      }
                    />
                  </Grid>
                </Grid>
                <Button 
                onClick={handleSubmit(onSubmit)}
                sx={{marginTop:4}} variant="outlined">Ingresar</Button>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const LoadingModal = () => {
  Loading("Ingresando por favor espere");
}

const wrongLoginModal = () => {
  WrongLogin();
}



export {LoginPanicButtonForm}