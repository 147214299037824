export const IconWomenHoliday = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={`${props.width}pt`}
      height={`${props.height}pt`}
      viewBox="0 0 107.000000 95.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,95.000000) scale(0.100000,-0.100000)"
        fill={props.color}
        stroke="none"
      >
        <path d="M481 684 c-17 -14 -36 -43 -42 -65 -11 -35 -14 -38 -38 -31 -65 17 -133 -7 -156 -55 -7 -17 -17 -21 -53 -20 -37 2 -48 -2 -69 -27 -19 -23 -24 -38 -21 -68 4 -39 3 -39 -24 -34 -45 9 -34 -18 20 -46 134 -73 362 -110 551 -89 128 13 231 41 312 82 58 29 75 57 31 51 -20 -3 -22 1 -22 42 0 32 -6 52 -20 66 -25 25 -63 35 -87 22 -14 -9 -20 -5 -30 18 -20 49 -89 73 -160 56 -29 -7 -33 -5 -33 14 0 52 -57 110 -109 110 -11 0 -33 -12 -50 -26z m94 -39 c54 -53 16 -178 -49 -161 -56 14 -71 113 -24 163 27 29 43 29 73 -2z m-162 -113 c23 -21 22 -77 -4 -118 -58 -94 -216 -112 -256 -29 -19 39 -11 81 17 90 29 9 60 -12 60 -41 0 -23 -3 -25 -26 -20 -21 6 -25 4 -22 -11 4 -24 75 -32 84 -10 4 11 16 14 42 10 29 -4 41 0 56 16 16 18 17 25 7 48 l-12 27 -12 -24 c-7 -13 -17 -25 -23 -27 -21 -7 -45 28 -42 61 2 25 9 33 38 43 39 13 66 9 93 -15z m370 0 c22 -19 22 -44 -1 -72 -21 -25 -52 -18 -52 12 0 17 -3 18 -15 8 -40 -33 -1 -88 55 -76 18 3 33 0 40 -9 11 -13 80 -22 80 -11 0 2 3 11 6 20 4 11 -1 15 -22 13 -39 -3 -44 26 -9 49 47 31 84 -15 59 -74 -24 -58 -104 -77 -179 -43 -133 60 -137 228 -5 203 14 -3 33 -12 43 -20z m-266 -103 c8 -8 21 -7 47 2 32 11 38 11 53 -4 22 -22 7 -55 -41 -92 l-36 -28 -39 27 c-45 31 -63 65 -49 89 10 19 48 23 65 6z m-28 -141 c1 -6 -47 -3 -118 8 l-34 5 30 19 c17 10 34 23 38 30 4 7 20 -1 45 -22 21 -18 39 -36 39 -40z m229 11 c-7 -3 -42 -9 -77 -12 l-63 -5 38 37 39 37 38 -25 c25 -17 33 -27 25 -32z" />
      </g>
    </svg>
  );
};
