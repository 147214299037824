import React, {useMemo} from 'react'
import MaterialReactTable from 'material-react-table'
import {useTheme} from '@emotion/react'
import {useNavigate} from 'react-router-dom'
import {Typography, Grid, Button} from '@mui/material'
import {useAppContext} from '../../context/DirectoryContex'
import {getAlertModal} from '../../hooks/getAlertModal'
import {Edit} from './Edit'
import {updateDucumentState} from '../../services/documents'
import {SucessModal, ErrorModal} from '../ComponentsUtilies/AlertsModal/Alerts'

const cellProps = {
  color: 'black',
  fontSize: 14,
  noWrap: true,
  margin: '0.1rem'
}

export const ListAll = ({columnFilters, isLoading, setIsLoading, setLoad, folders}) => {
  const {palette} = useTheme()
  const {documents} = useAppContext()
  const navigate = useNavigate()
  const pdateEstado = (id) => {
    setIsLoading(true)
    setLoad(true)
    updateDucumentState(id)
      .then(() => {
        setLoad(false)
        setIsLoading(false)
        SucessModal('la operacion se ejecuto', 'Operacion Exitosa')
      })
      .catch((error) => {
        setIsLoading(false)
        setLoad(false)

        ErrorModal('Operacion con errores', error)
      })
      .finally(() => {
        setIsLoading(false)
        setLoad(false)
      })
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        id: 'id',
        maxSize: 2,
        minSize: 1,
        header: <Typography></Typography>,
        Cell: ({row}) => (
          <Typography {...cellProps} sx={{paddingLeft: 1}}>
            {row.index + 1}.
          </Typography>
        )
      },
      {
        header: <Typography>Nombre documento</Typography>,
        accessorKey: 'nombre',
        id: 'nombre',
        Cell: ({cell}) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      },
      {
        header: <Typography>Carpeta</Typography>,
        accessorKey: 'carpeta',
        id: 'carpeta',
        Cell: ({cell}) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        ),
        filterFn: ({original: {carpeta: path}}, _, filter) =>
          path === filter || filter === '/'
      },
      {
        header: <Typography>Descripcion</Typography>,
        accessorKey: 'descripcion',
        Cell: ({cell}) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      },
      {
        id: 'categoriaNme',
        header: <Typography>Categoria</Typography>,
        accessorKey: 'doc_categoriaName',
        Cell: ({cell}) => <Typography {...cellProps}>{cell.getValue()}</Typography>
      },

      {
        accessorKey: 'docCategoriaId',
        Cell: ({row}) =>
          row.original.estado ? (
            <>
              <Grid>
                <Button
                  variant='text'
                  color='success'
                  sx={{margin: 1}}
                  onClick={() => pdateEstado(row.original.id)}
                >
                  Activo
                </Button>
                <Button
                  variant='text'
                  color='secondary'
                  disabled={row.estado}
                  href={`/Documento/ver/${row.original.id}`}
                  target='_blank'
                  sx={{margin: 1}}
                >
                  ver
                </Button>
                <Button
                  variant='text'
                  color='secondary'
                  sx={{margin: 1}}
                  onClick={getAlertModal(Edit, {
                    document: {...row.original},
                    folders,
                    setIsLoading,
                    setLoad
                  })}
                >
                  Editar{' '}
                </Button>
              </Grid>
            </>
          ) : (
            <Button
              variant='text'
              color='error'
              sx={{margin: 1}}
              onClick={() => pdateEstado(row.original.id)}
            >
              Inactivo
            </Button>
          )
      },
      {
        accessorKey: 'estado',
        id: 'estado',
        Cell: ({cell}) => (
          <Typography {...cellProps} noWrap={false}>
            {cell.getValue()}
          </Typography>
        )
      }
    ],
    [setLoad]
  )

  return (
    <Grid item xs={12}>
      <MaterialReactTable
        columns={columns}
        data={documents}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        //enablePagination={false}
        //enableColumnResizing
        initialState={{
          isLoading: true,
          columnVisibility: {estado: false, carpeta: false}
        }}
        state={{isLoading, columnFilters}}
        muiTableBodyProps={{
          sx: {
            borderCollapse: 'separate',
            border: `0.6rem solid ${palette.background.main}`
          }
        }}
        muiTableBodyRowProps={({row}) => ({
          sx: {
            cursor: 'pointer',
            borderCollapse: 'separate',
            backgroundColor: row.index % 2 === 0 ? 'white' : palette.background.main
          }
        })}
        muiTableBodyCellProps={{sx: {padding: '0', border: 'none'}}}
      />
    </Grid>
  )
}

export default ListAll
