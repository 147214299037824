import React from "react";

const OptionsPanicButtomContex = React.createContext();

const OptionsPanicButtomProvider = (props)=>{
    
    const [visiblePremium,SetVisiblePremium] = React.useState("none");
    const [visibleSecondLevel,SetVisibleSecondLevel] = React.useState("none");
    const [visibleNormal,SetVisibleNormal] = React.useState("none");
    const [visibleFirstLevel,SetVisibleFirstLevel] = React.useState("");
    const [optionsTicket,SetOptionsTicket] = React.useState("");


    return(
        <OptionsPanicButtomContex.Provider value={{
            visiblePremium,
            visibleSecondLevel,
            visibleNormal,
            visibleFirstLevel,
            optionsTicket,
            SetVisiblePremium,
            SetVisibleSecondLevel,
            SetVisibleNormal,
            SetVisibleFirstLevel,
            SetOptionsTicket
        }}>
            {props.children}
        </OptionsPanicButtomContex.Provider>
    )
}



export{OptionsPanicButtomProvider,OptionsPanicButtomContex};