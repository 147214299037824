import { useEffect,useState,useMemo } from "react";
import { TitleFirts } from "../ComponentsUtilies/Titles"
import {GetAllPositions} from "../../services/positionsService"
import MaterialReactTable from "material-react-table"
import { Button } from "@mui/material";
import {ModalCreatePositions} from "./CreatePositions"
import {UpdatePositionsState} from "./UpdateState"
import {ModalEditPositions} from "./EditPositions"

export const ListPositions=()=>{
    const [positions, setPositions]=useState()
    const [load,setLoad]=useState(false) 
    useEffect(()=>{
        GetAllPositions().then(data=>setPositions(data)).catch(error=>console.log(error))
        setLoad(false)
    },[load])

    const columns = useMemo(
        () => [
            {
                accessorKey: "idCargo",
                header: "Id posiciones"
            },
          {
            accessorKey: 'nombre', 
            header: 'Nombre',
            
          },
          {
            accessorKey: 'descripcion', 
            header: 'Descripcion',
            
          },
          {
            accessorKey: 'estado', 
            header: 'Estado',
            Cell : ({cell})=>cell.getValue() == 1 ? <Button variant="text" color="success">Activo</Button> : <Button variant="text" color="error">Inactivo</Button>
          },
          {
            accessorFn: (row) => row.estado ?  <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 108, marginBottom:1}} onClick={()=>ModalEditPositions(setLoad,row.idCargo)}>Editar </Button>
            <Button variant="contained" color="error" sx={{ marginLeft: 1 }}  onClick={()=>UpdatePositionsState(row.idCargo, setLoad)}  >Inactivar</Button> 
            </>  : <>
            <Button variant="contained" sx={{ marginLeft: 1, width: 90, marginBottom:1}} onClick={()=>ModalEditPositions(setLoad,row.idCargo)}>Editar </Button>
             <Button variant="contained" color="success" sx={{ marginLeft: 1 }} onClick={()=>UpdatePositionsState(row.idCargo, setLoad)}  >Activar</Button></>
                
                      , //alternate way
            header: 'Acciones',
           
           
           
            
          }
        
          
        ],
        [],
      );
 
  return(
    <>
      <TitleFirts>Posiciones</TitleFirts>
      <Button variant="contained" color="success" sx={{ width: 196,
      height: 35,
      marginBottom:1}} onClick={()=>ModalCreatePositions(setLoad)}>Crear</Button>
      {
        positions != null ? 
      <MaterialReactTable 
       columns={columns}
       data={positions}
      /> : <MaterialReactTable 
      columns={columns}
      data={0}
     />
         }
    </>
  )  
}