import Swal from "sweetalert2";
import { MyTickects } from "../../PanicButtom/MyTickects";

export const AlertSuccess=(text,title)=>{
    Swal.close()
    Swal.fire({
        icon: "success",
        text: text ?? "La operacion se ejecuto correctamente",
        title: title ??  "Se ejecuto la operacion"
    })
}

export const AlertSuccessHTML=(text,title)=>{
    Swal.close()
    Swal.fire({
        icon: "success",
        html: text ,
        title: title ,
        customClass:"classFormModal",
    })
}

export const AlertError=(text,title)=>{
    Swal.close()
    Swal.fire({
        icon: "error",
        text: text ?? "La operacion no se pudo ejecutar",
        title: title ?? "ocurrio un error"
    })   
}

export const AlertErrorHTML=(text,title)=>{
    Swal.close()
    Swal.fire({
        icon: "error",
        html: text ,
        title: title 
    })   
}

export const WrongLogin=()=>{
    Swal.close()
    Swal.fire({
        icon: "error",
        text: "Verifique su usuario y contraseña",
        title: "ocurrio un error"
    })   
}

export const SucessModal=(text,title)=>{

    Swal.fire({
        icon: "success",
        text: text,
        title: title
    })
}

export const ErrorModal=(text,title)=>{

    
    Swal.fire({
        icon: "error",
        text: text,
        title: title
    })
}

export const Loading=(title)=>{
    Swal.close()
    Swal.fire({
        title: title,
        didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b');
        },
        showClass: {
            popup: 'animate_animated animate_fadeInDown'
        },
        hideClass: {
            popup: 'animate_animated animate_fadeOutUp'
        },
        showConfirmButton: false,
        allowOutsideClick: false,
    })   
    
}