import axios from "axios"

export const getCategorys=async()=>{
     const peticion= await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/category/all")
     const data=await peticion.data
     return data
}

export const UpdateState=async(id)=>{
     const peticion= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/category/updateState/"+id)
     const data=await peticion.data
     return data
}

export const GetCategory=async(id)=>{
     const peticion= await axios.get("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/category/getcategory/"+id)
     const data=await peticion.data
     return data
}

export const AddCategory=async(object)=>{
     const peticion= await axios.post("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/category/create",object)
     const data=await peticion.data
     return data
}

export const UpdateCategory=async(object)=>{
     const peticion= await axios.put("https://0yets2xo9j.execute-api.us-east-1.amazonaws.com/category/update/"+object.idCategory,object)
     const data=await peticion.data
     return data
}