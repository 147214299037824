
import * as React from 'react';
import { Button,Box,Grid,TextField,InputAdornment,Autocomplete} from "@mui/material";
import { TitleFirts } from '../ComponentsUtilies/Titles'; 
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2"
import { useForm,Controller  } from "react-hook-form"
import { createTicket } from '../../services/createTicket';
import { sendWhatsMessage } from '../../services/sendWhatsMessage';
import { Loading,AlertSuccess,AlertError } from '../ComponentsUtilies/AlertsModal/Alerts';
import { categoriasPrincipales,categoriasSecundarias } from '../../categoriasContactoLista';
import { useState } from "react"
import Skeleton from '@mui/material/Skeleton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Typography from '@mui/joy/Typography';
import { getDataUserADS } from '../../services/getDataUserADS';

const SweetAlert = withReactContent(Swal);



const GenerateTickectTechnicians=()=>{
    const { register, handleSubmit,control,formState: { errors },watch,clearErrors,setError,setValue } = useForm();
    const [subcategoriaOptions, setSubcategoriaOptions] = useState([]);
    const [resetSubcategoriaValue, setResetSubcategoriaValue] = useState("");
    const [userValue, setUserValue] = useState("");
    const [showErrorSearchUser, setShowErrorSearchUser] = useState(false);
    const [userData, setUserData] = useState({
      cedula:"",
      nombre:""
    });
    const [userDataLoading, setUserDataLoading] = useState(false);

    
    const LoadingModal = () => {
        Loading("Estamos buscando ayuda");
    }

    const SuccessModal = (SR) => {
        AlertSuccess(`Por favor espérala caso generado ${SR}`,`La ayuda llegará pronto`);
    }

    const getAffecteddate = ()=>{
      let date = new Date();
      let day = date.getDate();
  
      if(String(day).length === 1) day = "0" + day;
      var month = (date.getMonth()+1);
      if(String(month).length === 1) month = "0" + month;
      let hour = (date.getHours()-1)
      if(String(hour).length === 1) hour = "0" + hour ;
      if(hour === -1) hour = "00" ;
      let minute = (date.getMinutes())
      if(String(minute).length === 1) minute = "0" + minute;
      let seconds = (date.getSeconds())
      if(String(seconds).length === 1) seconds = "0" + seconds;
      let AFFECTEDDATE = date.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + seconds ;
      
      return AFFECTEDDATE;
    }
    
    const sendProblem = (type) =>{
        console.log(type)
        
        LoadingModal();
        createTicket(
            {
                REPORTEDBY: type.usuarioRed.toUpperCase(),
                AFFECTEDPERSON: type.usuarioRed.toUpperCase() ,
                DESCRIPTION: `Soporte ${type.categoria.value} - ${type.subCategoria.value} / usuario ${type.usuarioNombre} - ${type.usuarioCedula} - ${type.usuarioRed} / técnico ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName}`,
                PLUSPCUSTOMER: "NUTRESA",
                CLASSSTRUCTUREID: type.subCategoria.value,
                EXTERNALSYSTEM: "EMAIL",
                STATUS: "INPROG",
                "CONSECAM":"",
                "AFFECTEDDATE":getAffecteddate(),
                "REPORTDATE":getAffecteddate(),
                "OWNER":JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName,
                "OWNERGROUP":"C-NUT-COL-CENTRO_DE_ADMIN_DE_DATOS_MAESTROS",
                DESCRIPTION_LONGDESCRIPTION: `Soporte ${type.categoria.value} - ${type.subCategoria.value} \n Usuario ${type.usuarioNombre} - ${type.usuarioCedula} - ${type.usuarioRed} \n Técnico ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName} - ${JSON.parse(localStorage.getItem("InforUser"))[0].description} - ${JSON.parse(localStorage.getItem("InforUser"))[0].sAMAccountName} \n Descripción falla: \n ${type.fallaDescripcion} `
            }
        ).then((response) =>{
            console.log(response)
            let data = JSON.parse(response.data.data); 
            let SR = data.data["soapenv:Envelope"]["soapenv:Body"].CreateIBMSRTUSEGUIMResponse.SRMboKeySet.SR.TICKETID._text;
            SuccessModal(SR);
            let message = `Hola ${JSON.parse(localStorage.getItem("InforUser"))[0].displayName},\nPronto resolveremos tu "${type.categoria.value}" el caso generado fue el ${SR}`
            sendWhatsMessage(message,JSON.parse(localStorage.getItem("InforUser"))[0].mobile)
            .then((response) =>{
                console.log(response);
            })
            .catch((err) =>{
                console.log(err);
            });
        })
        .catch((err) =>{
          AlertError("Ocurrió un error por favor vuelva a intentarlo","Ocurrió un error");
        });
        //sendWhatsMessage(valueContext.infoUser[0].mobile,valueContext.infoUser[0].displayName,type)
    } 

    const categoriaChange = (event, value) => {
      setResetSubcategoriaValue("");
      let filteredCategoria = [];
      let filteredSubCategoriasOptions = [];
      filteredCategoria = categoriasSecundarias.filter(element => element.categoria === value?.value);
      if (filteredCategoria) {
        filteredCategoria.forEach(element => {
          filteredSubCategoriasOptions.push({
            label: element.subCategoria,
            value: element.codigo
          });
        });
      }
      setSubcategoriaOptions(filteredSubCategoriasOptions);
    }

    const usuarioRedChange = (e)=>{
      setUserValue(e)
      setValue("usuarioRed",e)
    }
    const searchInfoUser = (e) =>{
      console.log(e)
      if(e!==""){
        setUserDataLoading(true)
        getDataUserADS(userValue)
        .then((response)=>{
          console.log(response)
          if(response[0]){
            setUserData({
              cedula:response[0].description,
              nombre:response[0].displayName
            })
            setValue("usuarioNombre",response[0].displayName)
            setValue("usuarioCedula",response[0].description  )
            clearErrors("usuarioRed")
            setShowErrorSearchUser(false);
            console.log(errors)
          }else{
            setShowErrorSearchUser(true)
            setUserValue("");
            setUserData({
              cedula:"",
              nombre:""
            })
          }
          setUserDataLoading(false)
        })
        .catch((error)=>{
          console.log(error)
          setUserDataLoading(false)
          setShowErrorSearchUser(true)
          setUserValue("");
          setUserData({
            cedula:"",
            nombre:""
          })
        })
      }
      
    }
    
    const onSubmit = (data) => {
        sendProblem(data)
    };
    return (
        <>
             
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} >
         <Grid container spacing={1}>
         
         <Grid item xs={12} sm={12} md={12} lg={12} >
            <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><PersonOutlineOutlinedIcon style={{color:'#3C1053'}} /></InputAdornment>),
              }}
              {...register("usuarioRed", { required: true })}
              label="Usuario de red cliente *"
              variant="outlined"
              value={userValue}
              onBlur={(e)=>searchInfoUser(e.target.value)}
              onChange={(e)=>usuarioRedChange(e.target.value)}
              sx={{ marginTop: 5,
                width: "90%",
              }} 

              helperText={(errors.usuarioRed?.type === 'required' && "Este campo es obligatorio!")
              || (showErrorSearchUser && "Error buscando el usuario vuelva a ingresarlo")}
              error={
                (errors.usuarioRed?.type === 'required' || showErrorSearchUser) 
                && true
              }
            />

            
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} display="flex"  justifyContent="center"  >
            
            
            {
              userDataLoading ?
              <Skeleton 
                sx={{ marginTop: 3,
                  width: "90%",
                }} 
              />
              : userData.cedula ?
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <TextField 
                  InputProps={{
                    startAdornment: (<InputAdornment position="start"><PersonOutlineOutlinedIcon style={{color:'#3C1053'}} /></InputAdornment>),
                  }}
                  {...register("usuarioCedula")}
                  label="Cedula usuario *"
                  variant="outlined"
                  value={userData.cedula}
                  disabled
                  onBlur={(e)=>searchInfoUser(e.target.value)}
                  onChange={(e)=>usuarioRedChange(e.target.value)}
                  sx={{ marginTop: 5,
                    width: "90%",
                  }} 

                  helperText={(errors.usuarioRed?.type === 'required' && "Este campo es obligatorio!")
                  || (showErrorSearchUser && "Error buscando el usuario vuelva a ingresarlo")}
                  error={
                    (errors.usuarioRed?.type === 'required' || showErrorSearchUser) 
                    && true
                  }
                />
              </Grid>:""
            }
            
            
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} display="flex"  justifyContent="center" >
            {
              userDataLoading ?
              <Skeleton 
                sx={{ marginTop: 3,
                  width: "90%",
                }} 
              />
              : userData.nombre ?
              
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <TextField 
                  InputProps={{
                    startAdornment: (<InputAdornment position="start"><PersonOutlineOutlinedIcon style={{color:'#3C1053'}} /></InputAdornment>),
                  }}
                  {...register("usuarioNombre")}
                  label="Nombre usuario *"
                  variant="outlined"
                  value={userData.nombre}
                  disabled
                  onBlur={(e)=>searchInfoUser(e.target.value)}
                  onChange={(e)=>usuarioRedChange(e.target.value)}
                  sx={{ marginTop: 5,
                    width: "90%",
                  }} 

                  helperText={(errors.usuarioRed?.type === 'required' && "Este campo es obligatorio!")
                  || (showErrorSearchUser && "Error buscando el usuario vuelva a ingresarlo")}
                  error={
                    (errors.usuarioRed?.type === 'required' || showErrorSearchUser) 
                    && true
                  }
                />

                
              </Grid>:""
            }
          </Grid>

          
          


         <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
            <Controller
              name="categoria"
              rules={{ required: true }}
              control={control}
              render={({ field:{onChange,value } }) => 
                <Autocomplete
                  disablePortal
                  options={categoriasPrincipales}
                  sx={{ marginTop: 5, width: "90%", }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label={"Seleccione la categoria *"}   
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <ConfirmationNumberIcon style={{color:'#3C1053'}}/>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}                  
                      helperText={(errors.categoria?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.categoria?.type === 'required')
                        && true
                      }
                    />
                  }
                  onChange={(_,data)=>{
                    onChange(data);
                    categoriaChange(_, data);
                    return data;
                  }}
                />
              }
            /> 
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
            <Controller
              name="subCategoria"
              rules={{ required: true }}
              control={control}
              render={({ field:{onChange,value } }) => 
                <Autocomplete
                  disablePortal
                  options={subcategoriaOptions}
                  value={resetSubcategoriaValue}
                  sx={{ marginTop: 5, width: "90%", }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label={"Seleccione la Sub-categoria *"}   
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <ConfirmationNumberIcon style={{color:'#3C1053'}}/>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}                  
                      helperText={(errors.subCategoria?.type === 'required' && "Este campo es obligatorio!")}
                      error={
                        (errors.subCategoria?.type === 'required')
                        && true
                      }
                    />
                  }
                  onChange={(_,data)=>{
                    onChange(data);
                    setResetSubcategoriaValue(data);
                    return data;
                  }}
                />
              }
            /> 
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField 
              InputProps={{
                startAdornment: (<InputAdornment position="start"><ConfirmationNumberIcon style={{color:'#3C1053'}} /></InputAdornment>),
              }}
              {...register("fallaDescripcion", { required: true })}
              label="Describe la falla *"
              variant="outlined"
              sx={{ marginTop: 5,
                width: "90%",
              }} 
              helperText={(errors.fallaDescripcion?.type === 'required' && "Este campo es obligatorio!") }
              error={
                (errors.fallaDescripcion?.type === 'required' )
                && true
              }
            />
          </Grid>

          
        </Grid>

     
        
      </Grid>
        <Button 
            variant="contained"
            color="success" 
            sx={{ marginTop: 5, width: 100, marginLeft: 3 }}
            onClick={handleSubmit(onSubmit)}
        >
            Crear
        </Button>
      <Button variant="contained" color="error" sx={{ marginTop: 5, width: 112, marginLeft: 3 }} onClick={() => SweetAlert.close()}>Cancelar</Button>
    
        </>
    )
}







const GenerateTickectTechniciansModal = () => {
    SweetAlert.fire({
        customClass:"classFormModal",
        title:"Crear Caso",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top",
      allowOutsideClick: true,
      showCloseButton: true,
      html: <GenerateTickectTechnicians />
      
    })
  }

export {GenerateTickectTechniciansModal,GenerateTickectTechnicians}