import React, {useEffect, useState} from 'react'
import {Grid, TextField, Button, Autocomplete, Card, CardMedia} from '@mui/material'
import {TitleFirts} from '../ComponentsUtilies/Titles'
import {useForm} from 'react-hook-form'
import {getCategorys} from '../../services/categoryService'
import {createProducts} from '../../services/productService'
import {AlertSuccess, AlertError} from '../ComponentsUtilies/AlertsModal/Alerts'

export const Create = ({setLoad, employees, close}) => {
  const [category, setCategory] = useState(0)
  const [image, setImage] = useState('')
  const [data2, setData2] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
    setError,
    clearErrors
  } = useForm()

  useEffect(() => {
    try {
      categorysGet()
    } catch (error) {
      AlertError(error.message, error)
    }
  }, [])

  const categorysGet = async () => {
    const res = await getCategorys()

    setCategory(res)
  }

  const onSubmit = (valores) => {
    const DataForm = valores
    const {categoriaIdCategoria, empleadoIdEmpleado} = valores
    const cedula = empleadoIdEmpleado.split('-')[1]
    const employeeFilter = employees.filter((e) => e.cedula == cedula)
    const categoryFilter = category.filter((e) => e.nombre == categoriaIdCategoria)
    DataForm.categoriaIdCategoria = categoryFilter[0].IdCategoria
    DataForm.empleadoIdEmpleado = employeeFilter[0].IdEmpleado
    DataForm.foto = data2
    const config = {
      headers: {'Content-Type': 'multipart/form-data'}
    }

    createProducts(DataForm, config)
      .then((res) => {
        if (res.IdProducto != null) {
          close()
          AlertSuccess()
          setLoad(true)
        } else {
          AlertError()
        }
      })
      .catch((error) => console.error(error))
    reset()
  }

  const encodeFileAsBase64URL = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('loadend', () => {
        resolve(reader.result)
      })
      reader.readAsDataURL(file)
    })
  }

  const uploadImage = async (e) => {
    let archivos = Array.from(e.files)

    const image = await encodeFileAsBase64URL(e.files[0])
    setImage(image)
    setData2(archivos)
  }

  return (
    <>
      {category != null ? (
        <form onSubmit={handleSubmit(onSubmit)} enctype='multipart/form-data'>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <TitleFirts>Agregar articulo</TitleFirts>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4}>
                <Autocomplete
                  disablePortal
                  id='combo-box-demo'
                  onChange={(e) => {
                    clearErrors('marca')
                    clearErrors('serial')
                    clearErrors('placa')
                    if (e.target.textContent != null) {
                      const categoriasTarget = e.target.textContent
                      const findCategorias = category.filter(
                        (e) => e.nombre == categoriasTarget
                      )
                      if (findCategorias != null) {
                        const campos = findCategorias[0].validaciones.validaciones.campos

                        campos.forEach((campo) => {
                          if (campo.nombre == 'marca') {
                            if (campo.requerido == 'si') {
                              setError('marca', {type: 'required'})
                            }
                          } else if (campo.nombre == 'serial') {
                            if (campo.requerido == 'si') {
                              setError('serial', {type: 'required'})
                            }
                          } else if (campo.nombre == 'placa') {
                            if (campo.requerido == 'si') {
                              setError('placa', {type: 'required'})
                            }
                          } else if (campo.nombre == 'capacidad') {
                            if (campo.requerido == 'si') {
                              setError('capacidad', {type: 'required'})
                            }
                          } else if (campo.nombre == 'tipo') {
                            if (campo.requerido == 'si') {
                              setError('tipo', {type: 'required'})
                            }
                          }
                        })
                      }
                    }
                  }}
                  options={
                    category != 0
                      ? category.map((e) => ({label: e.nombre, value: e.IdCategoria}))
                      : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register('categoriaIdCategoria', {required: true})}
                      label='Categoria'
                      helperText={
                        errors.categoriaIdCategoria?.type === 'required' &&
                        'Este campo es obligatorio!'
                      }
                      error={errors.categoriaIdCategoria}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Nombre producto'
                  {...register('nombre', {required: true})}
                  helperText={
                    errors.nombre?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.nombre}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Cantidad'
                  {...register('cantidad', {required: true, pattern: /^([0-9])*$/})}
                  helperText={
                    (errors.cantidad?.type === 'required' &&
                      'Este campo es obligatorio!') ||
                    (errors.cantidad?.type === 'pattern' &&
                      'En este campo son solo permitidas numeros!')
                  }
                  error={
                    (errors.cantidad?.type === 'required' ||
                      errors.cantidad?.type === 'pattern') &&
                    true
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Marca'
                  {...register('marca')}
                  helperText={
                    errors.marca?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.marca}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('marca')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Placa'
                  {...register('placa')}
                  helperText={
                    errors.placa?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.placa}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('placa')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Serial'
                  {...register('serial')}
                  helperText={
                    errors.serial?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.serial}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('serial')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Descripcion'
                  {...register('descripcion')}
                  helperText={
                    errors.descripcion?.type === 'required' &&
                    'Este campo es obligatorio!'
                  }
                  error={errors.descripcion}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('descripcion')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Tipo'
                  {...register('tipo')}
                  helperText={
                    errors.tipo?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.tipo}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('tipo')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id='outlined-basic'
                  label='Capacidad'
                  {...register('capacidad')}
                  helperText={
                    errors.capacidad?.type === 'required' && 'Este campo es obligatorio!'
                  }
                  error={errors.capacidad}
                  onChange={(e) => {
                    if (e.target.value.length != 0) {
                      clearErrors('capacidad')
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Autocomplete
                  disablePortal
                  id='combo-box-demo'
                  options={
                    employees != 0
                      ? employees.map((e) => ({
                          label:
                            e.primerNombre +
                            ' ' +
                            e.primerApellido +
                            ' ' +
                            e.segundoApellido +
                            ' - ' +
                            e.cedula,
                          value: e.IdEmpleado
                        }))
                      : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id='empleadoIdEmpleado'
                      name='empleadoIdEmpleado'
                      {...register('empleadoIdEmpleado', {required: true})}
                      label='Empleado'
                      helperText={
                        errors.empleadoIdEmpleado?.type === 'required' &&
                        'Este campo es obligatorio!'
                      }
                      error={errors.empleadoIdEmpleado}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Card sx={{width: 760, marginLeft: 2, objectFit: 'cover', marginTop: 3}}>
                  <CardMedia
                    component='img'
                    height='330'
                    image={image != '' ? image : ''}
                  />
                </Card>

                <Button
                  variant='contained'
                  component='label'
                  color='secondary'
                  sx={{
                    marginTop: 1,
                    marginLeft: 2,
                    height: 50,
                    float: 'left'
                  }}
                >
                  Varios archivos
                  <input
                    type='file'
                    onChange={(e) => uploadImage(e.target)}
                    hidden
                    multiple
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='success'
            type='submit'
            sx={{marginTop: 5, width: 100, marginLeft: 3}}
          >
            Enviar
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{marginTop: 5, width: 112, marginLeft: 3}}
            onClick={() => close()}
          >
            Cancelar
          </Button>
        </form>
      ) : null}
    </>
  )
}
