import axios from "axios"
import { Buffer } from "buffer";

export const validateLoginDirectory = async(data)=>{
   let auth = "Basic " + Buffer.from("admin" + ":" + "NutresaAxity2022%*").toString("base64");
   const request= await axios.post(`https://r9m9489j99.execute-api.us-east-1.amazonaws.com/validateLoginDirectory/validateLoginDirectory`,{
      user:data.user,
      password:data.password
   },
   /*{
      "headers":{
         "Authorization":auth,
         "Access-Control-Allow-Origin":"*"
      }
   }*/)
   console.log(request)
   const response= await request.data
   return response
}

