
import { ThemeProvider } from '@mui/material/styles';
import { axity } from '../../themes/axity.js';

export const AxityThemeProvider = (props) => {

    return (
        <ThemeProvider theme={axity} {...props}>
            {props.children}
        </ThemeProvider>
    );
}
