import React from 'react'


const Star = ({ size, color, branches, rotation,opacity,position }) => {
    const outerRadius = size / 2;
    const innerRadius = (size * 0.4) / 2;
    const centerX = size / 2;
    const centerY = size / 2;

    const starStyle = {
        width: size,
        height: size,
        borderRadius: '50%',
        position: 'absolute',
        zIndex: '-1', // Pone el círculo detrás de los demás componentes
        ...position,
        opacity: opacity,
        transform: `rotate(${rotation}rad)`
    };
  
    const points = [];
    for (let i = 0; i < branches * 2; i++) {
      const radius = i % 2 === 0 ? outerRadius : innerRadius;
      const angle = (i * Math.PI) / branches + rotation;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      points.push(`${x},${y}`);
    }
  
    const pointsString = points.join(' ');
  
    return (
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={starStyle}>
        <polygon points={pointsString} fill={color} />
      </svg>
    );
};

export default Star;
  