import styled from "styled-components"

export const TitleSecond = styled.h2`
color: #242424;
font-size: "10px";
text-align: center;
font-family: Arial, Helvetica, sans-serif;
margin-left: 25px;
margin-top: 15px;
`

export const TitleFirts = styled.h2`
color: #000;
font-size: "38px";
text-align: center;
font-family: Arial, Helvetica, sans-serif;
margin-left: 25px;
margin-top: 75px;
`

export const TitleLogin = styled.h2`
color: #000;
font-size: "38px";
text-align: center;
font-family: Arial, Helvetica, sans-serif;
margin-left: 25px;
margin-top: 16px;
`

export const TitleTickects = styled.h2`
color: #fff;
font-size: "38px";
text-align: center;
font-family: Arial, Helvetica, sans-serif;
margin-left: 25px;
margin-top: 16px;`