import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getCommitments } from '../../services/commitment'
import { Button, Grid, Typography } from '@mui/material'
import { getAlertModal } from '../../hooks/getAlertModal'
import { List } from './List'
import { Create } from './Create'

export const Commitment = () => {
  const navigate = useNavigate()
  const { employeeId } = useParams()
  const [load, setLoad] = useState(true)
  const [commitments, setCommitments] = useState([])
  const [employed, setEmployed] = useState('')

  useEffect(() => {
    if (load) getCommitments(employeeId)
      .then(data => {
        setCommitments(data.commitments)
        setEmployed(data.employed)
        setLoad(false)
      })
  }, [load])

  return (
    <Grid container>
      <Grid container justifyContent="center"  >
        <Typography color='primary' variant='h4' marginBottom={1}>
          Compromisos
        </Typography>
      </Grid>
      <Grid container justifyContent="center"  >
        <Typography color='primary' variant='h6' marginBottom={1}>
          {employed.nombre}
        </Typography>
      </Grid>
      <Grid container margin={1} spacing={1}>
        <Button
          variant='contained'
          color='success'
          onClick={getAlertModal(Create, { setLoad, employed })}
        >Crear compromiso</Button>
      </Grid>
      <List data={commitments} setLoad={setLoad} employed={employed} />
    </Grid>)
}
